import { useState } from "react";
import { useTheme } from "@emotion/react";
import { Calendar, momentLocalizer } from "react-big-calendar";

import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import LoadingScreen from "../../LoadingScreen";
import useSubmissions from "../hook";
import Toolbar from "./Toolbar";
import Event from "./Event";
import SubmissionPreview from "./SubmissionPreview";

import { PublishContentDialog } from "../../Publish";

import { Box, Typography } from "@mui/material";
const localizer = momentLocalizer(moment);

export default function Scheduler() {
	const [preview, setPreview] = useState(null);
	const [deferUntil, setDeferUntil] = useState(null);
	const theme = useTheme();
	const { page, loading, getPage } = useSubmissions();

	const events = page.map(submission => {
		const start = new Date(parseInt(submission?.deferUntil * 1000));
		const end = new Date(parseInt(submission?.deferUntil * 1000));

		return {
			...submission,
			start,
			end,
			shortTitle: submission.integration.destination,
		};
	});
	const scrollToTime = events[0]?.start || new Date();
	scrollToTime.setHours(scrollToTime.getHours() - 3);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100%",
				position: "relative",
			}}
		>
			<LoadingScreen
				loading={loading}
				absolute
				message={`preparing submissions calendar`}
			/>
			<span
				className="schedule-page-calendar"
				style={{
					// position in center of the page
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					pointerEvents: "none",
				}}
			/>

			{!loading && (
				<Calendar
					selectable
					views={["week", "day", "month"]}
					localizer={localizer}
					events={events}
					startAccessor="start"
					endAccessor="end"
					titleAccessor={"shortTitle"}
					scrollToTime={scrollToTime}
					style={{
						width: "100%",
						// height: 500,
						// maxWidth: "1200px",
						color: theme.palette.text.primary,
						backgroundColor: "transparent",
					}}
					onSelectSlot={slotInfo => {
						setDeferUntil(slotInfo.start);
					}}
					onRangeChange={range => {
						if (!range.start) return;
						getPage({
							after: range.start.getTime(),
							before: range.end.getTime(),
						});
					}}
					onDoubleClickEvent={event => {
						setPreview(event);
					}}
					components={{
						toolbar: Toolbar,
						event: Event,

						month: {
							dateHeader: ({ label, date }) => {
								return (
									<Typography>
										{moment(date).format("D")}
									</Typography>
								);
							},
							header: ({ label, date }) => {
								return <Typography>{label}</Typography>;
							},
						},
						dateCellWrapper: ({ children, range, value }) => {
							return (
								<Box
									sx={{
										border: theme =>
											`1px solid ${theme.palette.divider}`,

										width: "100%",
										height: "100% ",
									}}
								>
									{children}
								</Box>
							);
						},
					}}
				/>
			)}
			{preview && (
				<SubmissionPreview
					submission={preview}
					open={!!preview}
					onClose={() => setPreview(null)}
				/>
			)}

			<PublishContentDialog
				open={!!deferUntil}
				onClose={() => setDeferUntil(null)}
				deferUntil={deferUntil}
				contentIds={[]}
			/>
		</Box>
	);
}
