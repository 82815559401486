import { useContentSearchFilter } from "@/ContentSearch/hooks/useContentSearchFilter";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
export default function ContentSearchRadioGroup({ attribute }) {
	const { options, currentValue, refine } = useContentSearchFilter({
		attribute: attribute,
	});

	return (
		<RadioGroup
			value={currentValue}
			name={`${attribute}-radio-buttons-group`}
			onChange={e => refine(e.target.value)}
		>
			{options.map(option => (
				<FormControlLabel
					key={option.value}
					value={option.value}
					control={<Radio />}
					label={option.label}
				/>
			))}
		</RadioGroup>
	);
}
