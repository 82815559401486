import { useRequester } from "../../../../Apollo";
import { useSnackbar } from "notistack";
import { useState } from "react";
export const useCreateVersion = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { useMutation, definitions } = useRequester();
	const [loading, setIsLoading] = useState(false);
	const [contentVersionCreate] = useMutation(
		definitions.content.mutation.createVersion
	);

	return {
		loading,
		createVersion: async variables => {
			setIsLoading(true);

			await contentVersionCreate({
				variables,

				onCompleted: data => {
					let key = enqueueSnackbar("Version created successfully", {
						variant: "success",
						onClick: () => {
							closeSnackbar(key);
						},
					});
				},
				onError: error => {
					console.log(error);
					let key = enqueueSnackbar("Error creating version", {
						variant: "error",

						onClick: () => {
							closeSnackbar(key);
						},
					});
				},
			});
			setIsLoading(false);
		},
	};
};
