import { createContext, useContext, useEffect } from "react";
import { useIsSSR } from "react-aria";
import logdna from "@logdna/browser";
const CONTEXT = {
	hostname: process.env.REACT_APP_LOGGER_HOSTNAME,
	env: process.env.REACT_APP_LOGGER_ENV,
	app: process.env.REACT_APP_LOGGER_APP,
};

const Context = createContext({
	client: null,
	log: () => {},
	warn: () => {},
	error: () => {},
	setUser: () => {},
});

export const useLogger = () => useContext(Context);
export default function LoggerContext({ children }) {
	// if (process.env.REACT_APP_LOGGER_OFF) return children;
	return <LoggerContextOn>{children}</LoggerContextOn>;
}
function LoggerContextOn({ children }) {
	const isSSR = useIsSSR();
	useEffect(() => {
		if (isSSR) return;
		logdna.init(process.env.REACT_APP_LOGGER_API_KEY);
		logdna.addContext({
			hostname: process.env.REACT_APP_LOGGER_HOSTNAME,
			env: process.env.REACT_APP_LOGGER_ENV,
			app: process.env.REACT_APP_LOGGER_APP,
		});
	}, []);

	return (
		<Context.Provider
			value={{
				client: isSSR ? null : logdna,
				log: args => {
					if (isSSR) return;
					return logdna.log(args);
				},
				warn: args => {
					if (isSSR) return;
					return logdna.warn(args);
				},
				error: args => {
					if (isSSR) return;
					return logdna.error(args);
				},
				setUser: ({ user_id, workspace_id }) => {
					if (isSSR) return;
					return logdna.addContext({
						...CONTEXT,
						user: {
							id: user_id,
							workspace_id,
						},
					});
				},
			}}
		>
			{children}
		</Context.Provider>
	);
}
