import { useReactFlow } from "reactflow";
import SaveIcon from "@mui/icons-material/Save";
import { useRequester } from "../../../../../Apollo";
import {
	Dialog,
	DialogContent,
	DialogContentText,
	TextField,
	FormControl,
	FormControlLabel,
	IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
export default function SaveDialog({ open, onClose }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { getNodes, getEdges } = useReactFlow();
	const { useMutation, definitions } = useRequester();
	const [saveAutomation] = useMutation(
		definitions.automation.mutation.WORKFLOW_CREATE
	);
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent>
				<DialogContentText>Save Automation</DialogContentText>
				<FormControl
					component="form"
					onSubmit={e => {
						e.preventDefault();

						const nodes = getNodes();
						const edges = getEdges();

						// iterate edges and get nodes in order
						const hasSartEdge = edges.find(
							edge => edge.source === "0"
						);
						if (!hasSartEdge) {
							let key = enqueueSnackbar(
								"Automation must connect to a start node",
								{
									variant: "error",
									onClick: () => {
										closeSnackbar(key);
									},
								}
							);
							return;
						}

						const orderedNodes = [
							nodes.find(node => node.id === "0"),
						];
						let nextTarget = hasSartEdge.target;
						do {
							const foundNode = nodes.find(
								node => node.id === nextTarget
							);
							orderedNodes.push(foundNode);

							const edge = edges.find(
								edge => edge.source === nextTarget
							);

							nextTarget = edge?.target;
						} while (nextTarget);

						console.log({ orderedNodes });

						// onClose();
					}}
				>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Name"
						type="text"
						fullWidth
						// required
					/>
					<FormControlLabel
						control={
							<IconButton type="submit">
								<SaveIcon />
							</IconButton>
						}
						label="Save"
					/>
				</FormControl>
			</DialogContent>
		</Dialog>
	);
}
