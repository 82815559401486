import { Grid, Box, Stack } from "@mui/material";
import Search from "./SearchBox";
import Hits from "./Hits";
import FacetsToggler from "./FacetsToggler";
import { RefinementsPanel, RefinementsModal } from "./RefinementsDrawer";
import useLibraryMediaQuery from "./useIsWidescreen";
import Details from "./Details";
import { useLibraryViewContext } from "./Context";
import ImportDropboxButton from "./DropboxImport";
import UploadContentButton from "./Upload";
import OnlyfansImportButton from "../../Component/OnlyfansImport";
import GoogleDrivePicker from "@/GoogleDrive";
import MultiSelectButton from "./MultiSelect";
import { useSelectedContent } from "../../Component/SelectedContentContext";
import Joyride from "../../Router/Routes/Page/Content/Joyride";

const DetailsContainer = () => {
	const { id } = useSelectedContent();
	const { isMD } = useLibraryMediaQuery();
	if (!isMD && id) {
		return (
			<Box
				sx={{
					position: "absolute",
					top: "0",

					zIndex: 1,
					backgroundColor: theme => theme.palette.background.default,
					width: "100%",
					height: "100%",
				}}
			>
				<Joyride />
				<Details />
			</Box>
		);
	}

	if (isMD) {
		return (
			<Box
				sx={{
					height: "100%",
					width: id ? "23rem" : "0",
					transition: "width 0.3s ease-in-out",
					backgroundColor: theme => theme.palette.background.default,
				}}
			>
				<Details />
			</Box>
		);
	}
};

const UploadAndImportContainer = () => {
	const { isMD } = useLibraryMediaQuery();
	if (!isMD) return null;
	return (
		<Stack direction="row">
			<OnlyfansImportButton />
			<GoogleDrivePicker />

			<ImportDropboxButton />

			<UploadContentButton />
		</Stack>
	);
};

const RefinementsDrawerRenderer = () => {
	const { isMD } = useLibraryMediaQuery();
	const { id } = useSelectedContent();

	if (id) return null;
	if (isMD) return null;
	return <RefinementsModal />;
};

export default function View() {
	const { isMD } = useLibraryMediaQuery();
	const { ViewSwitchButton } = useLibraryViewContext();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",

					overflow: "hidden",

					position: "relative",
				}}
			>
				<Grid
					container
					sx={{
						padding: "10px 0",
						display: "flex",
					}}
				>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						{isMD && <ViewSwitchButton />}

						<Box
							className="library-search-box"
							sx={{
								width: "100%",
								flexGrow: 1,
								padding: "0 1rem",
							}}
						>
							<Search
								FacetsToggler={!isMD ? <FacetsToggler /> : null}
							/>
						</Box>
						<MultiSelectButton />
						<UploadAndImportContainer />
						<Box
							sx={{
								display: isMD ? "flex" : "none",
							}}
						>
							{/* <SortBy /> */}
						</Box>
					</Box>
				</Grid>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						position: "relative",
					}}
				>
					{isMD && <RefinementsPanel />}

					<Box
						sx={{
							width: "100%",
							height: "100%",
							position: "relative",
						}}
					>
						{/* <LoadingScreen transparent absolute loading /> */}
						<Hits />
					</Box>
					<DetailsContainer />
				</Box>

				<RefinementsDrawerRenderer />
			</Box>
		</>
	);
}
