import { Button, Grid, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
export default function Hero() {
	const theme = useTheme();

	const navigate = useNavigate();

	return (
		<>
			<Box
				sx={{
					width: "100%",
					backgroundColor: "primary.main",
					backgroundImage:
						/* trianglify.io */
						theme.palette.mode === "light"
							? `url(/hero/light.png)`
							: "url(/hero/dark.png)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					maxHeight: "65vh",
					height: "750px",
					marginBottom: 6,
				}}
			>
				<Grid
					item
					sx={{
						// offset items slightly above center line
						transform: "translateY(-10%)",
					}}
				>
					<Typography
						component="h1"
						sx={{
							color: "primary.contrastText",
							fontWeight: "bold",
							fontSize: "72px",
							textAlign: "center",
							padding: 1,
						}}
					>
						{process.env.REACT_APP_SITE_NAME}
					</Typography>
					<Typography
						component="h2"
						sx={{
							color: "primary.contrastText",
							fontWeight: "bold",
							fontSize: "28px",
							textAlign: "center",
							padding: 1,
						}}
					>
						{process.env.REACT_APP_SITE_TAGLINE}
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-around",
							alignItems: "center",
							marginTop: 3,
						}}
					>
						<Button
							variant="contained"
							sx={{
								backgroundColor: "secondary.main",
								color: "secondary.contrastText",
								fontWeight: "bold",
								fontSize: "18px",
							}}
							onClick={() => navigate("/subscribe")}
						>
							Grow your audience
						</Button>
					</Box>
				</Grid>
			</Box>
		</>
	);
}
