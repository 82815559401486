import { gql } from "@apollo/client";
export const getUsers = gql`
	query GetUsers($input: GetUsersInput) {
		getUsers(input: $input) {
			totalCount
			users {
				apiEnabled
				admin
				city
				createdAt
				country
				email
				emailVerified
				id
				phoneNumber
				state
				additionalStorage
				publicDisplayName
				usedStorage
				tz
				tz_offset
				lastLogin
				subscription {
					expires_at
				}
				workspacesConnection {
					edges {
						cursor
						node {
							id
							name
							createdAt
							chatbotEnabled
						}
					}
				}
			}
		}
	}
`;
export const getUserWorkspaces = gql`
	query GetUserWorkspaces($input: GetUserWorkspacesInput!) {
		getUserWorkspaces(input: $input) {
			workspaces {
				id
				name
				createdAt
				updatedAt
				chatbotEnabled
			}
		}
	}
`;
