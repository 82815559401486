import { useRequester } from "@/Apollo";
import WorkspaceWidgetWrapper from "@/Component/Workspace/Widget/Wrapper";
export default function WorkspaceWidgetStats() {
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.workspace.query.upcomingSubmissions);

	const submissions = data?.workspaceUpcomingSubmissions?.submissions || [];
	return (
		<WorkspaceWidgetWrapper
			title={"Upcoming Submissions"}
			href="/workspace/schedule"
			body={submissions.length}
		/>
	);
}
