import { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import UpdateDialog from ".././../../UpdateDialog";
import { useCookies } from "@/Cookies";
import { useRequester } from "@/Apollo";
export default function RenameAction() {
	const cookies = useCookies();
	const workspace_id = cookies.get("workspace");
	const { definitions, useQuery } = useRequester();
	const { data, loading } = useQuery(
		definitions.workspace.query.getWorkspace,
		{
			variables: {
				id: workspace_id,
			},
		}
	);
	const workspace = data?.workspace;

	const [show, setShow] = useState(false);

	return (
		<>
			<IconButton
				disabled={loading || !workspace?.isAdmin}
				className="workspace-rename-button"
				sx={{
					display: "flex",
					flexDirection: "row",
				}}
				aria-label="Rename"
				disableRipple
				onClick={() => setShow(true)}
			>
				<DriveFileRenameOutlineIcon fontSize="small" />
				{/* <Typography
					color="textPrimary"
					variant="caption"
					sx={{
						textAlign: "center",
					}}
				>
					Rename
				</Typography> */}
			</IconButton>
			<UpdateDialog show={show} handleClose={() => setShow(false)} />
		</>
	);
}
