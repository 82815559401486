import React from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import { useWorkspaceConfig } from "../../Context";
import { LabeledIcon } from "../Common/LabeledIcon/index";

export default function WorkspaceNavigation() {
	const { useLocationValue, views } = useWorkspaceConfig();
	const value = useLocationValue();

	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: "flex",

				alignItems: "center",
				height: "var(--workspace-toolbar-height)",
				width: "100vw",
			}}
		>
			<Tabs
				sx={{
					margin: "auto",
				}}
				allowScrollButtonsMobile
				scrollButtons={true}
				variant="scrollable"
				value={value}
			>
				{views.map(({ label, icon: Icon, path }) => {
					return (
						<Tab
							key={path}
							onClick={() => navigate(path)}
							icon={
								<LabeledIcon
									Icon={Icon}
									label={label}
									path={path}
								/>
							}
							iconPosition="top"
							value={path}
						/>
					);
				})}
			</Tabs>
		</Box>
	);
}
