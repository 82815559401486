import { TextField } from "@mui/material";
import { useContentEditor } from "../../../..";
export default function ProjectTitle() {
	const { updateTitle, projectTitle } = useContentEditor();

	return (
		<TextField
			className="project-title-input"
			value={projectTitle}
			onChange={e => updateTitle(e.target.value)}
		/>
	);
}
