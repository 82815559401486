import { useState } from "react";
import {
	Card,
	CardContent,
	CardHeader,
	Typography,
	CardActionArea,
} from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import ReportDialog from "../../ReportDialog";
import { useParams } from "react-router-dom";
export default function MissingContentCard() {
	const { id } = useParams();
	const [reportDialogOpen, setReportDialogOpen] = useState(false);
	return (
		<>
			<ReportDialog
				open={reportDialogOpen}
				onClose={() => setReportDialogOpen(false)}
				type={"BUG"}
				metadata={{
					contentId: id,
				}}
			/>
			<Card
				sx={{
					width: "fit-content",
					height: "fit-content",
					// position self
					marginX: "auto",
					marginTop: "5%",
				}}
			>
				<CardHeader title={"Content does not exist."} />
				<CardContent>
					<Typography>
						This content was either deleted or you do not have
						permission to view it.
					</Typography>
				</CardContent>
				<CardActionArea
					onClick={() => setReportDialogOpen(true)}
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						paddingX: 2,
						paddingY: 1,
					}}
				>
					<Typography
						sx={{
							marginRight: 1,
						}}
					>
						Report this issue
					</Typography>
					<ReportIcon />
				</CardActionArea>
			</Card>
		</>
	);
}
