import { PropTypes } from "prop-types";
import LoadingScreen from "../Component/LoadingScreen";
import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LayoutSPA from "./SPA";
import LayoutStandard from "./Standard";
import { Box } from "@mui/material";

const defaultVariant = "standard";
const Context = createContext({
	variant: defaultVariant,
	setVariant: () => {},
	currentVariant: defaultVariant,
});

export const useLayoutVariant = ({ variant }) => {
	const { setVariant, variant: defaultVariant } = useContext(Context);

	return {
		setVariant,
		variant,
		defaultVariant,
	};
};
useLayoutVariant.propTypes = {
	variant: PropTypes.oneOf(["standard", "SPA"]),
};

/**
 * @description use to create a full screen component
 */
export const AppHeight = ({
	children,
	debug = false,
	overflowY = "scroll",
	...other
}) => {
	return (
		<div
			style={{
				height: `var(--app-height)`,
				border: debug ? "1px solid red" : "none",
				overflowY,
				...other,
			}}
		>
			{children}
		</div>
	);
};

/**
 * @description Using this component without layout set to SPA will cause some extra padding to be added to the bottom of the page.
 */
export const BodyHeight = ({
	children,
	debug = false,
	overflowY = "scroll",
	sx,
	...other
}) => {
	return (
		<Box
			sx={{
				height: `var(--body-height)`,
				border: debug ? "1px solid red" : "none",
				overflowY,
				...sx,
			}}
			{...other}
		>
			{children}
		</Box>
	);
};
export const WorkspaceWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				overflowY: "scroll",

				overflowX: "hidden",

				padding: "1rem",
			}}
		>
			{children}
		</Box>
	);
};

export default function LayoutProvider({ children, variant = "standard" }) {
	const location = useLocation();

	const [currentVariant, setCurrentVariant] = useState(variant);

	const [locationMap, setLocationMap] = useState({});
	const [loading, setLoading] = useState(false);

	const handleSetVariant = variant => {
		setLoading(true);
		// Create a new locationMap with the new variant for the current path
		const newLocationMap = {
			...locationMap,
			[location.pathname]: variant,
		};

		// Check for mismatches between newLocationMap and locationMap
		const mismatches = Object.entries(newLocationMap).reduce(
			(acc, [key, value]) => {
				if (value !== locationMap[key]) acc++;
				return acc;
			},
			0
		);
		// if there are no mismatches, return
		if (mismatches === 0) return;

		setLocationMap(newLocationMap);
		setCurrentVariant(variant);
		setLoading(false);
	};

	const layouts = {
		standard: LayoutStandard,
		SPA: LayoutSPA,
	};

	// const Layout =
	// 	layouts[
	// 		locationMap[location.pathname] || currentVariant || defaultVariant
	// 	];

	return (
		<Context.Provider
			value={{
				variant: currentVariant,
				setVariant: handleSetVariant,
				currentVariant: currentVariant,
			}}
		>
			<LoadingScreen loading={loading} message={`setting selected theme`}>
				<LayoutSPA>{children}</LayoutSPA>
			</LoadingScreen>
		</Context.Provider>
	);
}

LayoutProvider.propTypes = {
	children: PropTypes.node.isRequired,
	variant: PropTypes.oneOf(["standard", "SPA"]),
};
