import { useEffect } from "react";
import { usePublishContentForm } from "../../..";
import { useRequester } from "../../../../../Apollo";
export default function useAddContent() {
	const { addContent, content, defaultContentId } = usePublishContentForm();
	const { definitions, useLazyQuery } = useRequester();
	const [getContent, { loading }] = useLazyQuery(
		definitions.content.query.getContent
	);
	useEffect(() => {
		if (!defaultContentId) return;
		if (content?.id === defaultContentId) return;
		if (content.find(({ id }) => id == defaultContentId)) return;
		handleAddcontent({ id: defaultContentId });
	}, [defaultContentId]);

	const handleAddcontent = async ({ id } = {}) => {
		if (!id) return;
		const response = await getContent({
			variables: {
				id,
			},
		});

		addContent(response.data.content);
	};
	return {
		loading,
		content,
		addContent: handleAddcontent,
		resetDefaultContent: () => handleAddcontent({ id: defaultContentId }),
	};
}
