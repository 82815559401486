import {
	Tooltip,
	Box,
	Stack,
	List,
	ListItem,
	Divider,
	ListItemText,
	ListItemAvatar,
	DialogTitle,
	IconButton,
	DialogActions,
	Container,
} from "@mui/material";
import EyeIcon from "@mui/icons-material/Visibility";
import EyeShutIcon from "@mui/icons-material/VisibilityOff";

import AcknowledgeIcon from "@mui/icons-material/ChecklistRtl";

import FHTooltip from "../../FHTooltip";
import LinkIcon from "@mui/icons-material/Link";

import Thumbnail from "./Thumbnail";
import FilterIcon from "@mui/icons-material/FilterList";

import { useEffect, useRef, useState } from "react";

import { useNotifications } from "@/Notifications";
import { useNavigate } from "react-router-dom";
import WorkspaceLogsEntityTypeFilter from "./Filter/EntityType";
import WorkspaceLogsLevelFilter from "./Filter/Level";

const buildLink = notification => {
	// import, integration, submission, content
	try {
		switch (notification.entity_type) {
			case "import": {
				if (!notification.import?.id) break;
				return `/workspace/import/${notification.import.id}`;
			}

			case "integration": {
				return `/workspace/integrations?destination=${notification.integration.destination}`;
			}
			case "content": {
				return `/workspace/content/${notification.content.id}`;
			}

			case "submission": {
				if (!notification.submission?.id) break;
				return `/workspace/submission/${notification.submission.id}`;
			}
			case "match": {
				return `/workspace/match/${notification.match.source.hash}`;
			}
			default: {
				break;
			}
		}
	} catch (e) {
		return;
	}
};

const IntersectionObserver = () => {
	const observer = useRef(null);
	const lastElementRef = useRef(null);
	const { loading, getNextPage } = useNotifications();
	useEffect(() => {
		if (observer.current) observer.current.disconnect();
		observer.current = new window.IntersectionObserver(entries => {
			if (loading) return;

			if (entries[0].isIntersecting) {
				getNextPage();
			}
		});
		observer?.current.observe(lastElementRef.current);
	}, [lastElementRef.current]);
	return <div ref={lastElementRef} />;
};

const NotificationListItem = ({ notification }) => {
	const navigate = useNavigate();

	const { acknowledgeNotification } = useNotifications();

	const onClick = () => acknowledgeNotification(notification);

	const shouldOnClick = !notification.acknowledged_at;
	const link = buildLink(notification);
	return (
		<>
			<ListItem
				onClick={onClick}
				sx={{
					position: "relative",
					width: "100%",
					// height: "75px",
					height: "fit-content",
					// highlight

					backgroundColor: theme => {
						return (
							notification.acknowledged_at ??
							theme.palette.action.hover
						);
					},

					borderLeft: theme => {
						let color;
						switch (notification.level) {
							case "SUCCESS": {
								color = theme.palette.success.main;
								break;
							}
							case "ERROR": {
								color = theme.palette.error.main;
								break;
							}
							case "WARNING": {
								color = theme.palette.warning.main;
								break;
							}
							case "INFO": {
								color = theme.palette.info.main;
								break;
							}
							case "DEBUG": {
								color = theme.palette.grey[500];
								break;
							}
							case "ADMIN": {
								// gold
								color = "#FFD700";
								break;
							}
							default: {
								color = theme.palette.grey[500];
								break;
							}
						}

						return `4px solid ${color}`;
					},
				}}
				{...(shouldOnClick && { onClick })}
				secondaryAction={
					<Stack direction="row">
						<FHTooltip
							title={
								link ? "Navigate to url" : "No url available"
							}
						>
							<IconButton
								disabled={!link}
								onClick={() => navigate(link)}
							>
								<LinkIcon />
							</IconButton>
						</FHTooltip>
					</Stack>
				}
			>
				<ListItemAvatar
					sx={{
						marginRight: 2,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",

						objectFit: "contain",
						marginLeft: 0,

						width: "fit-content",
						height: "75px",
					}}
				>
					<Thumbnail notification={notification} />
				</ListItemAvatar>

				<ListItemText
					sx={{
						width: "100%",
						textOverflow: "ellipsis",
					}}
					primary={
						`${new Date(
							notification?.timestamp
						).toLocaleString()} - ` + notification?.subject
					}
					secondary={notification?.body}
				/>
			</ListItem>
			<Divider />
		</>
	);
};
export default function NotificationsDataGrid() {
	const {
		notifications,
		toggleShowAcknowledged,
		showAcknowledged,
		acknowledgeAllNotifications,
		loading,
	} = useNotifications();

	const [showingFilters, setShowingFilters] = useState(false);
	const toggleShowingFilters = () => setShowingFilters(prev => !prev);

	return (
		<Box
			sx={{
				height: "100%",
				overflow: "auto",
			}}
		>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					height: "var(--workspace-toolbar-height)",
					position: "sticky",
					top: 0,
					backgroundColor: "background.paper",
					zIndex: 1,
					paddingRight: 0,
				}}
			>
				Logs
				<Stack direction={"row"}>
					<DialogActions>
						<IconButton onClick={acknowledgeAllNotifications}>
							<Tooltip title="Acknowledge all">
								<AcknowledgeIcon />
							</Tooltip>
						</IconButton>
						<IconButton
							onClick={toggleShowAcknowledged}
							sx={{
								color: !showAcknowledged
									? "text.disabled"
									: "text.primary",
							}}
						>
							<Tooltip
								title={
									!showAcknowledged
										? "Show acknowledged"
										: "Hide acknowledged"
								}
							>
								{!showAcknowledged ? (
									<EyeShutIcon />
								) : (
									<EyeIcon />
								)}
							</Tooltip>
						</IconButton>
						<IconButton>
							<Tooltip title="Filters">
								<FilterIcon onClick={toggleShowingFilters} />
							</Tooltip>
						</IconButton>
					</DialogActions>
				</Stack>
			</DialogTitle>
			<Box
				sx={{
					justifyContent: "flex-end",
					alignItems: "center",
					position: "sticky",
					top: `var(--workspace-toolbar-height)`,
					backgroundColor: theme => theme.palette.primary.main,
					zIndex: 1,
					display: showingFilters ? "flex" : "none",
					height: showingFilters
						? "var(--workspace-toolbar-height)"
						: 0,
				}}
			>
				<Stack
					direction="row"
					spacing={2}
					sx={{
						padding: 1,
						width: "100%",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<WorkspaceLogsEntityTypeFilter />
					<WorkspaceLogsLevelFilter />
				</Stack>
			</Box>
			<List
				sx={{
					position: "relative",
					paddingTop: 0,
				}}
			>
				{!notifications.length && (
					<>
						<ListItem>
							<ListItemText
								primary={"No new log entries."}
								secondary={"You're all caught up!"}
							/>
						</ListItem>
						<Divider />
					</>
				)}
				{notifications.map((notification, i) => {
					return (
						<NotificationListItem
							key={i}
							notification={notification}
						/>
					);
				})}

				{!loading && <IntersectionObserver />}
			</List>
		</Box>
	);
}
