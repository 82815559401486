import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Avatar,
	Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { makeCDNPath } from "@/util/makeCDNPath";
import { useRequester } from "../../../Apollo";
import { useSnackbar } from "notistack";
import { useIsSSR } from "react-aria";
import { useAuth0 } from "@auth0/auth0-react";
function RequestAlert() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useMutation, useQuery, definitions, useSubscription } =
		useRequester();
	const { client } = useQuery(definitions.virtualAssistant.query.requests, {
		onCompleted: data => {
			const requests = data.virtualAssistantRequests.requests;
			if (!requests.length) return;
			setAssistantRequest(requests[0]);
		},
	});
	const [acceptInvitation] = useMutation(
		definitions.virtualAssistant.mutation.acceptInvitation
	);
	const [rejectInvitation] = useMutation(
		definitions.virtualAssistant.mutation.rejectInvitation
	);
	const [assistantRequest, setAssistantRequest] = useState(null);
	useSubscription(
		definitions.virtualAssistant.subscription.workspaceInvitationReceived,
		{
			shouldResubscribe: true,
			onError: error => {
				console.log("Error getting invitations", { error });
			},
			onData: ({ data }) => {
				const assistant =
					data.data.workspaceInvitationReceived.assistant;

				setAssistantRequest(assistant);
			},
		}
	);

	useSubscription(
		definitions.virtualAssistant.subscription.assistantJoinedWorkspace,
		{
			shouldResubscribe: true,
			onError: error => {
				console.log("Error getting invitations", { error });
			},
			onData: ({ assistantJoinedWorkspace }) => {
				client.refetchQueries({
					include: [definitions.virtualAssistant.query.assistants],
				});
			},
		}
	);

	const Request = ({ request }) => {
		const handleInvitation = async ({ workspace, action }) => {
			const onError = error => {
				console.log({ error });
				const key = enqueueSnackbar(
					"Unable to process invitation. Please try again later.",
					{
						variant: "error",
						onClick: () => closeSnackbar(key),
					}
				);
			};

			if (action === "ACCEPT") {
				await acceptInvitation({
					variables: {
						input: {
							workspace,
						},
					},
					onError,
				});
			} else {
				await rejectInvitation({
					variables: {
						input: {
							workspace,
						},
					},
					onError,
				});
			}

			setAssistantRequest(null);
		};
		const avatar = makeCDNPath(`avatar/workspace/${request.workspace_id}`);
		const name = request.workspace_name;
		return (
			<DialogContent key={request.workspace_id}>
				<DialogTitle>You've received an invitation</DialogTitle>
				<DialogContent>
					<Avatar
						src={avatar}
						sx={{
							width: 100,
							height: 100,
							margin: "auto",
							marginBottom: 2,
						}}
					/>
					<Typography
						sx={{
							textAlign: "center",
							marginBottom: 2,
						}}
					>
						{name}
					</Typography>
					<Typography>
						has invited you to join their workspace.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						onClick={() => {
							handleInvitation({
								workspace: request.workspace_id,
								action: "REJECT",
							});
						}}
					>
						Decline
					</Button>
					<Button
						variant="contained"
						onClick={async () => {
							await handleInvitation({
								workspace: request.workspace_id,
								action: "ACCEPT",
							});
						}}
					>
						Accept
					</Button>
				</DialogActions>
			</DialogContent>
		);
	};
	return (
		<Dialog open={!!assistantRequest}>
			{assistantRequest && <Request request={assistantRequest} />}
		</Dialog>
	);
}

export default function VirtualAssistantRequest() {
	const isSSR = useIsSSR();
	const { isAuthenticated } = useAuth0();
	if (isSSR || !isAuthenticated) return;
	return <RequestAlert />;
}
