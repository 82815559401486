import { useRequester } from "@/Apollo";
import SubscriptionTierCard from "@/Component/SubscriptionTier/Card";
import { useSnackbar } from "notistack";
import {
	Grid,
	useMediaQuery,
	Box,
	ImageList,
	Switch,
	InputLabel,
	Typography,
	Link,
} from "@mui/material";
import LoadingScreen from "@/Component/LoadingScreen";
import { BodyHeight } from "@/Layout";

import { useEffect, useState } from "react";
export default function SubscribePage() {
	const [interval, setSelectedInterval] = useState("month");
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const md = useMediaQuery(theme => theme.breakpoints.up("md"));
	const { definitions, useQuery } = useRequester();

	const { data, loading: productsLoading } = useQuery(
		definitions.billing.query.listProducts,
		{
			onError: error => {
				enqueueSnackbar("Error fetching subscription plans", {
					variant: "error",
					autoHideDuration: 5000,

					onClick: () => {
						closeSnackbar();
					},
				});
			},
		}
	);

	const { data: mySubscriptionData, loading: mySubscriptionLoading } =
		useQuery(definitions.billing.query.getMySubscription, {
			onError: error => {
				enqueueSnackbar("Error fetching subscription plans", {
					variant: "error",
					autoHideDuration: 5000,

					onClick: () => closeSnackbar(),
				});
			},
		});

	useEffect(() => {
		if (!mySubscriptionData) return;
		const selectedPlan =
			mySubscriptionData.mySubscription.product.plans.find(
				plan => plan.selected
			);
		setSelectedInterval(selectedPlan.interval);
	}, [mySubscriptionData]);

	const products = data?.listProducts || [];

	if (!mySubscriptionData) return null;

	return (
		<BodyHeight
			sx={{
				position: "relative",
			}}
		>
			<LoadingScreen
				loading={productsLoading || mySubscriptionLoading}
				absolute
				message={`fetching subscription plans`}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginY: 2,
					}}
				>
					<InputLabel
						onClick={() => {
							setSelectedInterval("month");
						}}
					>
						Monthly
					</InputLabel>
					<Switch
						checked={interval === "year"}
						onChange={() => {
							setSelectedInterval(
								interval === "year" ? "month" : "year"
							);
						}}
					/>
					<InputLabel
						onClick={() => {
							setSelectedInterval("year");
						}}
					>
						Yearly
					</InputLabel>
				</Box>

				<ImageList
					sx={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{products.map((product, i) => {
						if (
							!product.plans.find(
								plan => plan.interval === interval
							)
						)
							return null;
						return (
							<Grid
								key={i}
								item
								xs={6}
								md={3}
								sx={{
									display: "flex",
									justifyContent: "center",
									margin: md ? "4px" : "0px",
									marginY: 2,
								}}
							>
								<SubscriptionTierCard
									key={i}
									product={product}
									plan={product.plans.find(
										plan => plan.interval === interval
									)}
									mySubscription={
										mySubscriptionData.mySubscription
									}
								/>
							</Grid>
						);
					})}
				</ImageList>

				<Typography variant="caption" color="text.secondary">
					Render hours and submissions reset every month regardless of
					billing interval. Storage is a fixed amount.
					<Link
						href="mailto:contact@fanheat.com"
						sx={{
							color: "inherit",
							textDecoration: "underline",
							marginX: 0.25,
						}}
					>
						Contact us
					</Link>
					for enterprise pricing.
				</Typography>
			</LoadingScreen>
		</BodyHeight>
	);
}
