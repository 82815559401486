import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useState } from "react";
export default function Icon() {
	const [hasMail, setHasMail] = useState(false);
	return (
		<IconButton onClick={() => {}}>
			{hasMail ? <MailIcon /> : <DraftsIcon />}
		</IconButton>
	);
}
