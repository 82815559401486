import { useIsSSR } from "react-aria";
import { useEffect, createContext, useContext } from "react";
import mixpanel from "mixpanel-browser";
const Context = createContext({
	track: ({ eventName, eventData }) => {},
	setUser: ({ email }) => {},
	resetUser: () => {},
	setReferralCode: ({ referralCode }) => {},
	getReferralCode: () => {},
});

export const useMixpanel = () => useContext(Context);

export default function AnalyticsContextProvider({ children }) {
	const isSSR = useIsSSR();

	useEffect(() => {
		if (isSSR) return;
		mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
			// debug: true,
			track_pageview: true,
			persistence: "localStorage",
		});
	}, [isSSR]);
	return (
		<Context.Provider
			value={{
				track: ({ eventName, eventData = {} }) => {
					return mixpanel.track(eventName, {
						...eventData,
					});
				},
				setUser: ({ email }) => {
					return mixpanel.identify(email);
				},
				resetUser: () => {
					return mixpanel.reset();
				},
				setReferralCode: ({ referralCode }) => {
					return mixpanel.register_once({ referralCode });
				},
				getReferralCode: () => {
					return mixpanel.get_property("referralCode");
				},
			}}
		>
			{children}
		</Context.Provider>
	);
}
