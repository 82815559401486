import Title from "./Title";
import NewProjectbutton from "./NewProjectButton";
import CreateContentButton from "./RenderContentButton";
import SaveProjectButton from "./SaveProjectButton";
import ProjectsList from "./List";
import { Stack, Box } from "@mui/material";
import QualityOption from "./Options/Quality";
import ResolutionOption from "./Options/Resolution";
import FPSOption from "./Options/FPS";
export default function ContentEditorProjectView() {
	return (
		<Stack
			spacing={3}
			direction="column"
			sx={{
				paddingTop: ".4rem",
				paddingX: ".4rem",
			}}
		>
			<Title />
			<Box
				flexGrow={1}
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<Stack direction="row" spacing={2}>
					<NewProjectbutton />
					<CreateContentButton />
					<SaveProjectButton />
				</Stack>
			</Box>
			<QualityOption />
			<ResolutionOption />
			<FPSOption />
			<ProjectsList />
		</Stack>
	);
}
