import { useContext, createContext, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
const Context = createContext({
	changes: 0,
	incrementChanges: () => {},
	decrementChanges: () => {},
});

export const useCanNavigateAway = () => useContext(Context);

export default function CanNavigateAwayContext({ children }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [changes, setChanges] = useState(0);
	useEffect(() => {
		let key;
		let show = () => {
			key = enqueueSnackbar(
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						height: "4rem",
					}}
				>
					Save changes before leaving this page.
				</Box>,
				{
					variant: "warning",
					persist: true,
				}
			);
		};

		let hide = () => {
			if (!key) return;
			closeSnackbar(key);
		};
		if (changes > 0 && !key) {
			show();
		} else {
			hide();
		}
		return () => {
			hide();
		};
	}, [changes]);

	return (
		<Context.Provider
			value={{
				changes,
				incrementChanges: () => {
					setChanges(changes + 1);
				},
				decrementChanges: () => {
					if (changes === 0) return;
					setChanges(changes - 1);
				},
			}}
		>
			{children}
		</Context.Provider>
	);
}
