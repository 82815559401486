import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewIcon from "@mui/icons-material/Visibility";
import NoBotIcon from "@mui/icons-material/Android";
import BotIcon from "@mui/icons-material/Adb";
import { DataGrid } from "@mui/x-data-grid";
import useSetChatbotEnabled from "../Users/Hook/useSetChatbotEnabled";
import { useRequester } from "@/Apollo";

const Cell = ({ value }) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "100%",
				objectFit: "contain",
			}}
		>
			{value}
		</Box>
	);
};
export default function Workspaces({ userId, onClose }) {
	console.log("showing workspaces for user: ", userId);
	const { useQuery, definitions, selectWorkspace } = useRequester();
	const { data, loading } = useQuery(
		definitions.admin.query.getUserWorkspaces,
		{
			variables: {
				input: {
					userId,
				},
			},
		}
	);
	const { setChatbotEnabled } = useSetChatbotEnabled();

	return (
		<Box sx={{ height: "100%", width: "100%", position: "relative" }}>
			<IconButton
				sx={{
					position: "absolute",
					top: 0,
					right: 0,
					zIndex: 1000,
				}}
				onClick={onClose}
			>
				<CloseIcon />
			</IconButton>

			<DataGrid
				loading={loading}
				rows={(data?.getUserWorkspaces.workspaces || []).map(
					workspace => {
						return {
							...workspace,
							view: workspace.id,
						};
					}
				)}
				onCellDoubleClick={params => {
					switch (params.field) {
						case "chatbotEnabled":
							setChatbotEnabled({
								enabled: !params.value,
								workspaceId: params.id,
							});
							break;
						default:
							break;
					}
				}}
				columns={[
					{ field: "id", headerName: "ID", width: 70 },
					{
						field: "view",
						headerName: "View",
						width: 130,
						renderCell: ({ value }) => {
							return (
								<IconButton
									onClick={() => {
										onClose();
										console.log(
											"admin select workspace: ",
											value
										);
										selectWorkspace({
											workspace: {
												id: value,
											},
										});
									}}
								>
									<ViewIcon />
								</IconButton>
							);
						},
					},
					{ field: "name", headerName: "Name", width: 130 },
					{
						field: "createdAt",
						headerName: "Created At",
						width: 130,
						renderCell: ({ value }) => {
							const date = new Date(value);
							return <Cell value={date.toLocaleDateString()} />;
						},
					},
					{
						field: "chatbotEnabled",
						headerName: "Chatbot Enabled",
						width: 130,
						renderCell: ({ value }) => (
							<Cell
								value={
									value ? (
										<BotIcon sx={{ color: "green" }} />
									) : (
										<NoBotIcon sx={{ color: "red" }} />
									)
								}
							/>
						),
					},
				]}
				disableSelectionOnClick
			/>
		</Box>
	);
}
