import { createTheme, ThemeProvider as Provider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import { red } from "@mui/material/colors";
import { createContext, useContext, useState } from "react";
import useCSSProps from "./useCSSProps";
import ThemeColor from "./ThemeColor";
import { useCookies } from "../Cookies";
import "./styles.css";

export { DarkModeSwitch } from "./DarkModeSwitch";

const DEFAULT_THEME = "dark";

const lightModeTheme = createTheme({
	// components: {
	// 	MuiDialog: {
	// 		styleOverrides: {
	// 			root: {
	// 				// Increase the zIndex of the dialog
	// 				zIndex: 1300, // Adjust the value as needed
	// 			},
	// 		},
	// 	},
	// 	MuiInputLabel: {
	// 		styleOverrides: {
	// 			root: {
	// 				// dark
	// 				color: "#212A3E !important",
	// 			},
	// 		},
	// 	},
	// 	MuiFormLabel: {
	// 		styleOverrides: {
	// 			root: {
	// 				color: "#212A3E !important",
	// 			},
	// 		},
	// 	},
	// 	MuiFormControlLabel: {
	// 		styleOverrides: {
	// 			root: {
	// 				color: "#212A3E !important",
	// 			},
	// 		},
	// 	},
	// 	// card header title
	// 	CardHeaderTitle: {
	// 		styleOverrides: {
	// 			title: "1.2rem !important",
	// 		},
	// 	},
	// 	MuiCardHeader: {
	// 		styleOverrides: {
	// 			title: {
	// 				color: "#212A3E !important",
	// 				// font size
	// 				fontSize: "1.2rem !important",
	// 			},
	// 			subheader: {
	// 				// black
	// 				color: "#212A3E !important",
	// 			},
	// 		},
	// 	},
	// },
	// palette: {
	// 	mode: "light",
	// 	primary: {
	// 		main: "#394867", // dark blue
	// 		secondary: "#212A3E", // dark grey
	// 	},
	// 	secondary: {
	// 		main: "#9BA4B5", // light grey
	// 		contrastText: "#fff",
	// 	},
	// 	text: {
	// 		primary: "#212A3E", // dark grey
	// 		secondary: "#F1F6F9", // light grey
	// 	},
	// 	background: {
	// 		default: "#F1F6F9", // light grey
	// 	},
	// 	error: {
	// 		main: red.A400,
	// 	},
	// 	warning: {
	// 		main: "#FFC107",
	// 	},
	// },
	// typography: {
	// 	fontFamily: "Roboto, sans-serif",
	// },
});
const darkModeTheme = createTheme({
	components: {
		MuiTab: {
			styleOverrides: {
				root: {
					// active tab color
					"&.Mui-selected": {
						color: "#fff !important",
					},
					// inactive tab color
					color: "#9BA4B5 !important",
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					// Increase the zIndex of the dialog
					zIndex: 1300, // Adjust the value as needed
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#fff !important",
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: "#fff !important",
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					color: "#fff !important",
				},
			},
		},
	},
	typography: {
		fontFamily: "Gotham, sans-serif",
	},
	palette: {
		mode: "dark",
		text: {
			primary: "#fff",
			secondary: "#fff",
		},
		background: {
			default: "#2f2f2f",
		},

		primary: {
			// dark mode secondary darker greyish
			main: "#121212",
		},
		secondary: {
			main: "#19857b",
		},
		error: {
			main: red.A400,
		},
	},
});

const AppThemeContext = createContext({
	toggleDarkMode: () => {},
	mode: DEFAULT_THEME,
});
export const useDarkMode = () => useContext(AppThemeContext);

const CSSPropsSetter = ({ theme }) => {
	useCSSProps();

	return <></>;
};

export default function ThemeContextProvider({ children }) {
	const cookies = useCookies();
	const getDeviceTheme = () => {
		if (typeof window === "undefined") return null;
		if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
			return "dark";
		}
		if (window.matchMedia("(prefers-color-scheme: light)").matches) {
			return "light";
		}
		return null;
	};
	const storeSelectedTheme = theme => {
		cookies.set("theme", theme);
	};
	const recallStoredTheme = () => {
		let theme = cookies.get("theme");
		// if (!theme) theme = getDeviceTheme();
		if (!theme) theme = DEFAULT_THEME;
		cookies.set("theme", theme);
		return theme;
	};
	const [theme, setTheme] = useState(recallStoredTheme());

	const handleSetTheme = theme => {
		setTheme(theme);
		storeSelectedTheme(theme);
	};

	const toggleDarkMode = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		handleSetTheme(newTheme);
	};

	return (
		<AppThemeContext.Provider
			value={{
				mode: theme,
				toggleDarkMode,
				setDarkMode: handleSetTheme,
			}}
		>
			<Provider
				theme={theme === "light" ? lightModeTheme : darkModeTheme}
				// theme={darkModeTheme}
			>
				<CssBaseline />
				<CSSPropsSetter />
				<ThemeColor />
				{children}
			</Provider>
		</AppThemeContext.Provider>
	);
}
