import { useContentSearch } from "@/ContentSearch/Context";

export const useSearchStats = () => {
	const { page, totalCount, totalPages, query, responseTimeMS } =
		useContentSearch();

	return {
		page,
		totalCount,
		totalPages,
		query,
		responseTimeMS,
	};
};
