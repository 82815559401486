import { Box, Typography } from "@mui/material";
import { useWorkspaceConfig } from "../../../Context";
export const LabeledIcon = ({ label, Icon, path }) => {
	const { useLocationValue } = useWorkspaceConfig();
	const value = useLocationValue();
	if (value !== path) return <Icon />;
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				position: "relative",
			}}
		>
			<Icon />
			<Typography
				sx={{
					position: "absolute",
					bottom: 0,
					left: "50%",
					transform: "translateX(-50%)",
					fontSize: "0.75rem",
					fontWeight: "bold",
					color: theme => theme.palette.info.main,
				}}
			>
				{label}
			</Typography>
		</Box>
	);
};
