import { useState, useRef, useEffect } from "react";
import { Typography, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IsEditingIcon from "@mui/icons-material/BorderColor";
import { useRequester } from "@/Apollo";
import { useSnackbar } from "notistack";
export default function EditableDisplayName() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const inputRef = useRef();
	const { useMutation, definitions, useQuery } = useRequester();
	const { data, loading } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "cache-first",
	});
	const [updateDisplayName] = useMutation(
		definitions.user.muation.updatePublicDisplayName
	);
	const [editing, setEditing] = useState(false);
	useEffect(() => {
		if (!editing) return;
		inputRef.current.focus();
	}, [editing]);
	const handleUpdate = async () => {
		const name = inputRef.current.value;
		if (name === data?.user.publicDisplayName) return;
		if (name === "") return;
		await updateDisplayName({
			variables: {
				input: {
					name,
				},
			},
			onError: e => {
				const key = enqueueSnackbar(e.message, {
					variant: "error",
					onClick: () => closeSnackbar(key),
				});
			},
			onCompleted: data => {
				inputRef.current.value =
					data.publicDisplayName.publicDisplayName;
				setEditing(false);
			},
		});
	};
	return (
		<Typography
			component={"form"}
			onSubmit={e => {
				e.preventDefault();
				handleUpdate();
			}}
			color="textPrimary"
			variant="h6"
			fontWeight={"regular"}
		>
			<IconButton
				onClick={() => {
					setEditing(prev => !prev);
				}}
			>
				{editing ? <IsEditingIcon /> : <EditIcon />}
			</IconButton>

			<TextField
				inputRef={inputRef}
				onBlur={handleUpdate}
				variant="standard"
				// not editable
				InputProps={{
					readOnly: !editing,
					defaultValue: data?.user.publicDisplayName,
					startAdornment: "@",
				}}
				inputProps={{
					maxLength: 8,
				}}
				sx={{
					// remove underline
					"& .MuiInput-underline:before": {
						display: "none",
					},
					// placeholder color
					"& .MuiInputBase-input::placeholder": {
						color: "inherit",
					},
				}}
				placeholder={data?.user.publicDisplayName}
			/>
		</Typography>
	);
}
