import HomeIcon from "@mui/icons-material/Home";
import FoldersIcon from "@mui/icons-material/Folder";
import LibraryIcon from "@mui/icons-material/LibraryBooks";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import CameraIcon from "@mui/icons-material/Camera";
import LogsIcon from "@mui/icons-material/History";
import AutomationIcon from "../../Navigation/Common/AutomationsIcon";
import IntegrationsIcon from "../../Navigation/Common/IntegrationsIcon";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ShareIcon from "@mui/icons-material/Share";
import AssistantsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNotifications } from "@/Notifications";
import { Badge } from "@mui/material";
import { useLocation } from "react-router-dom";

const BadgeIconLogs = props => {
	const { countNew } = useNotifications();
	return (
		<Badge badgeContent={countNew}>
			<LogsIcon {...props} />
		</Badge>
	);
};
export const views = [
	{
		label: "Home",
		icon: HomeIcon,
		path: "/workspace/home",
	},
	{
		label: "Library",
		icon: LibraryIcon,
		path: "/workspace/library",
	},
	{
		label: "Folders",
		icon: FoldersIcon,
		path: "/workspace/library/folders",
	},
	{
		label: "Schedule",
		icon: CalendarIcon,
		path: "/workspace/schedule",
	},
	// {
	// 	label: "Automations",
	// 	icon: AutomationIcon,
	// 	path: "/workspace/automations",
	// },
	{
		label: "Editor",
		icon: CameraIcon,
		path: "/workspace/editor",
	},
	{
		label: "Logs",
		icon: BadgeIconLogs,
		path: "/workspace/logs",
	},
	{
		label: "Assistants",
		icon: ManageAccountsIcon,
		path: "/workspace/assistants",
	},
	{
		label: "Integrations",
		icon: ShareIcon,
		path: "/workspace/integrations",
	},
	{
		label: "Settings",
		icon: SettingsIcon,
		path: "/workspace/settings",
	},
];
const getValue = location => {
	if (
		location.pathname === "/workspace/home" ||
		location.pathname === "/workspace" ||
		location.pathname === "/workspace/" ||
		location.pathname.startsWith("/workspace/match")
	)
		return "/workspace/home";
	if (views.find(({ path }) => path === location.pathname)) {
		return location.pathname;
	}
	return false;
};
export const useLocationValue = () => {
	const location = useLocation();

	return getValue(location);
};
