import { Stack, Typography } from "@mui/material";
import Integrations from "@/Component/Integration/Destinations";
import { WorkspaceWrapper } from "@/Layout";
export default function PageWorkspaceIntegrations() {
	return (
		<WorkspaceWrapper>
			<Stack spacing={2}>
				<Typography variant="h5">Integrations</Typography>
				<Integrations />
			</Stack>
		</WorkspaceWrapper>
	);
}
