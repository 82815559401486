import { gql } from "@apollo/client";

export const notifications = gql`
	query GetNotifications {
		notifications {
			body
			createdAt
			id
			acknowledgedAt
			subject
			entity_type
			level
			hidden
			thumbnail
		}
	}
`;
export const countNewNotifications = gql`
	query CountNewNotifications {
		countNewNotifications {
			count
		}
	}
`;
export const pageNotifications = gql`
	query (
		$entityTypes: [NotificationEntityTypeEnum!]!
		$page: Int
		$limit: Int
		$showHidden: Boolean
		$showAcknowledged: Boolean
		$levels: [NotificationLevelEnum!]
	) {
		notifications(
			entity_types: $entityTypes
			page: $page
			limit: $limit
			showHidden: $showHidden
			showAcknowledged: $showAcknowledged
			levels: $levels
		) {
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			edges {
				cursor
				node {
					acknowledged_at
					body
					id
					link
					subject
					timestamp
					entity_type
					level
					error_code
					thumbnail
					content {
						id
						source {
							... on VideoFile {
								thumbnailUrl
							}
							... on ImageFile {
								thumbnailUrl
							}
						}
					}
					submission {
						id
						createdAt
						title
						deferUntil
						submittedAt
						link
						publicationDestination
						contentID
						status
						integration {
							destination
							username
							avatar
							status
							is_default
						}
					}
					integration {
						id
						status
						username
						avatar
						destination
						is_default
					}
					import {
						id
						created_at
						completed_at
						source
						status
						importFilesConnection {
							totalCount
						}
					}
					match {
						id
						source {
							... on VideoFile {
								id
								hash
								thumbnailUrl
							}
							... on ImageFile {
								id
								hash
								thumbnailUrl
							}
						}
					}
				}
			}
			totalCount
		}
	}
`;

export const config = gql`
	query NotificationConfig {
		notification_config {
			enable_in_app
			enable_push
			enable_sms
			enable_email
		}
	}
`;
