import { usePublishContentForm } from "../..";
import { FormControlLabel, Stack, Checkbox } from "@mui/material";
import Tags from "./Tags";
import NextButton from "../../NextButton";
import { useSnackbar } from "notistack";

export default function RedgifsOptions({ disabled, hideNext }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const {
		redgifs: { tags, keepAudio, setKeepAudio, isPrivate, setPrivate },
		nextStep,
	} = usePublishContentForm();

	return (
		<Stack
			spacing={1}
			component="form"
			onSubmit={e => {
				e.preventDefault();

				if (tags.length >= 3) return nextStep();

				let key = enqueueSnackbar(
					"Please add at least 3 tags before continueing",
					{
						variant: "error",
						autoHideDuration: 5000,
						onClick: () => {
							closeSnackbar(key);
						},
					}
				);
			}}
		>
			<Tags disabled={disabled} />
			<FormControlLabel
				control={
					<Checkbox
						checked={keepAudio}
						onChange={e => setKeepAudio(e.target.checked)}
					/>
				}
				label="Keep Audio"
				labelPlacement="start"
				disabled={disabled}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={isPrivate}
						onChange={e => setPrivate(e.target.checked)}
					/>
				}
				label="Make Private"
				labelPlacement="start"
				disabled={disabled}
			/>
			{!hideNext && <NextButton />}
		</Stack>
	);
}
