import FolderIcon from "@mui/icons-material/Folder";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import OkIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import {
	Box,
	Typography,
	Stack,
	IconButton,
	TextField,
	FormControl,
} from "@mui/material";
import { useState } from "react";
import { useFolderContent } from "../hook/useFolderContent";
import { useFoldersContext } from "../../ContextMenu";
const ContentIcon = ({ type }) => {
	if (type === "image") return <ImageOutlinedIcon />;
	if (type === "video") return <SmartDisplayOutlinedIcon />;
	if (type === "folder") return <FolderIcon />;
};
export default function TreeNode({ node }) {
	const { setClickEvent, setItem } = useFoldersContext();

	const [isEditing, setIsEditing] = useState(false);
	const { selectedId, addNew, updateName } = useFolderContent();

	let type;
	if (node.type === "folder") {
		type = "folder";
	} else {
		type = node.contentType;
	}

	const handleUpdateTitle = ({ name, id }) => {
		updateName({
			name,
			id,
		});
		setIsEditing(false);
	};
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: "0.5rem",
				cursor: "pointer",
				"&:hover": {
					backgroundColor: "var(--background-color)",
				},
			}}
			onContextMenu={e => {
				e.preventDefault();
				e.stopPropagation();
				console.log({
					node,
				});
				setItem({
					type: node.type,
					data: {
						id: node.value,
					},
				});
				setClickEvent(e);
			}}
		>
			<Stack
				direction="row"
				sx={{
					gap: "0.5rem",
					alignItems: "center",
				}}
			>
				<ContentIcon type={type} />

				{isEditing ? (
					<FormControl
						component="form"
						onSubmit={e => {
							handleUpdateTitle({
								name: e.target.name.value || "",
								id: node.value,
							});
						}}
						onClick={
							e => e.stopPropagation() // don't cause treeView item to activate when editing
						}
					>
						<TextField
							name="name"
							type="text"
							defaultValue={node.label}
							InputProps={{
								onKeyDown: e => {
									if (e.key !== "Enter") return;
									handleUpdateTitle({
										name: e.target.value || "",
										id: node.value,
									});
									e.preventDefault();
									e.stopPropagation();
								},

								endAdornment: (
									<Stack direction="row">
										<IconButton
											type="submit"
											size="small"
											variant="outlined"
										>
											<OkIcon />
										</IconButton>

										<IconButton
											size="small"
											variant="outlined"
											onClick={e => {
												e.stopPropagation();
												setIsEditing(false);
											}}
										>
											<CancelIcon />
										</IconButton>
									</Stack>
								),
							}}
							autoFocus
						/>
					</FormControl>
				) : (
					<Typography
						sx={{
							paddingTop: "14px",
							color: theme =>
								selectedId === node.value
									? "highlight"
									: theme.palette.text.primary,
						}}
					>
						{node.label}{" "}
						{
							// if root folder, add + button
							node.value === "0" ? (
								<IconButton
									size="small"
									variant="outlined"
									onClick={e => {
										e.stopPropagation();
										console.log("add new");
										addNew();
									}}
								>
									<AddOutlinedIcon />
								</IconButton>
							) : (
								<>
									{node.type === "folder" && (
										<IconButton
											size="small"
											variant="outlined"
											onClick={e => {
												e.stopPropagation();
												setIsEditing(true);
											}}
										>
											<EditIcon />
										</IconButton>
									)}
								</>
							)
						}
					</Typography>
				)}
			</Stack>
		</Box>
	);
}
