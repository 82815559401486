import {
	IconButton,
	Stack,
	ImageList,
	ImageListItem,
	useMediaQuery,
	ImageListItemBar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NextButton from "../../NextButton";
import { usePublishContentForm } from "../..";
import { useSnackbar } from "notistack";
import useAddContent from "../hooks/useSetContent";
import ContentSearch from "@/ContentSearch/Context";
import ContentAutoComplete from "@/Component/ContentAutoComplete";

export default function SelectContent() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { nextStep, content, removeContent } = usePublishContentForm();
	const { addContent } = useAddContent();
	const isMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const hasUnapprovedContent = content.some(
		({ approvalStatus }) => approvalStatus !== "READY"
	);
	return (
		<Stack spacing={2}>
			<ContentSearch
				defaultFilters={{
					approvalStatus: "READY",
				}}
			>
				<ContentAutoComplete
					defaultRefinement={""}
					onSelect={content => {
						if (!content) return;
						addContent(content);
					}}
					label="Search for approved content*"
				/>
			</ContentSearch>

			<ImageList rowHeight={200} sx={{ height: 450 }} cols={isMD ? 4 : 2}>
				{content.map(content => {
					const title = content.title;
					return (
						<ImageListItem
							key={content.id}
							sx={{
								border: theme => {
									if (content.approvalStatus === "READY") {
										return null;
									}
									return `1px solid ${theme.palette.error.main}`;
								},
							}}
						>
							<img
								src={content.source.thumbnailUrl}
								alt={title}
								loading="lazy"
								title={
									content.approvalStatus !== "READY"
										? "This content is not ready to be published"
										: null
								}
							/>
							<ImageListItemBar
								title={title}
								actionIcon={
									<IconButton
										onClick={() => {
											removeContent(content.id);
										}}
										aria-label={`remove ${title}`}
									>
										<CloseIcon />
									</IconButton>
								}
							/>
						</ImageListItem>
					);
				})}
			</ImageList>

			<form
				onSubmit={e => {
					e.preventDefault();
					if (!content.length) {
						let key = enqueueSnackbar(
							`Please select at least one content item`,
							{
								variant: "error",
								onClick: () => closeSnackbar(key),
							}
						);
						return;
					}
					nextStep();
				}}
			>
				<NextButton
					title={
						!content.length
							? "Select at least one content item"
							: hasUnapprovedContent
							? "Remove unapproved content before moving on to the next step"
							: "Next"
					}
					disabled={hasUnapprovedContent || !content.length}
				/>
			</form>
		</Stack>
	);
}
