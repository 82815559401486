import React from "react";
import { Button } from "@mui/material";
import useDeleteRole from "./hook";
export default function DeleteRoleButton({ role }) {
	const { delete: deleteRole } = useDeleteRole();
	return (
		<Button color={"info"} variant="text" onClick={() => deleteRole(role)}>
			Delete Role
		</Button>
	);
}
