import { useRequester } from "../../../../../../Apollo";

export default function useFavoriteSubreddits({ DEFAULT_INPUT_VALUE }) {
	const { useQuery, definitions, useMutation } = useRequester();
	const { data, fetchMore, loading, refetch, updateQuery } = useQuery(
		definitions.integrations.query.reddit_subredditFavoriteSearch,
		{
			variables: {
				input: {
					query: DEFAULT_INPUT_VALUE,
				},
			},
			fetchPolicy: "cache-first",
			nextFetchPolicy: "cache-first",
		}
	);
	const [setSubredditFavorite] = useMutation(
		definitions.integrations.mutation.reddit_setSubredditFavorite
	);
	return {
		loading,
		subreddits: data?.subredditFavoriteSearch || [],
		refetch,

		fetchMore: async ({ query }) => {
			await fetchMore({
				query: definitions.integrations.query
					.reddit_subredditFavoriteSearch,
				variables: {
					input: {
						query,
					},
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					const combinedArrays = [
						...(prev?.subredditFavoriteSearch || []),
						...fetchMoreResult.subredditFavoriteSearch,
					];
					const uniqueArray = combinedArrays.filter(
						(item, index) =>
							combinedArrays.findIndex(
								item2 => item2.name === item.name
							) === index
					);
					return {
						...prev,
						subredditFavoriteSearch: uniqueArray,
					};
				},
			});
		},

		setFavorite: async ({ name, favorite }) => {
			await setSubredditFavorite({
				variables: {
					input: {
						subreddit_name: name,
						setIsFavorite: favorite,
					},
				},

				onCompleted: data => {
					updateQuery(prev => {
						const previousResults =
							prev?.subredditFavoriteSearch || [];
						if (favorite) {
							// add favorite to cached list
							const newSubreddits = [
								...previousResults,
								data.subredditFavorite.subreddit,
							];
							return {
								...prev,
								subredditFavoriteSearch: newSubreddits,
							};
						} else {
							// remove favorite from cached list
							const removed = previousResults.filter(item => {
								return (
									item.name !==
									data?.subredditFavorite?.subreddit?.name
								);
							});
							return {
								...prev,
								subredditFavoriteSearch: removed,
							};
						}
					});
				},
			});
		},
	};
}
