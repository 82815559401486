import MatchIcon from "@mui/icons-material/JoinInner";
import { Badge, Tooltip } from "@mui/material";
import { StyledSquareIconButton } from "@/Component/Styled/Button";
import { useRequester } from "@/Apollo";
import { useCallback } from "react";

import { useNavigate, useLocation } from "react-router-dom";
export default function ContentMatchIconButton() {
	const navigate = useNavigate();
	const location = useLocation();
	const handleNavigate = useCallback(() => {
		if (location.pathname.startsWith("/workspace/match")) return;
		navigate("/workspace/match");
	}, [navigate, location]);
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.common.query.countMatches);
	return (
		<Tooltip title="Matches">
			<StyledSquareIconButton
				onClick={handleNavigate}
				sx={{
					position: "relative",
				}}
			>
				<Badge badgeContent={data?.matchesCount?.count}>
					<MatchIcon />
				</Badge>
			</StyledSquareIconButton>
		</Tooltip>
	);
}
