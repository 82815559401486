import { Uploady, useUploady } from "@rpldy/uploady";
import { useSnackbar } from "notistack";
import { createContext, useContext, forwardRef, useRef } from "react";
import UploadProgress from "./Progress";
import RequestPresend from "./HookProvider";
import "./styles.css";
const Context = createContext({
	uploadType: (type, { onCompleted, onError }) => {},

	progress: {},
	setProgress: (id, data) => {},
});

export const useUploadType = () => useContext(Context);

const Uploader = forwardRef(
	({ accept, type: assetType, fileFilter, multiple }, ref) => {
		const { upload } = useUploady();
		return (
			<>
				<input
					ref={ref}
					type="file"
					accept={accept}
					hidden
					multiple={!!multiple}
					onChange={async e => {
						for (const file of e.target.files) {
							upload(file, {
								autoUpload: true,
								sendWithFormData: true,
								fileFilter,
								assetType,
								// on completed
								isSuccessfulCall: response => {
									const current = ref.current?.onCompleted();
									console.log(
										"handle completed",
										response,
										current
									);
								},
							});
						}
					}}
				/>
			</>
		);
	}
);
export default function UploadyContextProvider({ children }) {
	const { enqueueSnackbar } = useSnackbar();
	const ASSET_TYPES = [
		{
			type: "CONTENT",
			ref: useRef(),
			accept: "video/*,image/*",
			multiple: true,
		},
		{
			type: "WATERMARK",
			ref: useRef(),
			accept: "image/*",
			multiple: true,
		},
		{
			type: "BUMPER",
			ref: useRef(),
			accept: "video/*",
			multiple: true,
		},
		{
			type: "AUDIO",
			ref: useRef(),
			accept: "audio/*",
			multiple: true,
		},
		{
			type: "DOCUMENT",
			ref: useRef(),
			accept: "application/*",
			fileFilter: file => {
				enqueueSnackbar(`Not implemented`, {
					variant: "error",
				});
				return false;
			},
		},
		{
			type: "AVATAR_WORKSPACE",
			ref: useRef(),
			accept: "image/jpeg,image/png",
		},
		{
			type: "AVATAR_USER",
			ref: useRef(),
			accept: "image/jpeg,image/png",
		},
	];

	return (
		<Context.Provider
			value={{
				uploadType: (type, { onCompleted, onError } = {}) => {
					const found = ASSET_TYPES.find(
						assetType => assetType.type === type
					);
					if (!found) return console.error("Invalid asset type");

					found.ref.current.onCompleted = onCompleted;
					found.ref.current?.click();
				},
			}}
		>
			<Uploady
				accept={"*"}
				concurrent={false}
				destination={{}}
				maxConcurrent={1}
				multiple={true}
				// enhancer={retryEnhancer} // TODO: add retry enhancer
			>
				<RequestPresend />
				{children}
				{ASSET_TYPES.map(type => (
					<Uploader
						key={type.type}
						ref={type.ref}
						accept={type.accept}
						type={type.type}
						fileFilter={type.fileFilter}
						multiple={type.multiple}
					/>
				))}

				<UploadProgress />
			</Uploady>
		</Context.Provider>
	);
}
