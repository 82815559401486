export const Icon = ({ width = "48px", height = "48px" }) => {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width={width}
			height={height}
			viewBox="0 0 186 186"
			style={{ enableBackground: "new 0 0 186 186" }}
		>
			<path
				fill="#00AFF0"
				d="M186,93c0,1.5,0,3-0.1,4.6c-0.1,1.5-0.2,3-0.3,4.6c-0.1,1.5-0.3,3-0.6,4.5c-0.2,1.5-0.5,3-0.8,4.5
            c-0.3,1.5-0.6,3-1,4.5c-0.4,1.5-0.8,2.9-1.2,4.4c-0.4,1.5-0.9,2.9-1.4,4.3c-0.5,1.4-1.1,2.9-1.6,4.3c-0.6,1.4-1.2,2.8-1.8,4.2
            c-0.7,1.4-1.3,2.7-2.1,4.1c-0.7,1.3-1.5,2.7-2.2,4c-0.8,1.3-1.6,2.6-2.4,3.9c-0.8,1.3-1.7,2.5-2.6,3.7c-0.9,1.2-1.8,2.4-2.8,3.6
            s-2,2.3-3,3.5c-1,1.1-2.1,2.2-3.1,3.3c-1.1,1.1-2.2,2.1-3.3,3.1c-1.1,1-2.3,2-3.5,3c-1.2,1-2.4,1.9-3.6,2.8
            c-1.2,0.9-2.5,1.8-3.7,2.6c-1.3,0.8-2.6,1.7-3.9,2.4c-1.3,0.8-2.6,1.5-4,2.2c-1.3,0.7-2.7,1.4-4.1,2.1c-1.4,0.7-2.8,1.3-4.2,1.8
            c-1.4,0.6-2.8,1.1-4.3,1.6c-1.4,0.5-2.9,1-4.3,1.4s-2.9,0.8-4.4,1.2c-1.5,0.4-3,0.7-4.5,1c-1.5,0.3-3,0.6-4.5,0.8
            c-1.5,0.2-3,0.4-4.5,0.6c-1.5,0.1-3,0.3-4.6,0.3c-1.5,0.1-3,0.1-4.6,0.1c-1.5,0-3,0-4.6-0.1c-1.5-0.1-3-0.2-4.6-0.3
            c-1.5-0.1-3-0.3-4.5-0.6c-1.5-0.2-3-0.5-4.5-0.8s-3-0.6-4.5-1c-1.5-0.4-2.9-0.8-4.4-1.2s-2.9-0.9-4.3-1.4c-1.4-0.5-2.9-1.1-4.3-1.6
            c-1.4-0.6-2.8-1.2-4.2-1.8c-1.4-0.7-2.7-1.3-4.1-2.1c-1.3-0.7-2.7-1.5-4-2.2c-1.3-0.8-2.6-1.6-3.9-2.4c-1.3-0.8-2.5-1.7-3.7-2.6
            c-1.2-0.9-2.4-1.8-3.6-2.8c-1.2-1-2.3-2-3.5-3c-1.1-1-2.2-2.1-3.3-3.1c-1.1-1.1-2.1-2.2-3.1-3.3c-1-1.1-2-2.3-3-3.5
            c-1-1.2-1.9-2.4-2.8-3.6c-0.9-1.2-1.8-2.5-2.6-3.7c-0.8-1.3-1.7-2.6-2.4-3.9c-0.8-1.3-1.5-2.6-2.2-4c-0.7-1.3-1.4-2.7-2.1-4.1
            c-0.7-1.4-1.3-2.8-1.8-4.2c-0.6-1.4-1.1-2.8-1.6-4.3c-0.5-1.4-1-2.9-1.4-4.3s-0.8-2.9-1.2-4.4c-0.4-1.5-0.7-3-1-4.5s-0.6-3-0.8-4.5
            c-0.2-1.5-0.4-3-0.6-4.5c-0.1-1.5-0.3-3-0.3-4.6C0,96,0,94.5,0,93c0-1.5,0-3,0.1-4.6c0.1-1.5,0.2-3,0.3-4.6c0.1-1.5,0.3-3,0.6-4.5
            s0.5-3,0.8-4.5s0.6-3,1-4.5C3.2,68.9,3.6,67.5,4,66c0.4-1.5,0.9-2.9,1.4-4.3c0.5-1.4,1.1-2.9,1.6-4.3c0.6-1.4,1.2-2.8,1.8-4.2
            s1.3-2.7,2.1-4.1c0.7-1.3,1.5-2.7,2.2-4s1.6-2.6,2.4-3.9c0.8-1.3,1.7-2.5,2.6-3.7c0.9-1.2,1.8-2.4,2.8-3.6c1-1.2,2-2.3,3-3.5
            c1-1.1,2.1-2.2,3.1-3.3c1.1-1.1,2.2-2.1,3.3-3.1c1.1-1,2.3-2,3.5-3c1.2-1,2.4-1.9,3.6-2.8c1.2-0.9,2.5-1.8,3.7-2.6s2.6-1.7,3.9-2.4
            s2.6-1.5,4-2.2c1.3-0.7,2.7-1.4,4.1-2.1c1.4-0.7,2.8-1.3,4.2-1.8c1.4-0.6,2.8-1.1,4.3-1.6c1.4-0.5,2.9-1,4.3-1.4
            c1.5-0.4,2.9-0.8,4.4-1.2c1.5-0.4,3-0.7,4.5-1c1.5-0.3,3-0.6,4.5-0.8c1.5-0.2,3-0.4,4.5-0.6c1.5-0.1,3-0.3,4.6-0.3C90,0,91.5,0,93,0
            c1.5,0,3,0,4.6,0.1c1.5,0.1,3,0.2,4.6,0.3c1.5,0.1,3,0.3,4.5,0.6c1.5,0.2,3,0.5,4.5,0.8c1.5,0.3,3,0.6,4.5,1
            c1.5,0.4,2.9,0.8,4.4,1.2c1.5,0.4,2.9,0.9,4.3,1.4c1.4,0.5,2.9,1.1,4.3,1.6c1.4,0.6,2.8,1.2,4.2,1.8s2.7,1.3,4.1,2.1
            c1.3,0.7,2.7,1.5,4,2.2s2.6,1.6,3.9,2.4c1.3,0.8,2.5,1.7,3.7,2.6c1.2,0.9,2.4,1.8,3.6,2.8c1.2,1,2.3,2,3.5,3c1.1,1,2.2,2.1,3.3,3.1
            c1.1,1.1,2.1,2.2,3.1,3.3c1,1.1,2,2.3,3,3.5s1.9,2.4,2.8,3.6c0.9,1.2,1.8,2.5,2.6,3.7c0.8,1.3,1.7,2.6,2.4,3.9s1.5,2.6,2.2,4
            c0.7,1.3,1.4,2.7,2.1,4.1s1.3,2.8,1.8,4.2c0.6,1.4,1.1,2.8,1.6,4.3c0.5,1.4,1,2.9,1.4,4.3c0.4,1.5,0.8,2.9,1.2,4.4
            c0.4,1.5,0.7,3,1,4.5c0.3,1.5,0.6,3,0.8,4.5c0.2,1.5,0.4,3,0.6,4.5c0.1,1.5,0.3,3,0.3,4.6C186,90,186,91.5,186,93z"
			/>
			<path
				fill="#FFFFFF"
				fillOpacity="0.8"
				d="M72.7,111c-6.7,0-12.2-5.5-12.2-12.2s5.5-12.2,12.2-12.2c6.7,0,12.2,5.5,12.2,12.2c0,3.2-1.3,6.3-3.6,8.6
            C79,109.7,75.9,111,72.7,111L72.7,111z M72.7,58.1C50.2,58.1,32,76.3,32,98.8s18.2,40.7,40.7,40.7c22.5,0,40.7-18.2,40.7-40.7
            C113.3,76.3,95.1,58.1,72.7,58.1L72.7,58.1z"
			/>
			<path
				fill="#FFFFFF"
				d="M118.4,88.6c10.3,3,22.5,0,22.5,0c-3.5,15.5-14.8,25.1-31,26.3c-6.4,14.9-21.1,24.5-37.3,24.5l12.2-38.8
            c12.6-39.9,19-42.6,48.7-42.6H154C150.6,73.2,138.8,84.7,118.4,88.6z"
			/>
		</svg>
	);
};
