import { Stack, IconButton, Typography, Box } from "@mui/material";
import DestinationsList from "../../../../../Destinations";
import { Fragment } from "react";
export default function DestinationSelector({
	onSelect = () => {},
	selected = null,
}) {
	if (selected) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<IconButton onClick={() => onSelect(null)}>
					<selected.Icon />
				</IconButton>
				<Typography>{selected.destination}</Typography>
			</Box>
		);
	}
	return (
		<Stack>
			{DestinationsList.map(Destination => {
				const { destination, Icon, color } = Destination;
				return (
					<Fragment key={destination}>
						{destination}
						<IconButton onClick={() => onSelect(Destination)}>
							<Icon />
						</IconButton>
					</Fragment>
				);
			})}
		</Stack>
	);
}
