import { useUploadType } from "@/Upload";
import { Avatar, IconButton } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useAvatarCacheContext } from "@/Component/AvatarCacheContext";

export default function EditableAvatar({ type = "USER" }) {
	if (type !== "USER" && type !== "WORKSPACE") {
		throw new Error("EditableAvatar type must be USER or WORKSPACE");
	}
	const {
		userAvatar,
		updateCacheUser,
		workspaceAvatar,
		updateCacheWorkspace,
	} = useAvatarCacheContext();
	const { uploadType } = useUploadType();
	return (
		<IconButton
			onClick={() => {
				uploadType(`AVATAR_${type}`, {
					onCompleted: () => {
						if (type === "USER") {
							updateCacheUser();
						} else if (type === "WORKSPACE") {
							updateCacheWorkspace();
						}
					},
				});
			}}
			sx={{
				position: "relative",
				"&:hover": {
					"& > div": {
						display: "block",
					},
				},
			}}
		>
			<AddPhotoAlternateIcon
				sx={{
					position: "absolute",
					bottom: 0,
					right: 0,
				}}
			/>

			<Avatar
				alt={`${type} avatar`}
				src={type === "USER" ? userAvatar : workspaceAvatar}
				sx={{
					height: 100,
					width: 100,
				}}
			/>
		</IconButton>
	);
}
