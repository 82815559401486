import {
	Box,
	Avatar,
	IconButton,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Stack,
	Badge,
	Tabs,
	Tab,
	useMediaQuery,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { useRequester } from "../../../../Apollo";
import { useState } from "react";
import { useAvatarCacheContext } from "@/Component/AvatarCacheContext";
import GoToIcon from "@mui/icons-material/ArrowForwardIos";
import WorkspaceInfoPopover from "../WorkspaceInfoPopover";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const InfoIconButton = ({ workspace }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	return (
		<>
			<IconButton
				sx={{
					color: "rgba(255, 255, 255, 0.54)",
				}}
				onClick={e => {
					setAnchorEl(e.currentTarget);
					e.stopPropagation();
				}}
				aria-label={`info about ${workspace.name}`}
			>
				<InfoIcon />
			</IconButton>
			<WorkspaceInfoPopover
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				workspace={workspace}
			/>
		</>
	);
};
const SelectorList = ({ showAssisting }) => {
	const { definitions, useQuery, workspace_id, selectWorkspace } =
		useRequester();

	const { data } = useQuery(definitions.workspace.query.allWorkspaces, {
		fetchPolicy: "cache-and-network",
		variables: {
			input: {
				limit: 100,
				page: 1,
				showAssisting,
			},
		},
	});

	const workspaces =
		data?.myWorkspaces?.edges?.map(({ node }) => {
			return node;
		}) || [];
	return (
		<ImageList>
			{workspaces.map((workspace, i) => {
				const selected =
					parseInt(workspace.id) === parseInt(workspace_id);

				return (
					<ImageListItem key={workspace.id}>
						<Avatar
							sx={{
								width: "125px",
								height: "125px",
								alignSelf: "center",
							}}
							src={`https://${
								process.env.REACT_APP_CDN_HOST
							}/avatar/workspace/${
								workspace.id
							}?key=${new Date().getTime()}`}
						/>
						<ImageListItemBar
							title={workspace.name}
							sx={{
								// font color
								"& .MuiImageListItemBar-title": {
									color: theme => {
										if (selected) {
											return theme.palette.info.main;
										}
										return theme.palette.text.primary;
									},
								},
							}}
							subtitle={"@" + workspace.admin.public_display_name}
							actionIcon={
								<Stack direction="row">
									<InfoIconButton workspace={workspace} />
									<IconButton
										sx={{
											color: "rgba(255, 255, 255, 0.54)",
										}}
										onClick={() => {
											selectWorkspace({ workspace });
										}}
										aria-label={`start using ${workspace.name}`}
									>
										<GoToIcon />
									</IconButton>
								</Stack>
							}
						/>
					</ImageListItem>
				);
			})}
		</ImageList>
	);
};

export default function WorkspaceSelector() {
	const sm = useMediaQuery(theme => theme.breakpoints.up("sm"));
	const { workspaceAvatar } = useAvatarCacheContext();

	const [showDialog, setShowDialog] = useState(false);
	const [showAssisting, setShowAssisting] = useState(false);
	const handleCloseNavMenu = () => setShowDialog(false);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "flex-start",
				alignItems: "center",
				position: "relative",
			}}
		>
			<Tooltip title="Select workspace">
				<IconButton
					onClick={() => setShowDialog(true)}
					sx={{
						position: "relative",
					}}
				>
					<Avatar src={workspaceAvatar} />

					<ExpandCircleDownIcon
						sx={{
							position: "absolute",
							bottom: 0,
							left: 0,
						}}
					/>
				</IconButton>
			</Tooltip>

			<Dialog
				fullWidth
				fullScreen={!sm}
				open={showDialog}
				onClose={handleCloseNavMenu}
				sx={{
					overflowY: "auto",
				}}
			>
				<DialogTitle>Select a Workspace</DialogTitle>

				<DialogContent
					sx={{
						padding: 0,
					}}
				>
					<Tabs value={showAssisting ? 1 : 0}>
						<Tab
							label="My Workspaces"
							onClick={() => setShowAssisting(false)}
							value={0}
						/>
						<Tab
							label="Assisting"
							onClick={() => setShowAssisting(true)}
							value={1}
						/>
					</Tabs>
					<Box
						sx={{
							overflowY: "auto",
							height: sm ? "50vh" : "100%",
							position: "relative",
						}}
					>
						<SelectorList showAssisting={showAssisting} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant={"contained"} onClick={handleCloseNavMenu}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
