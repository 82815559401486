import { useRequester } from "@/Apollo";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "@/Confirm";
export default function useDeleteRole() {
	const { useMutation, definitions, client } = useRequester();
	const [deleteRole] = useMutation(definitions.rbac.mutation.deleteRole);
	const navigate = useNavigate();
	const { confirm } = useConfirm();

	return {
		delete: async role => {
			const confirmed = await confirm(
				"Are you sure you want to delete this role?"
			);
			if (!confirmed) return;
			deleteRole({
				variables: {
					input: {
						id: role.id,
					},
				},
				onCompleted: () => {
					// remove the role from the cache
					client.cache.modify({
						fields: {
							workspaceRoles(cached = []) {
								return cached.roles.filter(
									roleRef => roleRef.id !== role.id
								);
							},
						},
					});

					navigate("/workspace/assistants/roles");
				},
			});
		},
	};
}
