import HomeIcon from "@mui/icons-material/Home";
import AboutIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactIcon from "@mui/icons-material/ContactMail";
import PrivacyTip from "@mui/icons-material/PrivacyTip";
import TermsOfServiceIcon from "@mui/icons-material/Description";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CookieIcon from "@mui/icons-material/Cookie";
import AutomationIcon from "@mui/icons-material/Settings";

import SignInIcon from "@mui/icons-material/AccountCircle";
import RegisterAccountIcon from "@mui/icons-material/PersonAdd";
export const LinkSection = [
	{
		name: "Navigation",
		showInTopNav: true,
		showAnonymous: false,
		links: [
			{ to: "/account", text: "Account", Icon: HomeIcon },
			{
				to: "/api",
				text: "API",
				Icon: SettingsIcon,
			},
			{
				to: "/workspace",
				text: "Workspace",
				Icon: PhotoLibraryOutlinedIcon,
			},
		],
	},
	{
		name: "Company",
		showInTopNav: false,
		showAnonymous: true,
		links: [
			{ to: "/about", text: "About", Icon: AboutIcon },
			{ to: "/contact", text: "Contact", Icon: ContactIcon },
		],
	},
	{
		name: "Legal",
		showInTopNav: false,
		showAnonymous: true,

		links: [
			{
				to: "/privacy",
				text: "Privacy Policy",
				Icon: PrivacyTip,
			},
			{
				to: "/terms",
				text: "Terms of Service",
				Icon: TermsOfServiceIcon,
			},
			{
				to: "/cookies",
				text: "Cookie Policy",
				Icon: CookieIcon,
			},
		],
	},
	{
		name: "Admin",
		showInTopNav: true,
		showAnonymous: false,
		hideIfLoggedIn: false,
		links: [
			{
				to: "/admin",
				text: "Admin",
				Icon: SettingsIcon,
			},
		],
		admin: true,
	},
];

export const getAllowedLinks = ({ user, isTopNav, admin }) => {
	let linkSection = [...LinkSection];

	const userIsAnonymous = !user || user?.isAnonymous;

	if (userIsAnonymous) {
		linkSection = LinkSection.filter(({ showAnonymous }) => showAnonymous);
	}
	if (isTopNav) {
		linkSection = linkSection.filter(({ showInTopNav }) => showInTopNav);
	}
	if (!userIsAnonymous) {
		linkSection = linkSection.filter(
			({ hideIfLoggedIn }) => !hideIfLoggedIn
		);
	}
	if (!admin) linkSection = linkSection.filter(({ admin }) => !admin);

	return linkSection;
};
