import { usePlayTime } from "./usePlayTime";
import { useEffect, useMemo, useState } from "react";
import { useContentEditor } from "../../common/context";
import { ASPECT_RATIOS } from "../../common/constants";
export default function usePlayerOptions() {
	const {
		variables: {
			input: { timeline, options },
		},
	} = useContentEditor();

	const aspectRatio = options.aspectRatio || ASPECT_RATIOS[0];

	const { isPlaying, listener, selectedTime } = usePlayTime();
	const [activeTimelineAssetKeys, setActiveTimelineAssetKeys] = useState([]);

	useEffect(() => {
		setActiveTimelineAssetKeys(listener?._activeActionIds || []);
	}, [listener?._activeActionIds]);
	const assets = useMemo(() => {
		return timeline.tracks.reduce((acc, track) => {
			return [...acc, ...track.assets];
		}, []);
	}, [timeline]);

	const playerOptions = useMemo(() => {
		return assets.reduce((prev, asset) => {
			const trackIndex = asset.trackIndex;
			const assetIndex = asset.assetIndex;

			const key = `${trackIndex}-${assetIndex}`;
			const isActive = activeTimelineAssetKeys.find(
				activeKey => activeKey === key
			);
			if (!isActive) return prev;
			const start = asset.start;

			const currentTime = selectedTime;
			const startFrom = currentTime - start;
			const endAt = asset.trim.to;

			const mimetype = asset.file.mimetype;
			const url = asset.file.url;

			let type;
			if (mimetype.startsWith("image")) type = "image";
			if (mimetype.startsWith("video")) type = "video";
			if (mimetype.startsWith("audio")) type = "audio";
			const zIndex = 1400 + trackIndex * 1;

			let opacity = asset.opacity;
			if (type === "audio") opacity = 0;
			const newPlayer = {
				isPlaying,
				key: `${trackIndex}-${assetIndex}`,
				type,
				url,
				startFrom,

				endAt,
				zIndex,

				opacity,
				fit: asset.fit,
				scale: asset.scale,
				position: asset.position,
				asset,
			};

			return [...prev, newPlayer];
		}, []);
	}, [
		assets,
		activeTimelineAssetKeys,
		isPlaying,
		listener?._activeActionIds,
	]);
	const getHeight = aspectRatio => {
		const [width, height] = aspectRatio.split(":");
		return `${(height / width) * 100}%`;
	};
	const getWidth = aspectRatio => {
		const [width, height] = aspectRatio.split(":");
		return `${(width / height) * 100}%`;
	};
	const { width, height } = useMemo(() => {
		return {
			width: getWidth(aspectRatio),
			height: getHeight(aspectRatio),
		};
	}, [aspectRatio]);
	return {
		playerOptions,
		aspectRatio,
		width,
		height,
		isPlaying,
	};
}
