import {
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemIcon,
	Divider,
	SwipeableDrawer,
	ListSubheader,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getAllowedLinks } from "../Links";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment } from "react";
export const NavDrawer = ({ onClose: toggleDrawer, open }) => {
	const { user } = useAuth0();

	const allowedLinks = getAllowedLinks({
		isTopNav: false,
		user,
	});

	return (
		<SwipeableDrawer
			anchor="top"
			ModalProps={{
				keepMounted: true, // Better open performance on mobile.
			}}
			open={open}
			onOpen={toggleDrawer}
			onClose={toggleDrawer}
			sx={{
				zIndex: theme => theme.zIndex.drawer + 2,
			}}
		>
			<Box
				sx={{
					// Margin must remain because the NavDrawer zIndex is behind the AppBar
					// (only way to position a mui drawer behind an AppBar :shrug:)
					marginTop: "var(--nav-height)",
					height: "var(--body-height)",
				}}
				role="navigation"
			>
				<List subheader={<ListSubheader>Menu</ListSubheader>}>
					<>
						{allowedLinks.map(({ name, links }, i) => (
							<Fragment key={i}>
								<ListSubheader
									sx={{
										width: "fit-content",
									}}
								>
									{name}
								</ListSubheader>
								{links.map(({ to, text, Icon }, index) => (
									<ListItem
										key={index}
										sx={{
											py: 0,
											minHeight: 32,
											color: "rgba(255,255,255,.8)",
										}}
									>
										<ListItemButton
											component={Link}
											to={to}
											target={
												to.startsWith("http")
													? "_blank"
													: "_self"
											}
										>
											<ListItemIcon>
												<Icon />
											</ListItemIcon>
											<ListItemText
												primary={text}
												primaryTypographyProps={{
													fontSize: 14,
													fontWeight: "medium",
												}}
											/>
										</ListItemButton>
									</ListItem>
								))}
								{
									// Only add a divider if there is another section after this one
									i < allowedLinks.length - 1 && (
										<Divider
											sx={{
												marginTop: "8px",
											}}
										/>
									)
								}
							</Fragment>
						))}
					</>
				</List>
			</Box>
		</SwipeableDrawer>
	);
};
