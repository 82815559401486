import { useAuth0 } from "@auth0/auth0-react";

import { useSnackbar } from "notistack";
import { useRequester } from "../../../Apollo";
export default function useEmailVerify() {
	const { user, getIdTokenClaims } = useAuth0();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation } = useRequester();
	const [verifyEmail, { data, loading, error }] = useMutation(
		definitions.common.mutation.verifyEmail,
		{
			fetchPolicy: "no-cache",
		}
	);
	return {
		verifyEmail: async () => {
			// await verifyEmail();
			enqueueSnackbar("Service unavailable.", {
				// variant: "success",
				variant: "info",
				autoHideDuration: 5000,

				onClick: () => {
					closeSnackbar();
				},
			});
		},
	};
}
