import { useRequester } from "@/Apollo";
import { useSnackbar } from "notistack";
import { Stack, TextField, InputAdornment, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useOauthClientContext } from "@/Component/Oauth/OauthClientList/Client";
import { InputAdornmentPadding } from "../Style";
export default function RedirectUris() {
	const { client } = useOauthClientContext();

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useQuery, definitions, useMutation } = useRequester();

	const [deleteRedirectUri, { data: deleteData }] = useMutation(
		definitions.oauth.mutation.deleteRedirectUri
	);
	const [addRedirectUri, { data: addData }] = useMutation(
		definitions.oauth.mutation.addRedirectUri
	);

	return (
		<Stack spacing={2}>
			{client.redirectUris.map((redirectUriObject, i) => {
				return (
					<TextField
						key={i}
						value={redirectUriObject.uri}
						sx={{
							"& .MuiInputBase-root": {
								width: "fit-content",
							},

							"& .MuiInputLabel-root": {
								visibility: "visible",
							},
						}}
						InputProps={{
							readOnly: true,
							endAdornment: (
								<InputAdornment position="end">
									<InputAdornmentPadding />
									<IconButton
										onClick={async () => {
											await deleteRedirectUri({
												variables: {
													input: {
														client_id: client.id,
														uri: redirectUriObject.uri,
													},
												},
											});
										}}
									>
										<DeleteIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				);
			})}
			<form
				onSubmit={async e => {
					e.preventDefault();
					const uri = e.target.redirect_uri.value;
					await addRedirectUri({
						variables: {
							input: {
								client_id: client.id,
								uri,
							},
						},
						onError: e => {
							const key = enqueueSnackbar(e.message, {
								variant: "error",
								onClick: () => closeSnackbar(key),
							});
						},
						onCompleted: () => {
							e.target.redirect_uri.value = "";
						},
					});
				}}
			>
				<TextField
					required
					type="url"
					name="redirect_uri"
					sx={{
						"& .MuiInputBase-root": {
							width: "fit-content",
						},

						"& .MuiInputLabel-root": {
							visibility: "visible",
						},
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<InputAdornmentPadding />
								<IconButton type="submit">
									<AddIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</form>
		</Stack>
	);
}
