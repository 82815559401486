export const Icon = ({ height = "48px", width = "48px" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 48 48"
			width={width}
			height={height}
		>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLa"
				x1="10.585"
				x2="37.873"
				y1="42.333"
				y2="9.109"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#33c481" />
				<stop offset="1" stopColor="#21a366" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLa)"
				d="M34,42H14c-4.418,0-8-3.582-8-8V14c0-4.418,3.582-8,8-8h20c4.418,0,8,3.582,8,8v20	C42,38.418,38.418,42,34,42z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLb"
				x1="11.147"
				x2="24.593"
				y1="33.367"
				y2="6.545"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#124787" />
				<stop offset=".923" stopColor="#173b75" />
				<stop offset="1" stopColor="#173a73" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLb)"
				d="M33.9,32.49L24.39,42H14c-4.42,0-8-3.58-8-8V23.61l9.51-9.51l4.25,5.66l-8.49,8.48l8.49,8.49	l8.48-8.49L33.9,32.49z"
			/>
			<path
				fill="#fff"
				d="M19.648,37.332c-0.48,0-0.961-0.155-1.362-0.471c-0.992-0.779-2.458-1.991-3.807-3.34l0,0	c-1.355-1.355-2.564-2.819-3.341-3.809c-0.69-0.878-0.614-2.138,0.18-2.93l7.025-7.025l-2.941-2.941	c-0.522-0.521-0.708-1.282-0.484-1.984c0.226-0.709,0.823-1.227,1.56-1.353c4.806-0.814,10.345-1.169,16.925-1.104	c1.211,0.013,2.208,1.009,2.221,2.221c0.071,6.588-0.291,12.124-1.104,16.925c-0.125,0.736-0.643,1.334-1.351,1.56	c-0.703,0.227-1.463,0.04-1.986-0.483l-2.941-2.941l-7.026,7.026C20.786,37.113,20.218,37.332,19.648,37.332z M15.893,32.107	c1.279,1.278,2.679,2.436,3.628,3.182c0.084,0.065,0.204,0.056,0.282-0.021l8.439-8.44l4.355,4.355	c0.745-4.679,1.097-10.099,1.027-16.565c-0.001-0.13-0.112-0.241-0.242-0.242c-6.468-0.067-11.883,0.281-16.57,1.076l4.359,4.306	l-8.44,8.439c-0.077,0.077-0.086,0.197-0.021,0.28C13.319,29.253,14.541,30.755,15.893,32.107L15.893,32.107z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLc"
				x1="8.926"
				x2="11.687"
				y1="28.015"
				y2="22.821"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLc)"
				d="M10,26.5c-0.309,0-0.569-0.202-0.632-0.491c-0.18-0.809-0.621-1.257-1.346-1.368	c-0.303-0.057-0.511-0.305-0.522-0.607c-0.011-0.312,0.183-0.582,0.471-0.657c0.722-0.213,1.199-0.691,1.404-1.417	C9.488,21.663,9.727,21.5,10,21.5s0.512,0.163,0.61,0.414c0.22,0.771,0.697,1.249,1.433,1.466c0.274,0.071,0.468,0.342,0.457,0.653	c-0.011,0.303-0.219,0.551-0.505,0.604c-0.742,0.114-1.182,0.562-1.362,1.373C10.569,26.298,10.309,26.5,10,26.5z"
			/>
			<path
				fill="#fff"
				d="M11.902,23.859c-0.862-0.253-1.496-0.836-1.758-1.765c-0.049-0.126-0.238-0.126-0.287,0	c-0.262,0.929-0.896,1.511-1.758,1.765c-0.127,0.034-0.135,0.262,0,0.287c0.879,0.135,1.521,0.692,1.758,1.756	c0.028,0.13,0.259,0.13,0.287,0c0.237-1.064,0.879-1.621,1.758-1.756C12.037,24.121,12.029,23.893,11.902,23.859z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLd"
				x1="12.636"
				x2="14.96"
				y1="31.122"
				y2="26.752"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLd)"
				d="M13.5,22.5c-0.291,0-0.536-0.191-0.596-0.466c-0.124-0.559-0.412-0.854-0.906-0.931	c-0.287-0.052-0.486-0.286-0.497-0.571c-0.012-0.293,0.171-0.548,0.444-0.621c0.496-0.146,0.825-0.476,0.966-0.976	c0.108-0.283,0.333-0.436,0.589-0.436s0.481,0.152,0.573,0.389c0.157,0.547,0.486,0.877,0.994,1.026	c0.261,0.069,0.444,0.324,0.432,0.617c-0.011,0.285-0.21,0.52-0.483,0.569c-0.508,0.078-0.796,0.374-0.92,0.934	C14.036,22.309,13.791,22.5,13.5,22.5z"
			/>
			<path
				fill="#fff"
				d="M14.926,20.394c-0.647-0.19-1.122-0.627-1.319-1.323c-0.037-0.094-0.179-0.094-0.216,0	c-0.197,0.697-0.672,1.133-1.319,1.323c-0.095,0.025-0.101,0.196,0,0.215c0.659,0.101,1.141,0.519,1.319,1.317	c0.021,0.098,0.194,0.098,0.216,0c0.178-0.798,0.659-1.216,1.319-1.317C15.028,20.591,15.021,20.42,14.926,20.394z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLe"
				x1="24.636"
				x2="26.766"
				y1="15.122"
				y2="11.116"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLe)"
				d="M25.5,38.5c-0.291,0-0.536-0.191-0.596-0.466c-0.124-0.559-0.412-0.854-0.906-0.931	c-0.287-0.052-0.486-0.286-0.497-0.571c-0.012-0.293,0.171-0.548,0.444-0.621c0.496-0.146,0.825-0.476,0.966-0.976	c0.108-0.283,0.333-0.436,0.589-0.436s0.481,0.152,0.573,0.389c0.157,0.547,0.486,0.877,0.994,1.026	c0.261,0.069,0.444,0.324,0.432,0.617c-0.011,0.285-0.21,0.52-0.483,0.569c-0.508,0.078-0.796,0.374-0.92,0.934	C26.036,38.309,25.791,38.5,25.5,38.5z"
			/>
			<path
				fill="#fff"
				d="M26.926,36.394c-0.647-0.19-1.122-0.627-1.319-1.323c-0.037-0.094-0.179-0.094-0.216,0	c-0.197,0.697-0.672,1.133-1.319,1.323c-0.095,0.025-0.101,0.196,0,0.215c0.659,0.101,1.141,0.519,1.319,1.317	c0.021,0.098,0.194,0.098,0.216,0c0.178-0.798,0.659-1.216,1.319-1.317C27.028,36.591,27.021,36.42,26.926,36.394z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLf"
				x1="12.636"
				x2="14.597"
				y1="13.122"
				y2="9.435"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLf)"
				d="M13.5,40.5c-0.291,0-0.536-0.191-0.596-0.466c-0.124-0.559-0.412-0.854-0.906-0.931	c-0.287-0.052-0.486-0.286-0.497-0.571c-0.012-0.293,0.171-0.548,0.444-0.621c0.496-0.146,0.825-0.476,0.966-0.976	c0.108-0.283,0.333-0.436,0.589-0.436s0.481,0.152,0.573,0.389c0.157,0.547,0.486,0.877,0.994,1.026	c0.261,0.069,0.444,0.324,0.432,0.617c-0.011,0.285-0.21,0.52-0.483,0.569c-0.508,0.078-0.796,0.374-0.92,0.934	C14.036,40.309,13.791,40.5,13.5,40.5z"
			/>
			<path
				fill="#fff"
				d="M14.926,38.394c-0.647-0.19-1.122-0.627-1.319-1.323c-0.037-0.094-0.179-0.094-0.216,0	c-0.197,0.697-0.672,1.133-1.319,1.323c-0.095,0.025-0.101,0.196,0,0.215c0.659,0.101,1.141,0.519,1.319,1.317	c0.021,0.098,0.194,0.098,0.216,0c0.178-0.798,0.659-1.216,1.319-1.317C15.028,38.591,15.021,38.42,14.926,38.394z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLg"
				x1="8.349"
				x2="10.029"
				y1="19.217"
				y2="16.057"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLg)"
				d="M9,33.5c-0.235,0-0.511-0.213-0.561-0.443c-0.084-0.381-0.283-0.461-0.466-0.489	c-0.237-0.036-0.465-0.302-0.473-0.542c-0.01-0.273,0.164-0.512,0.422-0.579c0.27-0.08,0.446-0.256,0.524-0.535	C8.508,30.696,8.776,30.5,9,30.5c0.24,0,0.451,0.144,0.538,0.365c0.094,0.325,0.27,0.501,0.555,0.585	c0.219,0.064,0.416,0.348,0.407,0.575c-0.009,0.27-0.197,0.491-0.457,0.539c-0.199,0.031-0.397,0.111-0.482,0.495	C9.509,33.288,9.234,33.5,9,33.5z"
			/>
			<path
				fill="#fff"
				d="M9.951,31.93c-0.431-0.127-0.748-0.418-0.879-0.882c-0.024-0.063-0.119-0.063-0.144,0	c-0.131,0.464-0.448,0.756-0.879,0.882c-0.063,0.017-0.068,0.131,0,0.144c0.439,0.068,0.761,0.346,0.879,0.878	c0.014,0.065,0.13,0.065,0.144,0c0.118-0.532,0.44-0.811,0.879-0.878C10.018,32.06,10.014,31.946,9.951,31.93z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLh"
				x1="8.953"
				x2="10.273"
				y1="14.524"
				y2="12.042"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLh)"
				d="M9.5,37.75c-0.236,0-0.494-0.201-0.543-0.432c-0.049-0.221-0.149-0.255-0.247-0.27	c-0.237-0.036-0.452-0.286-0.46-0.525c-0.009-0.263,0.158-0.492,0.407-0.56c0.162-0.047,0.26-0.146,0.308-0.314	C9.026,35.434,9.276,35.25,9.5,35.25c0.233,0,0.437,0.14,0.52,0.355c0.063,0.213,0.161,0.312,0.334,0.362	c0.219,0.063,0.404,0.327,0.396,0.556c-0.009,0.259-0.19,0.474-0.441,0.522c-0.117,0.018-0.217,0.052-0.267,0.276	C9.991,37.551,9.735,37.75,9.5,37.75L9.5,37.75z"
			/>
			<path
				fill="#fff"
				d="M10.213,36.447c-0.323-0.095-0.561-0.313-0.659-0.662c-0.018-0.047-0.089-0.047-0.108,0	c-0.098,0.348-0.336,0.567-0.659,0.662c-0.048,0.013-0.051,0.098,0,0.108c0.33,0.051,0.571,0.26,0.659,0.659	c0.011,0.049,0.097,0.049,0.108,0c0.089-0.399,0.33-0.608,0.659-0.659C10.264,36.545,10.261,36.46,10.213,36.447z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLi"
				x1="27.953"
				x2="29.37"
				y1="17.524"
				y2="14.859"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLi)"
				d="M28.5,34.75c-0.236,0-0.494-0.201-0.543-0.432c-0.049-0.221-0.149-0.255-0.247-0.27	c-0.237-0.036-0.452-0.286-0.46-0.525c-0.009-0.263,0.158-0.492,0.407-0.56c0.162-0.047,0.26-0.146,0.308-0.314	c0.061-0.216,0.311-0.399,0.535-0.399c0.233,0,0.437,0.14,0.52,0.355c0.063,0.213,0.161,0.312,0.334,0.362	c0.219,0.063,0.404,0.327,0.396,0.556c-0.009,0.259-0.19,0.474-0.441,0.522c-0.117,0.018-0.217,0.052-0.267,0.276	C28.991,34.551,28.735,34.75,28.5,34.75L28.5,34.75z"
			/>
			<path
				fill="#fff"
				d="M29.213,33.447c-0.323-0.095-0.561-0.313-0.659-0.662c-0.018-0.047-0.089-0.047-0.108,0	c-0.098,0.348-0.336,0.567-0.659,0.662c-0.048,0.013-0.051,0.098,0,0.108c0.33,0.051,0.571,0.26,0.659,0.659	c0.011,0.049,0.097,0.049,0.108,0c0.089-0.399,0.33-0.608,0.659-0.659C29.264,33.545,29.261,33.46,29.213,33.447z"
			/>
			<linearGradient
				id="nYbKQiXtPqxRXVxyAj1HLj"
				x1="19.404"
				x2="20.79"
				y1="11.27"
				y2="8.298"
				gradientTransform="matrix(1 0 0 -1 0 50)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#436dcd" />
				<stop offset="1" stopColor="#14427f" />
			</linearGradient>
			<path
				fill="url(#nYbKQiXtPqxRXVxyAj1HLj)"
				d="M20,41.5c-0.235,0-0.51-0.212-0.56-0.442c-0.085-0.382-0.284-0.462-0.466-0.49	c-0.237-0.036-0.465-0.302-0.473-0.542c-0.01-0.273,0.164-0.512,0.422-0.58c0.27-0.08,0.446-0.255,0.524-0.534	C19.508,38.696,19.776,38.5,20,38.5c0.241,0,0.452,0.144,0.538,0.367c0.093,0.324,0.27,0.5,0.554,0.583	c0.219,0.064,0.416,0.347,0.407,0.575c-0.009,0.269-0.197,0.491-0.457,0.54c-0.199,0.031-0.397,0.111-0.482,0.495	C20.509,41.289,20.234,41.5,20,41.5z"
			/>
			<path
				fill="#fff"
				d="M20.951,39.93c-0.431-0.127-0.748-0.418-0.879-0.882c-0.024-0.063-0.119-0.063-0.144,0	c-0.131,0.464-0.448,0.756-0.879,0.882c-0.063,0.017-0.068,0.131,0,0.144c0.439,0.068,0.761,0.346,0.879,0.878	c0.014,0.065,0.13,0.065,0.144,0c0.118-0.532,0.44-0.811,0.879-0.878C21.018,40.06,21.014,39.946,20.951,39.93z"
			/>
		</svg>
	);
};
