import { StyledSquareIconButton } from "../../../Component/Styled/Button";
import UploadIcon from "@mui/icons-material/Upload";
import { useUploadType } from "../../../Upload";
import UploadDeviceIcon from "@mui/icons-material/UploadFile";
export default function UploadContentButton() {
	const { uploadType } = useUploadType({
		type: "CONTENT",
	});

	return (
		<StyledSquareIconButton
			className="library-upload-from-device"
			title="Upload Content"
			onClick={() => {
				uploadType("CONTENT");
			}}
			sx={{
				border: "none",
			}}
		>
			<UploadDeviceIcon />
		</StyledSquareIconButton>
	);
}
