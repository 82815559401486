import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useRequester } from "../../../../../Apollo";
import { useSelectedContent } from "../../../../SelectedContentContext";
export default function ExifDataDataGrid() {
	const { id } = useSelectedContent();
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0,
	});
	const file = data?.content?.source;

	const exif = {
		create_date: file.exif.create_date,
		data: Object.entries(JSON.parse(file.exif.data || [])[0]),
		gps_latitude: file.exif.gps_latitude,
		gps_longitude: file.exif.gps_longitude,
	};

	return (
		<>
			<DataGrid
				autoHeight
				density="compact"
				loading={false}
				columns={[
					{ field: "name", headerName: "Name", width: "200" },
					{
						field: "value",
						headerName: "Value",
						flex: 1,
					},
				]}
				showCellVerticalBorder
				rows={[
					{
						id: 8,

						name: "Exif Create Date",

						value: exif.create_date || "N/A",
					},

					...exif.data.map(([key, value]) => {
						return {
							id: key,
							name: key,
							value: value,
						};
					}),

					{
						id: 9,
						name: "Exif GPS Latitude",

						value: exif.gps_latitude || "N/A",
					},
					{
						id: 10,
						name: "Exif GPS Longitude",
						value: exif.gps_longitude || "N/A",
					},
				]}
				disableRowSelectionOnClick
				// hideFooter
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
			/>
		</>
	);
}
