export class TimelineTrack {
	constructor({ id, actions, assetType, name }) {
		this.id = id;
		this.actions = actions;
		this.assetType = assetType;
		this.name = name;
	}
}
export class TimelineAsset {
	constructor({
		id,
		assetType,
		minStart,
		maxEnd,
		start,
		end,
		movable,
		flexible,
		timeline,
		prev,
		data,
		selected,
		trim,
	}) {
		this.id = id;
		this.assetType = assetType;
		this.minStart = minStart;
		this.maxEnd = maxEnd;
		this.start = start;
		this.end = end;
		this.movable = movable;
		this.flexible = flexible;
		this.timeline = timeline;
		this.selected = selected;
		this.data = data;
		this.prev = prev;
		this.trim = trim;
	}
}
