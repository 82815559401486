import { useContentEditor } from "../../../..";
import TheatersRoundedIcon from "@mui/icons-material/TheatersRounded";
import WatermarkIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import AudiotrackTwoToneIcon from "@mui/icons-material/AudiotrackTwoTone";
import VideoIcon from "@mui/icons-material/VideoCallOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
export default function useActions() {
	const { setOnView } = useContentEditor();

	return [
		{
			label: "Project",
			value: "project",
			Icon: () => <AccountTreeIcon />,
			onClick: () => {
				setOnView("project");
			},
		},
		{
			label: "Content",
			value: "content",
			Icon: () => <VideoIcon />,
			onClick: () => {
				setOnView("content");
			},
		},
		{
			label: "Watermark",
			value: "watermark",
			Icon: () => <WatermarkIcon />,
			onClick: () => {
				setOnView("watermark");
			},
		},
		{
			label: "Audio Overlay",
			value: "audio",
			Icon: () => <AudiotrackTwoToneIcon />,
			onClick: () => {
				setOnView("audio");
			},
		},
		{
			label: "Bumpers",
			value: "bumpers",
			Icon: () => <TheatersRoundedIcon />,
			onClick: () => {
				setOnView("bumpers");
			},
		},
	];
}
