import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useContentEditor } from "../../../../../common/context";
import { FPS } from "../../../../../common/constants";
export default function FPSOption() {
	const {
		projectId,
		setFPS,
		variables: {
			input: {
				options: { fps },
			},
		},
	} = useContentEditor();

	return (
		<FormControl fullWidth>
			<InputLabel>FPS</InputLabel>
			<Select
				key={projectId}
				defaultValue={fps}
				onChange={e => setFPS(e.target.value)}
			>
				{FPS.map(value => {
					return (
						<MenuItem key={value} value={value}>
							{value}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
