import { gql } from "@apollo/client";
export const redgifs_getTags = gql`
	query GetRedGIFsTags($input: AllMetadataTagsInput!) {
		metadataTags(input: $input) {
			tags {
				id
				source
				value
			}
		}
	}
`;

export const reddit_getPopularSubreddits = gql`
	query GetPopularSubreddits($input: RedditPopularSubredditsInput) {
		redditPopularSubreddits(input: $input) {
			subreddits {
				allow_images
				allow_videos
				banner_img
				community_icon
				icon_img
				created
				description
				display_name
				header_img
				header_title
				id
				name
				over18
				public_description
				submit_link_label
				submit_text
				submit_text_label
				subreddit_type
				subscribers
				title
				url
			}
		}
	}
`;
export const reddit_subredditFavoriteSearch = gql`
	query ($input: SubredditFavoriteSearchInput!) {
		subredditFavoriteSearch(input: $input) {
			allow_images
			allow_videos
			banner_img
			community_icon
			icon_img
			created
			description
			display_name
			header_img
			header_title
			id
			name
			over18
			public_description
			submit_link_label
			submit_text
			submit_text_label
			subreddit_type
			subscribers
			title
			url
		}
	}
`;
export const integration = gql`
	query Integration($input: IntegrationQueryInput!) {
		integration(input: $input) {
			destination
			username
			status
			avatar
			is_default
		}
	}
`;

export const integrations = gql`
	query Integrations($input: IntegrationsQueryInput) {
		integrations(input: $input) {
			destination
			username
			status
			avatar
			is_default
		}
	}
`;

export const integrationMessagingList = gql`
	query Integration($input: IntegrationQueryInput!) {
		integration(input: $input) {
			username
			messagingList {
				id
				name
				usersCount
			}
		}
	}
`;

export const publishPreValidate = gql`
	query PublishPreValidate($input: SubmissionPreValidateInput!) {
		submissionPreValidate(input: $input) {
			submissions {
				id
				title
				publicationDestination
				config {
					imgur {
						album
						description
						disable_audio
						name
					}
					reddit {
						subreddit
					}
					redgifs {
						keepAudio
						private
						tags
					}
				}
			}
		}
	}
`;

export const import_of_status = gql`
	query Import_of_status {
		import_of_status {
			ready
		}
	}
`;
