import { Avatar, Box, Typography } from "@mui/material";
import { useAvatarCacheContext } from "@/Component/AvatarCacheContext";
import { useRequester } from "@/Apollo";
const AdminAvatarLabel = () => {
	return (
		<Box
			sx={{
				position: "absolute",
				bottom: "0",
				right: "0",

				display: "flex",
				justifyContent: "center",

				width: "100%",
				textAlign: "center",
				height: "fit-content",
				// gradient from bottom to top
				backgroundImage: theme =>
					`linear-gradient(to top, ${theme.palette.background.default}, transparent)`,
			}}
		>
			<Typography
				variant="caption"
				color={theme => theme.palette.info.main}
				sx={{
					fontWeight: "bold",
					textShadow: theme =>
						`0px 0px 5px ${theme.palette.background.default}`,
				}}
			>
				ADMIN
			</Typography>
		</Box>
	);
};
export default function AuthenticatedAvatar() {
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "cache-first",
	});
	const { userAvatar: src } = useAvatarCacheContext();

	return (
		<Box
			sx={{
				position: "relative",

				width: "fit-content",
				height: "100%",
				display: "flex",

				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Avatar src={src} />
			{data?.user?.admin && <AdminAvatarLabel />}
		</Box>
	);
}
