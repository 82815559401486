export const Icon = ({ height = "48px", width = "48px" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 16 16"
			width={width}
			height={height}
			baseProfile="basic"
		>
			<path
				fill="#fe3155"
				d="M8,0c4.418,0,8,3.582,8,8s-3.582,8-8,8s-8-3.582-8-8S3.582,0,8,0z"
			/>
			<path
				fill="#fff"
				d="M12.124,6.875c-0.285,0.009-0.558,0.127-0.766,0.324c-0.911-0.62-1.98-0.96-3.08-0.984l0.52-2.496	l1.711,0.36c0.047,0.44,0.442,0.758,0.882,0.711c0.438-0.049,0.756-0.442,0.709-0.882c-0.047-0.44-0.442-0.758-0.88-0.709	c-0.253,0.025-0.48,0.173-0.605,0.391l-1.96-0.391C8.522,3.167,8.387,3.251,8.358,3.387c0,0.002,0,0.002,0,0.004L7.767,6.167	c-1.113,0.016-2.198,0.36-3.12,0.984c-0.469-0.442-1.209-0.42-1.651,0.051c-0.442,0.471-0.42,1.209,0.053,1.651	c0.091,0.085,0.196,0.16,0.313,0.211c-0.007,0.118-0.007,0.235,0,0.353c0,1.791,2.089,3.249,4.664,3.249	c2.576,0,4.664-1.456,4.664-3.249c0.007-0.118,0.007-0.234,0-0.353c0.402-0.2,0.655-0.615,0.645-1.064	C13.311,7.356,12.771,6.853,12.124,6.875z M5.335,8.8c0-0.442,0.358-0.8,0.802-0.8c0.442,0,0.8,0.358,0.8,0.8s-0.358,0.8-0.8,0.8	S5.335,9.242,5.335,8.8z M9.971,11C9.616,11.328,8.988,11.616,8,11.616S6.384,11.328,6.029,11c-0.073-0.067-0.062-0.227,0.031-0.304	c0.078-0.065,0.193-0.065,0.275,0c0.48,0.353,1.07,0.48,1.665,0.48s1.185-0.127,1.665-0.48c0.082-0.065,0.196-0.065,0.275,0	C10.033,10.773,10.044,10.933,9.971,11z M9.842,9.6C9.4,9.6,9.04,9.242,9.04,8.8C9.042,8.358,9.4,8,9.842,8	c0.442,0,0.8,0.358,0.8,0.8S10.284,9.6,9.842,9.6z"
			/>
		</svg>
	);
};
