import OauthClientList from "@/Component/Oauth/OauthClientList";
import { Typography, Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { BodyHeight } from "../../../../Layout";
import DocsIcon from "@mui/icons-material/DescriptionOutlined";
import ForwardArrow from "@mui/icons-material/ArrowForward";
export default function PageApi() {
	return (
		<BodyHeight
			sx={{
				padding: "1rem",
			}}
		>
			<Box
				sx={{
					marginBottom: "12px",
				}}
			>
				<Typography variant="h5">Oauth Api</Typography>

				<Stack
					spacing={1}
					sx={{
						marginY: "1rem",
					}}
				>
					<Link target="_blank" to="https://api.fanheat.com/api/docs">
						<DocsIcon fontSize="inherit" />
						Read the docs
					</Link>
					<Link to="/api/connections">
						<ForwardArrow fontSize="inherit" />
						Manage Connections
					</Link>
				</Stack>
			</Box>

			<OauthClientList />
		</BodyHeight>
	);
}
