import { useContentSearch } from "@/ContentSearch/Context";

export const useContentSearchFilter = ({ attribute }) => {
	const { filters, setFilter, availableFilters } = useContentSearch();
	const found = availableFilters.find(f => f.name === attribute);
	if (!found) throw new Error(`Filter ${attribute} not found`);

	return {
		min: found.min,
		max: found.max,
		options: found.options,
		currentValue: filters[attribute],
		refine: value => setFilter(attribute, value),
	};
};
