import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Suspend } from "../Suspend";
import { ErrorBoundary } from "react-error-boundary";
import PageError from "./Page/Error"; // can't lazyload the error page. needs to be available immediately
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingScreen from "../../Component/LoadingScreen";
// import LayoutProvider from "../../Layout";
// const Page404 = lazy(() => import("./Page/404"));
// const PageContact = lazy(() => import("./Page/Contact"));
// const PageSignIn = lazy(() => import("./Page/SignIn"));
// const PageHome = lazy(() => import("./Page/Home"));
// const PageAbout = lazy(() => import("./Page/About"));
// const PageLanding = lazy(() => import("./Page/Landing"));
// const PageSettings = lazy(() => import("./Page/Settings"));
// const PageSubscribe = lazy(() => import("./Page/Subscribe"));

// import PageHome from "./Page/Home";

import Page404 from "./Page/404";

import PageContact from "./Page/Workspace/Content";
import PageAbout from "./Page/About";
import PageTOS from "./Page/Legal/TOS";
import PagePrivacy from "./Page/Legal/Privacy";
import PageCookies from "./Page/Legal/Cookies";
import PageLanding from "./Page/Landing";
import PageAccount from "./Page/Account";
import PageSubscribe from "./Page/Subscribe";

// import PageSignIn from "./Page/SignIn";
// import PageRegister from "./Page/Register";

import PageAuthorize from "./Page/Authorize";
import PageSignOut from "./Page/SignOut";

import PageAdmin from "./Page/Admin";

import PageApi from "./Page/Api";
import PageApiConfirmConnect from "./Page/Api/ConfirmConnect";
import PageApiOauthAppConnections from "./Page/Api/Connections";

import PageWorkspace from "./Page/Workspace";
import PageWorkspaceHome from "./Page/Workspace/Home";
import PageWorkspaceLibrary from "./Page/Workspace/Library";
import PageWorkspaceContent from "./Page/Workspace/Content";
import PageWorkspaceSubmission from "./Page/Workspace/Submission";
import PageWorkspaceFolders from "./Page/Workspace/Folders";
import PageWorkspaceSchedule from "./Page/Workspace/Schedule";
import PageWorkspaceSettings from "./Page/Workspace/Settings";
import PageWorkspaceAssistants from "./Page/Workspace/Assistants";
import PageWorkspaceAssistantsInvitations from "./Page/Workspace/Assistants/Invitations";
import PageWorkspaceAssistantsAssistant from "./Page/Workspace/Assistants/Assistant";
import PageWorkspaceAssistantsRoles from "./Page/Workspace/Assistants/Roles";
import PageWorkspaceAssistantsRole from "./Page/Workspace/Assistants/Roles/Role";
import PageWorkspaceEditor from "./Page/Workspace/Editor";
import PageWorkspaceAutomations from "./Page/Workspace/Automations";
import PageWorkspaceLogs from "./Page/Workspace/Logs";
import PageWorkspaceIntegrations from "./Page/Workspace/Integrations";
import PageWorkspaceImportBatch from "./Page/Workspace/ImportBatch";
import PageWorkspaceMatch from "./Page/Workspace/Match";

import { Grid } from "@mui/material";

const ProtectedRoute = ({ component }) => {
	const location = useLocation();

	const returnTo = location.pathname + location.search;
	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => (
			<Grid
				container
				sx={{
					height: "var(--app-height)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Helmet title={"Log in to 🔥FanHeat to continue"} />
				<LoadingScreen
					loading
					message={`redirecting to log in page...`}
				/>
			</Grid>
		),
		loginOptions: {
			authorizationParams: {
				// /authorize page will redirect to the returnTo url
				redirect_uri: `${process.env.REACT_APP_AUTH0_CALLBACK_URL}?returnTo=${returnTo}`,
				// these scopes are required to create the access token
				// used to interact with the api
				scope: "read:current_user offline_access",
			},
			fragment: location.pathname,
		},
		returnTo,
	});
	return <Component />;
};

export default function AppRoutes() {
	const location = useLocation();

	return (
		<ErrorBoundary
			// resetKeys is used to reset the error boundary when the location changes
			// without this the router context will lose its location state
			// and navigating to a new page will not work
			resetKeys={[location.pathname, location.search]}
			FallbackComponent={() => <PageError />}
			onError={(error, componentStack, resetErrorBoundary) => {
				console.log("Error rendering route: ", {
					error,
					componentStack,
					resetErrorBoundary,
				});
			}}
		>
			<Routes>
				<Route
					path="/"
					element={
						<Suspend>
							<PageLanding />
						</Suspend>
					}
				/>
				<Route
					path="/about"
					element={
						<Suspend>
							<PageAbout />
						</Suspend>
					}
				/>
				<Route
					path="terms"
					element={
						<Suspend>
							<PageTOS />
						</Suspend>
					}
				/>
				<Route
					path="privacy"
					element={
						<Suspend>
							<PagePrivacy />
						</Suspend>
					}
				/>
				<Route
					path="cookies"
					element={
						<Suspend>
							<PageCookies />
						</Suspend>
					}
				/>
				<Route
					path="/authorize"
					element={
						<Suspend>
							<PageAuthorize />
						</Suspend>
					}
				/>
				<Route
					path="/contact"
					element={
						<Suspend>
							<Helmet title="Contact FanHeat" />
							<PageContact />
						</Suspend>
					}
				/>
				<Route
					path="/signout"
					element={
						<Suspend>
							<PageSignOut />
						</Suspend>
					}
				/>
				{/* 
				<Route
					path="/register"
					element={
						<Suspend>
							<PageRegister />
						</Suspend>
					}
				/> */}
				<Route
					path="/account"
					element={
						<Suspend variant="SPA">
							<Helmet title="Manage your FanHeat account" />
							<ProtectedRoute component={PageAccount} />
						</Suspend>
					}
				/>
				<Route
					path={"/api"}
					exact={false}
					element={
						<Suspend>
							<Helmet title="API" />
							<ProtectedRoute component={PageApi} />
						</Suspend>
					}
				/>
				<Route
					path={"/api/connections"}
					exact={false}
					element={
						<Suspend>
							<Helmet title="API" />
							<ProtectedRoute
								component={PageApiOauthAppConnections}
							/>
						</Suspend>
					}
				/>
				<Route
					path={"/api/connect"}
					exact={false}
					element={
						<Suspend>
							<Helmet title="API" />
							<ProtectedRoute component={PageApiConfirmConnect} />
						</Suspend>
					}
				/>
				<Route
					path={"/admin"}
					exact={false}
					element={
						<Suspend>
							<Helmet title="Admin" />
							<ProtectedRoute component={PageAdmin} />
						</Suspend>
					}
				/>
				<Route
					path={"/subscribe"}
					exact={false}
					element={
						<Suspend>
							<Helmet title="Admin" />
							<PageSubscribe />
						</Suspend>
					}
				/>
				<Route
					path={"/workspace"}
					exact={true}
					element={
						<Suspend>
							<Helmet title="Workspace" />
							<ProtectedRoute component={PageWorkspace} />
						</Suspend>
					}
				>
					<Route
						index
						exact={true}
						element={
							<Suspend>
								<Helmet title="Workspace Home" />
								<ProtectedRoute component={PageWorkspaceHome} />
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/home"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Home" />
								<ProtectedRoute component={PageWorkspaceHome} />
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/library"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Library" />
								<ProtectedRoute
									component={PageWorkspaceLibrary}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/library/folders"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Folders" />
								<ProtectedRoute
									component={PageWorkspaceFolders}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/match/:id"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Content Match" />
								<ProtectedRoute
									component={PageWorkspaceMatch}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/match"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Content Match" />
								<ProtectedRoute
									component={PageWorkspaceMatch}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/integrations"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Integrations" />
								<ProtectedRoute
									component={PageWorkspaceIntegrations}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/assistants"}
						index
						exact={true}
						element={
							<Suspend>
								<Helmet title="Workspace Assistants" />
								<ProtectedRoute
									component={PageWorkspaceAssistants}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/assistants/:id"}
						index
						exact={true}
						element={
							<Suspend>
								<Helmet title="Workspace Assistants" />
								<ProtectedRoute
									component={PageWorkspaceAssistantsAssistant}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/assistants/roles"}
						index
						exact={true}
						element={
							<Suspend>
								<Helmet title="Roles and Permissions" />
								<ProtectedRoute
									component={PageWorkspaceAssistantsRoles}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/assistants/roles/:id"}
						index
						exact={true}
						element={
							<Suspend>
								<Helmet title="Roles and Permissions" />
								<ProtectedRoute
									component={PageWorkspaceAssistantsRole}
								/>
							</Suspend>
						}
					/>

					<Route
						path={"/workspace/schedule"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Content Schedule" />
								<ProtectedRoute
									component={PageWorkspaceSchedule}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/automations"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Automations" />
								<ProtectedRoute
									component={PageWorkspaceAutomations}
								/>
							</Suspend>
						}
					/>

					<Route
						path={"/workspace/assistants"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Virtual Assistants" />
								<ProtectedRoute
									component={() => <>Virtual Assistants</>}
								/>
							</Suspend>
						}
					/>

					<Route
						path={"/workspace/editor"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Content Editor" />
								<ProtectedRoute
									component={() => <PageWorkspaceEditor />}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/logs"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Logs" />
								<ProtectedRoute
									component={() => <PageWorkspaceLogs />}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/content/:id"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Content" />
								<ProtectedRoute
									component={() => <PageWorkspaceContent />}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/submission/:id"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Submission" />
								<ProtectedRoute
									component={() => (
										<PageWorkspaceSubmission />
									)}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/import/:id"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Import" />
								<ProtectedRoute
									component={() => (
										<PageWorkspaceImportBatch />
									)}
								/>
							</Suspend>
						}
					/>
					<Route
						path={"/workspace/settings"}
						exact={false}
						element={
							<Suspend>
								<Helmet title="Workspace Settings" />
								<ProtectedRoute
									component={() => <PageWorkspaceSettings />}
								/>
							</Suspend>
						}
					/>
				</Route>
				<Route
					path={"*"}
					exact={false}
					element={
						<Suspend>
							<Helmet title="Home" />
							<ProtectedRoute component={Page404} />
						</Suspend>
					}
				/>
			</Routes>
		</ErrorBoundary>
	);
}
