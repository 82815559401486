import { useRequester } from "@/Apollo";
import WorkspaceWidgetWrapper from "../Wrapper";
export default function WorkspaceWidgetStats() {
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(
		definitions.notifications.query.countNewNotifications
	);

	return (
		<WorkspaceWidgetWrapper
			title={"Unread Notifications"}
			href="/workspace/logs"
			body={data?.countNewNotifications?.count}
		/>
	);
}
