import { BodyHeight } from "@/Layout";
import ImportBatch from "@/Component/ImportBatch";
import { useParams } from "react-router-dom";
export default function ViewImportBatchPage() {
	const { id } = useParams();
	return (
		<BodyHeight>
			<ImportBatch id={id} />
		</BodyHeight>
	);
}
