import { usePublishContentForm } from "..";
import { Stack } from "@mui/material";
import NextButton from "../NextButton";

import { HotTitles } from "../../HotTitles";
export default function BasicOptions() {
	const { title, setTitle, nextStep } = usePublishContentForm();
	return (
		<Stack
			component={"form"}
			onSubmit={e => {
				e.preventDefault();
				nextStep();
			}}
		>
			{title !== null && ( // don't load the hot titles component until the data is returned from the api and the default title is set
				<HotTitles
					onSuggest={suggestion => {
						if (title) return;
						setTitle(suggestion);
					}}
					onChange={newTitle => {
						if (newTitle === title) return;
						setTitle(newTitle || "");
					}}
					defaultQuery={title || ""}
				/>
			)}

			<NextButton
				disabled={!title}
				title={!title ? "Enter a title before continuing" : null}
			/>
		</Stack>
	);
}
