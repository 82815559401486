import { gql } from "@apollo/client";
export const bumpers = gql`
	query getBumperList($cursor: Cursor) {
		allBumpers(after: $cursor) {
			pageInfo {
				endCursor
				hasNextPage
			}
			totalCount
			edges {
				cursor
				node {
					id
					name
					file {
						id
						name
						url
						isProcessed
						mimetype
						... on VideoFile {
							thumbnailUrl
							url
							duration
						}
						... on ImageFile {
							thumbnailUrl
						}
						... on AudioFile {
							thumbnailUrl
						}
					}
				}
			}
		}
	}
`;

export const watermarks = gql`
	query (
		$status: AssetStatusEnum
		$type: WatermarkTypeEnum
		$after: Cursor
		$before: Cursor
		$first: Int
		$last: Int
	) {
		allWatermarks(
			status: $status
			type: $type
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			edges {
				cursor
				node {
					id
					name
					type
					file {
						id
						url
						name
						mimetype
						... on ImageFile {
							isProcessed
							thumbnailUrl
							url
							duration
							width
							height
						}

						... on AudioFile {
							id
							url
							duration
							thumbnailUrl
						}
					}
					... on ImageWatermark {
						image {
							id
							isProcessed
							url
							duration
							thumbnailUrl
						}
					}
					... on TextWatermark {
						attributes {
							text
							font
							color
							fontSize
							url
						}
					}
				}
			}
		}
	}
`;
