import {
	Grid,
	Card,
	CardContent,
	FormControlLabel,
	Switch,
} from "@mui/material";
import depthEffect from "../../Theme/sx/depth-effect";
export default function SettingsPanel({ options, className }) {
	return (
		<Card
			className={className}
			sx={{
				...depthEffect(),
				maxWidth: 500,
			}}
		>
			<CardContent>
				<Grid container spacing={2}>
					{options.map(
						(
							{ value, name, onChange, label, className },
							index
						) => {
							return (
								<Grid
									item
									xs={12}
									key={index}
									className={className}
								>
									<FormControlLabel
										control={
											<Switch
												name={name}
												checked={!!value}
												color="primary"
											/>
										}
										label={label}
										onChange={onChange}
									/>
								</Grid>
							);
						}
					)}
				</Grid>
			</CardContent>
		</Card>
	);
}
