import { useEffect, useState, memo } from "react";
import { Grid, Box, Breadcrumbs, Typography } from "@mui/material";
import { DndContext, DragOverlay } from "@dnd-kit/core";

import { useRequester } from "../../Apollo";
import useLibraryMediaQuery from "../../InstantSearch/view/useIsWidescreen";
import LoadingScreen from "../LoadingScreen";
import { ContentCard, FolderCard, CleanContentCard } from "./Card";
import TreeView from "./TreeView";
import { useFolderContent } from "./TreeView/hook/useFolderContent";
import PageLibraryFolderOutlet from "../../Router/Routes/Page/Workspace/Folders/Folder";

const RootFolderBreadCrumb = () => {
	const { setSelectedFolder } = useFolderContent();
	return (
		<Typography
			sx={{
				cursor: "pointer",
				"&:hover": {
					textDecoration: "underline",
				},
			}}
			onClick={() => setSelectedFolder(null)}
		>
			Root
		</Typography>
	);
};
const FolderBreadCrumb = () => {
	const { selectedFolder: id } = useFolderContent();
	const { definitions, useQuery } = useRequester();
	const { data, called, loading } = useQuery(
		definitions.content.query.folder,
		{
			variables: {
				folderId: id,
			},
			fetchPolicy: "cache-first",
		}
	);

	let folderName;
	if (called && !loading) folderName = data?.folder.name;
	if (!folderName) return "Untitled Folder";

	return <Typography>{folderName}</Typography>;
};
const SelectedContentBreadCrumb = () => {
	const { selectedId } = useFolderContent();
	const { definitions, useQuery } = useRequester();
	const { data, loading } = useQuery(definitions.content.query.getContent, {
		variables: {
			id: selectedId,
		},
	});
	const getContentName = () => {
		if (loading) return "";
		return data?.content.title;
	};
	if (!selectedId) return "";
	return <>{selectedId && getContentName()}</>;
};
const ActiveContentDragOverlay = memo(({ activeContent }) => {
	return (
		<DragOverlay
			wrapperElement="div"
			dropAnimation={{
				duration: 500,
				keyframes: e => {
					const hasMoved = Object.entries(e.transform.initial).some(
						([key, value]) => {
							return value !== e.transform.final[key];
						}
					);
					if (!hasMoved) return [];
					// TODO: [FANHEAT-220] position the card under the cursor when it is dropped
					return [
						{ transform: "scale(1)" },
						{ transform: "scale(.8)" },
						{ transform: "scale(.5)" },
						{ transform: "scale(.4)" },
						{ transform: "scale(.3)" },
						{ transform: "scale(.2)" },
					];
				},
				easing: "cubic-bezier(0.25, 0.1, 0.25, 1)",
			}}
		>
			{activeContent && (
				<Box
					sx={{
						position: "fixed",
						width: "100%",
						height: "100%",
						pointerEvents: "none",
						zIndex: 9999,
					}}
				>
					<CleanContentCard
						content={activeContent}
						sx={{
							opacity: 0.5,
							pointerEvents: "none",
						}}
					/>
				</Box>
			)}
		</DragOverlay>
	);
});
export default function LibraryFolderView() {
	const { setSelectedId, selectedId, selectedFolder } = useFolderContent();

	const [activeContent, setActiveContent] = useState(null);
	const { definitions, useQuery, useMutation } = useRequester();
	const { data: foldersData, loading: foldersLoading } = useQuery(
		definitions.content.query.folders
	);
	const {
		data: contentData,
		fetchMore,
		refetch,
	} = useQuery(definitions.content.query.allContent, {
		variables: {
			folderId: selectedFolder,
			last: 100,
		},
		fetchPolicy: "cache-first",
		errorPolicy: "all",
	});
	const content = contentData?.allContent.edges || [];
	useEffect(() => {
		// when content is set into preview pane
		// the selected id is also set
		// in order to display the folder name in the breadcrumbs
		// so here we need to reset selectedId when navigating
		// to a new folder so that the breadcrumbs don't show the
		// content name that existed in the previously selected folder
		setSelectedId(null);
	}, [selectedFolder]);
	const [folderAddContent] = useMutation(
		definitions.content.mutation.folderAddContent
	);
	const { isMD } = useLibraryMediaQuery();
	if (foldersLoading)
		return <LoadingScreen loading message={`fetching folders`} />;
	const folders = foldersData?.allFolders.edges || [];
	return (
		<Box
			onContextMenu={e => e.preventDefault()} // prevent right click menu near folders and content
			sx={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
				userSelect: "none",
			}}
		>
			<DndContext
				onDragStart={({ active }) => {
					setActiveContent(active.data.current.content);
				}}
				cancelDrop={data => {
					setActiveContent(null);
				}}
				onDragEnd={event => {
					if (!event.over) return;
					folderAddContent({
						variables: {
							input: {
								folderId: event.over?.id,
								contentIds: [event.active.id],
							},
						},
						onCompleted: () => {
							setActiveContent(null);
							refetch();
						},
					});
				}}
			>
				<ActiveContentDragOverlay activeContent={activeContent} />

				{isMD && (
					<Box
						sx={{
							width: "18rem",
							height: "100%",
							border: "1px solid black",
							backgroundColor: theme =>
								theme.palette.background.default,
						}}
					>
						<TreeView />
					</Box>
				)}

				<Grid
					container
					spacing={2}
					sx={{
						position: "relative",
						display: "flex",
						alignContent: "flex-start",
						height: "100%",

						overflowY: "scroll",
						overflowX: "hidden",
					}}
				>
					<Box
						sx={{
							width: "100%",
							padding: "1rem",
							paddingX: "1.5rem",
							position: "sticky",
							top: 0,

							// zIndex is set to 999 so that the breadcrumbs
							zIndex: 999,
						}}
					>
						<Breadcrumbs
							separator="›"
							aria-label="breadcrumb"
							sx={{
								backgroundColor: theme =>
									theme.palette.background.default,
								width: "fit-content",
							}}
						>
							<RootFolderBreadCrumb />
							{selectedFolder && <FolderBreadCrumb />}
							{selectedId && <SelectedContentBreadCrumb />}
						</Breadcrumbs>
					</Box>

					{!selectedFolder && (
						<>
							{folders.map(({ node: folder }) => {
								return (
									<FolderCard
										key={folder.id}
										folder={folder}
									/>
								);
							})}
							{content
								.filter(item => item.node)
								.map(({ node: content }) => {
									return (
										<ContentCard
											key={content.id}
											content={content}
										/>
									);
								})}
						</>
					)}

					{selectedFolder && <PageLibraryFolderOutlet />}
				</Grid>
			</DndContext>
		</Box>
	);
}
