import { useSnackbar } from "notistack";
import {
	Avatar,
	CardContent,
	Box,
	IconButton,
	ListItemText,
	ListItemAvatar,
	ListItem,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LabeledIcon from "../../LabeledIcon";
import { StatusChip } from "./StatusChip";

import { useRequester } from "../../../Apollo";
import IOSChip from "./Styles";
export default function IntegrationAccount({ integration }) {
	const { definitions, useMutation } = useRequester();
	const [removeIntegration] = useMutation(
		definitions.integrations.mutation.remove
	);
	const [setDefaultIntegration] = useMutation(
		definitions.integrations.mutation.setDefault
	);

	const { avatar, destination, is_default, proxy, status, username } =
		integration;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const handleRemove = () => {
		removeIntegration({
			onCompleted: () => {
				const key = enqueueSnackbar(
					`Removed ${username} integration for ${destination}.`,
					{
						variant: "success",
						onClick: () => closeSnackbar(key),
					}
				);
			},
			onError: () => {
				const key = enqueueSnackbar(
					`Failed to remove ${username} integration for ${destination}.`,
					{
						variant: "error",
						onClick: () => closeSnackbar(key),
					}
				);
			},

			refetchQueries: [
				{
					query: definitions.integrations.query.integrations,
					variables: {
						input: {
							destination: destination.toUpperCase(),
						},
					},
				},
			],
			awaitRefetchQueries: true,
			variables: {
				input: {
					destination: destination.toUpperCase(),
					username,
				},
			},
		});
	};
	const handleToggleDefault = () => {
		if (is_default) return;
		setDefaultIntegration({
			onCompleted: () => {
				let snackbarKey = enqueueSnackbar(
					`Set ${username} as default integration for ${destination}.`,
					{
						variant: "success",
						onClick: () => {
							closeSnackbar(snackbarKey);
						},
					}
				);
			},
			onError: () => {
				let snackbarKey = enqueueSnackbar(
					`Failed to set ${username} as default integration for ${destination}.`,
					{
						variant: "error",
						onClick: () => {
							closeSnackbar(snackbarKey);
						},
					}
				);
			},

			refetchQueries: [
				{
					query: definitions.integrations.query.integrations,
					variables: {
						input: {
							destination: destination.toUpperCase(),
						},
					},
				},
			],
			awaitRefetchQueries: true,
			variables: {
				input: {
					destination: destination.toUpperCase(),
					username,
				},
			},
		});
	};

	return (
		<>
			<ListItemAvatar>
				<Avatar src={avatar} />
			</ListItemAvatar>
			<ListItemText
				primary={
					<Typography
						color={theme => {
							if (status === "DISCONNECTED") {
								return theme.palette.error.main;
							}
							return theme.palette.info.main;
						}}
					>
						{username}
					</Typography>
				}
				secondary={status}
			/>
			<IconButton>
				<CloseIcon onClick={handleRemove} />
			</IconButton>
		</>
	);
}
