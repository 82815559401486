import { gql } from "@apollo/client";
export const notifications = gql`
	subscription NotificationsSubscription {
		notifications {
			notifications {
				acknowledged_at
				body
				id
				link
				subject
				timestamp
				entity_type
				level
				error_code
				thumbnail
				content {
					id
					source {
						... on VideoFile {
							thumbnailUrl
						}
						... on ImageFile {
							thumbnailUrl
						}
					}
				}
				submission {
					id
					createdAt
					title
					deferUntil
					submittedAt
					link
					publicationDestination
					contentID
					status
					integration {
						destination
						username
						avatar
						status
						is_default
					}
				}
				integration {
					id
					status
					username
					avatar
					destination
					is_default
				}
				import {
					id
					created_at
					completed_at
					source
					status
					importFilesConnection {
						totalCount
					}
				}
				match {
					id
					source {
						... on VideoFile {
							id
							hash
							thumbnailUrl
						}
						... on ImageFile {
							id
							hash
							thumbnailUrl
						}
					}
				}
			}
		}
	}
`;
