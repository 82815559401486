import { useRequester } from "@/Apollo";
export default function useSetAdditionalStorage() {
	const { useMutation, definitions } = useRequester();
	const [mutateSetAdditionalStorage] = useMutation(
		definitions.admin.mutation.SET_ADDITIONAL_STORAGE
	);
	return {
		setAdditionalStorage: async ({ userId, allotment }) => {
			return mutateSetAdditionalStorage({
				variables: {
					input: {
						userId,
						allotment,
					},
				},
			});
		},
	};
}
