import {
	Toolbar,
	Box,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { useContentEditor } from "../../..";
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined";
import useActions from "../hooks/useActions";
export default function ToolbarActions() {
	const {
		toolbarActionsWidth,
		onView,
		joyride: { run, nextStep, resetProgress },
	} = useContentEditor();
	const actions = useActions();

	return (
		<Box
			sx={{
				display: "flex",
				width: `${toolbarActionsWidth}px`,

				height: "100%",
				flexDirection: "column",
				// give background color paper to all children
				"& > *": {
					backgroundColor: "background.paper",
				},
			}}
		>
			<Toolbar
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				{/* <IconButton color="inherit">
					<HamburgerIcon />
				</IconButton> */}
			</Toolbar>
			<Divider />
			<List>
				{actions.map(({ label, value, Icon, onClick }, index) => {
					const isSelected = onView === value;
					return (
						<ListItem
							className={`toolbar-action-${value}`}
							key={index}
							disablePadding
							sx={{
								"& .MuiListItemIcon-root": {
									minWidth: "auto",
								},
								backgroundColor: theme => {
									// give selected highlight if selected
									if (isSelected) {
										return theme.palette.action.selected;
									}
									return "transparent";
								},
							}}
						>
							<ListItemButton
								onClick={onClick}
								sx={{
									display: "flex",
									flexDirection: "column",
									textAlign: "center",
								}}
							>
								<ListItemIcon>
									<Icon />
								</ListItemIcon>
								<ListItemText primary={label} />
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
			<Divider />
			<List
				sx={{
					flexGrow: 1,
				}}
			/>
			<List>
				<ListItem
					disablePadding
					sx={{
						"& .MuiListItemIcon-root": {
							minWidth: "auto",
						},
					}}
				>
					<ListItemButton
						onClick={async () => {
							await resetProgress();
							run(true);
						}}
						sx={{
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
						}}
					>
						<ListItemIcon>
							<HelpIcon />
						</ListItemIcon>
						<ListItemText primary={"Help"} />
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);
}
