import { gql } from "@apollo/client";

export const retry = gql`
	mutation PublishRetry($input: PublishRetryInput!) {
		publishRetry(input: $input) {
			submission {
				id
				createdAt
				title
				deferUntil
				submittedAt
				link
				publicationDestination
				config {
					reddit {
						parentSubmission {
							id
							createdAt
							title
							deferUntil
							submittedAt
							link
							publicationDestination
							contentID
							status
						}
						subreddit
					}
					imgur {
						name
						album
						description
						disable_audio
					}
					redgifs {
						private
						keepAudio
						tags
					}
					onlyfans {
						fundraising_target
						description
						dm_recipient
					}
				}
				integration {
					destination
					username
					avatar
					status
					is_default
					messagingList {
						id
						name
						usersCount
					}
					proxy {
						protocol
						host
						port
						username
						password
						approvedAt
						deniedAt
						isActive
						isDeleted
					}
				}
				contentID
				status
			}
		}
	}
`;
