import { useRequester } from "../../../Apollo";
import { useMultiselectContent } from "../..";
import { useSnackbar } from "notistack";
export default function useShareToChatAppMultiple() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { selected, getSelection } = useMultiselectContent();
	const { definitions, useMutation } = useRequester();
	const [approveMultiple, { client }] = useMutation(
		definitions.content.mutation.shareToChatApp
	);
	return {
		setShared: async share => {
			const contentIds = getSelection().map(content => content.id);

			await approveMultiple({
				variables: {
					input: {
						contentIds,
						share: !!share,
					},
				},
				onError: e => {
					const key = enqueueSnackbar(
						`Failed to ${share ? "share" : "un-share"} ${
							selected.size
						} items. ${e.message}`,
						{
							variant: "error",
							onClick: () => closeSnackbar(key),
						}
					);
				},
				onCompleted: () => {
					for (const id of contentIds) {
						const existingData = client.readQuery({
							query: definitions.content.query.getContent,
							variables: {
								id,
							},
						});
						if (!existingData) continue;
						client.writeQuery({
							query: definitions.content.query.getContent,
							variables: {
								id,
							},
							data: {
								content: {
									...existingData.content,
									shared: share,
								},
							},
						});
					}
					const key = enqueueSnackbar(
						`Successfully ${share ? "shared" : "un-shared"} ${
							selected.size
						} items.`,
						{
							variant: "success",
							onClick: () => closeSnackbar(key),
						}
					);
				},
			});

			return true;
		},
	};
}
