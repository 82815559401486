import { useRequester } from "../../Apollo";
import {
	CardContent,
	Grid,
	Card,
	CardHeader,
	Avatar,
	Typography,
	CardActions,
	InputLabel,
	Stack,
} from "@mui/material";
import depthEffect from "../../Theme/sx/depth-effect";
import LoadingScreen from "../../Component/LoadingScreen";
import WarningIcon from "@mui/icons-material/Warning";
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import ManageCustomerButton from "./ManageCustomerAccountButton";
import AddPaymentMethodButton from "./ManagePaymentMethodButton";
const convertBytesToGigabytes = bytes => {
	const gigabytes = bytes / 1000000000;
	return gigabytes.toFixed(2);
};

export default function SubscriptionQuota(props) {
	const { definitions, useQuery } = useRequester();
	const { data, loading } = useQuery(
		definitions.billing.query.getMySubscription,
		{
			fetchPolicy: "cache-first",
		}
	);

	const expires_at = new Date(data?.mySubscription?.expires_at);
	const renew_at = new Date(data?.mySubscription?.renew_at);

	const product = data?.mySubscription?.product;
	const formatExpiresAt = expires_at => {
		const date = new Date(expires_at);
		const month = date.toLocaleString("default", { month: "long" });
		const day = date.getDate();
		const year = date.getFullYear();
		return `${month} ${day}, ${year}`;
	};

	const now = new Date();
	const isExpired = expires_at < now;
	const tier = product?.tier;

	return (
		<Card
			className="account-subscription-card"
			sx={{
				width: "100%",
				maxWidth: 500,
				minHeight: 200,
				height: "100%",
				...depthEffect(),
				position: "relative",
			}}
		>
			<LoadingScreen
				loading={loading}
				absolute
				message={`calculating subscription quota`}
			>
				<CardHeader
					avatar={
						<Avatar
							sx={{
								backgroundColor: theme => {
									switch (tier) {
										case 3: {
											// gold
											return "#FFD700";
											break;
										}
										case 2: {
											// silver
											return "#acacac";

											break;
										}
										case 1: {
											// bronze
											return "#b08d57";
											break;
										}
										default: {
											// grey
											return "#838996";
											break;
										}
									}
								},
							}}
						>
							<InputLabel>{tier}</InputLabel>
						</Avatar>
					}
					subheader={product?.name}
					title="Subscription"
				/>
				<CardContent>
					<Grid container spacing={2}>
						{loading
							? []
							: [
									[
										{
											label: "Storage",
											color:
												product?.quota?.storage?.used >=
												product?.quota?.storage
													?.allotted
													? "warning"
													: "info",
											value: `${parseFloat(
												product?.quota?.storage?.used
											).toFixed(2)}GB / ${
												product?.quota?.storage
													?.allotted
											}GB`,
										},
										{
											label: "Submissions",
											color:
												product?.quota?.submissions
													?.used >=
												product?.quota?.submissions
													?.allotted
													? "warning"
													: "info",
											value: `${product?.quota?.submissions?.used} / ${product?.quota?.submissions?.allotted}`,
										},
										{
											label: "Render Hours",
											color:
												product?.quota?.renderHours
													?.used >=
												product?.quota?.renderHours
													?.allotted
													? "warning"
													: "info",
											value: `${product?.quota?.renderHours?.used} / ${product?.quota?.renderHours?.allotted}`,
										},

										{
											label: isExpired
												? "Subscription Expired"
												: "Renews At",
											color: isExpired
												? "warning"
												: "info",
											value: formatExpiresAt(renew_at),
										},
									],
							  ].map((section, i) => (
									<Grid item key={i} xs={12}>
										<Grid container spacing={2}>
											{section.map((item, i) => {
												return (
													<Grid item key={i} xs={12}>
														<InputLabel>
															<Typography
																color={theme => {
																	return theme
																		.palette[
																		item
																			.color
																	].main;
																}}
															>
																{item.color ===
																	"warning" && (
																	<WarningIcon />
																)}
																{item.label}
															</Typography>
														</InputLabel>
														<Typography
															color={item.color}
															variant="h6"
														>
															{item.value}
														</Typography>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
							  ))}
					</Grid>
				</CardContent>
				<CardActions>
					<Stack
						direction="column"
						spacing={2}
						sx={
							{
								// align text in buttons to the left side
							}
						}
					>
						<ManageSubscriptionButton />
						<AddPaymentMethodButton />
						<ManageCustomerButton />
					</Stack>
				</CardActions>
			</LoadingScreen>
		</Card>
	);
}
