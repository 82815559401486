import { IconButton, CardContent, CardActions } from "@mui/material";
import LabeledIcon from "../../LabeledIcon";
import AddIcon from "@mui/icons-material/Add";
import useOnlyFansExtension from "../OnlyFans/useOnlyFansExtension";
import { useRequester } from "../../../Apollo";
import { useSnackbar } from "notistack";

export default function AddNewIntegration({ destination }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation } = useRequester();
	const [addIntegration] = useMutation(
		definitions.integrations.mutation.authorize
	);
	const { runExtension } = useOnlyFansExtension();

	return (
		<CardActions>
			<LabeledIcon
				label={`Add ${destination} integration`}
				Icon={() => (
					<IconButton
						onClick={async () => {
							if (destination.toUpperCase() === "ONLYFANS") {
								const isExtensionRunning = await runExtension();
								if (isExtensionRunning) return;
							}
							addIntegration({
								variables: {
									input: {
										destination: destination.toUpperCase(),
									},
								},
								onCompleted: ({ authorizeIntegration }) => {
									window.open(
										authorizeIntegration.oauthURL,
										"_blank"
									);
								},
								onError: () => {
									const key = enqueueSnackbar(
										`Failed to add ${destination} integration.`,
										{
											variant: "error",
											onClick: () => closeSnackbar(key),
										}
									);
								},
							});
						}}
					>
						<AddIcon />
					</IconButton>
				)}
			/>
		</CardActions>
	);
}
