import { IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
export const InputAdornmentPadding = ({ count = 2 }) => {
	return (
		<>
			{Array.from({ length: count }).map((_, i) => {
				return (
					<IconButton
						sx={{
							// invisible
							opacity: 0,
							// no click
							pointerEvents: "none",
						}}
						key={i}
					>
						<SaveIcon />
					</IconButton>
				);
			})}
		</>
	);
};
