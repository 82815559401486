import {
	Card,
	Typography,
	Box,
	useMediaQuery,
	Button,
	CardHeader,
} from "@mui/material";

import depthEffect from "../../../../../../Theme/sx/depth-effect";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const Wrapper = ({ children }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const [ref, inView] = useInView({
		triggerOnce: true, // Only trigger once when the element comes into view
	});
	const [show, setShow] = useState(false);
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (inView) setShow(true);
		}, 200);
		return () => {
			timeout && clearTimeout(timeout);
		};
	}, [inView]);
	return (
		<Card
			sx={{
				...depthEffect(8),
				borderRadius: "10px",
				padding: 3,
				marginX: isMobile ? 1 : 3,
				marginY: 2,
				width: isMobile ? "100%" : "50%",
				maxWidth: isMobile ? "100vw" : "400px",
				height: "350px",
				textAlign: "center",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",

				opacity: show ? 1 : 0,
				// slide down
				transform: show ? "translateY(0)" : "translateY(-100px)",
				transition: "all 1s ease",
			}}
		>
			<div
				ref={ref}
				style={{
					position: "absolute",
					height: "3px",
					width: "100%",
					top: "20%",
				}}
			/>
			{children}
		</Card>
	);
};
export const TextCard = ({ feature: { title, description, image } }) => {
	return (
		<Wrapper>
			<CardHeader
				title={title}
				subheader={description}
				sx={{
					fontWeight: "bold",
					fontSize: "1.75rem",
					marginBlock: 2,
				}}
				titleTypographyProps={{
					sx: {
						fontWeight: "bold",

						fontSize: "1.75rem",
						marginBlock: 2,

						// offset items slightly above center line
					},
				}}
				subheaderTypographyProps={{
					sx: {
						fontSize: "1.15rem",
					},
				}}
			/>
		</Wrapper>
	);
};
export const ImageDescriptor = ({ feature }) => {
	return (
		<Wrapper>
			<img
				className="feature-card"
				src={feature.image}
				alt={feature.title}
				title={feature.title}
				style={{
					height: "100%",
					width: "100%",
					objectFit: "contain",
				}}
			/>
		</Wrapper>
	);
};

export const CallToActdionCard = () => {
	const navigate = useNavigate();
	return (
		<Wrapper>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",

					justifyContent: "space-around",
					alignItems: "center",
					height: "100%",
				}}
			>
				<Typography
					sx={{
						fontWeight: "bold",
						fontSize: "1.75rem",
					}}
				>
					Sign up now to get started
				</Typography>
				<Button
					variant="contained"
					sx={{
						backgroundColor: "secondary.main",
						color: "secondary.contrastText",
						fontWeight: "bold",
						fontSize: "18px",
					}}
					onClick={() => navigate("/account")}
				>
					Create an account
				</Button>
				<Box
					component="img"
					sx={{
						height: "45px",
						width: "auto",

						objectFit: "contain",
					}}
					src="/logo/wide.png"
				/>
			</Box>
		</Wrapper>
	);
};
