import { useState, useCallback } from "react";
import { IconButton } from "@mui/material";
import PlayIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useContentEditor } from "../../";

import { debounce } from "lodash";
export default function PlayPauseButton() {
	const { timelineEngine } = useContentEditor();
	const [isPlaying, setIsPlaying] = useState(false);

	// without this wait the players will cause a ton of lag
	// and eventually crash the page
	const toggleIsPlaying = useCallback(
		debounce(
			() => {
				const engine = timelineEngine?.current;

				if (!engine) return;

				if (engine.isPlaying) {
					engine.pause();
					setIsPlaying(false);
				} else {
					setIsPlaying(true);
					engine.play({ autoEnd: true });
				}
			},
			500,
			{
				leading: true,
				maxWait: 1000,
			}
		),
		[]
	);

	return (
		<IconButton onClick={toggleIsPlaying}>
			{!isPlaying ? <PlayIcon /> : <PauseIcon />}
		</IconButton>
	);
}
