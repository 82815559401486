import { TextField, Autocomplete, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useRequester } from "../../../../../Apollo";
import { debounce } from "lodash";
import { useCallback } from "react";
import { usePublishContentForm } from "../../..";

export default function MultiSelect({ disabled }) {
	const {
		redgifs: { tags, setTags },
	} = usePublishContentForm();
	const { useQuery, definitions } = useRequester();
	const { data, refetch, updateQuery } = useQuery(
		definitions.integrations.query.redgifs_getTags,
		{
			variables: {
				input: {
					source: "REDGIFS",
					query: " ",
					includeWorkspaceTags: false,
				},
			},
		}
	);

	const fetchOptions = useCallback(
		debounce(async (_, newInputValue) => {
			const response = await refetch({
				input: {
					source: "REDGIFS",
					query: newInputValue,
					includeWorkspaceTags: false,
				},
			});
			updateQuery(prev => {
				return {
					...prev,
					metadataTags: {
						...prev.metadataTags,
						tags: response.data.metadataTags.tags,
					},
				};
			});
		}, 500),
		[]
	);

	const options = data?.metadataTags.tags || [];
	return (
		<Autocomplete
			disabled={disabled}
			fullWidth
			multiple
			freeSolo
			openOnFocus
			disableCloseOnSelect
			autoComplete
			options={options}
			getOptionLabel={option => option.value}
			onInputChange={fetchOptions}
			onChange={(_, value) => {
				if (value.length < tags.length) return setTags(value);
				let newValue = value[value.length - 1];
				if (typeof newValue === "object") newValue = newValue.value;
				const found = options.find(option => option.value === newValue);
				if (!found) return;
				setTags([...tags, found]);
			}}
			value={tags}
			renderInput={params => (
				<TextField
					{...params}
					variant="outlined"
					label="Select at least 3 tags"
				/>
			)}
			renderOption={(props, option, { selected }) => (
				<MenuItem
					{...props}
					key={option.id}
					value={option.value}
					sx={{ justifyContent: "space-between" }}
				>
					{option.value}
					{selected ? <CheckIcon color="info" /> : null}
				</MenuItem>
			)}
		/>
	);
}
