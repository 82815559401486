import logo from "./takedownpiracy.gif";
import { useRequester } from "@/Apollo";
import depthEffect from "@/Theme/sx/depth-effect";
import { Button, Card, CardActions, CardMedia } from "@mui/material";
export default function DarkModeSettings() {
	const { definitions, useQuery, useMutation } = useRequester();
	const { data, loading } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "cache-first",
	});
	const [createLink] = useMutation(
		definitions.user.muation.takedownPiracyLink,
		{
			onCompleted: ({ takedownPiracyLink }) => {
				window.open(takedownPiracyLink.url, "_blank");
			},
		}
	);
	const takedownPiracyConnected = !!data?.user?.takedownPiracyConnected;

	const SignupButton = () => {
		return (
			<Button
				variant="text"
				sx={{
					color: theme => theme.palette.action.active,
				}}
				onClick={createLink}
				className={"take-down-piracy-config"}
			>
				Signup for Takedown Piracy
			</Button>
		);
	};

	const AccountStatus = () => {
		return (
			<Button
				variant="text"
				sx={{
					color: theme => theme.palette.action.active,
				}}
				className={"take-down-piracy-config"}
			>
				Account Status: Active
			</Button>
		);
	};

	return (
		<Card
			sx={{
				...depthEffect(),
				maxWidth: 500,
				padding: 2,
			}}
		>
			<CardMedia
				component={"img"}
				height={50}
				image={logo}
				alt="TakeDown Piracy"
				sx={{
					objectFit: "contain",
				}}
			/>
			<CardActions
				sx={{
					justifyContent: "center",
				}}
			>
				{!loading && takedownPiracyConnected ? (
					<AccountStatus />
				) : (
					<SignupButton />
				)}
			</CardActions>
		</Card>
	);
}
