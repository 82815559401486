import UploadButton from "../UploadButton";
import BumpersList from "./List";
export default function BumpersView() {
	return (
		<>
			<UploadButton type="BUMPER" />
			<BumpersList />
		</>
	);
}
