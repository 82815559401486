import {
	IconButton,
	Stack,
	Card,
	CardHeader,
	Box,
	CardActionArea,
	Typography,
} from "@mui/material";
import { useRequester } from "@/Apollo";
import Connection from "./Connection";
import { useNavigate } from "react-router-dom";

import GoBackIcon from "@mui/icons-material/ArrowBack";

export default function OauthAppConnectionList() {
	const navigate = useNavigate();
	const { useQuery, definitions } = useRequester();
	const { data, loading } = useQuery(definitions.oauth.query.connections);
	if (loading) return null;
	const connections = data?.oauthAppConnections?.connections || [];
	if (!connections.length)
		return (
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Card
					sx={{
						width: "fit-content",
					}}
				>
					<CardHeader title="No Connections" />
					<CardActionArea>
						<IconButton disableRipple onClick={() => navigate(-1)}>
							<GoBackIcon />
							<Typography variant="body1">Go back</Typography>
						</IconButton>
					</CardActionArea>
				</Card>
			</Box>
		);

	return (
		<Stack>
			{connections.map((connection, i) => (
				<Connection key={i} connection={connection} />
			))}
		</Stack>
	);
}
