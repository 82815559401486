import { IconButton, Tooltip } from "@mui/material";
import QuestionIcon from "@mui/icons-material/HelpOutline";
export default function Hint({
	title,
	placement = "top",
	onClick = () => {},
	children,
}) {
	return (
		<Tooltip title={title} placement={placement} arrow>
			<IconButton onClick={onClick}>
				<QuestionIcon
					sx={{
						backgroundColor: theme =>
							theme.palette.background.paper,
						borderRadius: "50%",
						color: theme => theme.palette.action.active,
					}}
				/>
			</IconButton>
		</Tooltip>
	);
}
