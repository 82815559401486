import { useRequester } from "../../../../../../Apollo";
import { useSelectedContent } from "../../../../../SelectedContentContext";
import { useSnackbar } from "notistack";
import { useConfirm } from "../../../../../../Confirm";

export default function useDeleteContent() {
	const { confirm } = useConfirm();

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation, useQuery } = useRequester();
	const { id, setId } = useSelectedContent();
	const { data } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});

	const [mutate, { client }] = useMutation(
		definitions.common.mutation.deleteContent
	);
	return {
		status: data?.content?.approvalStatus,
		delete: async () => {
			const deleteChildren = await confirm(
				"Delete children?",
				"If any content has been created using these items as a source, they will be deleted as well."
			);
			const isConfirmed = await confirm(
				"Delete Content",
				"Are you sure you want to delete this content?"
			);
			if (!isConfirmed) return;

			await mutate({
				variables: {
					input: {
						contentIds: [id],
						deleteChildren,
					},
				},
				errorPolicy: "all",
				onCompleted: data => {
					const deletedIds = data.deleteContent.contentIds;

					let key = enqueueSnackbar("Content Deleted", {
						variant: "success",
						onClick: () => {
							closeSnackbar(key);
						},
					});
					// remove from contentSearch cache
					client.cache.modify({
						fields: {
							contentSearch: existing => {
								const edges = existing.edges.filter(edge => {
									return !deletedIds.includes(
										edge.node.__ref.split(":")[1]
									);
								});
								return {
									...existing,
									edges,
								};
							},
						},
					});
					setId(null);
				},
			});
		},
	};
}
