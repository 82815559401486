import { createContext, useContext } from "react";
import { useRequester } from "@/Apollo";
import depthEffect from "@/Theme/sx/depth-effect";
import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	Stack,
} from "@mui/material";
import DeleteClientButton from "./DeleteClientButton";
import ClientId from "./ClientId";
import HiddenSecret from "./HiddenSecret";
import RedirectUris from "./RedirectUris";
import Name from "./Name";
import Dates from "./Dates";

const OauthClientContext = createContext({
	client: null,
});
export const useOauthClientContext = () => useContext(OauthClientContext);

export default function ClientWrapper({ client_id }) {
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.oauth.query.getOauthClient, {
		variables: {
			input: {
				client_id,
			},
		},
		fetchPolicy: "cache-first",
	});

	if (!data?.oauthClient?.client) return null;
	return (
		<OauthClientContext.Provider
			value={{
				client: data?.oauthClient?.client,
			}}
		>
			<Card
				sx={{
					width: "fit-content",
					...depthEffect(1),
				}}
			>
				<CardHeader
					title={
						<Typography variant="h5" component="div">
							FanHeat API Client
						</Typography>
					}
					action={<DeleteClientButton />}
				/>

				<CardContent>
					<Dates />
					<Name />
					<Stack
						spacing={2}
						sx={{
							marginTop: "18px",
						}}
					>
						<ClientId />
						<HiddenSecret />
						<Typography>Redirect Uris</Typography>
						<RedirectUris />
					</Stack>
				</CardContent>
			</Card>
		</OauthClientContext.Provider>
	);
}
