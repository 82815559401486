import { usePublishContentForm } from "../../";
import {
	TextField,
	Stack,
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import SubredditSelect from "./SubredditSelect";
import { useSnackbar } from "notistack";

import SourceAccountSelector from "./SourceAccountSelector";
import NextButton from "../../NextButton";

export default function RedditOptions({ disabled = false, hideNext = false }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const {
		reddit: { nsfw, setNsfw, sourceAccount },
		nextStep,
	} = usePublishContentForm();
	return (
		<FormControl
			sx={{
				width: "100%",
				height: "100%",
				paddingTop: 2,
			}}
			component={"form"}
			fullWidth
			onSubmit={e => {
				e.preventDefault();
				if (!sourceAccount) {
					let key = enqueueSnackbar(
						"Please select a source account",
						{
							variant: "error",
							onClick: () => {
								closeSnackbar(key);
							},
						}
					);
					return;
				}
				nextStep();
			}}
		>
			<Stack spacing={1}>
				<SubredditSelect disabled={disabled} />
				<SourceAccountSelector disabled={disabled} />

				<FormControlLabel
					checked={nsfw}
					onChange={e => setNsfw(e.target.checked)}
					control={<Checkbox />}
					labelPlacement="start"
					label="NSFW"
					disabled={disabled}
				/>
			</Stack>
			{!hideNext && <NextButton />}
		</FormControl>
	);
}
