import { useRef } from "react";

export default function useMobileContextMenu({ onLongPress, duration = 750 }) {
	const timeout = useRef();

	const isTriggered = useRef(false);

	return {
		onTouchStart: e => {
			timeout.current = setTimeout(() => {
				isTriggered.current = true;
				onLongPress(e);
			}, duration); // Define your desired long press duration
		},

		onTouchEnd: e => {
			if (isTriggered.current) {
				e.preventDefault();
				e.stopPropagation();
				isTriggered.current = false;
			} else {
				clearTimeout(timeout.current);
			}
		},
	};
}
