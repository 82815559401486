import { Box, Typography } from "@mui/material";
import "./styles.css";

export const LoadingIcon = ({ size }) => {
	return (
		<Box
			className={"loadingScreen__spinner"}
			sx={{
				...(size ? { width: size, height: size } : {}),
			}}
		>
			<div className={"loadingScreen__spinner__dot"}></div>
			<div className={"loadingScreen__spinner__dot"}></div>
			<div className={"loadingScreen__spinner__dot"}></div>
			<div className={"loadingScreen__spinner__dot"}></div>
			<div className={"loadingScreen__spinner__dot"}></div>
			<div className={"loadingScreen__spinner__dot"}></div>
		</Box>
	);
};
export default function LoadingScreen({
	children = <></>,
	loading,
	transparent,
	absolute = false,
	message = "",
	sx,
}) {
	return (
		<>
			<Box
				className={`loadingScreen ${
					loading ? "" : "loadingScreen--hidden"
				}`}
				sx={{
					backgroundColor: transparent
						? "transparent"
						: "background.default",
					position: absolute ? "absolute" : "fixed",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					...sx,
				}}
			>
				<LoadingIcon />
				<Typography
					variant="h6"
					sx={{
						marginTop: "1rem",
						color: "text.primary",
					}}
				>
					Loading...
				</Typography>
				<Typography
					variant="body1"
					sx={{
						color: "text.secondary",
					}}
				>
					{message?.toLowerCase()}
				</Typography>
			</Box>
			{!loading && children ? children : null}
		</>
	);
}
