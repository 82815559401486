import { useParams } from "react-router-dom";
import { createContext, useContext } from "react";

import { Card, CardContent, Tabs, Tab } from "@mui/material";

import FileDetailsTab from "./FileDetails";
import ExifDataTab from "./Exif";
import RelatedContentTab from "./Related";
import Submissions from "./Submissions";
import { useState } from "react";
const Context = createContext({
	tabValue: "related",
	setTabValue: () => {},
});
export const useManageContentTabs = () => useContext(Context);
export const ManageContentTabsContext = ({ children }) => {
	const { tab } = useParams();

	const [tabValue, setTabValue] = useState(tab || "submissions");

	return (
		<Context.Provider value={{ tabValue, setTabValue }}>
			{children}
		</Context.Provider>
	);
};
export default function ContentDetailsTabs() {
	const { setTabValue, tabValue } = useManageContentTabs();

	return (
		<>
			<Tabs
				variant="scrollable"
				value={tabValue}
				onChange={(e, tab) => {
					setTabValue(tab);
				}}
			>
				<Tab
					className="manage-content-tab-submissions"
					value={"submissions"}
					label="Submissions"
				/>
				<Tab
					className="manage-content-tab-related-content"
					value={"related"}
					label="Related Content"
				/>
				<Tab
					className="manage-content-tab-file-details"
					value={"details"}
					label="File Details"
				/>
				<Tab
					className="manage-content-tab-exif-data"
					value={"exif"}
					label="Exif"
				/>
			</Tabs>
			<CardContent>
				{tabValue === "submissions" && <Submissions />}
				{tabValue === "related" && <RelatedContentTab />}
				{tabValue === "details" && <FileDetailsTab />}
				{tabValue === "exif" && <ExifDataTab />}
			</CardContent>
		</>
	);
}
