import { Typography, Stack } from "@mui/material";
import { useSearchStats } from "@/ContentSearch/hooks/useSearchStats";
export default function Stats() {
	const { totalCount, responseTimeMS } = useSearchStats();

	return (
		<Stack>
			<Typography variant="body2" sx={{ color: "text.secondary" }}>
				{totalCount} results
			</Typography>
		</Stack>
	);
}
