import {
	List,
	ListItem,
	ListItemText,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Divider,
	Box,
} from "@mui/material";
import RBACConfigColor from "./Color";
import DeleteRoleButton from "./Delete";
import NameField from "./Name";
import { Fragment } from "react";
import { useRequester } from "@/Apollo";
const permissionsToMappableObject = role => {
	return Object.entries(role.permissions).reduce((acc, [key, value]) => {
		if (key === "__typename") return acc;
		acc.push({ permission: key, value });
		return acc;
	}, []);
};

export default function RBACConfig({ role }) {
	const { definitions, useMutation } = useRequester();

	const [updateRolePermissions] = useMutation(
		definitions.rbac.mutation.updateRolePermissions
	);

	const setInput = input => {
		// go into the permissions object, and then into all the sub objects of permissions
		// remove the __typename key
		// then send the permissions object to the server
		const cleanPermissions = {};

		// for (const [key, value] of Object.entries(permissions)) {
		Object.entries(input.permissions).forEach(([key, value]) => {
			if (key === "__typename") return;
			cleanPermissions[key] = Object.entries(value).reduce(
				(acc, [k, v]) => {
					if (k === "__typename") return acc;
					acc[k] = v;
					return acc;
				},
				{}
			);
		});

		const variables = {
			input: {
				id: role.id,
				admin: input.admin,
				permissions: cleanPermissions,
			},
		};

		updateRolePermissions({
			variables,
		});
	};

	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: 500,
			}}
		>
			<List
				sx={{
					width: "100%",
					maxWidth: 500,
				}}
				dense
			>
				<ListItem
					sx={{
						marginY: "1rem",
					}}
					secondaryAction={<RBACConfigColor role={role} />}
				>
					<ListItemText primary={<NameField role={role} />} />
				</ListItem>

				<Divider />
				<ListItem
					sx={{
						marginY: "1rem",
					}}
					secondaryAction={
						<FormControl
							component="fieldset"
							onChange={e => {
								setInput({
									id: role.id,
									admin: role.admin,
									permissions: Object.entries(
										role.permissions
									).reduce((acc, [key, value]) => {
										if (key === "__typename") return acc;
										acc[key] = Object.entries(value).reduce(
											(acc, [k, v]) => {
												if (k === "__typename") {
													return acc;
												}
												acc[k] = v;
												if (k === e.target.name) {
													acc[k] = e.target.checked;
												}
												return acc;
											},
											{}
										);
										return acc;
									}, {}),
								});
							}}
						>
							<FormGroup row>
								{["read", "write", "delete"].map(name => {
									return (
										<FormControlLabel
											name={name}
											disabled={role.admin}
											control={
												<Checkbox
													checked={permissionsToMappableObject(
														role
													)
														.filter(
															({ permission }) =>
																permission !==
																"admin"
														)
														.every(
															({ value }) =>
																value[name]
														)}
												/>
											}
											label={name}
											labelPlacement={"top"}
										/>
									);
								})}
							</FormGroup>
						</FormControl>
					}
				/>
				<Divider />
				{permissionsToMappableObject(role)
					.filter(({ permission }) => permission !== "admin")
					.map(({ permission }) => {
						return (
							<Fragment key={permission}>
								<ListItem
									sx={{
										marginY: "1rem",
									}}
									key={permission}
									secondaryAction={
										<FormControl
											component="fieldset"
											onChange={e => {
												const { name, checked } =
													e.target;
												const [permission, action] =
													name.split(":");

												setInput({
													id: role.id,
													admin: role.admin,
													permissions: {
														...role.permissions,
														[permission]: {
															...role.permissions[
																permission
															],
															[action]: checked,
														},
													},
												});
											}}
										>
											<FormGroup row>
												{[
													"read",
													"write",
													"delete",
												].map(name => {
													const checked =
														!!role.permissions[
															permission
														][name];

													return (
														<FormControlLabel
															name={`${permission}:${name}`}
															disabled={
																role.admin
															}
															control={
																<Checkbox
																	checked={
																		checked
																	}
																/>
															}
															label={name}
															labelPlacement={
																"top"
															}
														/>
													);
												})}
											</FormGroup>
										</FormControl>
									}
								>
									<ListItemText primary={permission} />
								</ListItem>
								<Divider />
							</Fragment>
						);
					})}
				<ListItem
					sx={{
						marginY: "1rem",
					}}
					secondaryAction={
						<FormControl
							component="fieldset"
							onChange={e => {
								setInput({
									id: role.id,
									admin: e.target.checked,
									permissions: role.permissions,
								});
							}}
						>
							<FormGroup row>
								<FormControlLabel
									control={<Checkbox checked={role.admin} />}
									labelPlacement={"top"}
								/>
							</FormGroup>
						</FormControl>
					}
				>
					<ListItemText primary={"admin"} />
				</ListItem>
			</List>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<DeleteRoleButton role={role} />
			</Box>
		</Box>
	);
}
