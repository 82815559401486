import { useIsSSR } from "react-aria";
const get = key => {
	const value = localStorage.getItem(key);
	return value ? JSON.parse(value) : null;
};
const set = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

const remove = (key, value) => localStorage.removeItem(key);
const allKeys = () => Object.keys(localStorage);

export default function useLocalStorage() {
	const isSSR = useIsSSR();
	if (isSSR)
		return {
			get: () => {},
			set: () => {},
			remove: () => {},
            allKeys: () => {},
		};
	return {
		get,
		set,
		remove,
		allKeys,
	};
}
