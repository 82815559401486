import useDrivePicker from "react-google-drive-picker";
import { StyledSquareIconButton } from "@/Component/Styled/Button";
import { useRequester } from "@/Apollo";
import { useEffect, useRef } from "react";
export default function Picker({ size = "28px" }) {
	const [openPicker, authResponse] = useDrivePicker();
	const authResponseRef = useRef(authResponse);
	useEffect(() => {
		authResponseRef.current = authResponse;
	}, [authResponse]);

	const { useMutation, definitions } = useRequester();
	const [importContent] = useMutation(
		definitions.content.mutation.importContent
	);

	return (
		<StyledSquareIconButton
			sx={{
				border: "none",
			}}
			onClick={() => {
				openPicker({
					clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
					developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
					viewId: "DOCS_IMAGES_AND_VIDEOS",
					// token: token, // pass oauth token in case you already have one
					showUploadView: true,
					showUploadFolders: true,
					supportDrives: true,
					multiselect: true,
					customScopes: [
						"https://www.googleapis.com/auth/drive.readonly",
						"https://www.googleapis.com/auth/drive.metadata.readonly",
					],

					// customViews: customViewsArray, // custom view
					callbackFunction: data => {
						if (data.action === "cancel") return;
						if (data.action === "loaded") return;

						importContent({
							variables: {
								input: {
									source: "GOOGLE_DRIVE",
									config: {
										token: authResponseRef.current
											.access_token,
										importFiles: data.docs.map(doc => {
											return {
												key: doc.id,
												mimetype: doc.mimeType,
											};
										}),
									},
								},
							},
						});
					},
				});
			}}
		>
			<img
				style={{
					height: size,
					width: size,
				}}
				src="/icon/googledrive.png"
				alt="Google Drive"
			/>
		</StyledSquareIconButton>
	);
}
