import ExifDataDataGrid from "./DataGrid";
import GoogleMap from "./GoogleMap";
export default function ExifDataTab() {
	return (
		<>
			<ExifDataDataGrid />
			<GoogleMap />
		</>
	);
}
