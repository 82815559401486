import { gql } from "@apollo/client";
import {
	VIRTUAL_ASSISTANT_FRAGMENT,
	WORKSPACE_ADMIN_FRAGMENT,
} from "../../virtualAssistant/fragment";

const WORKSPACE_FIELDS = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	${WORKSPACE_ADMIN_FRAGMENT}

	fragment WorkspaceFields on Workspace {
		id
		createdAt
		name
		updatedAt
		assistants {
			...VirtualAssistantFragment
		}
		admin {
			...WorkspaceAdminFragment
		}
	}
`;

export const getWorkspace = gql`
	${WORKSPACE_FIELDS}

	query Workspace($id: ID) {
		workspace(id: $id) {
			...WorkspaceFields
			isAdmin
		}
	}
`;
export const allWorkspaces = gql`
	${WORKSPACE_FIELDS}
	query myWorkspaces($input: MyWorkspacesInput!) {
		myWorkspaces(input: $input) {
			edges {
				cursor
				node {
					...WorkspaceFields
				}
			}
		}
	}
`;

export const usedStorage = gql`
	query WorkspaceUsedStorage {
		workspaceUsedStorage {
			gb
		}
	}
`;

export const upcomingSubmissions = gql`
	query UpcomingSubmissions {
		workspaceUpcomingSubmissions {
			submissions {
				id
				createdAt
				title
				deferUntil
				submittedAt
				link

				status
			}
		}
	}
`;
