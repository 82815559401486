import { gql } from "@apollo/client";
export const onboardingProgress = gql`
	mutation OnboardingProgress($input: OnboardingProgressInput!) {
		onboardingProgress(input: $input) {
			id
			progress {
				on_step_account
				on_step_automations
				on_step_content_editor
				on_step_integrations
				on_step_library
				on_step_notifications
				on_step_publish
				on_step_schedule
				on_step_workspaces
			}
		}
	}
`;

export const updatePublicDisplayName = gql`
	mutation PublicDisplayName($input: PublicDisplayNameInput!) {
		publicDisplayName(input: $input) {
			id
			publicDisplayName
		}
	}
`;

export const takedownPiracyLink = gql`
	mutation TakedownPiracyLink {
		takedownPiracyLink {
			url
		}
	}
`;
