import { Box } from "@mui/material";
export default function SmallLogoSVG() {
	return (
		<object
			aria-label="logo"
			alt="logo"
			data="/logo/small.svg"
			type="image/svg+xml"
			preserveAspectRatio="none"
			width="100%"
			height="100%"
			style={{
				height: `calc(var(--nav-height) - 24px)`,
				width: "auto",
				shapeRendering: "geometricPrecision",
				userSelect: "none",
				pointerEvents: "none",
			}}
		/>
	);
}

export const SmallLogoPNG = () => {
	return (
		<Box
			sx={{
				width: `calc(var(--nav-height) - 24px)`,
				height: "auto",
				userSelect: "none",
				pointerEvents: "none",
				display: "flex",

				// center image
				alignItems: "center",
				justifyContent: "center",
				// keep image within the border
			}}
		>
			<img
				aria-label="logo"
				alt="logo"
				src="/logo/small.png"
				style={{
					width: "100%",
					height: "auto",
				}}
			/>
		</Box>
	);
};
