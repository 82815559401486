import { useRequester } from "../../../../../../Apollo";

import AssetSelectorBase from "../Base";
export default function WatermarkSelector({
	onSelect = () => {},
	defaultWatermark,
}) {
	const { definitions, useQuery } = useRequester();
	const { data: watermarksData } = useQuery(
		definitions.assets.query.watermarks,
		{
			variables: {},
		}
	);
	const options = watermarksData?.allWatermarks?.edges?.map(
		({ node }) => node
	);

	return (
		<AssetSelectorBase
			defaultAsset={defaultWatermark}
			label="Watermark"
			onSelect={onSelect}
			options={options}
		/>
	);
}
