import { useRef, useEffect } from "react";
import { useContentSearch } from "@/ContentSearch";
import { Box, Grid } from "@mui/material";
import { useLibraryViewContext } from "../Context";

import LoadingScreen from "@/Component/LoadingScreen";

const IntersectionObserver = () => {
	const { fetchMore, loading } = useContentSearch();
	const observer = useRef(null);
	const lastElementRef = useRef(null);
	useEffect(() => {
		if (observer.current) observer.current.disconnect();
		observer.current = new window.IntersectionObserver(entries => {
			if (loading) return;
			if (entries[0].isIntersecting) fetchMore();
		});
		observer?.current.observe(lastElementRef.current);
	}, [lastElementRef.current]);
	return <div ref={lastElementRef} />;
};

export default function Hits() {
	const wrapperRef = useRef(null);
	const { content, loading, page, totalPages } = useContentSearch();

	const { HitComponent: Hit } = useLibraryViewContext();
	useEffect(() => {
		if (page !== 1) return;
		// scroll to top of page when content updates
		wrapperRef.current.scrollTo(0, 0);
	}, [content]);

	return (
		<>
			<LoadingScreen
				transparent
				absolute
				loading={loading}
				message={`awaiting content search results`}
			/>

			<Grid
				ref={wrapperRef}
				className="hidden-scrollbar"
				container
				sx={{
					display: "flex",
					alignContent: "flex-start",
					height: "var(--body-height)",
					overflowY: "scroll",
					overflowX: "hidden",
					paddingBottom: "300px", // offset the pagination bar
					userSelect: "none",
					position: "relative",
				}}
			>
				<span
					className="library-hits-joyride-sentinel"
					style={{
						position: "absolute",
						top: "15%",
						left: "5%",
						pointerEvents: "none",
					}}
				/>

				{content?.map(content => {
					return <Hit key={content.id} content={content} />;
				})}

				{!loading && content?.length > 0 && <IntersectionObserver />}
				{page === totalPages && !loading && (
					<>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "fit-content",
							}}
						>
							<Box
								sx={{
									fontSize: "1.5rem",
									color: "var(--text-secondary)",
								}}
							>
								No more results
							</Box>
						</Box>
					</>
				)}
				{!loading && content?.length === 0 && (
					<>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<Box
								sx={{
									fontSize: "1.5rem",
									color: "var(--text-secondary)",
								}}
							>
								No results found
							</Box>
						</Box>
					</>
				)}
			</Grid>
		</>
	);
}
