import { Box, Paper } from "@mui/material";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import useActions from "../hooks/useActions";
import Hint from "../../../../Hint";

import { useState } from "react";
import { useContentEditor } from "../../..";
export default function FixedBottomNavigation() {
	const {
		setDrawerOpen,
		onView,
		joyride: { run, resetProgress },
		drawerOpen,
	} = useContentEditor();
	const [value, setValue] = useState(onView);
	const actions = useActions();

	return (
		<Box sx={{ pb: 7 }}>
			<Box
				sx={{
					position: "fixed",
					bottom: 60,
					zIndex: 1400,
					width: "fit-content",
					height: "fit-content",
					display: "flex",
					alignItems: "center",

					ml: 2,

					right: 0,
					mr: 2,
				}}
			>
				<Hint
					className="help"
					onClick={async () => {
						console.log("running");
						await resetProgress();
						run();
					}}
				/>
			</Box>
			<Paper
				sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
				elevation={3}
			>
				<BottomNavigation
					showLabels
					value={value}
					onChange={(event, newValue) => {
						console.log({
							onView,
							newValue,
							drawerOpen,
						});
						if (onView === newValue && drawerOpen) {
							console.log("closing drawer");
							return setDrawerOpen(false);
						}
						setDrawerOpen(true);
						setValue(newValue);
					}}
				>
					{actions.map(({ label, value, Icon, onClick }, i) => (
						<BottomNavigationAction
							key={i}
							className={`toolbar-action-${value}`}
							label={label}
							value={value}
							icon={<Icon />}
							onClick={() => {
								onClick();
								// setDrawerOpen(true);
							}}
							sx={{
								"&.Mui-selected": {
									color: theme => theme.palette.action.active,
								},
								"&:not(.Mui-selected)": {
									color: theme =>
										theme.palette.action.disabled,
								},
							}}
						/>
					))}
				</BottomNavigation>
			</Paper>
		</Box>
	);
}
