import { useContentSearch } from "@/ContentSearch";
import {
	Typography,
	Autocomplete,
	TextField,
	Box,
	InputAdornment,
	IconButton,
	Chip,
	Stack,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useEffect, useState } from "react";
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDown";

import {
	// slow down search to prevent too many requests
	debounce,
} from "lodash";
import { useCallback } from "react";
const getTitle = content => {
	if (typeof content === "string") return content; // handle press enter key on autocomplete
	return content?.title;
};
const Title = ({ text, inputValue }) => {
	const matches = match(text, inputValue, {
		insideWords: true,
	});
	const parts = parse(text, matches);
	return parts.map((part, index) => {
		return (
			<Typography
				key={index}
				sx={{
					fontWeight: part.highlight ? 700 : 400,
				}}
			>
				{part.text.replace(/ /g, "\u00a0")}
			</Typography>
		);
	});
};
const Tag = ({ text, inputValue }) => {
	const matches = match(text, inputValue, {
		insideWords: true,
	});
	const parts = parse(text, matches);
	return (
		<Chip
			label={parts.map((part, index) => {
				return (
					<span
						key={index}
						style={{
							fontWeight: part.highlight ? 700 : 400,
						}}
					>
						{part.text.replace(/ /g, "\u00a0")}
					</span>
				);
			})}
		/>
	);
};
export default function Search({
	FacetsToggler = <></>,
	label = "Search For Content",
	onSelect = () => {},
}) {
	const [open, setOpen] = useState(false);
	const { content, filters, search, query } = useContentSearch();
	const [inputValue, setInputValue] = useState(query);

	const handleSearch = useCallback(
		debounce(value => {
			search(value);
		}, 500),
		[search]
	);
	useEffect(() => {
		if (inputValue === query) return;
		handleSearch(inputValue);
	}, [inputValue]);
	return (
		<Autocomplete
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			disablePortal
			autoComplete={false}
			loading // when options.length === 0 will show loading indicator
			loadingText="No results found in the current page. Press enter to refine your search." // show this text when "loading"
			freeSolo // allow any javascript object to be passed through as an option
			inputMode="search"
			inputValue={inputValue}
			options={content || []}
			filterOptions={(options, { inputValue }) => {
				return options;
			}}
			groupBy={option => {
				if (option?.content?._file?.file_type === "VIDEO")
					return "videos";
				return "images";
			}}
			getOptionLabel={getTitle}
			openOnFocus={false}
			// autoHighlight <- don't use this - it will cause the first option to be highlighted and on press enter it will automatically refine with this value. We want to refine with the value of the input field.
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			onChange={(event, newValue) => {
				onSelect(newValue);

				if (!newValue) return handleSearch("");
				handleSearch(getTitle(newValue));
			}}
			onKeyDown={event => {
				if (event.key !== "Enter") return;
				if (inputValue === filters.query) return;
			}}
			clearOnEscape
			disableClearable // stop padding from appearing on the right side of the input field
			renderInput={params => {
				return (
					<TextField
						{...params}
						label={label}
						variant="outlined"
						InputProps={{
							...params.InputProps,
							type: "search",
							endAdornment: (
								<InputAdornment position="start">
									<span>
										<IconButton
											onClick={() => {
												// if (
												// 	inputValue ===
												// 	currentRefinement
												// )
												// 	return;
												handleSearch(inputValue);
											}}
										>
											🔍
										</IconButton>
										{FacetsToggler}
										<IconButton
											onClick={e => {
												e.preventDefault();
												e.stopPropagation();
												setOpen(!open);
											}}
										>
											<DownArrowIcon
												sx={{
													transform: open
														? "rotate(180deg)"
														: "rotate(0deg)",
												}}
											/>
										</IconButton>
									</span>
								</InputAdornment>
							),
						}}
					/>
				);
			}}
			renderOption={(props, option) => {
				// highlight matching text AND TAGS from the input value

				const Tags = () => {
					const tags = option.metadataTags;

					const tagsParts = tags.reduce((acc, tag) => {
						const matches = match(tag, inputValue, {
							insideWords: true,
						});

						const tagParts = parse(tag, matches);

						return [...acc, tagParts];
					}, []);

					return (
						<>
							{tags?.map((tag, index) => {
								const Label = () => {
									return (
										<>
											{tagsParts[index].map(
												(part, index) => {
													return (
														<span
															key={index}
															style={{
																fontWeight:
																	part.highlight
																		? 700
																		: 400,
															}}
														>
															{part.text.value.replace(
																/ /g,
																"\u00a0"
															)}
														</span>
													);
												}
											)}
										</>
									);
								};
								return (
									<Chip
										key={index}
										style={{
											fontWeight: 700,
										}}
										label={<Label />}
									/>
								);
							})}
						</>
					);
				};

				return (
					<Box
						{...props}
						component="li"
						sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
						key={option.id}
					>
						<img
							loading="lazy"
							width="40"
							src={option?.source.thumbnailUrl}
							alt={option.title}
						/>
						<Stack direction="row" spacing={0}>
							<Title
								text={option.title}
								inputValue={inputValue}
							/>

							<Stack direction="row" spacing={1}>
								{option.metadataTags?.map((tag, i) => {
									return (
										<Tag
											key={i}
											text={tag.value}
											inputValue={inputValue}
										/>
									);
								})}
							</Stack>
						</Stack>
					</Box>
				);
			}}
		/>
	);
}
