import { gql } from "@apollo/client";
export const WORKSPACE_ROLE_FIELDS = gql`
	fragment WorkspaceRoleFields on WorkspaceRole {
		id
		workspace_id
		name
		color
		admin
		permissions {
			integrations {
				read
				write
				delete
			}
			assets {
				read
				write
				delete
			}
			render {
				read
				write
				delete
			}
			submission {
				read
				write
				delete
			}
			settings {
				read
				write
				delete
			}
			assistants {
				read
				write
				delete
			}

			projects {
				read
				write
				delete
			}
			automations {
				read
				write
				delete
			}
			folders {
				read
				write
				delete
			}
			notifications {
				read
				write
				delete
			}
			tags {
				read
				write
				delete
			}
		}
	}
`;
