import {
	Chip,
	TextField,
	Autocomplete,
	ListItem,
	List,
	ListItemText,
} from "@mui/material";
import { useRequester } from "@/Apollo";
export default function RoleAutocomplete({ assistant }) {
	const { useMutation, useQuery, definitions, client } = useRequester();
	const [addToRole] = useMutation(
		definitions.virtualAssistant.mutation.addToRole
	);
	const [removeFromRoles] = useMutation(
		definitions.virtualAssistant.mutation.removeFromRoles
	);
	const { data, loading } = useQuery(definitions.rbac.query.workspaceRoles);

	const roles = data?.workspaceRoles?.roles || [];

	const onCompleted = assistant => {
		// now update the cached value
		client.cache.writeQuery({
			query: definitions.virtualAssistant.query.assistant,
			variables: {
				input: {
					assistant: assistant.user_id,
				},
			},

			data: {
				virtualAssistant: {
					assistant,
				},
			},
		});

		client.cache.writeQuery({
			query: definitions.virtualAssistant.query.assistants,
			data: {
				virtualAssistants: {
					assistants: [
						...(client.cache.readQuery({
							query: definitions.virtualAssistant.query
								.assistants,
						})?.virtualAssistants.assistants || []),
					].map(a => {
						if (a.user_id === assistant.user_id) {
							return assistant;
						}
						return a;
					}),
				},
			},
		});
	};

	return (
		<Autocomplete
			multiple
			value={assistant.roles}
			loading={loading}
			onChange={(event, newValue, action) => {
				switch (action) {
					case "removeOption": {
						const whichWasRemoved = assistant.roles.filter(
							role => !newValue.includes(role)
						);
						removeFromRoles({
							onCompleted: ({
								removeAssistantFromRoles: { assistant },
							}) => onCompleted(assistant),
							variables: {
								input: {
									assistant: assistant.user_id,
									roles: [whichWasRemoved[0].id],
								},
							},
						});

						break;
					}
					case "selectOption": {
						const whichWasAdded = newValue.find(
							role => !assistant.roles.includes(role)
						);
						addToRole({
							onCompleted: ({
								addAssistantToRole: { assistant },
							}) => onCompleted(assistant),
							variables: {
								input: {
									assistant: assistant.user_id,
									role: whichWasAdded.id,
								},
							},
						});
						break;
					}

					case "clear": {
						removeFromRoles({
							onCompleted: ({
								removeAssistantFromRoles: { assistant },
							}) => onCompleted(assistant),
							variables: {
								input: {
									assistant: assistant.user_id,
									roles: assistant.roles.map(role => role.id),
								},
							},
						});

						break;
					}
					default: {
						break;
					}
				}
			}}
			options={roles}
			renderGroup={(params, group, components) => {
				return <List {...params}>{components}</List>;
			}}
			renderOption={(props, option) => {
				return (
					<ListItem
						sx={{
							height: "50px",
						}}
						key={option.id}
						{...props}
					>
						<ListItemText
							primary={option.name}
							color={option.color}
						/>
					</ListItem>
				);
			}}
			getOptionDisabled={option => {
				return !!assistant.roles.find(role => role.id === option.id);
			}}
			getOptionLabel={option => option.name}
			renderTags={(tagValue, getTagProps) =>
				tagValue.map((option, index) => (
					<Chip
						label={option.name}
						sx={{
							backgroundColor: option.color,
						}}
						onDelete={deleted => {
							console.log("onDelete:", deleted);
							removeFromRoles({
								onCompleted: ({
									removeAssistantFromRoles: { assistant },
								}) => onCompleted(assistant),
								variables: {
									input: {
										assistant: assistant.user_id,
										roles: [option.id],
									},
								},
							});
						}}
						{...getTagProps({ index })}
					/>
				))
			}
			sx={{
				width: "100%",
				maxWidth: 500,
			}}
			renderInput={params => (
				<TextField
					{...params}
					label="Assistant Roles"
					placeholder="Assistant Roles"
				/>
			)}
		/>
	);
}
