import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import { createContext, useContext, useState } from "react";
const Context = createContext({
	confirm: async () => {},
});

export const useConfirm = () => useContext(Context);

export default function ConfirmContext({ children }) {
	const [state, setState] = useState({
		title: "",
		message: "",
	});
	const [promise, setPromise] = useState(null);

	const confirm = async (
		title = "Are you sure?",
		message = "Are you sure you would like to do this"
	) => {
		setState({
			title,
			message,
		});
		return new Promise((resolve, reject) => {
			setPromise({ resolve });
		});
	};

	const handleClose = () => {
		setPromise(null);
	};

	const handleConfirm = () => {
		promise?.resolve(true);
		handleClose();
	};

	const handleCancel = () => {
		promise?.resolve(false);
		handleClose();
	};

	return (
		<Context.Provider
			value={{
				confirm,
			}}
		>
			{children}
			<Dialog
				open={promise !== null}
				fullWidth
				// sx={{
				// 	zIndex: 1401,
				// }}
			>
				<DialogTitle>{state.title}</DialogTitle>
				<DialogContent>
					<DialogContentText
						sx={{
							color: theme => theme.palette.text.primary,
						}}
					>
						{state.message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleConfirm}
						// set keyboard selected
						autoFocus
						// on key down
						onKeyDown={e => {
							// right arrow
							// focus next button
							if (e.keyCode === 39) {
								e.preventDefault();
								e.stopPropagation();
								document.activeElement.nextElementSibling.focus();
							}
						}}
						sx={{
							"&:focus": {
								border: theme =>
									`1px solid ${theme.palette.primary.main}`,
							},
						}}
					>
						<Typography
							sx={{
								color: theme => theme.palette.action.active,
							}}
						>
							Yes
						</Typography>
					</Button>
					<Button
						// on key down
						onKeyDown={e => {
							// left arrow
							// focus other button
							if (e.keyCode === 37) {
								e.preventDefault();
								e.stopPropagation();
								document.activeElement.previousElementSibling.focus();
							}
						}}
						onClick={handleCancel}
						sx={{
							"&:focus": {
								border: theme =>
									`1px solid ${theme.palette.primary.main}`,
							},
						}}
					>
						<Typography
							sx={{
								color: theme => theme.palette.action.active,
							}}
						>
							No
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</Context.Provider>
	);
}
