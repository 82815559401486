import { useState } from "react";
import {
	Grid,
	ImageListItem,
	ImageListItemBar,
	Tooltip,
	IconButton,
} from "@mui/material";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import RightArrowIcon from "@mui/icons-material/ArrowForward";
import TrashIcon from "@mui/icons-material/Delete";
import { useMatchesListContext } from "..";
import { useConfirm } from "@/Confirm";
import { useRequester } from "@/Apollo";
import PruneDuplicatesIconButton from "../PruneDuplicatesIconButton";
import depthEffect from "@/Theme/sx/depth-effect";
import { useNavigate, useParams } from "react-router-dom";
export default function MatchItem({ content }) {
	const { refetch } = useMatchesListContext();
	const { id } = useParams();
	const navigate = useNavigate();
	const { definitions, useMutation } = useRequester();
	const { confirm } = useConfirm();
	const { hash } = useMatchesListContext();
	const [mutate] = useMutation(definitions.common.mutation.deleteContent);
	const [hovering, setHovering] = useState(false);
	return (
		<Grid
			item
			xs={4}
			md={2}
			xl={1}
			sx={{
				position: "relative",
				padding: "3px",
				"&:hover": {
					...depthEffect(3),
					border: theme => `1px solid ${theme.palette.primary.main}`,
				},
			}}
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
		>
			<Tooltip title={content.title} arrow>
				<ImageListItem>
					<img
						src={content?.source?.thumbnailUrl}
						alt={content.title}
						style={{
							objectFit: "contain",
							width: "100%",
							height: "100%",
						}}
					/>

					<IconButton
						sx={{
							position: "absolute",
							display: hovering ? "block" : "none",
							top: "0",
							right: "0",
						}}
						onClick={() => {
							// if viewing specific hash match, navigate to the content
							if (id) {
								navigate(`/workspace/content/${content.id}`);
							} else {
								// else navigate to the matches page for that content hash
								navigate(
									`/workspace/match/${content.source.hash}`
								);
							}
						}}
					>
						{id ? <RightArrowIcon /> : <EyeIcon />}
					</IconButton>
					<ImageListItemBar
						title={content.title}
						subtitle={content.source.type}
						actionIcon={
							<>
								{!hash ? (
									<PruneDuplicatesIconButton
										hash={content.source.hash}
									/>
								) : (
									<IconButton
										sx={{
											backgroundColor: "background.paper",
										}}
										onClick={async () => {
											const confirmed = await confirm(
												"Are you sure you want to delete this match?"
											);
											if (!confirmed) return;

											const res = await mutate({
												variables: {
													input: {
														contentIds: [
															content.id,
														],
														deleteChildren: false,
													},
												},

												refetchQueries: [
													{
														query: definitions
															.common.query
															.countMatches,
													},
												],
												onCompleted: refetch,
											});
											console.log("res", res);
										}}
									>
										<TrashIcon />
									</IconButton>
								)}
							</>
						}
					/>
				</ImageListItem>
			</Tooltip>
		</Grid>
	);
}
