import { useEffect, useImperativeHandle, useState, useRef } from "react";
import { useRequester } from "../../../Apollo";
import ReactJoyride from "react-joyride";
import { useContentEditor } from "../common/context";
const JOYRIDE_DOMAIN = "CONTENT_EDITOR";
export default function Joyride() {
	const {
		joyride: { ref: parentRef },
		setOnView,
		open,
		mobile,
		setDrawerOpen,
	} = useContentEditor();
	const ref = useRef();
	const { definitions, useQuery, useMutation } = useRequester();
	const { data } = useQuery(definitions.user.query.onboardingProgress, {
		fetchPolicy: "cache-first",
	});
	const onStep =
		data?.onboardingProgress.progress[
			`on_step_${JOYRIDE_DOMAIN.toLowerCase()}`
		];
	const [run, setRun] = useState(open && onStep === 0); // don't automatically show this if the user has already progressed through the joyride

	const [progress, setProgress] = useState(onStep);
	useEffect(() => {
		parentRef.current = ref.current;
	}, [ref]);
	const [updateStepProgress] = useMutation(
		definitions.user.muation.onboardingProgress,
		{
			variables: {
				input: {
					domain: JOYRIDE_DOMAIN,
					progress: 1,
				},
			},
		}
	);

	useImperativeHandle(ref, () => ({
		run: (run = true) => {
			setProgress(0);
			setRun(run);
		},
		isRunning: run,
		progress,
	}));

	return (
		<ReactJoyride
			ref={ref}
			run={!onStep && run}
			hideBackButton
			callback={({ action, index, type, ...other }) => {
				const indexMap = {
					0: "project",
					2: "content",
					6: "watermark",
					9: "audio",
					12: "preview",
					15: "project",
				};

				const handleAction = async ({ action, index }) => {
					if (action === "update") {
						const step = indexMap[index];
						switch (step) {
							case "project": {
								setOnView("project");
								if (mobile) setDrawerOpen(true);
								break;
							}
							case "content": {
								setOnView("content");
								if (mobile) setDrawerOpen(true);
								break;
							}
							case "watermark": {
								setOnView("watermark");
								if (mobile) setDrawerOpen(true);
								break;
							}
							case "audio": {
								setOnView("audio");
								if (mobile) setDrawerOpen(true);
								break;
							}
							case "preview": {
								setOnView("preview");
								if (mobile) setDrawerOpen(true);
								break;
							}
							default: {
								break;
							}
						}
					}
				};

				const handleDrawer = open => {
					if (!mobile) return;
					if (type === "tooltip") setDrawerOpen(open);
					if (type === "step:after") setDrawerOpen(open);
				};

				if (action === "update") {
					switch (index) {
						case 0: {
							handleDrawer(true);

							setOnView("project");
							break;
						}

						case 2: {
							handleDrawer(true);
							setOnView("content");
							break;
						}
						case 4: {
							handleDrawer();
							break;
						}
						case 6: {
							handleDrawer(true);
							setOnView("watermark");
							break;
						}
						case 7: {
							handleDrawer(true);
							break;
						}
						case 8: {
							handleDrawer();
							break;
						}
						case 9: {
							handleDrawer(true);
							setOnView("audio");
							break;
						}
						case 11: {
							handleDrawer();
							break;
						}
						case 12: {
							handleDrawer(true);
							setOnView("bumpers");

							break;
						}
						case 14: {
							handleDrawer();
							break;
						}
						case 15: {
							handleDrawer(true);
							setOnView("project");
							break;
						}

						default: {
							break;
						}
					}
				}
				setProgress(index);

				switch (action) {
					case "reset": {
						updateStepProgress();
						break;
					}
					default:
						break;
				}
			}}
			floaterProps={{
				styles: {
					wrapper: {
						zIndex: 1400, // above the drawer
					},
				},
			}}
			styles={{
				options: {
					zIndex: 1400,
				},
			}}
			continuous
			showProgress
			showSkipButton
			spotlightClicks
			scrollToFirstStep={false}
			disableScrolling
			steps={[
				{
					target: ".toolbar-action-project",
					content:
						"While you're on the project tab you can save and load your existing projects.",
					placement: "right",
					disableOverlayClose: true,
					disableBeacon: true,
				},
				{
					target: ".project-title-input",
					content:
						"Give your project a title and click next to continue.",
					placement: "right",
					disableOverlayClose: true,
				},

				{
					target: ".toolbar-action-content",
					content:
						"The content tab is where you can find content from your library to add to your project.",

					disableBeacon: true,
					disableOverlayClose: true,
				},
				{
					target: ".content-search",
					content:
						"Use the search box to find content from your library to add to your project.",
					disableOverlayClose: true,
					placement: "right-end",
					disableOverlay: true,
				},

				{
					target: ".xzdarcy-timeline",
					content:
						"Your content was just added here to your timeline. You can use the timeline to trim or split your content. Try dragging the edges of the content to trim it.",
					disableOverlayClose: true,
					placement: "top-start",
				},
				{
					target: ".parent-content-star",
					content: `This star means that when you render your project, it will be placed in your library as a version of this content.`,
					disableOverlayClose: true,
					placement: "right-start",
				},

				{
					target: ".toolbar-action-watermark",
					content:
						"Now switch to the watermarks tab to select a watermark to add to your project.",
					disableOverlayClose: true,
				},
				{
					target: ".options-scroller",
					content:
						"Select a watermark to add to your project and click next.",
					placement: "top-end",

					disableOverlayClose: true,
				},
				{
					target: ".xzdarcy-timeline",
					content:
						"Stretch your watermark across the entire timeline by dragging the edges of the watermark. Right click to configure opacity.",
					disableOverlayClose: true,
					placement: "top-start",
				},
				{
					target: ".options-scroller",
					content:
						"Use the audio tab to select an audio track to add to your project.",

					placement: "top-end",
					disableOverlayClose: true,
				},
				{
					target: ".options-scroller",
					content:
						"Select an audio track to add to your project and click next.",
					placement: "right-end",

					disableOverlayClose: true,
				},

				{
					target: ".xzdarcy-timeline",
					content:
						"Right click on the audio to configure the volume. You may split pieces of the track using the split tool.",
					disableOverlayClose: true,

					placement: "top-start",
				},
				{
					target: ".toolbar-action-bumpers",
					content:
						"Use the bumpers tab to select a bumper to add to your project.",
					placement: "bottom-end",
				},
				{
					target: ".options-scroller",
					content:
						"Select a bumper to add to your project and click next.",
					placement: "right-end",
				},
				{
					target: ".xzdarcy-timeline",
					content:
						"Right click to add transition effects to your bumper.",
					disableOverlayClose: true,
					placement: "top-start",
				},
				{
					target: ".toolbar-action-project",
					content:
						"Click the project tab to return to your project. You can also save your project here.",
					placement: "right",
				},
				{
					target: ".save-project-btn",
					content:
						"Click the save project button to save your project. (hint: ctrl+click to save as new)",

					placement: "right-end",

					disableOverlayClose: true,
				},
				{
					target: ".render-version-btn",
					content:
						"Click the render button to generate your content version.",

					placement: "right-end",

					disableOverlayClose: true,
				},
			]}
		/>
	);
}
