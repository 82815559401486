import { useSnackbar } from "notistack";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers";
import NextButton from "../../NextButton";

import { usePublishContentForm } from "../..";

export default function BasicDatePicker() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { setDeferUntil, deferUntil, nextStep } = usePublishContentForm();

	return (
		<>
			<MultiSectionDigitalClock
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				value={deferUntil}
				onChange={setDeferUntil}
			/>
			<NextButton
				onClick={() => {
					if (!deferUntil) {
						let key = enqueueSnackbar(
							"Please select a date before moving on",
							{
								variant: "error",
								onClick: () => {
									closeSnackbar(key);
								},
							}
						);
						return;
					}
					nextStep();
				}}
			/>
		</>
	);
}
