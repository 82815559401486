import { useRequester } from "../../../../Apollo";
import useRetrySubmission from "./useRetrySubmission";
import { useSelectedContent } from "../../../SelectedContentContext";
import destinations from "../../../../Destinations";
import moment from "moment";
import { Link } from "react-router-dom";
import {
	IconButton,
	ListItemIcon,
	ListItemButton,
	ListItemText,
	ListItem,
	List,
	Typography,
	Button,
} from "@mui/material";
import RetryIcon from "@mui/icons-material/Replay";
import LinkIcon from "@mui/icons-material/Link";
export default function SubmissionsTab() {
	const { id } = useSelectedContent();
	const { retry } = useRetrySubmission();
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.submissions.query.page, {
		fetchPolicy: "network-only",
		variables: {
			input: {
				content_id: id,
			},
		},
	});

	return (
		<List
			sx={{ width: "100%", maxWidth: 600, bgcolor: "background.paper" }}
		>
			{!data?.submissions.edges.length && (
				<>
					<ListItem>
						<ListItemText primary="No Submissions" />
					</ListItem>
				</>
			)}
			{data?.submissions.edges.map(({ node: submission }) => {
				const { Icon, destination } = destinations.find(
					({ destination }) =>
						destination.toUpperCase() ===
						submission.integration.destination
				);

				return (
					<ListItem
						key={submission.id}
						secondaryAction={
							<IconButton
								edge="end"
								aria-label="comments"
								disabled={!submission.link}
								title={
									submission.link
										? "View Submission"
										: "No Submission Link"
								}
							>
								<Link to={`/submission/${submission.id}`}>
									<LinkIcon />
								</Link>
							</IconButton>
						}
						disablePadding
					>
						<ListItemButton role={undefined} dense>
							<ListItemIcon>
								<Icon />
							</ListItemIcon>
							<ListItemText
								primary={submission.title}
								secondary={moment(submission.created_at).format(
									"MMMM Do YYYY, h:mm:ss a"
								)}
							/>
							<Typography
								sx={{
									color: theme => {
										const color = {
											SUCCESS: "success.main",
											FAILED: theme.palette.error.main,
											PENDING:
												theme.palette.text.secondary,
										}[submission.status];

										return color;
									},
								}}
							>
								{submission.status}
							</Typography>
							{submission.status !== "SUCCESS" && (
								<>
									<IconButton
										edge="end"
										aria-label="comments"
										title="Retry Submission"
										onClick={() => {
											retry({
												id: submission.id,
											});
										}}
									>
										<RetryIcon />
									</IconButton>
								</>
							)}
						</ListItemButton>
					</ListItem>
				);
			})}
		</List>
	);
}
