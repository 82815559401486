import { useRequester } from "../../Apollo";
import {
	Autocomplete,
	TextField,
	MenuItem,
	Box,
	Stack,
	Typography,
	ListSubheader,
} from "@mui/material";
import { useCallback, useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import AwardIcon from "@mui/icons-material/EmojiEventsRounded";
import UpvoteIcon from "@mui/icons-material/ThumbUpOutlined";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
const dedupe = options => {
	const seen = {};
	return options.filter(option => {
		const k = option.text;
		return seen.hasOwnProperty(k) ? false : (seen[k] = true);
	});
};
const sortOptions = options => {
	return options.slice().sort((a, b) => b.totalUpvotes - a.totalUpvotes);
};

export const HotTitles = ({
	disabled = false,
	defaultQuery = "",
	onChange = title => {},
	onSuggest = title => {},
	alwaysOpen = false,
}) => {
	const { useQuery, definitions } = useRequester();
	const { data, fetchMore, loading, called } = useQuery(
		definitions.common.query.hotTitles,
		{
			variables: {
				input: {
					query: "",
				},
			},

			fetchPolicy: disabled ? "standby" : "cache-and-network",
			nextFetchPolicy: "cache-and-network",
			returnPartialData: true,
			refetchWritePolicy: "cache-and-network",
			onCompleted: data => {
				if (disabled) return;
				// on first return from api, suggest the most upvoted option
				const sorted = sortOptions(data?.suggestedTitles.titles || []);
				onSuggest(sorted[0]?.text);
			},
		}
	);

	const [value, setValue] = useState(null); // auto-set the value to the first option (or the default query if it exists)
	const [options, setOptions] = useState(
		defaultQuery
			? [
					{
						text: defaultQuery,
						value: defaultQuery,
						subreddit: "",
						totalAwards: 0,
						totalUpvotes: 0,
					},
			  ]
			: sortOptions(dedupe(data?.suggestedTitles?.titles || []))
	);

	useEffect(() => {
		if (value) return;
		if (disabled) {
			// set the value to show in the autocomplete while in disabled (preview) mode
			return setValue(
				defaultQuery
					? {
							text: defaultQuery,
							subreddit: "",
							totalAwards: 0,
							totalUpvotes: 0,
					  }
					: null
			);
		}
		if (!called) return;
		if (loading) return;

		// set the input value to the first option (or the default query if it exists)
		setValue(
			defaultQuery
				? {
						text: defaultQuery,
						subreddit: "",
						totalAwards: 0,
						totalUpvotes: 0,
				  }
				: options[0]
		);
	}, [called, loading]);

	useEffect(() => {
		if (disabled) return;
		if (!data) return;
		if (loading) return;
		// only update the options list once the data has loaded
		setOptions(sortOptions(data?.suggestedTitles?.titles || []));
	}, [data, loading]);

	const fetchOptions = useCallback(
		debounce(async (newInputValue = "") => {
			if (newInputValue === " ") newInputValue = "";
			fetchMore({
				query: definitions.common.query.hotTitles,
				variables: {
					input: {
						query: newInputValue,
					},
				},

				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					return {
						...prev,
						suggestedTitles: {
							query: newInputValue,
							titles: fetchMoreResult.suggestedTitles.titles,
						},
					};
				},
			});
		}, 500),
		[fetchMore]
	);

	const [inputValue, setInputValue] = useState(defaultQuery);

	const shouldStayOpen = {};
	if (alwaysOpen) shouldStayOpen.open = true;
	return (
		<Autocomplete
			sx={{
				position: "relative",
				// target options list
				"& .MuiAutocomplete-listbox": {
					height: "100%",
				},
			}}
			{...shouldStayOpen}
			loading
			disabled={disabled}
			fullWidth
			freeSolo
			value={value}
			options={options}
			groupBy={option => option.subreddit}
			openOnFocus
			forcePopupIcon
			filterOptions={options => options}
			getOptionLabel={option => option.text}
			onInputChange={(_, value) => {
				if (disabled) return;
				onChange(value);
				fetchOptions(value);
				setInputValue(value);
			}}
			onChange={(_, value) => {
				if (disabled) return;
				onChange(value?.text || null);
			}}
			isOptionEqualToValue={(option, value) => {
				return (
					option.text === value.text &&
					option.subreddit === value.subreddit
				);
			}}
			renderInput={params => {
				return (
					<TextField
						{...params}
						label="Find a 🔥Hot Title"
						variant="outlined"
						required
					/>
				);
			}}
			renderGroup={params => {
				return (
					<ListSubheader
						key={params.key}
						component={() => {
							return (
								<>
									<MenuItem
										key={params.key}
										disabled
										sx={{
											backgroundColor: theme =>
												theme.palette.primary.main,
											color: theme =>
												theme.palette.primary
													.contrastText,
										}}
									>
										<Typography
											variant="body1"
											sx={{
												// italic
												fontStyle: "italic",
											}}
										>
											r/{params.group}
										</Typography>
									</MenuItem>
									{params.children}
								</>
							);
						}}
					/>
				);
			}}
			renderOption={(props, option) => {
				const matches = match(option.text, inputValue);

				const parts = parse(option.text, matches);

				return (
					<MenuItem
						{...props}
						key={`${option.text}-${option.subreddit}-${option.totalUpvotes}`}
						value={option.value}
					>
						<Stack direction="column">
							<Box>
								<Stack direction="row" spacing={1}>
									<Stack direction="row" spacing={2}>
										<AwardIcon
											sx={{
												color: theme => {
													// gold color:
													return theme.palette.warning
														.main;
												},
											}}
										/>
										{option.totalAwards}
									</Stack>
									<Stack direction="row" spacing={2}>
										<UpvoteIcon color="info" />
										{option.totalUpvotes}
									</Stack>
								</Stack>
							</Box>

							<Box>
								<Typography>
									{parts.map((part, index) => (
										<span
											key={index}
											style={{
												fontWeight: part.highlight
													? 700
													: 400,
											}}
										>
											{part.text.replace(/ /g, "\u00a0")}
										</span>
									))}
								</Typography>
							</Box>
						</Stack>
					</MenuItem>
				);
			}}
		/>
	);
};
