import { useRequester } from "../../Apollo";
import {
	Card,
	CardHeader,
	CardContent,
	CardMedia,
	Avatar,
	AvatarGroup,
	Box,
	Typography,
} from "@mui/material";
import destinations from "../../Destinations";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";

export default function Submission({ id }) {
	const { useQuery, definitions } = useRequester();
	const { data, loading } = useQuery(
		definitions.submissions.query.submission,
		{
			variables: {
				submissionId: id,
			},
		}
	);

	if (loading) return <>loading...</>;

	const destination = destinations.find(
		destination =>
			destination.destination.toUpperCase() ===
			data.submission.integration.destination.toUpperCase()
	);

	const createdAtDate = new Date(data.submission.createdAt).toLocaleString();
	const deferUntilDate = new Date(
		data.submission.deferUntil * 1000
	).toLocaleString();
	const submittedAtDate = new Date(
		data.submission.submittedAt * 1000
	).toLocaleString();
	const file = data.submission.file;
	let mediaComponent = "img";

	if (file.__typename === "VideoFile") mediaComponent = "video";

	return (
		<>
			<Card>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
						padding: "10px",
						width: "fit-content",
					}}
				>
					<AvatarGroup>
						<destination.Icon />
						<Avatar src={data.submission.integration.avatar} />
					</AvatarGroup>
					<Typography variant="h6">
						{data.submission.integration.username}
					</Typography>
				</Box>
				<Link to={`/workspace/content/${data.submission.contentID}`}>
					<CardHeader
						avatar={<LinkIcon />}
						title={`View related content`}
					/>
				</Link>
				<Typography variant="body1">
					Created at: ({createdAtDate})
				</Typography>
				<Typography variant="body1">
					Scheduled for: ({deferUntilDate})
				</Typography>
				<Typography variant="body1">
					Submitted at: ({submittedAtDate})
				</Typography>
				<CardHeader title={data.submission.title} />
				<CardContent>
					<CardMedia
						sx={{
							height: "250px",
							width: "250px",
							objectFit: "contain",
							objectPosition: "center",
						}}
						controls
						component={mediaComponent}
						image={file.url}
					/>
				</CardContent>
			</Card>
		</>
	);
}
