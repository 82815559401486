import { gql } from "@apollo/client";
import { ContentFragment } from "../../content/fragment";
export const verifyEmail = gql`
	mutation verifyEmail {
		verifyEmail
	}
`;

export const assetMatchConfig = gql`
	mutation ($input: AssetMatchConfigInput!) {
		assetMatchConfig(input: $input) {
			config {
				should_keep
				should_warn
				should_warn_visual
			}
		}
	}
`;

export const approvalStatus = gql`
	mutation ($input: UpdateContentApprovalStatusMultipleInput!) {
		approvalStatusContent(input: $input) {
			approvalStatus
			id
		}
	}
`;

export const deleteContent = gql`
	mutation DeleteContent($input: DeleteContentInput!) {
		deleteContent(input: $input) {
			contentIds
		}
	}
`;

export const getSignedURL = gql`
	mutation GetSignedUrl($input: GetSignedUrlInput!) {
		getSignedUrl(input: $input)
	}
`;
export const addTag = gql`
	mutation AddTag($input: AddWorkspaceTagInput!) {
		addWorkspaceTag(input: $input) {
			tag {
				id
				isWorkspaceTag
				source
				value
			}
		}
	}
`;

export const report = gql`
	mutation Report($input: ReportInput!) {
		report(input: $input) {
			isReported
			timestamp
		}
	}
`;

export const pruneDuplicates = gql`
	${ContentFragment}
	mutation PruneDuplicates($input: PruneDuplicatesInput!) {
		pruneDuplicates(input: $input) {
			content {
				...ContentFragment
			}
		}
	}
`;
