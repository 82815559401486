import { useContentEditor } from "../../../..";
import { useAutoComplete } from "../../../..";
import { useEffect } from "react";
import { Stack } from "@mui/material";
export default function SelectContentAutocomplete() {
	const {
		addTrack,
		variables: {
			input: {
				timeline: { tracks },
			},
		},
	} = useContentEditor();
	const { ImageSearch, VideoSearch, setOnSelectContent } = useAutoComplete();
	useEffect(() => {
		setOnSelectContent(async content => {
			if (!content) return;
			addTrack({
				assetType: "CONTENT",
				file: content.source,
				id: content.id,
				name: content.title,
			});
		});
	}, [tracks.length]);
	return (
		<Stack
			direction={"column"}
			spacing={1}
			sx={{
				paddingX: "6px",
			}}
		>
			{VideoSearch}
			{ImageSearch}
		</Stack>
	);
}
