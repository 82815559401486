import { usePublishContentForm } from "..";
import {
	TextField,
	Stack,
	Box,
	Button,
	FormControlLabel,
	Divider,
	CardMedia,
	useMediaQuery,
	IconButton,
	FormLabel,
	ImageList,
	ImageListItem,
	ImageListItemBar,
} from "@mui/material";
import { TimeField, DateField } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import ImgurOptions from "../DestinationOptions/Imgur";
import RedgifsOptions from "../DestinationOptions/Redgifs";
import RedditOptions from "../DestinationOptions/Reddit";
import OnlyfansOptions from "../DestinationOptions/Onlyfans";
import TwitterOptions from "../DestinationOptions/Twitter";
import { useState } from "react";

export default function Review() {
	const [loading, setLoading] = useState(false);
	const mobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const {
		destination,
		deferUntil,
		content,
		title,
		setOnStep,
		publish,
		validate,
	} = usePublishContentForm();

	const Options = {
		Imgur: () => <ImgurOptions disabled hideNext />,
		Redgifs: () => <RedgifsOptions disabled hideNext />,
		Reddit: () => <RedditOptions disabled hideNext />,
		OnlyFans: () => <OnlyfansOptions disabled hideNext />,
		Twitter: () => <TwitterOptions disabled hideNext />,
	}[destination];
	const EditableOptionLabel = ({ label, goToStep, className }) => {
		return (
			<Box
				className={className}
				sx={{
					width: "fit-content",
					mb: 2,
					display: "flex",
					alignItems: "center",
				}}
			>
				<FormControlLabel
					labelPlacement="start"
					label={label}
					onClick={() => setOnStep(goToStep)}
					control={
						<IconButton size="small">
							<EditIcon fontSize=".4rem" />
						</IconButton>
					}
				/>
			</Box>
		);
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "80vh",
					overflowY: "scroll",
					overflowX: "hidden",
					// paddingBotom: 2,
				}}
			>
				<EditableOptionLabel label="Submission Options" goToStep={0} />
				<Stack
					direction={mobile ? "column-reverse" : "row"}
					spacing={2}
				>
					<Box
						sx={{
							bgcolor: "background.paper",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							flexGrow: 1,
						}}
					>
						<ImageList rowHeight={125}>
							{content.map(content => {
								return (
									<ImageListItem
										key={content.id}
										sx={{
											objectFit: "contain",
											height: 125,
											width: 125,
										}}
									>
										<img
											src={content.source.thumbnailUrl}
											alt={content.source.thumbnailUrl}
											height={125}
											width={125}
										/>
										<ImageListItemBar
											title={content.title}
											subtitle={content.source.source}
										/>
									</ImageListItem>
								);
							})}
						</ImageList>
					</Box>
					<TextField label="Title" value={title} disabled />
				</Stack>

				<Divider />

				<EditableOptionLabel
					label={`${destination} Options`}
					goToStep={3}
				/>
				<Options />

				<Divider />

				<EditableOptionLabel label="Schedule" goToStep={4} />
				<Stack direction={"column"}>
					<DateField disabled value={deferUntil} />
					<TimeField disabled value={deferUntil} />
				</Stack>
				<Divider />
			</Box>

			<Button
				sx={{
					position: "absolute",
					bottom: 0,
				}}
				className="content-publish-review-options"
				variant="contained"
				color="primary"
				fullWidth
				disabled={loading}
				onClick={async () => {
					setLoading(true);
					if (!(await validate())) return setLoading(false);

					await publish();
					setLoading(false);
				}}
			>
				Publish
			</Button>
		</>
	);
}
