import { useRequester } from "../../../Apollo";
import { useState } from "react";

import {
	Card,
	CardActions,
	CardContent,
	Typography,
	Box,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	IconButton,
	List,
	ListItem,
} from "@mui/material";
import depthEffect from "@/Theme/sx/depth-effect";
import BackArrowIcon from "@mui/icons-material/ArrowBack";
import {
	// query params
	useLocation,
} from "react-router-dom";
import LabeledIcon from "../../../Component/LabeledIcon";
import destinationList from "../../../Destinations";
import Account from "../Account";
import AddNewIntegration from "../AddNew";
export const DestinationView = ({ destination, Icon }) => {
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.integrations.query.integrations, {
		variables: {
			input: {
				destination: destination.toUpperCase(),
			},
		},
		fetchPolicy: "cache-first",
	});

	return (
		<>
			<CardContent>
				<LabeledIcon label={destination} Icon={Icon} />
			</CardContent>
			<CardActions>
				<AddNewIntegration destination={destination} />
			</CardActions>
			<List>
				{Array.from(data?.integrations || []).map((integration, i) => {
					return (
						<ListItem key={i}>
							<Account integration={integration} />
						</ListItem>
					);
				})}
			</List>
		</>
	);
};
const IntegrationsCount = ({ Icon, destination }) => {
	const { definitions, useQuery } = useRequester();

	const { data } = useQuery(definitions.integrations.query.integrations, {
		fetchPolicy: "cache-first",
		variables: {
			input: {
				destination: destination.toUpperCase(),
			},
		},
	});

	return (
		<Typography
			variant="body1"
			sx={{
				marginTop: 1,
			}}
		>
			{Array.from(data?.integrations || []).length}
		</Typography>
	);
};

const useDefaultDestination = () => {
	const location = useLocation();
	const search = new URLSearchParams(location.search);
	const destinationParam = search.get("destination");
	if (!destinationParam) return;
	const found = destinationList.find(
		({ destination }) =>
			destination.toUpperCase() === destinationParam.toUpperCase()
	);
	console.log("found default destination", found, "from", destinationParam);
	return found;
};
export default function DestinationList() {
	const defaultDestination = useDefaultDestination();
	const makeDestinationState = destination => {
		if (!destination) return { destination: null, Icon: () => null };
		const found = destinationList.find(
			({ destination: d }) =>
				d.toUpperCase() === destination.toUpperCase()
		);

		return (
			found || {
				destination: null,
				Icon: () => null,
			}
		);
	};
	const [{ destination, Icon }, setSelectedDestinationListItem] = useState(
		makeDestinationState(defaultDestination?.destination)
	);
	const handleDestinationListItemClick = destination => {
		setSelectedDestinationListItem(makeDestinationState(destination));
	};

	return (
		<Box
			sx={{
				maxWidth: 500,
				position: "relative",
			}}
		>
			{!destination && (
				<Box
					sx={{
						width: "100%",
						height: "100%",
					}}
				>
					<ImageList>
						{destinationList.map((item, i) => {
							const { Icon } = item;
							return (
								<ImageListItem
									key={i}
									value={item.destination}
									onClick={() =>
										setSelectedDestinationListItem(item)
									}
								>
									<Icon size={"125px"} />

									<ImageListItemBar
										title={item.destination}
									/>
								</ImageListItem>
							);
						})}
					</ImageList>
				</Box>
			)}

			{destination && (
				<>
					<IconButton>
						<BackArrowIcon
							onClick={() => handleDestinationListItemClick(null)}
						/>
					</IconButton>
					<Card
						className="home-integration-destinations-list"
						sx={{
							width: "100%",
							height: "100%",

							...depthEffect(1),
						}}
					>
						<DestinationView
							destination={destination}
							Icon={Icon}
						/>
					</Card>
				</>
			)}
		</Box>
	);
}
