import { useParams } from "react-router-dom";
import { useRequester } from "../../../../../Apollo";
import { Grid, Box } from "@mui/material";
import { BodyHeight } from "../../../../../Layout";
import LoadingScreen from "../../../../../Component/LoadingScreen";

import ContentCard from "../../../../../Component/Content/Card";
import Tabs from "../../../../../Component/Content/Tabs";
import MissingContentCard from "../../../../../Component/Content/Missing";
import Joyride from "../../Content/Joyride";
import { ManageContentTabsContext } from "../../../../../Component/Content/Tabs";
import CanNavigateAwayContext from "../../../../../util/CanNavigateAway";
import SelectedContentContext from "../../../../../Component/SelectedContentContext";
export default function PageContent() {
	const { definitions, useQuery } = useRequester();
	const { id } = useParams();

	// this sets the content id in the cache
	// content card sub-components will generally use the cached value that is created once here.
	const { loading, error, data } = useQuery(
		definitions.content.query.getContent,
		{
			fetchPolicy: "cache-first",
			variables: {
				id,
			},
		}
	);

	return (
		<LoadingScreen loading={loading} message={`Fetching content`}>
			<SelectedContentContext id={id}>
				<CanNavigateAwayContext>
					<ManageContentTabsContext>
						<BodyHeight overflowY="scroll">
							<Box
								sx={{
									height: "100%",
								}}
							>
								{error ||
								data?.content?.assetStatus === "DELETED" ? (
									<MissingContentCard />
								) : (
									<>
										<ContentCard />

										<Box
											sx={{
												height: "100%",
												padding: 0,
											}}
										>
											<Tabs />
										</Box>
									</>
								)}
							</Box>
						</BodyHeight>

						<Joyride />
					</ManageContentTabsContext>
				</CanNavigateAwayContext>
			</SelectedContentContext>
		</LoadingScreen>
	);
}
