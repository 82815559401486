import RBACConfig from "@/Component/RBAC/Config";
import { Stack, Typography, Box } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import LeftArrowIcon from "@mui/icons-material/ArrowBack";
import { useRequester } from "@/Apollo";
import LoadingScreen from "@/Component/LoadingScreen";
import { WorkspaceWrapper } from "@/Layout";
export default function WorkspaceRole() {
	const { id } = useParams();
	const { definitions, useQuery } = useRequester();
	const { data, loading, refetch, networkStatus, error } = useQuery(
		definitions.rbac.query.workspaceRole,
		{
			fetchPolicy: "network-only",
			variables: {
				input: {
					id,
				},
			},
		}
	);

	const role = data?.workspaceRole.role;
	return (
		<WorkspaceWrapper>
			<Stack spacing={2}>
				<Typography variant="h5">Roles And Permissions</Typography>
				<Stack
					spacing={1}
					sx={{
						marginY: "1rem",
					}}
				>
					<Link to="/workspace/assistants/roles">
						<LeftArrowIcon fontSize="inherit" />
						Manage Roles And Permissions
					</Link>
				</Stack>
			</Stack>

			<LoadingScreen loading={loading} message={`Fetching role`}>
				{role && <RBACConfig role={role} />}

				{!role && <Typography>Role not found</Typography>}
			</LoadingScreen>
		</WorkspaceWrapper>
	);
}
