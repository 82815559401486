import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import {
	Box,
	AppBar,
	Toolbar,
	IconButton,
	Button,
	useMediaQuery,
	Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { getAllowedLinks } from "./Links";
import { NavDrawer } from "./Drawer";
import { useRequester } from "@/Apollo";

import AccountWidget from "./AccountWidget";

import { NotificationsWidget } from "../Notifications";
import MailWidget from "@/Component/Mail";

import { useAuth0 } from "@auth0/auth0-react";
import depthEffect from "../Theme/sx/depth-effect";
import SmallLogo from "@/Logo/Small";
import { WideLogoPNG as WideLogo } from "@/Logo/Wide";
export { Footer } from "./Footer";

export const NavigationBar = () => {
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "network-only",
	});
	const { user, isAuthenticated } = useAuth0();
	const isMedium = useMediaQuery(theme => theme.breakpoints.up("md"));
	const [open, setOpen] = useState(false);
	const toggleDrawer = () => {
		setOpen(open => !open);
	};
	const location = useLocation();

	useEffect(() => {
		if (isMedium && open) setOpen(false);
	}, [isMedium]);

	useEffect(() => {
		if (open) setOpen(false);
	}, [location]);

	return (
		<>
			<AppBar
				position="sticky"
				sx={{
					backgroundColor: "primary.main",
					...depthEffect(),
					borderWidth: "0px 0px 1px 0px",
					zIndex: theme => theme.zIndex.drawer + 3,
				}}
			>
				<Toolbar
					id="navigation"
					component={"nav"}
					role="navigation"
					disableGutters={isMedium}
				>
					<Box>
						{!isMedium ? (
							<>
								<IconButton
									size="large"
									edge="start"
									color="inherit"
									aria-label="menu"
									onClick={toggleDrawer}
									sx={{
										marginLeft: "auto",
									}}
								>
									<MenuIcon />
								</IconButton>
								<Link to="/">
									<IconButton disableRipple>
										<SmallLogo />
									</IconButton>
								</Link>
							</>
						) : (
							<Link
								to="/"
								style={{
									cursor: "pointer",
								}}
							>
								<IconButton disableRipple>
									<WideLogo />
								</IconButton>
							</Link>
						)}
					</Box>

					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						{isMedium &&
							getAllowedLinks({
								isTopNav: true,
								user,
								admin: data?.user?.admin,
							}).map(({ name, links }, i) => {
								return links.map(
									({ to, text, Icon }, index) => {
										return (
											<Button
												key={index}
												color="inherit"
												component={Link}
												sx={{
													// disable highlight
													"&:hover": {
														backgroundColor:
															"transparent",
													},
												}}
												disableRipple
												disabled={location.pathname.startsWith(
													to
												)}
												to={to}
												target={
													to.startsWith("http")
														? "_blank"
														: "_self"
												}
											>
												{text}
											</Button>
										);
									}
								);
							})}
					</Box>

					{isAuthenticated && (
						<Stack
							direction="row"
							spacing={1}
							sx={{
								marginLeft: "auto",
							}}
						>
							<MailWidget />
						</Stack>
					)}
					<Box
						sx={{
							marginLeft: "auto",
							marginRight: !isMedium ? "0px" : "8px",
						}}
					>
						<AccountWidget />
					</Box>
				</Toolbar>
			</AppBar>

			<NavDrawer onClose={toggleDrawer} open={open} />
		</>
	);
};
