export default function deriveAssetTimeOptions({
	start,
	end,
	prevStart,
	prevEnd,
	fileDuration,
	prevTrim,
	mimeType,
}) {
	const TrimOptions = {
		start,
		end,
		trim: {
			from: null,
			to: null,
		},
	};

	// item hasn't moved. no need to trim
	if (start === prevStart && end === prevEnd) {
		return {
			...TrimOptions,
			trim: prevTrim,
		};
	}

	let whichTrim;
	if (start !== prevStart && end !== prevEnd) whichTrim = "none"; // asset has moved, but not trimmed
	if (start !== prevStart && end === prevEnd) whichTrim = "start"; // left handle has been moved
	if (start === prevStart && end !== prevEnd) whichTrim = "end"; // right handle has been moved

	const trimmedDuration = Math.abs(TrimOptions.end - TrimOptions.start);
	const isImage = mimeType.includes("image");
	const isVideo = mimeType.includes("video");
	const isAudio = mimeType.includes("audio");

	switch (whichTrim) {
		case "start": {
			TrimOptions.trim.from = prevTrim.from + (start - prevStart);
			TrimOptions.trim.to = prevTrim.to;
			if (TrimOptions.trim.from < 0) {
				const howMuchLess = Math.abs(TrimOptions.trim.from);
				TrimOptions.trim.from = 0;
				TrimOptions.trim.to = TrimOptions.trim.to + howMuchLess;
			}
			TrimOptions.end = prevEnd;
			break;
		}

		case "end":
			TrimOptions.trim.from = prevTrim.from;
			TrimOptions.trim.to = prevTrim.to + (end - prevEnd);
			TrimOptions.start = prevStart;
			break;
		case "none":
			TrimOptions.trim = prevTrim;
			break;

		default:
			break;
	}

	const handleVideo = () => {
		if (trimmedDuration > fileDuration) {
			const howMuchMore = trimmedDuration - fileDuration;
			switch (whichTrim) {
				case "start":
					TrimOptions.trim.from = 0;
					TrimOptions.trim.to = fileDuration;
					TrimOptions.start = TrimOptions.start + howMuchMore;
					break;
				case "end":
					TrimOptions.trim.to = fileDuration;
					TrimOptions.trim.from = 0;
					TrimOptions.end = TrimOptions.end - howMuchMore;
					break;
				default:
					break;
			}
		}
	};

	const handleImage = () => {
		if (trimmedDuration < 0.4) {
			// ensure image is at least one frame long
			TrimOptions.trim.from = 0;
			TrimOptions.trim.to = 0.4;
			TrimOptions.end = TrimOptions.start + 0.4;
			return;
		}
		if (trimmedDuration > fileDuration) {
			// ensure image trim starts from 0 and ends at the total duration
			TrimOptions.trim.from = 0;
			TrimOptions.trim.to = Math.abs(start - end);
		}
	};

	if (isVideo) handleVideo();

	if (isImage) handleImage();

	if (isAudio) handleVideo();

	return TrimOptions;
}
