import {
	Paper,
	IconButton,
	Typography,
	AppBar,
	Dialog,
	DialogContent,
	Box,
} from "@mui/material";
import Joyride from "./Joyride";
import { useLocation } from "react-router-dom";

import DestinationSelector from "./DestinationSelect";
import IntegrationSelector from "./IntegrationSelect";
import DestinationOptions from "./DestinationOptions";
import TitleOptions from "./BasicOptions";
import ContentOptions from "./BasicOptions/Content";
import DatePicker from "./Scheduler/DatePicker";
import TimePicker from "./Scheduler/TimePicker";
import Review from "./Review";

import GoBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useRequester } from "../../Apollo";
import { utc } from "moment";
import { useSnackbar } from "notistack";

const clone = object => Object.assign({}, object);

const CONTEXT = {
	setOnStep: int => {},
	nextStep: () => {},
	previousStep: () => {},

	joyrideRef: null,

	// FORM OPTIONS:
	defaultContentId: null,
	content: [],
	addContent: content => {},
	removeContent: id => {},

	deferUntil: new Date(),
	setDeferUntil: date => {},
	destination: [],
	setDestination: string => {},
	integration: null,
	setIntegration: string => {},

	title: null,
	setTitle: string => {},
	imgur: {
		album: null,
		setAlbum: string => {},
		description: null,
		setDescription: string => {},
		disable_audio: false,
		setDisable_audio: boolean => {},
		name: null,
		setName: string => {},
		nsfw: true,
		setNsfw: boolean => {},
		hidden: false,
		setHidden: boolean => {},
	},
	onlyfans: {
		description: null,
		setDescription: string => {},
		dm_recipient: null,
		setDm_recipient: string => {},
		fundraising_target: null,
		setFundraising_target: double => {},
		vault: false,
		setVault: boolean => {},
	},
	reddit: {
		subreddit: null,
		setSubreddit: string => {},
		nsfw: true,
		setNsfw: boolean => {},
		sourceAccount: null,
		setSourceAccount: string => {},
	},
	redgifs: {
		keepAudio: true,
		setKeepAudio: boolean => {},
		isPrivate: false,
		setPrivate: boolean => {},
		tags: [],
		setTags: strings => {},
	},
	twitter: {
		sourceAccount: null,
		setSourceAccount: string => {},
	},
	validate: async () => {},
	publish: async () => {},
};
const Context = createContext(CONTEXT);
export const usePublishContentForm = () => useContext(Context);
export const PublishContentDialog = ({
	open,
	onClose,
	contentIds,
	deferUntil,
}) => {
	return (
		<Dialog open={open} onClose={onClose} fullWidth fullScreen>
			<PublishContentForm
				contentIds={contentIds}
				onClose={onClose}
				deferUntil={deferUntil}
			/>
		</Dialog>
	);
};

export default function PublishContentForm({
	contentIds,
	onClose,
	deferUntil: withDeferUntil = new Date(),
}) {
	const { pathname } = useLocation();
	const joyrideRef = useRef(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation, useLazyQuery, useQuery } = useRequester();
	const {
		data: contentData,
		called,
		loading,
	} = useQuery(definitions.content.query.getContents, {
		variables: {
			ids: contentIds,
		},
		fetchPolicy: "cache-first",
	});
	const [content, setContent] = useState(null);
	useEffect(() => {
		if (!contentData) return;
		if (content !== null) return;
		setContent(contentData.contents);
	}, [contentData]);

	const [publishContent] = useMutation(
		definitions.integrations.mutation.publish
	);
	const [validate] = useLazyQuery(
		definitions.integrations.query.publishPreValidate
	);

	const [onStep, setOnStep] = useState(0);
	useEffect(() => {
		joyrideRef.current?.helpers.go(onStep);
	}, [onStep]);

	// skip the content selection step if we're already on a content page
	const isOnContentPage = pathname.includes("/content/");
	const nextStep = () => {
		let nextStep = Math.min(7, onStep + 1);
		if (isOnContentPage && nextStep === 1) nextStep++;
		setOnStep(nextStep);
	};
	const previousStep = () => {
		let nextStep = Math.max(0, onStep - 1);
		if (isOnContentPage && nextStep === 1) nextStep--;
		setOnStep(nextStep);
	};

	const [destination, setDestination] = useState(null);

	const [deferUntil, setDeferUntil] = useState(withDeferUntil);
	const [integration, setIntegration] = useState(null);

	const [title, setTitle] = useState(null);
	useEffect(() => {
		if (!contentData || !called || loading) return;
		if (title !== null) return;
		let newTitle = null;
		for (const content of contentData.contents) {
			if (content.title) {
				newTitle = content.title;
				break;
			}
		}
		setTitle(newTitle || "");
	}, [loading]);

	const [imgurOptions, setImgurOptions] = useState(
		Object.assign({}, { ...CONTEXT.imgur, name: title })
	);
	const [onlyfansOptions, setOnlyfansOptions] = useState(
		clone(CONTEXT.onlyfans)
	);
	const [redditOptions, setRedditOptions] = useState(clone(CONTEXT.reddit));
	const [redgifsOptions, setRedgifsOptions] = useState(
		clone(CONTEXT.redgifs)
	);
	const [twitterOptions, setTwitterOptions] = useState(
		clone(CONTEXT.twitter)
	);

	const buildVariables = () => {
		try {
			const options = {};
			// add options for specified destination
			const dest = destination.toLowerCase();
			options[dest] = Object.entries(
				{
					imgur: imgurOptions,
					onlyfans: onlyfansOptions,
					reddit: redditOptions,
					redgifs: redgifsOptions,
					twitter: twitterOptions,
				}[dest] // extract the options for the currently selected destination
			).reduce(
				// don't include setter functions,
				// only add the actual values
				(acc, [k, v]) => {
					if (k === "integration") return acc; // skip the integration property (it's already set)
					if (typeof v !== "function") acc[k] = v;
					return acc;
				},
				{
					// all destinations need the integration property
					integration: integration.username,
				}
			);
			if (destination.toLowerCase() === "redgifs") {
				options.redgifs.tags = options.redgifs.tags.map(
					tag => tag.value
				);
				options.redgifs.private = options.redgifs.isPrivate;
				delete options.redgifs.isPrivate;
			}

			const variables = {
				input: {
					options: content.map(content => {
						return {
							contentId: content.id,
							destinations: [destination],
							title,
							deferUntil: Math.floor(
								utc(deferUntil).valueOf() / 1000
							),
							...options,
						};
					}),
				},
			};
			return variables;
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<Context.Provider
			value={{
				joyrideRef,
				onStep,
				nextStep,
				setOnStep: setOnStep,
				previousStep,
				content,
				defaultContentId: contentIds[0],
				addContent: item => {
					console.log("adding content");
					if (item.approvalStatus !== "READY") {
						let key = enqueueSnackbar(
							"Selected content is not approved for publication.",
							{
								variant: "error",
								autoHideDuration: 5000,
								onClick: () => closeSnackbar(key),
							}
						);
						return;
					}
					if (content.find(c => c.id === item.id)) {
						let key = enqueueSnackbar(
							"Selected content is already in the list.",
							{
								variant: "error",
								autoHideDuration: 5000,
								onClick: () => closeSnackbar(key),
							}
						);
						return;
					}
					setContent([...content, item]);
				},
				removeContent: id => {
					setContent(content.filter(c => c.id !== id));
				},
				destination,
				setDestination,

				integration,
				setIntegration,
				title,
				setTitle,
				deferUntil,
				setDeferUntil,

				imgur: {
					...imgurOptions,
					setAlbum: album => {
						setImgurOptions({
							...imgurOptions,
							album,
						});
					},
					setDescription: description => {
						setImgurOptions({
							...imgurOptions,
							description,
						});
					},
					setDisable_audio: disable_audio => {
						setImgurOptions({
							...imgurOptions,
							disable_audio,
						});
					},
					setName: name => {
						setImgurOptions({
							...imgurOptions,
							name,
						});
					},
					setNsfw: nsfw => {
						setImgurOptions({
							...imgurOptions,
							nsfw,
						});
					},
					setHidden: hidden => {
						setImgurOptions({
							...imgurOptions,
							hidden,
						});
					},
				},
				onlyfans: {
					...onlyfansOptions,
					setDescription: description => {
						setOnlyfansOptions({
							...onlyfansOptions,
							description,
						});
					},
					setDm_recipient: dm_recipient => {
						setOnlyfansOptions({
							...onlyfansOptions,
							dm_recipient,
						});
					},
					setFundraising_target: fundraising_target => {
						setOnlyfansOptions({
							...onlyfansOptions,
							fundraising_target,
						});
					},
					setVault: vault => {
						setOnlyfansOptions({
							...onlyfansOptions,
							vault,
						});
					},
				},
				reddit: {
					...redditOptions,
					setSubreddit: subreddit => {
						setRedditOptions({
							...redditOptions,
							subreddit,
						});
					},
					setNsfw: nsfw => {
						setRedditOptions({
							...redditOptions,
							nsfw,
						});
					},
					setSourceAccount: sourceAccount => {
						setRedditOptions({
							...redditOptions,
							sourceAccount,
						});
					},
				},
				redgifs: {
					...redgifsOptions,
					setKeepAudio: keepAudio => {
						setRedgifsOptions({
							...redgifsOptions,
							keepAudio,
						});
					},
					setPrivate: isPrivate => {
						setRedgifsOptions({
							...redgifsOptions,
							isPrivate,
						});
					},
					setTags: tags => {
						setRedgifsOptions({
							...redgifsOptions,
							tags,
						});
					},
				},
				twitter: {
					...twitterOptions,
					setSourceAccount: sourceAccount => {
						setTwitterOptions({
							...twitterOptions,
							sourceAccount,
						});
					},
				},

				validate: async () => {
					// TODO: create a validator function for each destination
					return true;
					let isValidated;
					switch (destination.toLowerCase()) {
						case "imgur":
							break;
						case "onlyfans":
							break;
						case "reddit":
							break;
						case "redgifs":
							break;
						case "twitter":
							break;

						default:
							break;
					}
					if (isValidated) return true;

					let key = enqueueSnackbar(
						"Failed to publish. Please check your configuration before continuing.",
						{
							variant: "error",
							onClick: () => {
								closeSnackbar(key);
							},
						}
					);
				},
				publish: async () => {
					const variables = buildVariables();

					await publishContent({
						variables,
						onError: error => {
							let key = enqueueSnackbar(error.message, {
								variant: "error",
								onClick: () => {
									closeSnackbar(key);
								},
							});
						},

						onCompleted: data => {
							let key = enqueueSnackbar(
								"Your content is being processed for publishing.",
								{
									variant: "success",
									autoHideDuration: 5000,
									onClick: () => {
										closeSnackbar(key);
									},
								}
							);
							onClose(data); // use this to close external dialogs or what-have-you
						},
					});
				},
			}}
		>
			<Joyride />
			<AppBar
				sx={{
					height: "fit-content",
				}}
				position={"fixed"}
			>
				<Paper
					sx={{
						paddingX: 2,
						paddingY: 1,
						display: "flex",
						height: "fit-content",
						alignItems: "center",
					}}
				>
					{onStep > 0 && (
						<IconButton
							title={"Go back to previous step"}
							onClick={() => previousStep()}
						>
							<GoBackIcon />
						</IconButton>
					)}

					<Typography variant="h6">
						{onStep === 0 && `Give it a Title`}
						{onStep === 1 && `Select Content`}
						{onStep === 2 && "Select Destination"}
						{onStep === 3 && "Select Integration"}
						{onStep === 4 && `${destination} Options`}

						{onStep === 5 && `Select Date`}
						{onStep === 6 && `Select Time`}
						{onStep === 7 && `Review and submit`}
					</Typography>
					<IconButton
						sx={{
							marginLeft: "auto",
						}}
						title={"Go back to previous step"}
						onClick={() => onClose()}
					>
						<CloseIcon />
					</IconButton>
				</Paper>
			</AppBar>

			<DialogContent
				sx={{
					width: "100%",
					height: "100%",
					position: "relative",
					marginTop: 6,
					paddingX: 0,
					marginX: "auto",
					maxWidth: "700px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						position: "relative",
						width: "100%",
						height: "100%",
					}}
				>
					{onStep === 0 && (
						<span className="hot-titles">
							<TitleOptions />
						</span>
					)}
					{onStep === 1 && (
						<span className="content-publish-select-content">
							<ContentOptions />
						</span>
					)}

					{onStep === 2 && (
						<span className="content-publish-select-destination">
							<DestinationSelector />
						</span>
					)}
					{onStep === 3 && (
						<span className="content-publish-select-integration">
							<IntegrationSelector
								defaultSelected={integration}
								destination={destination}
								onSelect={integration => {
									setIntegration(integration);
									nextStep();
								}}
							/>
						</span>
					)}
					{onStep === 4 && (
						<span className="content-publish-destination-options">
							<DestinationOptions />
						</span>
					)}
					{onStep === 5 && (
						<span className="content-publish-scheduler-date-picker">
							<DatePicker />
						</span>
					)}
					{onStep === 6 && (
						<span className="content-publish-scheduler-time-picker">
							<TimePicker />
						</span>
					)}
					{onStep === 7 && <Review />}
				</Box>
			</DialogContent>
		</Context.Provider>
	);
}
