import { useRequester } from "@/Apollo";
import { Box, Grid, Stack } from "@mui/material";
import OauthClient from "./Client";
import CreateOauthClientbutton from "./CreateClientButton";
export default function OauthClientList() {
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.oauth.query.listOauthClients);

	const clients = data?.oauthClients?.clients || [];
	return (
		<Box>
			<Stack spacing={2}>
				{clients.map(client => {
					return (
						<OauthClient key={client.id} client_id={client.id} />
					);
				})}
				<Box
					sx={{
						width: "fit-content",
					}}
				>
					<CreateOauthClientbutton />
				</Box>
			</Stack>
		</Box>
	);
}
