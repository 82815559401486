import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { Tree } from "rsuite";
import { useFolderContent } from "./hook/useFolderContent";
import { useRequester } from "../../../Apollo";
import TreeNode from "./TreeNode";
import ViewContentDetails from "./ContentDetails";
import "./styles.css";
export default function FolderTreeItem() {
	const { definitions, useMutation } = useRequester();
	const [folderAddContent] = useMutation(
		definitions.content.mutation.folderAddContent
	);

	const {
		folders,
		fetchFolderContent,
		expandItemValues,
		updateExpanded,
		setSelectedId,
		setSelectedFolder,
	} = useFolderContent();

	return (
		<Box
			sx={{
				height: "100%",
				overflowY: "hidden",
				overflowX: "hidden",
				position: "relative",
				width: "18rem",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					overflowY: "auto",
					overflowX: "hidden",
				}}
			>
				<Tree
					data={folders}
					onSelectItem={item => {
						if (item.type === "content") {
							return setSelectedId(item.value);
						}
						if (item.value === "0") {
							return setSelectedFolder(null);
						}
						setSelectedFolder(item.value);
					}}
					onExpand={updateExpanded}
					expandItemValues={expandItemValues}
					getChildren={fetchFolderContent}
					draggable
					defaultExpandItemValues={expandItemValues}
					onDrop={async data => {
						if (data.dragNode.type === "folder") return;

						let folderId; // folder to add content to
						if (data.dropNode.type === "folder") {
							folderId = data.dropNode.value;
						} else {
							// if content is dropped on another content node,
							// get the folder the content belongs to
							folderId = data.dropNode.data.parent.value;
						}
						// store expanded folders before refetching to prevent tree from collapsing
						const expandedFolderIds = Array.from(expandItemValues);
						await folderAddContent({
							variables: {
								input: {
									contentIds: [data.dragNode.value],
									folderId,
								},
							},
							refetchQueries: [
								{
									query: definitions.content.query.folder,
									variables: {
										folderId:
											data.dragNode.parent.value !== 0
												? data.dragNode.parent.value
												: null,
									},
									errorPolicy: "all",
								},
								folderId === "0" && {
									query: definitions.content.query.allContent,
									variables: {
										folderId: null,
									},
									errorPolicy: "all",
								},
							],
							awaitRefetchQueries: true,
							errorPolicy: "all",
						});
						// update expanded folders after refetching to ensure the state is correct
						updateExpanded(expandedFolderIds);
					}}
					renderTreeIcon={node => {
						if (node.value === "0") return <></>;
						return (
							<ExpandMoreIcon
								sx={{
									color: "var(--text-color)",
									"&:hover": {
										color: "var(--text-color)",
									},
									// if not expanded transform 90 degrees counter clockwise
									transform: node.expand
										? "rotate(0deg)"
										: "rotate(-90deg)",
								}}
							/>
						);
					}}
					renderTreeNode={node => <TreeNode node={node} />}
				/>
			</Box>
			<Box
				sx={{
					width: "100%",
				}}
			>
				<ViewContentDetails />
			</Box>
		</Box>
	);
}
