import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	InputBase,
	Box,
	IconButton,
} from "@mui/material";
import XIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useRequester } from "../../Apollo";
export default function ReportDialog({ open, onClose, metadata, type }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useMutation, definitions } = useRequester();
	const [report] = useMutation(definitions.common.mutation.report);
	const { pathname, search } = useLocation();

	return (
		<Dialog fullWidth open={open} onClose={onClose}>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				Report this issue
				<IconButton onClick={onClose}>
					<XIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box
					component="form"
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
					onSubmit={e => {
						e.preventDefault();
						let key = enqueueSnackbar(
							"Thank you for submitting this bug report. We'll be looking into the issue to get it resolved.",
							{
								variant: "info",
								autoHideDuration: 5000,
								onClick: () => {
									closeSnackbar(key);
								},
							}
						);

						report({
							variables: {
								input: {
									text: e.target.text.value,
									type,
									metadata: JSON.stringify({
										...metadata,
										pathname,
										search,
									}),
								},
							},
						});

						onClose();
					}}
				>
					<InputBase
						name="text"
						sx={{
							height: "250px",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",

							alignItems: "flex-start",

							marginBottom: "1rem",
							border: theme =>
								`1px solid ${theme.palette.divider}`,
						}}
						required
						multiline
						placeholder={"Describe the issue"}
					/>
					<Button type="submit" variant="contained">
						Submit
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}
