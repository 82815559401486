import { StyledSquareIconButton } from "../../../Component/Styled/Button";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import { Badge } from "@mui/material";
import { useLibraryViewContext } from "../Context";
import { useActiveFilters } from "@/ContentSearch/hooks/useActiveFilters";

export default function FacetsToggler() {
	const { setDrawerOpen, drawerOpen } = useLibraryViewContext();
	const activeFilters = useActiveFilters();

	return (
		<StyledSquareIconButton
			className="library-facets-toggler"
			title="Open Search Management Drawer"
			onClick={e => {
				e.stopPropagation();
				setDrawerOpen(!drawerOpen);
			}}
			sx={{
				border: "none",
			}}
		>
			<Badge badgeContent={activeFilters.length} color="primary">
				<FilterIcon />
			</Badge>
		</StyledSquareIconButton>
	);
}
