import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";

export default function useCSSProps() {
	const isSmall = useMediaQuery(theme => theme.breakpoints.up("sm"));

	useEffect(() => {
		const setCSSProps = () => {
			/* --app-height fixes IOS window height bug (url bar covers part of page unless --app-height is set dynamically) */
			const appHeight = window.innerHeight;
			document.documentElement.style.setProperty(
				"--app-height",
				`${appHeight}px`
			);

			/* navigation element is in file: src/Navigation/index.js */
			let navHeight = isSmall ? "64px" : "57px";

			document.documentElement.style.setProperty(
				"--nav-height",
				navHeight
			);

			const bodyHeight = `calc(var(--app-height) - var(--nav-height))`;
			document.documentElement.style.setProperty(
				"--body-height",
				bodyHeight
			);

			const footerMinHeight = `15vh`;
			document.documentElement.style.setProperty(
				"--footer-min-height",

				footerMinHeight
			);

			const workspaceSidebarWidthExpanded = `168px`;
			document.documentElement.style.setProperty(
				"--workspace-sidebar-width-expanded",
				workspaceSidebarWidthExpanded
			);
			const workspaceSidebarWidthCollapsed = `56px`;
			document.documentElement.style.setProperty(
				"--workspace-sidebar-width-collapsed",
				workspaceSidebarWidthCollapsed
			);

			// const workspaceToolbarHeight = `42px`;
			const workspaceToolbarHeight = navHeight;
			document.documentElement.style.setProperty(
				"--workspace-toolbar-height",
				workspaceToolbarHeight
			);
		};
		setCSSProps();
		window.addEventListener("resize", setCSSProps);
		return () => {
			window.removeEventListener("resize", setCSSProps);
		};
	}, [isSmall]);
}
