export const createInput = ({
	filters,
	query = "",
	limit = 200,
	page = 1,
	availableFilters,
}) => {
	if (query && typeof query !== "string") {
		throw new Error("query must be a string");
	}
	if (limit && typeof limit !== "number") {
		throw new Error("limit must be a number");
	}
	if (page && typeof page !== "number") {
		throw new Error("page must be a number");
	}

	const input = {
		query,
		limit,
		page,
		filters: Object.fromEntries(
			availableFilters.map(f => {
				return [f.name, f.default];
			})
		),
	};
	if (filters) {
		for (const [k, v] of Object.entries(filters)) {
			if (!availableFilters.find(f => f.name === k)) {
				throw new Error(`Filter ${k} not found`);
			}
			input.filters[k] = v;
		}
	}
	return input;
};
