import UploadButton, { DropboxImportButton } from "../UploadButton";
import AutoComplete from "./AutoComplete";
import { Box } from "@mui/material";
export default function ContentView() {
	return (
		<Box>
			<UploadButton type="CONTENT" />
			<Box
				sx={{
					width: "50%",
				}}
			>
				<DropboxImportButton />
			</Box>

			<div className="content-search">
				<AutoComplete />
			</div>
		</Box>
	);
}
