import { useCallback } from "react";
import { Controls, ControlButton, useReactFlow } from "reactflow";
import { generateNodeID } from "../generateNodeID";
export default function CustomControls({ currentReactFlowRef }) {
	const { addNodes, project } = useReactFlow();
	const addNode = useCallback(
		type => {
			const { top, left } = currentReactFlowRef.getBoundingClientRect();

			addNodes({
				sourcePosition: "right",
				position: project({
					x: top - left + 75,
					y: top - top + 75,
				}),
				id: generateNodeID(),
				type,
				connectable: true,
			});
		},
		[project]
	);

	return (
		<Controls>
			<ControlButton onClick={() => addNode("renderNode")} title="action">
				<div>+R</div>
			</ControlButton>
			<ControlButton
				onClick={() => addNode("publishNode")}
				title="another action"
			>
				<div>+P</div>
			</ControlButton>
		</Controls>
	);
}
