import { createContext, useContext, useState } from "react";
import useLibraryMediaQuery from "@/InstantSearch/view/useIsWidescreen";
import WorkspaceNavigationDesktop from "../Navigation/Desktop";
import WorkspaceNavigationMobile from "../Navigation/Mobile";
import { useLocationValue, views } from "./LocationValue";
const workspaceConfigContext = createContext({
	expanded: true,
	setExpanded: () => {},
	ComponentDesktop: () => <></>,
	ComponentMobile: () => <></>,
	useLocationValue,
	views,
	isMD: false,
});
export const useWorkspaceConfig = () => useContext(workspaceConfigContext);

export default function WorkspaceConfigContext({ children }) {
	const [expanded, setExpanded] = useState(true);
	const { isMD } = useLibraryMediaQuery();

	return (
		<workspaceConfigContext.Provider
			value={{
				expanded,
				setExpanded: expanded => setExpanded(!!expanded),
				ComponentDesktop: WorkspaceNavigationDesktop,
				ComponentMobile: WorkspaceNavigationMobile,
				useLocationValue,
				views,
				isMD,
			}}
		>
			{children}
		</workspaceConfigContext.Provider>
	);
}
