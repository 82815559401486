import { useState, useContext, createContext } from "react";
import { useRequester } from "../../../../../Apollo";
import ReactJoyride from "react-joyride";

import { IconButton, Box, Typography } from "@mui/material";
import QuestionIcon from "@mui/icons-material/HelpOutline";
import { useMultiselectContent } from "../../../../../MultiselectContent";

const Context = createContext({
	running: false,
});
export const useLibraryJoyride = () => useContext(Context);
const JOYRIDE_DOMAIN = "LIBRARY";
export default function JoyrideContext() {
	const { selected } = useMultiselectContent();
	const { definitions, useQuery, useMutation } = useRequester();
	const { data } = useQuery(definitions.user.query.onboardingProgress, {
		fetchPolicy: "cache-first",
	});
	const onStep =
		data?.onboardingProgress.progress[
			`on_step_${JOYRIDE_DOMAIN.toLowerCase()}`
		];
	const [run, setRun] = useState(onStep === 0);

	const [updateStepProgress] = useMutation(
		definitions.user.muation.onboardingProgress
	);
	const [reset, setReset] = useState(0);

	return (
		<>
			<Box
				sx={{
					position: "absolute",
					bottom: 12,
					right: 12,
					zIndex: 1000,
				}}
			>
				<IconButton
					onClick={() => {
						setRun(true);
						setReset(reset + 1);
						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 0,
								},
							},
						});
					}}
				>
					<QuestionIcon />
				</IconButton>
			</Box>
			<ReactJoyride
				run={!onStep && run}
				key={reset}
				continuous
				showProgress
				showSkipButton
				spotlightClicks
				scrollToFirstStep={false}
				callback={({ action, index, status }) => {
					if (action === "close" || status === "skipped") {
						setRun(false);
						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 1,
								},
							},
						});
					}
				}}
				disableScrolling
				disableOverlayClose
				steps={[
					{
						target: ".library-search-box",
						content: "Search for content",
						placement: "bottom",
						disableBeacon: true,
					},
					{
						target: ".library-facets-toggler",
						content: "Filter by content type, status, and more",
						placement: "bottom",
					},
					{
						target: ".library-import-onlyfans",
						content: "Import your content from OnlyFans",
						placement: "bottom",
					},
					{
						target: ".library-import-dropbox",
						content: "Import your content from Dropbox",
						placement: "bottom",
					},
					{
						target: ".library-upload-from-device",
						content: "Or upload directly from your device",
						placement: "bottom",
					},
					{
						target: ".library-hits-joyride-sentinel",
						isFixed: true,
						content: (
							<Box>
								<Typography>
									Click your content to select then click
									next.
								</Typography>
								{selected.size} items selected
							</Box>
						),

						placement: "bottom-start",
						disableOverlay: true,
					},
					{
						target: ".library-multiselect-menu-button",
						content: `Click here to show the multiselect menu. Hover over items in the list to learn more about them.`,
						placement: "bottom-start",
						disableOverlay: true,
					},
				]}
			/>
		</>
	);
}
