import { StaticDatePicker } from "@mui/x-date-pickers";
import NextButton from "../../NextButton";
import { usePublishContentForm } from "../..";
import { useSnackbar } from "notistack";
export default function BasicDatePicker() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { setDeferUntil, deferUntil, nextStep } = usePublishContentForm();
	return (
		<>
			<StaticDatePicker
				required
				value={deferUntil}
				onChange={setDeferUntil}
				autoFocus
			/>

			<NextButton
				onClick={() => {
					if (!deferUntil) {
						let key = enqueueSnackbar(
							"Please select a date before moving on",
							{
								variant: "error",
								onClick: () => {
									closeSnackbar(key);
								},
							}
						);
						return;
					}
					nextStep();
				}}
			/>
		</>
	);
}
