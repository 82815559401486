import { Box, Button, DialogActions } from "@mui/material";

export default function NextButton({
	onClick = () => {},
	disabled = false,
	title = "Next",
}) {
	return (
		<Box
			title={title}
			sx={{
				display: "flex",
				justifyContent: "flex-end",
				paddingRight: 2,
				marginTop: 2,
				position: "absolute",
				width: "100%",
				bottom: 12,
				right: 2,
				// backgroundColor: theme => theme.palette.background.default,
			}}
		>
			<DialogActions>
				<Button
					type="submit"
					onClick={onClick}
					variant="contained"
					disabled={disabled}
				>
					Next
				</Button>
			</DialogActions>
		</Box>
	);
}
