import { useEffect, useState } from "react";
import { CircularProgress, Box, Fab, Grow } from "@mui/material";
import {
	useItemProgressListener,
	useBatchFinishListener,
	useBatchStartListener,
} from "@rpldy/uploady";
export default function CircularStatic() {
	const item = useItemProgressListener();

	const [{ label, value }, setlabelValue] = useState({
		label: "0%",

		value: 0,
	});
	const [open, setOpen] = useState(false);

	useBatchStartListener(() => {
		console.log("batch start");
		setOpen(true);
	});
	useBatchFinishListener(() => {
		let timer = setTimeout(() => {
			setOpen(false);
		}, 7000);
		return () => {
			clearTimeout(timer);
		};
	});

	useEffect(() => {
		if (!item) {
			return setlabelValue({
				label: "0%",
				value: 0,
			});
		}
		setlabelValue({
			label: `${Math.round(item.completed)}%`,
			value: item.completed,
		});
	}, [item?.completed]);

	return (
		<Box
			sx={{
				display: open ? "block" : "none",
				position: "absolute",
				zIndex: 1500,
				bottom: 24,
				right: 24,
			}}
		>
			<Grow in={open}>
				<Box sx={{ m: 1, position: "relative" }}>
					<Fab aria-label="save" color="primary">
						{label}
					</Fab>
					<CircularProgress
						value={value}
						size={68}
						sx={{
							display: value === 100 ? "none" : "block",
							color: "red",
							position: "absolute",
							top: -6,
							left: -6,
							zIndex: 1,
						}}
					/>
				</Box>
			</Grow>
		</Box>
	);
}
