import formatSeconds from "../../../../util/formatSeconds";

import { Avatar } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CompareTwoToneIcon from "@mui/icons-material/CompareTwoTone";
import ContextMenu from "../../../ContextMenu";
import { FITS } from "../../common/constants";
import { useContentEditor } from "../..";
const getSplitAtTime = action => {
	const startTime = action?.action.start || 0;
	const trimFrom = action?.action.trim.from;
	return action?.time - startTime + trimFrom;
};
export default function TimelineContextMenu({ event, action }) {
	const {
		updateTrackAssetPosition,

		splitTrackAsset,
		removeAssetFromTrack,
		muteTrackAsset,
		transitionTypes,
		updateTrackAssetTransitionSpeed,
		updateTrackAssetTransitionType,
		updateTrackAssetOpacity,
		updateTrackAssetScale,
		updateTrackAssetFit,
		positions,
		transitionSpeeds,
	} = useContentEditor();

	const isMuted = action?.action.data.volume === 0;

	return (
		<ContextMenu
			zIndex={1999}
			options={
				!action
					? [
							// this ensures that the context menu will not display empty labels
							// in the moment after the asset is deleted and the context menu is in the process of closing
					  ]
					: [
							{
								name: "Action Name",
								label: action?.action.data.name,
								onClick: () => {},
								endIcon: (
									<Avatar
										sx={{
											height: 24,
											width: 24,
										}}
										src={
											action?.action.data.file
												.thumbnailUrl
										}
									/>
								),
								secondary: `${
									action?.action.data.file.mimetype.split(
										"/"
									)[0]
								}`,
							},
							{
								name: "Delete",
								label: "Delete",
								onClick: () => {
									removeAssetFromTrack({
										assetIndex:
											action?.action.data.assetIndex,
										trackIndex:
											action?.action.data.trackIndex,
									});
								},

								endIcon: <DeleteIcon />,
								endIconLabel: "",
							},
							{
								name: "Mute",
								label: isMuted ? "Unmute" : "Mute",
								onClick: () => {
									muteTrackAsset({
										assetIndex:
											action?.action.data.assetIndex,
										trackIndex:
											action?.action.data.trackIndex,
									});
								},
								endIcon: !isMuted ? (
									<VolumeUpIcon />
								) : (
									<VolumeOffIcon />
								),
								endIconLabel: "",
							},
							{
								name: "Fit",
								label: "Fit",
								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",
								secondary: action?.action.data.fit,
								options: FITS.map((fit, index) => {
									return {
										name: fit,
										label: fit,
										endIcon: <CompareTwoToneIcon />,
										endIconLabel: "",
										onClick: () => {
											console.log(
												"updating asset fit:",
												action
											);
											updateTrackAssetFit({
												assetIndex:
													action?.action.data
														.assetIndex,
												trackIndex:
													action?.action.data
														.trackIndex,
												fit,
											});
										},
									};
								}),
							},
							{
								name: "Split",
								label: `Split`,
								secondary: `${
									action &&
									formatSeconds(getSplitAtTime(action))
								}`,
								onClick: () => {
									splitTrackAsset({
										assetIndex:
											action?.action.data.assetIndex,
										trackIndex:
											action?.action.data.trackIndex,
										time: action?.time,
									});
									// handleClose();
								},
								endIcon: <ContentCutIcon />,
								endIconLabel: "",
							},
							{
								name: "Opacity",
								label: "Opacity",
								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",
								secondary: `${
									action?.action.data.opacity * 100
								}%`,

								options: [0, 25, 50, 75, 100].map(
									(opacity, index) => {
										return {
											name: `opacity`,
											label: `${opacity}%`,
											endIcon: <CompareTwoToneIcon />,
											endIconLabel: "",
											onClick: () => {
												const newOpacity =
													opacity * 0.01;
												updateTrackAssetOpacity({
													assetIndex:
														action?.action.data
															.assetIndex,
													trackIndex:
														action?.action.data
															.trackIndex,
													opacity: newOpacity,
												});
											},
										};
									}
								),
							},
							{
								name: "Scale",
								label: "Scale",
								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",

								secondary: `${
									action?.action.data.scale * 100
								}%`,
								options: [
									25, 50, 75, 100, 125, 150, 175, 200,
								].map((scale, index) => {
									return {
										name: `${scale}%`,
										label: `${scale}%`,
										endIcon: <CompareTwoToneIcon />,
										endIconLabel: "",
										onClick: () => {
											const newScale = scale * 0.01;

											updateTrackAssetScale({
												assetIndex:
													action?.action.data
														.assetIndex,
												trackIndex:
													action?.action.data
														.trackIndex,
												scale: newScale,
											});
										},
									};
								}),
							},

							{
								name: "Position",
								label: "Position",
								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",
								secondary: action?.action.data.position || "",
								options: positions.map(({ name }, index) => {
									return {
										name,
										label: name,
										endIcon: <CompareTwoToneIcon />,
										endIconLabel: "",
										onClick: () => {
											updateTrackAssetPosition({
												assetIndex:
													action?.action.data
														.assetIndex,
												trackIndex:
													action?.action.data
														.trackIndex,
												position: name,
											});
										},
									};
								}),
							},

							{
								name: "TransitionInType",
								label: "Transition In Type",
								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",
								secondary:
									action?.action.data.transition.in.type,
								options: transitionTypes.map(
									(transition, index) => {
										return {
											name: transition,
											label: transition,
											endIcon: <CompareTwoToneIcon />,
											endIconLabel: "",
											onClick: () => {
												updateTrackAssetTransitionType({
													assetIndex:
														action?.action.data
															.assetIndex,
													trackIndex:
														action?.action.data
															.trackIndex,
													type: transition,
													which: "in",
												});
											},
										};
									}
								),
							},
							{
								name: "TransitionInSpeed",

								label: "Transition In Speed",
								secondary:
									action?.action.data.transition.in.speed,

								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",

								options: transitionSpeeds.map(
									(transition, index) => {
										return {
											name: transition,
											label: transition,
											endIcon: <CompareTwoToneIcon />,
											endIconLabel: "",
											onClick: () => {
												updateTrackAssetTransitionSpeed(
													{
														assetIndex:
															action?.action.data
																.assetIndex,
														trackIndex:
															action?.action.data
																.trackIndex,
														speed: transition,
														which: "in",
													}
												);
											},
										};
									}
								),
							},
							{
								name: "TransitionOutType",
								label: "Transition Out Type",
								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",
								secondary:
									action?.action.data.transition.out.type,
								options: transitionTypes.map(
									(transition, index) => {
										return {
											name: transition,
											label: transition,
											endIcon: <CompareTwoToneIcon />,
											endIconLabel: "",
											onClick: () => {
												updateTrackAssetTransitionType({
													assetIndex:
														action?.action.data
															.assetIndex,
													trackIndex:
														action?.action.data
															.trackIndex,
													type: transition,
													which: "out",
												});
											},
										};
									}
								),
							},
							{
								name: "TransitionOutSpeed",

								label: "Transition Out Speed",
								secondary:
									action?.action.data.transition.out.speed,

								endIcon: <CompareTwoToneIcon />,
								endIconLabel: "",

								options: transitionSpeeds.map(
									(transition, index) => {
										return {
											name: transition,
											label: transition,
											endIcon: <CompareTwoToneIcon />,
											endIconLabel: "",
											onClick: () => {
												updateTrackAssetTransitionSpeed(
													{
														assetIndex:
															action?.action.data
																.assetIndex,
														trackIndex:
															action?.action.data
																.trackIndex,
														speed: transition,
														which: "out",
													}
												);
											},
										};
									}
								),
							},
					  ]
			}
			clickEvent={event}
		/>
	);
}
