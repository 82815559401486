import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useContentEditor } from "../../../../../common/context";
import { RESOLUTION } from "../../../../../common/constants";
export default function ResolutionOption() {
	const {
		projectId,
		setResolution,
		variables: {
			input: {
				options: { resolution },
			},
		},
	} = useContentEditor();

	return (
		<FormControl fullWidth>
			<InputLabel>Resolution</InputLabel>
			<Select
				key={projectId}
				defaultValue={resolution}
				onChange={e => setResolution(e.target.value)}
			>
				{RESOLUTION.map(value => {
					return (
						<MenuItem key={value} value={value}>
							{value}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
