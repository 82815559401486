import { useContentEditor } from "@/Component/ContentEditor";
import { Box, Typography, IconButton } from "@mui/material";
import FullScreenIcon from "@mui/icons-material/Fullscreen";

export default function PageEditor() {
	const { setOpen, open } = useContentEditor();

	// console.log("rendering editor component: ", EditorComponent);

	// return <EditorComponent />;
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					textAlign: "center",
				}}
			>
				<Typography>Click to go full screen</Typography>
				<IconButton
					onClick={() => {
						setOpen(open => !open);
					}}
				>
					<FullScreenIcon />
				</IconButton>
			</Box>
		</Box>
	);
}
