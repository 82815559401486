import { Tooltip as TooltipBase, Zoom, Box } from "@mui/material";
export default function FHTooltip({
	children,
	title = "",
	placement = "top-end",
}) {
	return (
		<TooltipBase
			TransitionComponent={Zoom}
			title={title}
			placement={placement}
			arrow
			enterDelay={750}
		>
			<Box
				sx={{
					width: "fit-content",
				}}
			>
				{children}
			</Box>
		</TooltipBase>
	);
}
