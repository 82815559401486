import { useRequester } from "../../../../../../Apollo";
import { useContentEditor } from "../../../..";

import { AssetList } from "../../../../../AssetList";
export default function AudioOverlayList() {
	const { addTrack } = useContentEditor();
	const { useQuery, definitions, useMutation } = useRequester();
	const { data, client } = useQuery(definitions.assets.query.watermarks, {
		fetchPolicy: "cache-and-network",
		variables: {
			first: 100,
			status: "ENABLED",
			type: "AUDIO",
		},
	});
	const [deleteAudioOverlay] = useMutation(
		definitions.assets.mutation.DELETE_WATERMARK
	);

	return (
		<AssetList
			assets={data?.allWatermarks?.edges || []}
			onClick={audio => {
				addTrack({
					assetType: "AUDIO",
					file: audio.file,
					id: audio.id,
					name: audio.name || "Untitled",
				});
			}}
			onDelete={async audio => {
				await deleteAudioOverlay({
					variables: {
						input: {
							watermarkId: audio.id,
						},
					},
				});
				// remove from cache
				client.cache.evict({
					id: client.cache.identify(audio),
				});
			}}
		/>
	);
}
