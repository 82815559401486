import {
	List,
	ListItemAvatar,
	ListItemText,
	ListItemButton,
	Avatar,
} from "@mui/material";
import destinations from "../../../Destinations";
import { usePublishContentForm } from "..";
import NextButton from "../NextButton";
export default function DestinationSelector() {
	const {
		setDestination,
		nextStep,
		destination: selectedDestination,
	} = usePublishContentForm();

	return (
		<>
			<List>
				{destinations.map(({ destination, Icon, color }) => {
					return (
						<ListItemButton
							selected={destination === selectedDestination}
							key={destination}
							onClick={() => {
								setDestination(destination);
								nextStep();
							}}
						>
							<ListItemAvatar>
								<Avatar
									sx={{
										backgroundColor: "transparent",
									}}
								>
									<Icon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={destination}
								// secondary="Jan 9, 2014"
							/>
						</ListItemButton>
					);
				})}
			</List>
			<NextButton
				disabled={!selectedDestination}
				onClick={() => {
					nextStep();
				}}
			/>
		</>
	);
}
