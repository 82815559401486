import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const useStoredOpenState = (key, defaultValue = false) => {
	const k = `refinement-accordion-${key}`;
	const [open, setOpen] = useState(JSON.parse(localStorage.getItem(k)));
	return {
		open: !!open,
		setOpen: open => {
			localStorage.setItem(k, JSON.stringify(open));
			setOpen(open);
		},
	};
};
export default function RefinementAccordion({ title, children }) {
	const { open, setOpen } = useStoredOpenState(title);
	return (
		<Accordion
			disableGutters
			square
			expanded={open}
			onChange={(_, open) => setOpen(open)}
			sx={{
				width: "100%",
				border: theme => `1px solid ${theme.palette.divider}`,
				backgroundColor: theme => theme.palette.background.default,
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
}
