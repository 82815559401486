import { Box, Card, Stack } from "@mui/material";
import ContentPreview from "./Preview";
import ContentDetails from "./Details";
import ContentActions from "./Actions";
import ContentTags from "./Tags";
import { CardActions, useMediaQuery } from "@mui/material";
export default function ContentCard() {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	return (
		<Card
			sx={{
				width: "100%",
				backgroundColor: theme => theme.palette.background.default,
				height: "fit-content",
			}}
		>
			<ContentPreview />
			<CardActions>
				<ContentActions />
			</CardActions>
			<Stack
				sx={{
					paddingLeft: isMobile ? 0 : 2,
					width: "100%",
					borderTop: theme => `1px solid ${theme.palette.divider}`,
					paddingY: 1,
				}}
				spacing={2}
			>
				<ContentDetails />
				<ContentTags />
			</Stack>
		</Card>
	);
}
