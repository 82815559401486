import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useRequester } from "../../../../../../Apollo";
import { useContentEditor } from "../../../..";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";
import FHTooltip from "../../../../../FHTooltip";
export default function RenderContent() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { validateInput, projectId, projectTitle, newProjectId, variables } =
		useContentEditor();
	const { useMutation, definitions, client } = useRequester();
	const [saveProject] = useMutation(definitions.content.mutation.saveProject);
	return (
		<FHTooltip title={"Save Project"} placement="top-end">
			<span>
				<Button
					className="save-project-btn"
					variant="contained"
					sx={{
						width: "fit-content",
						height: "fit-content",
					}}
					onClick={async e => {
						const {
							isValid,
							reason,
							variables: validatedVariables,
						} = validateInput();
						if (!isValid) {
							let key = enqueueSnackbar(reason, {
								variant: "error",
								autoHideDuration: 5000,
								onClick: () => {
									closeSnackbar(key);
								},
							});
							return;
						}
						let id = projectId;
						if (e.ctrlKey) {
							id = uuid();
							newProjectId(id);
						}
						console.log({
							variables,

							validatedVariables,
						});

						const response = await saveProject({
							variables: {
								input: {
									projectId: validatedVariables.projectId,
									title: validatedVariables.input.title,
									contentId:
										validatedVariables.input.contentId,
									options: validatedVariables.input.options,

									timeline: validatedVariables.input.timeline,
								},
							},
							errorPolicy: "all",
							onError: error => {
								console.log("onError: ");
								let key = enqueueSnackbar(error.message, {
									variant: "error",
									autoHideDuration: 5000,
									onClick: () => {
										closeSnackbar(key);
									},
								});
							},
							refetchQueries: [
								{
									query: definitions.content.query.projects,

									variables: {
										input: {},
									},
								},
							],
						});
					}}
					endIcon={<SaveIcon />}
				>
					Save
				</Button>
			</span>
		</FHTooltip>
	);
}
