import { useEffect, useState } from "react";
import {
	IconButton,
	TextField,
	InputAdornment,
	Dialog,
	DialogTitle,
} from "@mui/material";

import CopyIcon from "@mui/icons-material/FileCopy";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSnackbar } from "notistack";
import { useRequester } from "@/Apollo";
import { useOauthClientContext } from "@/Component/Oauth/OauthClientList/Client";
import { useConfirm } from "@/Confirm";
const randomString = () => {
	return new Array(64)
		.fill(0)
		.map(() => "*")
		.join("");
};

export default function HiddenSecret() {
	const { confirm } = useConfirm();
	const { client } = useOauthClientContext();
	const { enqueueSnackbar } = useSnackbar();
	const { useLazyQuery, definitions, useMutation } = useRequester();
	const [fetch, { data, loading }] = useLazyQuery(
		definitions.oauth.query.clientSecret,
		{
			variables: {
				input: {
					client_id: client.id,
				},
			},
			fetchPolicy: "network-only",
		}
	);
	const [rotate, { loading: generating }] = useMutation(
		definitions.oauth.mutation.rotateClientSecret,
		{
			variables: {
				input: {
					client_id: client.id,
				},
			},
		}
	);

	const [hidden, setHidden] = useState(true);

	const defaultValue = randomString();
	const [value, setValue] = useState(defaultValue);
	useEffect(() => {
		if (!data) return;
		setValue(data?.clientSecret?.secret);
	}, [data]);

	return (
		<>
			<Dialog open={loading}>
				<DialogTitle>Decrypting secret...</DialogTitle>
			</Dialog>

			<TextField
				value={value}
				title={hidden ? "" : value}
				sx={{
					"& .MuiInputBase-root": {
						width: "fit-content",
					},
					"& .MuiInputLabel-root": {
						visibility: "visible",
					},
				}}
				InputLabelProps={{ shrink: true }}
				type={hidden ? "password" : "text"}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								onClick={async () => {
									let copyValue = value;
									if (!data) {
										const { data } = await fetch();
										console.log("data", data);
										copyValue = data.clientSecret.secret;
									}
									try {
										navigator.clipboard.writeText(
											copyValue
										);
										enqueueSnackbar("Copied to clipboard", {
											variant: "success",
										});
									} catch (e) {
										enqueueSnackbar(
											"Failed to copy to clipboard",
											{
												variant: "error",
											}
										);
									}
								}}
							>
								<CopyIcon />
							</IconButton>
							<IconButton
								aria-label="toggle password visibility"
								onClick={async () => {
									if (!data) await fetch();
									setHidden(!hidden);
								}}
							>
								{hidden ? <VisibilityOff /> : <Visibility />}
							</IconButton>
							<IconButton
								onClick={async () => {
									const confirmed = await confirm(
										"Are you sure you want to rotate this client's secret?",
										"This is a destructive action and cannot be undone."
									);
									if (!confirmed) return;
									const { data } = await rotate();
									const newValue =
										data.rotateClientSecret.client.secret;

									setValue(newValue);
								}}
							>
								<RefreshIcon
									sx={{
										// loading animation spin on generating
										animation: generating
											? "spin 2s linear infinite"
											: "",
										"@keyframes spin": {
											"0%": {
												transform: "rotate(0deg)",
											},
											"100%": {
												transform: "rotate(360deg)",
											},
										},
									}}
								/>
							</IconButton>
						</InputAdornment>
					),
				}}
				label="Client Secret"
			/>
		</>
	);
}
