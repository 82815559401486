import { useRequester } from "@/Apollo";
import { useNavigate } from "react-router-dom";
import {
	ImageList,
	ImageListItem,
	ImageListItemBar,
	IconButton,
	Typography,
	Box,
} from "@mui/material";
import NavigateNext from "@mui/icons-material/NavigateNext";
import ErrorIcon from "@mui/icons-material/Error";
const SuccessItem = ({ content }) => {
	const navigate = useNavigate();
	return (
		<ImageListItem key={content.id}>
			<img src={content.source.thumbnailUrl} alt={content.title} />
			<ImageListItemBar
				title={content.title}
				actionIcon={
					<IconButton
						aria-label={`navigate to content`}
						onClick={() =>
							navigate(`/workspace/content/${content.id}`)
						}
					>
						<NavigateNext />
					</IconButton>
				}
			/>
		</ImageListItem>
	);
};
const ErrorImageListItem = ({ item }) => {
	const src = item.key.replace("&dl=0", "&raw=1");
	console.log({ src });
	return (
		<ImageListItem
			key={item.id}
			sx={{
				position: "relative",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					// center

					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<ErrorIcon
					sx={{
						color: theme => theme.palette.error.main,
						height: "50%",
						width: "50%",
					}}
				/>
				<Typography
					sx={{
						color: theme => theme.palette.error.main,
						fontWeight: "bold",
					}}
				>
					Error importing
				</Typography>
			</Box>
			<img src={src} alt={item.title} />
			<ImageListItemBar title={item.title} />
		</ImageListItem>
	);
};
export default function ImportBatch({ id }) {
	const { definitions, useQuery } = useRequester();

	const { data } = useQuery(definitions.content.query.importBatch, {
		variables: { input: { id } },
	});

	return (
		<Box>
			<Typography variant="h6">
				Import from {data?.import?.source}
			</Typography>
			<ImageList cols={3}>
				{data?.import?.importFilesConnection?.edges?.map(({ node }) => {
					if (node.status === "SUCCESS") {
						return <SuccessItem content={node.content} />;
					} else {
						return <ErrorImageListItem item={node} />;
					}
				})}
			</ImageList>
		</Box>
	);
}
