import {
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
	ListItemButton,
	IconButton,
	ListItemSecondaryAction,
} from "@mui/material";
import ShieldIcon from "@mui/icons-material/Shield";
import { useRequester } from "@/Apollo";
import CreateRoleButton from "./CreateRoleButton";
import depthEffect from "@/Theme/sx/depth-effect";
import { useNavigate } from "react-router-dom";
export default function RolesList() {
	const { definitions, useQuery } = useRequester();

	const { data } = useQuery(definitions.rbac.query.workspaceRoles, {
		fetchPolicy: "cache-first",
	});
	const navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: theme => theme.palette.background.paper,
				...depthEffect(1),
			}}
		>
			<CreateRoleButton />
			{Array.from(data?.workspaceRoles.roles || []).map(
				({ name, color, id }) => {
					return (
						<ListItem key={id}>
							<ListItemButton onClick={() => navigate(`./${id}`)}>
								<ListItemAvatar>
									<Avatar
										sx={{
											backgroundColor: color,
										}}
									>
										{name[0]}
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary={name} />
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="manage role"
									>
										<ShieldIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItemButton>
						</ListItem>
					);
				}
			)}
		</List>
	);
}
