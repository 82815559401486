import { BodyHeight } from "@/Layout";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useWorkspaceConfig } from "@/Component/Workspace/Context";
import WorkspaceStatusBar from "@/Component/Workspace/StatusBar";

export default function PageWorkspaceInner() {
	const { ComponentDesktop, ComponentMobile, isMD } = useWorkspaceConfig();

	return (
		<BodyHeight
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				overflow: "hidden",
			}}
		>
			<WorkspaceStatusBar />
			<Box
				sx={{
					display: "flex",
					flexGrow: 1,
					overflow: "hidden",
					height: "calc(var(--body-height) - var(--workspace-toolbar-height))",
				}}
			>
				{isMD ? <ComponentDesktop /> : <></>}
				<Box
					sx={{
						flexGrow: 1,
						overflow: "hidden",
						height: "100%",
						position: "relative",
					}}
				>
					<Outlet />
				</Box>
			</Box>
			{isMD ? <></> : <ComponentMobile />}
		</BodyHeight>
	);
}
