import GoogleMapReact from "google-map-react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Typography, Box } from "@mui/material";
import { useSelectedContent } from "../../../../SelectedContentContext";
import { useRequester } from "../../../../../Apollo";
const LocationPin = ({ text }) => (
	<div className="pin">
		<LocationOnOutlinedIcon />
		<p className="pin-text">{text}</p>
	</div>
);
export default function Map({ location, zoomLevel = 12 }) {
	const { id } = useSelectedContent();
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});
	const file = data?.content?.source;

	location = {
		lat: file.exif.gps_latitude,
		lng: file.exif.gps_longitude,
	};
	if (!location.lat || !location.lng) {
		return <></>;
	}
	return (
		<>
			<Typography variant="h6" component="h2">
				This content was created at this location:
			</Typography>

			<Box
				sx={{
					height: "400px",
					maxWidth: "900px",
					marginX: "auto",
				}}
			>
				<GoogleMapReact
					bootstrapURLKeys={{ key: "" }}
					defaultCenter={location}
					defaultZoom={zoomLevel}
				>
					<LocationPin
						lat={location.lat}
						lng={location.lng}
						text={location.address}
					/>
				</GoogleMapReact>
			</Box>
		</>
	);
}
