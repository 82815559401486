import { useEffect, useState } from "react";
import { useRequester } from "../../Apollo";

import { StyledSquareIconButton } from "../Styled/Button";
import destinationList from "../../Destinations";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
const Onlyfans = destinationList.find(
	({ destination }) => destination === "OnlyFans"
);

const ImportListItem = ({ integration, onClose }) => {
	const { useMutation, definitions, useQuery } = useRequester();
	const { refetch, data } = useQuery(
		definitions.integrations.query.import_of_status,
		{
			fetchPolicy: "cache-only",
		}
	);
	const [mutate, { loading }] = useMutation(
		definitions.integrations.mutation.onlyfansImport
	);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const ready = data?.import_of_status?.ready;

	return (
		<ListItem disablePadding>
			<ListItemButton
				disabled={loading || !ready}
				onClick={() => {
					mutate({
						variables: {
							input: {
								fromFolder: "",
								integration: integration.username,
							},
						},
						onError: error => {
							const key = enqueueSnackbar(error.message, {
								variant: "error",
								onClick: () => {
									closeSnackbar(key);
								},
							});
						},
						onCompleted: () => {
							// onClose();
							const key = enqueueSnackbar("Import started", {
								variant: "success",
								onClick: () => {
									closeSnackbar(key);
								},
							});
							refetch();
						},
					});
				}}
			>
				<ListItemAvatar>
					<Onlyfans.Icon size={"28px"} />
				</ListItemAvatar>
				<ListItemText
					primary={integration.username}
					secondary={ready ? "Click to import" : ""}
				/>
			</ListItemButton>
		</ListItem>
	);
};
export default function OnlyfansImportButton() {
	const { definitions, useLazyQuery, useQuery } = useRequester();
	const { data: statusData, refetch } = useQuery(
		definitions.integrations.query.import_of_status,
		{
			fetchPolicy: "cache-then-network",
		}
	);
	const [getIntegrations, { data }] = useLazyQuery(
		definitions.integrations.query.integrations,
		{
			variables: {
				input: {
					destination: "ONLYFANS",
				},
			},
		}
	);

	const [open, setOpen] = useState(false);
	useEffect(() => {
		if (!open) return;
		refetch();
	}, [open]);

	return (
		<>
			<StyledSquareIconButton
				className="library-import-onlyfans"
				title="Import Onlyfans"
				sx={{
					border: "none",
				}}
				onClick={() => {
					getIntegrations();
					setOpen(true);
				}}
			>
				<Onlyfans.Icon size={"28px"} />
			</StyledSquareIconButton>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>Import from OnlyFans Vault</DialogTitle>

				<DialogContent>
					<DialogContentText>
						{!statusData?.import_of_status.ready
							? `Import in progress`
							: `Select an integration to import from vault`}
					</DialogContentText>
					<List>
						{data?.integrations?.map(integration => {
							return (
								<ImportListItem
									key={integration.username}
									integration={integration}
									onClose={() => setOpen(false)}
								/>
							);
						})}
					</List>
				</DialogContent>
			</Dialog>
		</>
	);
}
