import { useState, createContext, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import AssistantList from "./List";
import AssistantDialog from "./Dialog";
import { useRequester } from "@/Apollo";
const Context = createContext({
	selectedAssistant: null,
	setSelectedAssistant: () => {},
	open: false,
	setClosed: () => {},
	setOpen: () => {},
	refetch: () => {},
});

export const useAssistantContext = () => useContext(Context);

export default function AssistantsTable() {
	const { useLazyQuery, definitions } = useRequester();

	const [open, setOpen] = useState(false);
	const [getAssistant, { data }] = useLazyQuery(
		definitions.virtualAssistant.query.assistant,

		{
			fetchPolicy: "cache-and-network",
		}
	);
	const selectedAssistant = data?.virtualAssistant?.assistant;

	return (
		<Context.Provider
			value={{
				selectedAssistant,
				setSelectedAssistant: assistant => {
					getAssistant({
						variables: {
							input: {
								assistant: assistant.user_id,
							},
						},
					});
				},
				open,
				setOpen: () => setOpen(true),
				setClosed: () => setOpen(false),
			}}
		>
			<AssistantDialog />
			<Box
				sx={{
					width: "100%",
					maxWidth: 500,
				}}
			>
				<AssistantList />
			</Box>
		</Context.Provider>
	);
}
