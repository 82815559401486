import { createContext } from "react";

export const Context = createContext({
	displayName: "ContentSearch",
	filters: [],
	query: "",
	page: 1,
	limit: 200,
	totalPages: 1,
	totalCount: 0,
	hasNextPage: false,
	hasPreviousPage: false,
	setFilter: (key, value) => {},
	search: () => {},
	content: [],
	availableFilters: [],
	nextPage: () => {},
	prevPage: () => {},
	goToPage: () => {},
	loading: true,

	sortBy: () => {},
	clearFilters: () => {},
	activeFilters: [],
	responseTimeMS: 0,
	refine: () => {},
	fetchMore: () => {},
});
