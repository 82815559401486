import SwipeDrawer from "../../../Component/SwipeDrawer";
import RefinementControls from "./RefinementControls";
import { useLibraryViewContext } from "../Context";
import UploadContentButton from "../Upload";
import ImportDropboxButton from "../DropboxImport";
import Stats from "./Stats";
import { Box, Slide, CardActions, Card } from "@mui/material";
import UpArrowsIcon from "@mui/icons-material/KeyboardArrowUp";

import useLibraryMediaQuery from "../useIsWidescreen";

import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import UploadAndImportContentButton from "./UploadAndImport";
const RightLabel = () => {
	const { isMD } = useLibraryMediaQuery();
	if (isMD) return null;

	return (
		<Box
			sx={{
				position: "relative",
				height: "56px",
				width: "auto",

				zIndex: theme => theme.zIndex.drawer + 1,
			}}
		>
			<UploadAndImportContentButton />
		</Box>
	);
};
const LeftLabel = () => {
	const { ViewSwitchButton } = useLibraryViewContext();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				height: "100%",
			}}
		>
			<ViewSwitchButton />
			<Stats />
		</Box>
	);
};

export const RefinementsDrawer = () => {
	console.log("rendering refinements drawer");
	const { drawerOpen, setDrawerOpen } = useLibraryViewContext();
	const toggleDrawer = () => setDrawerOpen(!drawerOpen);

	return (
		<Box
			sx={{
				width: "100%",
				// border: theme => `1px solid ${theme.palette.divider}`,
				border: theme => `1px solid red`,
				height: "100%",
			}}
		>
			<SwipeDrawer
				RightLabel={<RightLabel />}
				LeftLabel={<LeftLabel />}
				open={drawerOpen}
				toggleDrawer={toggleDrawer}
			>
				<RefinementControls />
			</SwipeDrawer>
		</Box>
	);
};

const HEADER_HEIGHT = "62px";
export const RefinementsModal = () => {
	const { drawerOpen, setDrawerOpen } = useLibraryViewContext();
	const toggleDrawer = () => setDrawerOpen(!drawerOpen);

	const headerRef = useRef(null);
	const [headerDimensions, setHeaderDimensions] = useState(null);
	useEffect(() => {
		if (!headerRef.current) return;
		if (headerDimensions) return;
		const { width, height } = headerRef.current.getBoundingClientRect();
		setHeaderDimensions({ width, height });
	}, [headerRef.current]);

	const Header = () => {
		return (
			<Card
				sx={{
					width: "100%",
					height: HEADER_HEIGHT,
					position: "relative",
					// top left and right rounded
					borderRadius: "12px 12px 0 0",
				}}
			>
				<CardActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						position: "relative",
					}}
				>
					<LeftLabel />
					<Box
						sx={{
							position: "absolute",
							// center in the middle of the header

							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					>
						{drawerOpen ? (
							<CloseIcon onClick={toggleDrawer} />
						) : (
							<UpArrowsIcon onClick={toggleDrawer} />
						)}
					</Box>

					<RightLabel />
				</CardActions>
			</Card>
		);
	};
	return (
		<>
			<Box
				ref={headerRef}
				sx={{
					zIndex: theme => theme.zIndex.drawer + 1,
					position: "absolute",
					width: "100%",
					height: HEADER_HEIGHT,
					// bottom: "calc(var(--workspace-toolbar-height) + 124px)",
					bottom: `calc(var(--workspace-toolbar-height) + ${
						(headerDimensions?.height || 0) * 2
					}px)
					`,

					display: "flex",
					flexDirection: "column",
				}}
			>
				<Header />
			</Box>
			<Slide in={drawerOpen} direction="up">
				<Card
					sx={{
						zIndex: theme => theme.zIndex.drawer + 1,
						position: "absolute",
						width: "100%",
						height: "calc(var(--body-height) - var(--workspace-toolbar-height) - var(--nav-height))",
						borderRadius: "12px 12px 0 0",
					}}
				>
					<Header />

					<RefinementControls />
				</Card>
			</Slide>
		</>
	);
};
export const RefinementsPanel = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "calc(var(--body-height) - var(--workspace-toolbar-height) - var(--nav-height))",
				position: "sticky",
				width: "18rem",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					padding: "8px",
				}}
			>
				<Stats />
			</Box>
			<RefinementControls />
		</Box>
	);
};
