import { gql } from "@apollo/client";
export const newWatermark = gql`
	subscription NewWatermark {
		newWatermark {
			watermark {
				id
				name
				type
				file {
					id
					url
					name
					mimetype
					... on ImageFile {
						isProcessed
						thumbnailUrl
						url
						duration
						width
						height
					}

					... on AudioFile {
						id
						url
						duration
						thumbnailUrl
					}
				}
				... on ImageWatermark {
					image {
						id
						isProcessed
						url
						duration
						thumbnailUrl
					}
				}
				... on TextWatermark {
					attributes {
						text
						font
						color
						fontSize
						url
					}
				}
			}
		}
	}
`;
