import { gql } from "@apollo/client";
export const acknowledge = gql`
	mutation NotificationAcknowledge($input: NotificationAcknowledgeInput!) {
		notificationAcknowledge(input: $input) {
			notification {
				acknowledged_at
				timestamp
				body
				timestamp
				id
				subject
				entity_type
				level
				hidden
				thumbnail
				link
			}
		}
	}
`;
export const acknowledgeAll = gql`
	mutation NotificationAcknowledgeAll {
		notificationAcknowledgeAll {
			acknowledged
		}
	}
`;
export const hide = gql`
	mutation NotificationHide($input: NotificationHideInput!) {
		notificationHide(input: $input) {
			notification {
				acknowledged_at
				timestamp
				body
				timestamp
				id
				subject
				entity_type
				level
				hidden
				thumbnail
				link
			}
		}
	}
`;
export const hideAll = gql`
	mutation NotificationHideAll {
		notificationHideAll {
			hidden
		}
	}
`;
export const config = gql`
	mutation NotificationConfig($input: NotificationConfigInput!) {
		notificationConfig(input: $input) {
			config {
				enable_email
				enable_in_app
				enable_push
				enable_sms
			}
		}
	}
`;
