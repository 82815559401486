import PlusIcon from "@mui/icons-material/Add";
import { useState } from "react";
import {
	Avatar,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	ListItem,
	ListItemText,
	ListItemAvatar,
	ListItemButton,
} from "@mui/material";

import { useRequester } from "@/Apollo";

export default function CreateRoleButton() {
	const [open, setOpen] = useState(false);
	const { useMutation, definitions } = useRequester();
	const [createRole] = useMutation(definitions.rbac.mutation.createRole);
	return (
		<>
			<ListItem>
				<ListItemButton onClick={() => setOpen(true)}>
					<ListItemAvatar>
						<Avatar>
							<PlusIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="Create Role" />
				</ListItemButton>
			</ListItem>
			<Dialog
				component={"form"}
				onSubmit={e => {
					e.preventDefault();
					const name = e.target.name.value;
					createRole({
						variables: {
							input: {
								name,
							},
						},
						refetchQueries: [definitions.rbac.query.workspaceRoles],
						onCompleted: () => setOpen(false),
					});
				}}
				open={open}
				onClose={() => {
					setOpen(false);
				}}
			>
				<DialogTitle>Create Role</DialogTitle>
				<DialogContent>
					<TextField
						label="Role Name"
						type="text"
						required
						name="name"
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button variant="contained" type="submit">
						Create
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
