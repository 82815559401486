import { useContentSearchFilter } from "@/ContentSearch/hooks/useContentSearchFilter";
import { Slider, Box, TextField, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import formatSeconds from "@/util/formatSeconds";
export default function ContentSearchSlider() {
	const {
		max,
		currentValue: durationMaxCurrentValue,
		refine: refineMax,
	} = useContentSearchFilter({
		attribute: "durationMax",
	});
	const {
		min,
		currentValue: durationMinCurrentValue,
		refine: refineMin,
	} = useContentSearchFilter({
		attribute: "durationMin",
	});

	const [value, setValue] = useState([
		durationMinCurrentValue,
		durationMaxCurrentValue,
	]);
	useEffect(() => {
		setValue([min, max]);
	}, [max]);
	useEffect(() => {
		setValue([durationMinCurrentValue, durationMaxCurrentValue]);
	}, [durationMaxCurrentValue, durationMinCurrentValue]);

	return (
		<Box>
			<Slider
				value={value}
				name={`duration-slider-group`}
				onChange={(_, value) => setValue(value)}
				onChangeCommitted={(_, value) => {
					refineMin(value[0]);
					refineMax(value[1]);
				}}
				valueLabelDisplay="auto"
				valueLabelFormat={value => formatSeconds(value)}
				min={min}
				max={max}
			/>
			<Stack direction="row" spacing={2}>
				<TextField
					size="small"
					label="Min Seconds"
					type="number"
					value={value[0]}
					onChange={e => {
						const value = e.target.value;
						const seconds = parseInt(value);
						if (isNaN(seconds)) return;
						refineMin(seconds);
					}}
				/>
				<TextField
					value={value[1]}
					size="small"
					label="Max Seconds"
					// label always covering input, so we need to hide it
					InputLabelProps={{ shrink: true }}
					type="number"
					onChange={e => {
						const value = e.target.value;
						const seconds = parseInt(value);
						if (isNaN(seconds)) return;
						refineMax(seconds);
					}}
				/>
			</Stack>
		</Box>
	);
}
