import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Avatar,
	Typography,
	useMediaQuery,
	IconButton,
} from "@mui/material";
import XIcon from "@mui/icons-material/Close";
import { makeCDNPath } from "@/util/makeCDNPath";
import RoleAutocomplete from "../RoleAutocomplete";
import RemoveButton from "../RemoveButton";
import { useAssistantContext } from "@/Component/VirtualAssistant/Assistants";
export default function AssistantDialog() {
	const {
		selectedAssistant: assistant,
		open,
		setClosed,
	} = useAssistantContext();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

	return (
		<Dialog
			open={open && assistant}
			onClose={setClosed}
			maxWidth={"xs"}
			fullWidth
			fullScreen={isMobile}
		>
			<DialogActions>
				<IconButton onClick={setClosed}>
					<XIcon />
				</IconButton>
			</DialogActions>
			<DialogTitle>
				<Typography variant="h5">Manage Virtual Assistant</Typography>
			</DialogTitle>
			<DialogContent
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar
					sx={{
						width: "4rem",
						height: "4rem",
						marginBottom: "1rem",
					}}
					src={makeCDNPath(`avatar/user/${assistant?.user_id}`)}
				>
					{assistant?.public_display_name[0]}
				</Avatar>
				<Typography variant="h6">
					{assistant?.public_display_name}
				</Typography>
				<RoleAutocomplete assistant={assistant} />
			</DialogContent>
			<DialogActions>
				<RemoveButton />
			</DialogActions>
		</Dialog>
	);
}
