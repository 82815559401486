import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import MatchesList from "@/Component/Matches";
export default function PageWorkspaceMatch() {
	const { id } = useParams();
	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				overflowY: "scroll",

				overflowX: "hidden",

				position: "relative",
			}}
		>
			<Typography
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: theme => theme.palette.background.paper,
				}}
				variant="h4"
			>
				Duplicate Content
			</Typography>
			<MatchesList hash={id} />
		</Box>
	);
}
