import { gql } from "@apollo/client";
export const fonts = gql`
	query ApplicationConfig {
		applicationConfig {
			fonts
		}
	}
`;
export const durationMaxContent = gql`
	query DurationMaxContent {
		durationMaxContent {
			duration
		}
	}
`;
export const getSignedCookies = gql`
	query getSignedCookies {
		getSignedCookies {
			CloudFront_Key_Pair_Id
			CloudFront_Policy
			CloudFront_Signature
		}
	}
`;

export const assetMatchConfig = gql`
	query {
		assetMatchConfig {
			should_keep
			should_warn
			should_warn_visual
		}
	}
`;

export const hotTitles = gql`
	query GetTitles($input: SuggestedTitlesInput!) {
		suggestedTitles(input: $input) {
			query
			titles {
				link
				text
				totalAwards
				totalUpvotes
				subreddit
			}
		}
	}
`;

export const serverStatus = gql`
	query ServerStatus {
		serverStatus {
			uptime
			serverTime
			status
		}
	}
`;

export const countMatches = gql`
	query MatchesCount {
		matchesCount {
			count
		}
	}
`;
export const matches = gql`
	query Matches($input: MatchesQueryInput!) {
		matches(input: $input) {
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			totalCount
			distinctCount
			edges {
				cursor
				node {
					id
					title
					source {
						... on ImageFile {
							id
							hash
							thumbnailUrl
						}
						... on VideoFile {
							id
							hash
							thumbnailUrl
						}
					}
				}
			}
		}
	}
`;
