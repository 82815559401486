import { gql } from "@apollo/client";

export const createClient = gql`
	mutation CreateOauthClient {
		createOauthClient {
			client {
				id
				secret
				createdAt
				updatedAt
				redirectUris {
					id
					uri
					createdAt
				}
			}
		}
	}
`;

export const deleteClient = gql`
	mutation DeleteOauthClient($input: DeleteOauthClientInput!) {
		deleteOauthClient(input: $input) {
			client_id
			isDeleted
		}
	}
`;
export const addRedirectUri = gql`
	mutation AddRedirectUri($input: AddOauthClientRedirectUriInput!) {
		addRedirectUri(input: $input) {
			client {
				id
				secret
				createdAt
				updatedAt
				redirectUris {
					id
					uri
					createdAt
				}
			}
		}
	}
`;

export const rotateClientSecret = gql`
	mutation RotateClientSecret($input: RotateClientSecretInput!) {
		rotateClientSecret(input: $input) {
			client {
				id
				secret
				createdAt
				updatedAt
				redirectUris {
					id
					uri
					createdAt
				}
			}
		}
	}
`;
export const deleteRedirectUri = gql`
	mutation RemoveRedirectUri($input: AddOauthClientRedirectUriInput!) {
		removeRedirectUri(input: $input) {
			client {
				id
				secret
				createdAt
				updatedAt
				redirectUris {
					id
					uri
					createdAt
				}
			}
		}
	}
`;

export const updateClientName = gql`
	mutation UpdateOauthClientName($input: UpdateOauthClientNameInput!) {
		updateOauthClientName(input: $input) {
			client {
				id
				name
				secret
				createdAt
				updatedAt
				redirectUris {
					id
					uri
					createdAt
				}
			}
		}
	}
`;

export const removeConnection = gql`
	mutation RemoveOauthAppConnection($input: RemoveOauthAppConnectionInput!) {
		removeOauthAppConnection(input: $input) {
			connections {
				name
				client_id
				createdAt
				updatedAt
			}
		}
	}
`;
