import { useRequester } from "../../../../../../Apollo";
import { AssetList } from "../../../../../AssetList";
import { useContentEditor } from "../../../../";
export default function WatermarkList({ type = "TEXT" }) {
	const { useQuery, definitions, useMutation, client } = useRequester();
	const { data, loading, error } = useQuery(
		definitions.assets.query.watermarks,
		{
			variables: {
				first: 100,
				status: "ENABLED",
				type,
			},
		}
	);

	const [deleteWatermark] = useMutation(
		definitions.assets.mutation.DELETE_WATERMARK
	);
	const { addTrack } = useContentEditor();
	const watermarks = loading ? [] : data?.allWatermarks?.edges || [];
	return (
		<AssetList
			assets={watermarks}
			onClick={watermark => {
				addTrack({
					assetType: "WATERMARK",
					file: watermark.file,
					id: watermark.id,
					name: watermark.name,
				});
			}}
			onDelete={async watermark => {
				await deleteWatermark({
					variables: {
						input: {
							watermarkId: watermark.id,
						},
					},
				});
				// remove from cache
				client.cache.evict({
					id: client.cache.identify(watermark),
				});
			}}
		/>
	);
}
