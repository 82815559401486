import { Stack, Typography } from "@mui/material";
import DuplicateContentDetectionConfig from "@/Component/Workspace/FileMatchConfig";

import { WorkspaceWrapper } from "@/Layout";
export default function PageWorkspaceSettings() {
	return (
		<WorkspaceWrapper>
			<Stack spacing={2}>
				<Typography variant="h5">Configuration</Typography>
				<DuplicateContentDetectionConfig />
			</Stack>
		</WorkspaceWrapper>
	);
}
