import UploadButton from "../UploadButton";
import AudioList from "./List";
import Divider from "../Common/Divider";
import { FormLabel } from "@mui/material";
export default function BumpersView() {
	return (
		<>
			<UploadButton type="AUDIO" />

			<Divider />
			<FormLabel>All Audio Overlays</FormLabel>
			<AudioList />
		</>
	);
}
