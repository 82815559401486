import { useState, memo } from "react";
import {
	Dialog,
	ImageList,
	ImageListItem,
	Box,
	Typography,
	IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
export default memo(function AssetSelectorBase({
	onSelect = () => {},
	options = [],
	defaultAsset,
	label = "Asset",
}) {
	const [open, setOpen] = useState(false);

	const [selected, setSelected] = useState(defaultAsset);

	const handleSetSelected = watermark => {
		setSelected(watermark);
		onSelect(watermark);
		setOpen(false);
	};

	return (
		<Box
			sx={{
				position: "relative",
				width: "fit-content",
			}}
		>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<ImageList cols={3}>
					{options?.map(option => (
						<ImageListItem
							key={option.id}
							onClick={() => handleSetSelected(option)}
						>
							<img
								src={option?.file?.thumbnailUrl}
								alt={option.name}
								style={{
									width: "150px",
									height: "auto",
								}}
							/>
						</ImageListItem>
					)) || <span>loading...</span>}
				</ImageList>
			</Dialog>
			<Typography>{label}</Typography>
			<Box
				component="img"
				onClick={() => setOpen(true)}
				src={
					selected?.file?.thumbnailUrl ||
					"https://via.placeholder.com/150"
				}
				alt={selected?.name}
				sx={{
					width: "150px",
					height: "auto",
					border: theme => `1px solid ${theme.palette.divider}`,
				}}
			/>
			<IconButton
				onClick={() => handleSetSelected(null)}
				sx={{
					position: "absolute",
					top: "0",
					right: "0",
					display: selected ? "block" : "none",
				}}
			>
				<ClearIcon />
			</IconButton>
		</Box>
	);
});
