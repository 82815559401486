import { Stack, Typography } from "@mui/material";
import { useOauthClientContext } from "@/Component/Oauth/OauthClientList/Client";

export default function OauthClientCreateDate() {
	const { client } = useOauthClientContext();

	return (
		<Stack direction="row" spacing={2}>
			<Typography variant="caption">
				Created: {new Date(client.createdAt).toLocaleDateString()}
			</Typography>
			<Typography variant="caption">
				Updated: {new Date(client.updatedAt).toLocaleDateString()}
			</Typography>
		</Stack>
	);
}
