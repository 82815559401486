import { useRequester } from "../../../Apollo";
import { useMultiselectContent } from "../..";
import { useContentEditor } from "../../../Component/ContentEditor";
import { useSnackbar } from "notistack";
export default function useAddToContentEditor() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { addTracks, IconButton: ContentEditorIconButton } =
		useContentEditor();
	const { getSelection } = useMultiselectContent();
	const { definitions, useLazyQuery } = useRequester();

	const [getContents] = useLazyQuery(definitions.content.query.getContents);

	return {
		addToContentEditor: async () => {
			const selection = getSelection();
			const { data } = await getContents({
				variables: {
					ids: selection.map(content => content.id),
				},
			});

			addTracks(
				data.contents.map(content => {
					return {
						assetType: "CONTENT",
						file: content.source,
						id: content.id,
						name: content.title,
					};
				})
			);
			let key = enqueueSnackbar(
				<>
					Successfully added ${selection.length} items to the content
					editor. <ContentEditorIconButton />
				</>,
				{
					variant: "success",
					onClick: () => closeSnackbar(key),
				}
			);
		},
	};
}
