import { useState } from "react";
import { useRequester } from "../../../Apollo";

import { useSnackbar } from "notistack";
const PAGE_SIZE = 15;
export default function useSubmissions() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useQuery } = useRequester();
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

	const { data, fetchMore, loading } = useQuery(
		definitions.submissions.query.page,
		{
			variables: {
				input: {},
			},
			fetchPolicy: "cache-and-network",
			nextFetchPolicy: "cache-and-network",
			errorPolicy: "all",
		}
	);
	return {
		loading,
		currentMonth,
		page: data?.submissions?.edges?.map(edge => edge.node) || [],
		getPage: async ({ before, after }) => {
			fetchMore({
				variables: {
					input: {
						after,
						before,
					},
				},
				updateQuery: (previousResult, { fetchMoreResult }) => {
					return {
						submissions: {
							...fetchMoreResult.submissions,
							edges: [...fetchMoreResult.submissions.edges],
						},
					};
				},
			});
			setCurrentMonth(new Date(after || before).getMonth());
		},
	};
}
