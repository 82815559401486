import { Box, Avatar, IconButton } from "@mui/material";
import { LoadingIcon } from "../../../LoadingScreen";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useUploadType } from "../../../../Upload";
import { useRequester } from "../../../../Apollo";
import { useState } from "react";
import { useAvatarCacheContext } from "@/Component/AvatarCacheContext";
export default function UploadAvatar() {
	const { definitions, useQuery, workspace_id } = useRequester();
	const { data, client } = useQuery(
		definitions.workspace.query.getWorkspace,
		{
			fetchPolicy: "cache-and-network",
			variables: {
				id: workspace_id,
			},
		}
	);
	const workspace = data?.workspace;
	const { workspaceAvatar: src, updateCacheWorkspace: updateCache } =
		useAvatarCacheContext();

	const { uploadType } = useUploadType();
	const [loading, setLoading] = useState(false);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				position: "relative",
			}}
		>
			<IconButton
				onClick={async () => {
					if (loading) {
						// if the user clicks the button while the upload is in progress
						client.refetchQueries({
							optimistic: false,
							include: [definitions.workspace.query.getWorkspace],
							onQueryUpdated: () => {
								setLoading(false);
							},
						});
						return;
					}
					// else upload a new avatar and refetch on completion to update the cache
					uploadType("AVATAR_WORKSPACE", {
						onCompleted: () => {
							updateCache();
							setLoading(false);
						},
					});
					setLoading(true);
					// refetch is called in the useUploadType hook
				}}
				sx={{
					position: "relative",
				}}
			>
				{loading && <LoadingIcon size={45} />}
				{!loading && (
					<>
						<Avatar
							alt="User"
							src={src}
							sx={{
								height: 100,
								width: 100,
							}}
						>
							{workspace?.name[0]}
						</Avatar>
						<CameraAltOutlinedIcon
							sx={{
								position: "absolute",
								bottom: -6,
								right: -6,
							}}
						/>
					</>
				)}
			</IconButton>
		</Box>
	);
}
