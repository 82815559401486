import { gql } from "@apollo/client";
export const uploadFile = gql`
	mutation UploadFile($input: UploadInput!) {
		upload(input: $input) {
			id
			assetType
			presignedURL {
				url
				method
				path
				fields {
					bucket
					Content_Type
					key
					Policy
					X_Amz_Algorithm
					X_Amz_Credential
					X_Amz_Date
					X_Amz_Signature
				}
			}
		}
	}
`;
export const uploadAvatar = gql`
	mutation UploadAvatar($input: UploadInput!) {
		uploadAvatar(input: $input) {
			id
			assetType
			presignedURL {
				url
				method
				path
				fields {
					bucket
					Content_Type
					key
					Policy
					X_Amz_Algorithm
					X_Amz_Credential
					X_Amz_Date
					X_Amz_Signature
				}
			}
		}
	}
`;
