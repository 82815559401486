import { useEffect, useState } from "react";

import {
	Menu,
	MenuItem,
	ListItemIcon,
	MenuList,
	ListItemText,
	Typography,
	Popover,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import calculateLocation from "../../util/calculateEventLocation";
export default function ContextMenu({
	options,
	clickEvent,
	height = 300,
	zIndex = 1400,
}) {
	const [contextMenu, setContextMenu] = useState(null);

	const handleClose = () => setContextMenu(null);
	const handleContextMenu = event => {
		if (!event) return handleClose();
		event.preventDefault();
		setContextMenu(calculateLocation(event));
	};

	useEffect(() => {
		handleContextMenu(clickEvent);
	}, [clickEvent]);

	const Option = ({ option }) => {
		const [anchorEl, setAnchorEl] = useState(false);
		const handleClick = e => {
			e.stopPropagation();
			if (option.options)
				return setAnchorEl(anchorEl => {
					return anchorEl ? null : e.currentTarget;
				});
			option.onClick?.();
		};

		const ExpanderButton = () => {
			if (!option.options) return null;
			return (
				<MenuOpenIcon
					sx={{
						transform: !anchorEl
							? "rotateY(180deg)"
							: "rotate(0deg)",
						transition: "transform 0.8s",
						transformStyle: "preserve-3d",
					}}
				/>
			);
		};

		const OptionRow = ({ option }) => {
			return (
				<>
					<ListItemIcon>{option.endIcon}</ListItemIcon>
					<ListItemText
						secondaryTypographyProps={{
							sx: {
								color: theme => theme.palette.text.primary,
							},
						}}
						primary={option.label}
						secondary={option.secondary}
					/>
					<Typography variant="body2" color="text.primary">
						{option.endIconLabel}
					</Typography>
					<ExpanderButton />
					{option?.options && (
						<Popover
							open={!!anchorEl}
							anchorEl={anchorEl}
							sx={{
								zIndex: zIndex + 1,
							}}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
						>
							<NestedMenuList options={option.options} />
						</Popover>
					)}
				</>
			);
		};
		return (
			<MenuItem
				selected={option.selected}
				disabled={option.disabled}
				onClick={handleClick}
				disableTouchRipple
			>
				<OptionRow option={option} />
			</MenuItem>
		);
	};
	const NestedMenuList = ({ options }) => {
		return (
			<MenuList
				sx={{
					maxHeight: height,
					zIndex,
				}}
			>
				{options.map((option, i) => {
					return <Option key={i} option={option} />;
				})}
			</MenuList>
		);
	};

	return (
		<div
			// onContextMenu will allow the user to click and drag the asset around the timeline
			// without mouse input being sent to the menu
			onContextMenu={handleContextMenu}
			style={{ cursor: "context-menu" }}
		>
			<Menu
				open={!!contextMenu}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={contextMenu}
				sx={{
					"& .MuiPaper-root": {
						width: 320,
						maxWidth: "100%",
						height: height || "auto",
					},
					zIndex,
				}}
			>
				<NestedMenuList options={options} />
			</Menu>
		</div>
	);
}
