import { useCookies } from "../../../../Cookies";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMixpanel } from "../../../../Mixpanel";
export default function SignOutPage() {
	const { resetUser } = useMixpanel();
	const navigate = useNavigate();
	const cookies = useCookies();
	useEffect(() => {
		cookies.removeAll();
		resetUser();
		navigate("/");
	}, []);

	return <></>;
}
