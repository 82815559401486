import { Stack, Typography } from "@mui/material";
import LeftArrowIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import RBAC from "@/Component/RBAC";
import { WorkspaceWrapper } from "@/Layout";
export default function PageVirtualAssistants() {
	return (
		<WorkspaceWrapper>
			<Stack spacing={2}>
				<Typography variant="h5">Roles And Permissions</Typography>
				<Stack
					spacing={1}
					sx={{
						marginY: "1rem",
					}}
				>
					<Link to="/workspace/assistants">
						<LeftArrowIcon fontSize="inherit" />
						Virtual Assistants Home
					</Link>
				</Stack>
			</Stack>

			<RBAC />
		</WorkspaceWrapper>
	);
}
