import { CardMedia } from "@mui/material";
import { useParams } from "react-router-dom";
import { useRequester } from "../../../../Apollo";
export default function ContentPreview() {
	const { definitions, useQuery } = useRequester();
	const { id } = useParams();

	const { data } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});

	const source = data?.content?.source;
	const __typename = source?.__typename;
	const type = __typename === "VideoFile" ? "video" : "image";
	const thumbnail = data?.content?.source?.url;

	return (
		<CardMedia
			controls
			autoPlay
			component={type === "image" ? "img" : "video"}
			alt="green iguana"
			sx={{
				objectFit: "contain",
				height: "40vh",
				width: "fit-content",
				maxWidth: "100%",
				marginX: "auto",
				borderRadius: 1,
			}}
			image={thumbnail}
		/>
	);
}
