import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useState } from "react";
import "./styles.css";

// type should be either VIDEO or IMAGE
export default function FullScreenPreview({ src, type }) {
	const [open, setOpen] = useState(false);
	const handleSetOpen = value => {
		setOpen(value);
	};

	return (
		<>
			<IconButton
				onClick={() => handleSetOpen(true)}
				sx={{
					background: theme => theme.palette.primary.main,
					color: "white",
					borderRadius: "0%",
					backgroundColor: theme => theme.palette.background.default,
				}}
			>
				<FullscreenIcon />
			</IconButton>
			<Dialog
				fullScreen
				open={open}
				componentsProps={{
					backdrop: {
						className: "background-blur",
					},
				}}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						height: "100%",
						width: "auto",
					},
				}}
				onClose={() => handleSetOpen(false)}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "8px",
						right: "8px",
						color: "white",
						zIndex: 100,
					}}
					onClick={() => handleSetOpen(false)}
				>
					<CloseIcon />
				</IconButton>
				<>
					{type?.toUpperCase() === "VIDEO" ? (
						<video
							className="contained-content-fullscreen"
							src={src}
							controls
							autoPlay
							style={{
								height: "100%",
								width: "auto",
								maxWidth: "100vw",
								objectFit: "contain",
							}}
						/>
					) : (
						<img
							className="contained-content-fullscreen"
							src={src}
							alt={"preview"}
							style={{
								height: "100%",
								width: "auto",
								maxWidth: "100vw",
								objectFit: "contain",
							}}
						/>
					)}
				</>
			</Dialog>
		</>
	);
}
