export default function WideLogoSVG() {
	return (
		<object
			aria-label="logo"
			alt="logo"
			data="/logo/wide.svg"
			type="image/svg+xml"
			preserveAspectRatio="none"
			width="100%"
			height="100%"
			style={{
				height: `calc(var(--nav-height) - 24px)`,
				width: "auto",
				shapeRendering: "crispEdges",
				pointerEvents: "none",
				userSelect: "none",
			}}
		/>
	);
}

export const WideLogoPNG = () => {
	return (
		<img
			aria-label="logo"
			alt="logo"
			src="/logo/wide.png"
			style={{
				height: `calc(var(--nav-height) - 24px)`,
				width: "auto",
				shapeRendering: "crispEdges",
				pointerEvents: "none",
				userSelect: "none",
			}}
		/>
	);
};
