import { ReactFlowProvider } from "reactflow";
import CustomNodeFlow from "../../../../../Component/Automations";

export default function PageReactFlow() {
	return (
		<ReactFlowProvider>
			<CustomNodeFlow />
		</ReactFlowProvider>
	);
}
