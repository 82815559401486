const calculateMobileLocation = e => {
	if (!e) return null;
	return {
		top: e.touches[0].clientY,
		left: e.touches[0].clientX,
	};
};
const calculateDesktopLocation = e => {
	if (!e) return null;
	let location = {
		top: e.clientY,
		left: e.clientX,
	};
	return location;
};
export default function calculateLocation(e) {
	if (!e) return null;
	let location = calculateDesktopLocation(e);
	if (e.touches) location = calculateMobileLocation(e);
	return location;
}
