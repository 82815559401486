import { Box, Grid, Typography } from "@mui/material";
export default function LabeledIcon({
	label,
	Icon,
	fontSize = "inherit",
	color = "inherit",
}) {
	return (
		<Grid
			container
			sx={{
				// align the items to the center
				alignItems: "center",
				// add some spacing between the items
				"& > :not(style) + :not(style)": { ml: 1 },
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "3rem",
					height: "3rem",
					borderRadius: "50%",
					backgroundColor: "primary.main",
					color: "primary.contrastText",
					position: "relative",
				}}
			>
				<Icon />
			</Box>
			<Box sx={{ ml: 2 }}>
				<Typography
					variant="h6"
					component="div"
					fontSize={fontSize}
					color={color}
				>
					{label}
				</Typography>
			</Box>
		</Grid>
	);
}
