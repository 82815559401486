import { gql } from "@apollo/client";
export const hasPaymentMethod = gql`
	query HasPaymentMethod {
		hasPaymentMethod {
			paymentMethodExists
		}
	}
`;
export const listPlans = gql`
	query ($input: ListPlansInput) {
		listBillingPlans(input: $input) {
			id
			nickname
			amount
			currency
			interval
		}
	}
`;
export const listBilingPlans = gql`
	query ListBillingPlans($input: ListPlansInput) {
		listBillingPlans(input: $input) {
			amount
			currency
			id
			interval
			nickname
		}
	}
`;

export const getMySubscription = gql`
	query MySubscription {
		mySubscription {
			expires_at
			begin_at
			renew_at
			product {
				id
				tier
				name
				description
				image
				plans {
					id
					amount
					currency
					interval
					selected
				}
				quota {
					submissions {
						used
						percentUsed
						canUse
						allotted
						unit
					}
					storage {
						used
						percentUsed
						canUse
						allotted
						unit
					}
					renderHours {
						used
						percentUsed
						canUse
						allotted
						unit
					}
				}
			}
		}
	}
`;
export const listProducts = gql`
	query ListProducts {
		listProducts {
			id
			tier
			name
			description
			image
			plans {
				id
				amount
				currency
				interval
			}
			quota {
				renderHours {
					allotted
					unit
				}
				storage {
					allotted
					unit
				}
				submissions {
					allotted
					unit
				}
			}
		}
	}
`;
