import { SnackbarProvider as Snackbar } from "notistack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Head from "@/Head";
import Theme from "@/Theme";
import Apollo, { SubscriptionPlanProvider } from "@/Apollo";
import Notifications from "@/Notifications";
import Uploady from "@/Upload";
import Confirm from "@/Confirm";

import Routes from "@/Router/Routes";
import Auth from "@/Auth";
import { NavigationBar } from "@/Navigation";

import ContentEditorContextProvider from "@/Component/ContentEditor";
import FolderContext from "@/Component/Folders/TreeView/hook/useFolderContent";
import VirtualAssistantRequest from "@/Component/VirtualAssistant/RequestAlert";
import ContentAdded from "@/Component/ContentAdded";
import LayoutSPA from "@/Layout/SPA";
import AvatarCacheContext from "@/Component/AvatarCacheContext";
import WorkspaceConfigContext from "@/Component/Workspace/Context";
import { WorkspaceContentSearchContextProvider } from "@/Router/Routes/Page/Workspace/Library";

import "./style.css";

export default function App() {
	return (
		<Theme>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Confirm>
					<Head />
					<Snackbar>
						<Auth>
							<Apollo>
								<SubscriptionPlanProvider>
									<AvatarCacheContext>
										<WorkspaceConfigContext>
											{/* <WorkspaceContentSearchContextProvider> */}
												<VirtualAssistantRequest />
												<ContentAdded>
													<Uploady>
														<FolderContext>
															<ContentEditorContextProvider>
																<Notifications>
																	<NavigationBar />
																	<LayoutSPA>
																		<Routes />
																	</LayoutSPA>
																</Notifications>
															</ContentEditorContextProvider>
														</FolderContext>
													</Uploady>
												</ContentAdded>
											{/* </WorkspaceContentSearchContextProvider> */}
										</WorkspaceConfigContext>
									</AvatarCacheContext>
								</SubscriptionPlanProvider>
							</Apollo>
						</Auth>
					</Snackbar>
				</Confirm>
			</LocalizationProvider>
		</Theme>
	);
}
