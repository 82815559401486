import { gql } from "@apollo/client";

export const authorize = gql`
	mutation AuthorizeIntegration($input: AuthorizeIntegrationInput!) {
		authorizeIntegration(input: $input) {
			oauthURL
			status
		}
	}
`;
export const setDefault = gql`
	mutation ($input: IntegrationDefaultInput!) {
		integrationDefault(input: $input) {
			integration {
				avatar
				is_default
				destination
			}
		}
	}
`;

export const proxyAdd = gql`
	mutation IntegrationAddProxy($input: IntegrationAddProxyInput!) {
		integrationAddProxy(input: $input) {
			integration {
				avatar
				proxy {
					host
					password
					port
					username
					protocol
					approvedAt
					denitedAt
					isActive
					isDeleted
				}
			}
		}
	}
`;

export const remove = gql`
	mutation RemoveIntegration($input: IntegrationRemoveInput!) {
		integrationRemove(input: $input) {
			isRemoved
		}
	}
`;
export const reddit_setSubredditFavorite = gql`
	mutation ($input: SubredditFavoriteInput!) {
		subredditFavorite(input: $input) {
			isFavorite
			subreddit {
				allow_images
				allow_videos
				banner_img
				community_icon
				icon_img
				created
				description
				display_name
				header_img
				header_title
				id
				name
				over18
				public_description
				submit_link_label
				submit_text
				submit_text_label
				subreddit_type
				subscribers
				title
				url
			}
		}
	}
`;

export const publish = gql`
	mutation Publish($input: PublishMultipleContentInput!) {
		publish(input: $input) {
			id
			title
			publicationDestination
			createdAt
			deferUntil
			integration {
				destination
				status
				username
			}
			config {
				imgur {
					album
					description
					disable_audio
					name
				}
				reddit {
					subreddit
					parentSubmission {
						id
					}
				}
			}
		}
	}
`;

export const proxyEnable = gql`
	mutation IntegrationProxyEnable($input: IntegrationProxyEnableInput!) {
		integrationProxyEnable(input: $input) {
			integration {
				avatar
				proxy {
					host
					password
					port
					username
					protocol
					isActive
					approvedAt
					deniedAt
				}
			}
		}
	}
`;

export const onlyfansImport = gql`
	mutation ImportOF($input: ImportOFInput!) {
		importOF(input: $input) {
			batchID
			createdAt
			folderID
		}
	}
`;
