import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useRequester } from "../../../../Apollo";

import { useSelectedContent } from "../../../SelectedContentContext";
import { convertBytes } from "../../../../util/convertBytes";

export default function FileDetailsDataGrid() {
	const { id } = useSelectedContent();

	const { definitions, useQuery } = useRequester();
	const { data, loading } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "network-only",
		variables: {
			id,
		},
	});
	if (loading) return <></>;

	const file = data?.content?.source;
	const aspectRatio = file.aspectRatio;
	const duration = file.duration;
	const extension = file.extension;
	const framecaptureUrl = file.framecaptureUrl;
	const height = file.height;

	const width = file.width;
	const isProcessed = file.isProcessed;
	const name = file.filename;
	const size = file.size;

	const prettySize = convertBytes(parseFloat(size));

	return (
		<DataGrid
			autoHeight
			density="compact"
			loading={false}
			columns={[
				{
					field: "name",
					headerName: "Name",
					width: "100",
					resizable: true,
				},
				{
					field: "value",
					headerName: "Value",
					flex: 1,
					resizable: true,
				},
			]}
			showCellVerticalBorder
			rows={[
				{
					id: 1,
					name: "Name",
					value: name || "N/A",
				},
				{
					id: 2,
					name: "Size",
					value: prettySize,
				},
				{
					id: 3,
					name: "Width",
					value: width,
				},
				{
					id: 4,
					name: "Height",
					value: height,
				},

				{
					id: 5,
					name: "Aspect Ratio",

					value: aspectRatio,
				},

				{
					id: 7,

					name: "Duration",
					value: duration,
				},

				{
					id: 11,
					name: "Extension",
					value: extension,
				},
				{
					id: 12,
					name: "Frame Capture URL",
					value: framecaptureUrl,
				},
				{
					id: 13,
					name: "Is Processed",
					value: isProcessed,
				},
			]}
			disableRowSelectionOnClick
			hideFooter
		/>
	);
}
