import { useMultiselectContent } from "../..";

import {
	Dialog,
	DialogTitle,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function PublishMultiple() {
	const {
		showingPreviewDialog,
		setShowingPreviewDialog,
		getSelection,
		deselect,
		selected,
	} = useMultiselectContent();

	return (
		<Dialog
			fullWidth
			open={showingPreviewDialog} // will only be true when the menu is open
			onClose={() => setShowingPreviewDialog(false)}
		>
			<DialogTitle>
				Previewing {selected.size} item
				{selected.size > 1 ? "s" : ""}
			</DialogTitle>
			<ImageList
				cols={3}
				sx={{
					paddingX: theme => theme.spacing(2),
				}}
			>
				{getSelection().map(({ id, thumbnail, name, extension }) => {
					return (
						<ImageListItem
							key={id}
							sx={{
								position: "relative",
							}}
						>
							<IconButton
								onClick={() => {
									deselect({ id });
								}}
								sx={{
									position: "absolute",
									top: theme => theme.spacing(1),
									right: theme => theme.spacing(1),
								}}
							>
								<CloseIcon />
							</IconButton>
							<img src={thumbnail} alt={name} loading="lazy" />
							<ImageListItemBar
								title={name}
								subtitle={extension}
							/>
						</ImageListItem>
					);
				})}
			</ImageList>
		</Dialog>
	);
}
