import { Icon as ImgurIcon } from "../Component/Integration/Assets/Imgur";
import { Icon as TwitterIcon } from "../Component/Integration/Assets/Twitter";
import { Icon as RedditIcon } from "../Component/Integration/Assets/Reddit";
import { Icon as RedgifsIcon } from "../Component/Integration/Assets/Redgifs";
import { Icon as OnlyFansIcon } from "../Component/Integration/Assets/OnlyFans";
import { Icon as FanslyIcon } from "../Component/Integration/Assets/Fansly";
const list = [
	{
		destination: "OnlyFans",
		Icon: ({ size }) => <OnlyFansIcon height={size} width={size} />,

		color: "#1da1f2",
	},
	{
		destination: "Fansly",
		Icon: ({ size }) => <FanslyIcon height={size} width={size} />,
		color: "#85bf25",
		disabled: true,
	},
	{
		destination: "Twitter",
		Icon: ({ size }) => <TwitterIcon height={size} width={size} />,
		color: "#ffd700",
	},
	{
		destination: "Redgifs",
		Icon: ({ size }) => <RedgifsIcon width={size} height={size} />,
		color: "#1F1F1F",
	},
	{
		destination: "Reddit",
		Icon: ({ size }) => <RedditIcon width={size} height={size} />,

		color: "#ff4500",
	},

	{
		destination: "Imgur",
		Icon: ({ size }) => <ImgurIcon width={size} height={size} />,
		color: "#85bf25",
	},
];

export default list;
