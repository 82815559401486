import {
	useState,
	useEffect,
	useRef,
	useImperativeHandle,
	useMemo,
} from "react";
import depthEffect from "../../../Theme/sx/depth-effect";
import { Box, Stack } from "@mui/material";
import ContextMenu from "./ContextMenu";

import { Timeline } from "@xzdarcy/react-timeline-editor";
import Time from "./Time";
import PlayPauseButton from "./PlayButton";
import FullScreenButton from "./FullScreenButton";

import TimelineRenderer from "./Timeline";
import { useContentEditor } from "../";
import TrackList, { HideTrackListButton } from "./TrackList";
import {
	parseMontageToTimeline as parseTracksToEditorData,
	formatTracks,
} from "./util/formatActions";
import ZoomFactorSelector from "./ZoomFactor";
export default function TrimSplitEditor() {
	const {
		timelineEngine: parentRef,
		variables: {
			input: { timeline },
		},
		updateTimeline,
		selectTrackAsset,
	} = useContentEditor();
	const timelineEngine = useRef();
	const trackListRef = useRef();
	const [trackListHidden, setTrackListHidden] = useState(false);
	const toggleTrackListHidden = () => setTrackListHidden(!trackListHidden);
	useEffect(() => {
		parentRef.current = timelineEngine.current;
	}, [timelineEngine]);
	useImperativeHandle(parentRef, () => {
		// ensure that in all components which implement timelineEngine
		// stay in sync with the current timelineEngine
		return timelineEngine.current;
	});
	const [scale, setScale] = useState(5);
	const [fullScreen, setFullScreen] = useState(false);
	const [rightClickEvent, setRightClickEvent] = useState(null);
	useEffect(() => {
		setRightClickEvent(null);
		// ensure the context menu closes when an asset is deleted
	}, [timeline.tracks.reduce((acc, track) => acc + track.assets.length, "")]);

	const MemoizedTimeline = useMemo(() => {
		return (
			<Timeline
				ref={timelineEngine}
				autoReRender={false}
				onContextMenuAction={(e, action) => {
					e.preventDefault();
					setRightClickEvent({
						event: e,
						action,
					});
				}}
				onDoubleClickAction={(e, action) => {
					selectTrackAsset({
						assetIndex: action.action.data.assetIndex,
						trackIndex: action.action.data.trackIndex,
					});
				}}
				gridSnap
				dragLine
				style={{
					height: fullScreen ? "500px" : "212px",
					maxHeight: "90vh",

					width: "100vw",

					overflow: "hidden",
					paddingBottom: "1em",
					// disable highlight text
					userSelect: "none",
				}}
				rowHeight={75}
				onScroll={({ scrollTop }) => {
					trackListRef.current.scrollTop = scrollTop;
				}}
				scale={scale}
				onChange={data => {
					updateTimeline(formatTracks(data));
				}}
				editorData={parseTracksToEditorData(timeline)}
				getActionRender={props => {
					return <TimelineRenderer {...props} scale={scale} />;
				}}
			/>
		);
	}, [timeline, fullScreen, scale]);

	return (
		<Box
			sx={{
				width: "100%",
				height: "fit-content",
				overflow: "hidden",
				background: theme => theme.palette.background.default,
			}}
		>
			<Stack
				direction="row"
				sx={{
					...depthEffect(1),
				}}
			>
				<PlayPauseButton />

				<Time />

				<Box
					sx={{
						width: "100%",
						height: "100%",
						display: "flex",

						alignSelf: "flex-end",
					}}
				>
					<ZoomFactorSelector scale={scale} setScale={setScale} />
				</Box>
				<HideTrackListButton
					toggleHidden={toggleTrackListHidden}
					hidden={trackListHidden}
				/>
				<FullScreenButton
					isFullScreen={fullScreen}
					onClick={() => {
						setFullScreen(!fullScreen);
					}}
				/>
			</Stack>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",

					alignItems: "flex-start",
					width: "100%",
					height: "100%",
					overflow: "hidden",
					"& *": {
						// Apply styles to all child elements
						scrollbarWidth: "none", // For Firefox
						msOverflowStyle: "none", // For Internet Explorer and Edge
						"&::-webkit-scrollbar": {
							width: "0", // Set the width to 0 to hide the scrollbar (WebKit browsers)
						},
					},
				}}
			>
				<TrackList
					className="xzdarcy-track-list"
					ref={trackListRef}
					fullScreen={fullScreen}
					onScroll={scrollTop => {
						timelineEngine.current.setScrollTop(scrollTop);
					}}
					hidden={trackListHidden}
				/>
				<Box
					className="xzdarcy-timeline"
					sx={{
						width: "100%",
						height: "fit-content",

						overflow: "hidden",
					}}
				>
					{MemoizedTimeline}
				</Box>
			</Box>
			<ContextMenu
				event={rightClickEvent?.event}
				action={rightClickEvent?.action}
			/>
		</Box>
	);
}
