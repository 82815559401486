import { gql } from "@apollo/client";

export const DELETE_WATERMARK = gql`
	mutation DeleteWatermark($input: DeleteWatermarkInput!) {
		deleteWatermark(input: $input) {
			isDeleted
		}
	}
`;
export const DELETE_BUMPER = gql`
	mutation DeleteBumper($input: BumperDeleteInput!) {
		bumperDelete(input: $input) {
			isDeleted
		}
	}
`;

export const CREATE_WATERMARK = gql`
	mutation CreateWatermark($input: CreateWatermarkInput!) {
		createWatermark(input: $input) {
			watermark {
				id
				name
			}
		}
	}
`;
