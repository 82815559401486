import { createContext, useContext, useState } from "react";
import { useRequester } from "../../../Apollo";
import ContextMenu from "../../ContextMenu";
import RenameIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/CreateNewFolder";
import MoveIcon from "@mui/icons-material/MoveToInbox";
import { useFolderContent } from "../TreeView/hook/useFolderContent";

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	FormControl,
	Button,
	FormControlLabel,
	Checkbox,
	Typography,
} from "@mui/material";
import { useConfirm } from "../../../Confirm";
import { useSnackbar } from "notistack";
const Context = createContext({
	folder_rename: ({ id, title }) => {},
	folder_delete: ({ id }) => {},
	folder_create: ({ title }) => {},
	content_move: ({ id, folderId }) => {},
	setClickEvent: () => {},
	setItem: ({ type, data }) => {},
});
export const useFoldersContext = () => useContext(Context);
const useHooks = () => {
	const { selectedFolder } = useFolderContent();

	const { definitions, useMutation } = useRequester();
	const [folderRename] = useMutation(
		definitions.content.mutation.folderUpdate
	);
	const [folderDelete] = useMutation(
		definitions.content.mutation.folderDelete
	);
	const [contentMove] = useMutation(
		definitions.content.mutation.folderAddContent
	);
	const [folderCreate] = useMutation(
		definitions.content.mutation.folderCreate
	);

	return {
		folder_rename: ({ id, title }) => {
			folderRename({
				variables: {
					input: {
						id,
						name: title,
					},
				},
			});
		},
		folder_delete: ({ id, deleteContent = false }) => {
			folderDelete({
				variables: {
					input: {
						id,
						deleteContent,
					},
				},
				errorPolicy: "all",
				refetchQueries: [
					{
						query: definitions.content.query.folders,
						errorPolicy: "all",
					},
				],
			});
		},
		folder_create: ({ title }) => {
			folderCreate({
				variables: {
					input: {
						name: title,
					},
				},
				refetchQueries: [
					{
						query: definitions.content.query.folders,
						errorPolicy: "all",
					},
				],
			});
		},
		content_move: async ({ id, folderId, fromFolder }) => {
			return contentMove({
				variables: {
					input: {
						folderId,
						contentIds: [id],
					},
				},
				refetchQueries: [
					{
						query: definitions.content.query.folder,
						variables: {
							folderId: selectedFolder || 0,
							last: 100,
						},
						errorPolicy: "all",
					},
					{
						query: definitions.content.query.allContent,
						variables: {
							folderId: selectedFolder,
							last: 100,
						},
						errorPolicy: "all",
					},
				],
				errorPolicy: "all",
				awaitRefetchQueries: true,
			});
		},
	};
};
export default function FoldersContextMenuContext({ children }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { confirm } = useConfirm();
	const { folders } = useFolderContent();
	const [clickEvent, setClickEvent] = useState(null);
	const [dialog, setDialog] = useState(null);

	const [item, setItem] = useState({
		type: null,
		data: null,
	});
	const { content_move, folder_create, folder_delete, folder_rename } =
		useHooks();
	const folderOptions = [
		{
			label: "Rename Folder",
			endIcon: <RenameIcon />,
			onClick: () => {
				setClickEvent(null);
				setDialog("Rename Folder");
			},
		},

		{
			label: "Delete",
			endIcon: <DeleteIcon />,
			onClick: () => {
				setClickEvent(null);
				setDialog("Delete Folder");
			},
		},
	];
	const contentOptions = [
		{
			label: "Move",
			endIcon: <MoveIcon />,

			options: folders.map(folder => {
				let folderId = folder.value;
				let itemIsInFolderId = item.data?.folderId;
				if (!itemIsInFolderId) itemIsInFolderId = "0";
				if (folder.value === "root") folderId = "0";

				const isInCurrentFolder = folderId === itemIsInFolderId;
				if (folder)
					return {
						label: folder.label,
						selected: isInCurrentFolder,
						disabled: isInCurrentFolder,
						onClick: async () => {
							console.log(
								"moving from: ",
								item.data.folderId,
								"to ",
								folder.value
							);
							content_move({
								id: item.data.id,
								fromFolder: item.data.folderId,
								folderId: folder.value,
							});
							setClickEvent(null);
						},
					};
			}),
		},
	];

	const otherOptions = [
		{
			label: "Create",
			endIcon: <CreateIcon />,
			onClick: () => {
				setClickEvent(null);
				setDialog("Create Folder");
			},
		},
	];

	const allOptions = [];
	if (item.type === "folder") {
		allOptions.push(...folderOptions, ...otherOptions);
	} else if (item.type === "content") {
		allOptions.push(...contentOptions);
	} else {
		allOptions.push(...otherOptions);
	}
	return (
		<Context.Provider
			value={{
				setClickEvent,
				setItem,
			}}
		>
			{children}
			<ContextMenu clickEvent={clickEvent} options={allOptions} />
			<Dialog open={!!dialog} onClose={() => setDialog(null)}>
				<DialogTitle>{dialog}</DialogTitle>

				<DialogContent>
					{dialog === "Rename Folder" && (
						<FormControl
							component={"form"}
							onSubmit={e => {
								e.preventDefault();
								const title = e.target.title.value;
								folder_rename({
									id: item.data.id,
									title,
								});
								setDialog(null);
							}}
						>
							<TextField
								autoFocus
								margin="dense"
								label="Folder Name"
								type="text"
								fullWidth
								variant="standard"
								title="title"
								name="title"
							/>
							<DialogActions>
								<Button type="submit" variant="contained">
									Submit
								</Button>
							</DialogActions>
						</FormControl>
					)}
					{dialog === "Delete Folder" && (
						<>
							<DialogActions>
								<FormControl
									component={"form"}
									onSubmit={async e => {
										e.preventDefault();
										const deleteContent =
											e.target.deleteContent.checked;
										const isConfirmed = await confirm(
											"Confirm Delete Folder",
											<>
												Are you sure you want to delete
												this folder
												{deleteContent ? (
													<Typography
														sx={{
															display: "inline",
															color: theme =>
																theme.palette
																	.warning
																	.main,
														}}
													>
														{" "}
														and all of its content
													</Typography>
												) : (
													""
												)}
												? This cannot be undone!
											</>
										);
										if (!isConfirmed) {
											return setDialog(null);
										}
										if (isConfirmed) {
											folder_delete({
												id: item.data.id,
												deleteContent,
											});
										}

										setDialog(null);
									}}
								>
									<FormControlLabel
										labelPlacement="start"
										control={
											<Checkbox name="deleteContent" />
										}
										label="Delete Content?"
									/>
									<Button variant="contained" type="submit">
										Delete?
									</Button>
								</FormControl>
							</DialogActions>
						</>
					)}
					{dialog === "Create Folder" && (
						<FormControl
							component={"form"}
							onSubmit={e => {
								e.preventDefault();
								const title = e.target.title.value;
								folder_create({
									id: item.data.id,
									title,
								});
								setDialog(null);
							}}
						>
							<TextField
								autoFocus
								margin="dense"
								label="Folder Name"
								type="text"
								fullWidth
								variant="standard"
								title="title"
								name="title"
							/>
							<DialogActions>
								<Button type="submit" variant="contained">
									Submit
								</Button>
							</DialogActions>
						</FormControl>
					)}
				</DialogContent>
			</Dialog>
		</Context.Provider>
	);
}
