import { useConfirm } from "@/Confirm";
import { useRequester } from "@/Apollo";
import { useMatchesListContext } from "..";
import { useSnackbar } from "notistack";
export default function usePruneDuplicates() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { confirm } = useConfirm();
	const { definitions, useMutation, useLazyQuery } = useRequester();

	const [getHashMatches] = useLazyQuery(definitions.common.query.matches);
	const { refetch } = useMatchesListContext();
	const [mutate] = useMutation(definitions.common.mutation.pruneDuplicates);
	return {
		prune: async ({ hash }) => {
			if (!hash) return;
			console.log("prune duplicates", hash);
			const { data } = await getHashMatches({
				variables: {
					input: {
						hash,
					},
				},
				fetchPolicy: "network-only",
			});
			console.log("res", data);

			const totalCount = data?.matches?.totalCount;
			if (totalCount === 0) {
				const key = enqueueSnackbar(`No duplicates found for ${hash}`, {
					variant: "info",
					onClick: () => closeSnackbar(key),
				});
				return;
			}
			const count = Math.ceil(totalCount - 1, 100);

			const confirmed = await confirm(
				`Are you sure you want to prune ${count} duplicates?`
			);
			if (!confirmed) return;
			mutate({
				variables: {
					input: {
						hash,
					},
				},
				onCompleted: refetch,
				refetchQueries: [
					{
						query: definitions.common.query.countMatches,
					},
				],
			});
		},
	};
}
