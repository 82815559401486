import { Stack, Grid } from "@mui/material";
import { ImageDescriptor, TextCard } from "./FeatureCard";
const feature = [
	{
		title: "Connect your accounts",
		description:
			"Connect your accounts to easily manage your content from one place.",
		image: "/previews/integrations.png",
	},
	{
		title: "Edit your videos",
		description: "Add branding, effects, and more with our media editor.",
		image: "/previews/editor.png",
	},

	{
		title: "Stay up to date with notifications",
		description: "Know when your post goes live!",
		image: "/previews/notifications.png",
	},
	{
		title: "Publish your content on a schedule",
		description:
			'Easily schedule your content to be published on "autopilot".',
		image: "/previews/schedule.png",
	},
];
export default function FeatureStack() {
	return (
		<Stack>
			{feature.map((feature, index) => {
				return (
					<Grid
						key={index}
						container
						sx={{
							alignSelf: "center",

							maxWidth: "1650px",
							display: "flex",
							flexDirection:
								index % 2 === 0 ? "row" : "row-reverse",
							justifyContent: "space-around",
						}}
					>
						<TextCard feature={feature} />

						<ImageDescriptor feature={feature} />
					</Grid>
				);
			})}
		</Stack>
	);
}
