import { Typography } from "@mui/material";
import { useRequester } from "../../../../Apollo";
import { useSelectedContent } from "../../../SelectedContentContext";
import EditableTitle from "./EditableTitle";
export default function ContentDetails() {
	const { definitions, useQuery } = useRequester();
	const { id } = useSelectedContent();

	const { data } = useQuery(definitions.content.query.getContent, {
		variables: {
			id,
		},
		fetchPolicy: "cache-only",
	});
	const createdDate = new Date(data?.content?.createdAt);

	const source = data?.content?.source;
	const __typename = source?.__typename;
	const type = __typename === "VideoFile" ? "video" : "image";
	return (
		<>
			<EditableTitle />
			<Typography variant="h5">{type}</Typography>
			<Typography>
				Uploaded On {createdDate.toLocaleDateString()}
			</Typography>
		</>
	);
}
