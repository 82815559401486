import { useRequester } from "@/Apollo";
import { useMemo } from "react";
export const useAvailableFilters = () => {
	const { useQuery, definitions } = useRequester();
	const { data: durationMaxData } = useQuery(
		definitions.common.query.durationMaxContent,
		{
			fetchPolicy: "cache-first",
		}
	);

	const durationMax = durationMaxData?.durationMaxContent?.duration;

	const filters = useMemo(() => {
		if (isNaN(durationMax)) return;
		return [
			{
				label: "Max Date Created",
				name: "createdAtMax",
				type: "date",
				default: null,
			},
			{
				label: "Min Date Created",
				name: "createdAtMin",
				type: "date",
				default: null,
			},
			{
				label: "File Type",
				name: "fileType",
				type: "string",
				default: "SHOW_ALL",
				options: [
					{
						value: "IMAGE",
						label: "Image",
					},
					{
						value: "VIDEO",
						label: "Video",
					},
					{
						value: "AUDIO",
						label: "Audio",
					},
					{
						value: "SHOW_ALL",
						label: "Show All",
					},
				],
			},

			{
				label: "Approval Status",
				name: "approvalStatus",
				type: "string",
				default: "SHOW_ALL",
				options: [
					{
						value: "READY",
						label: "Ready",
					},
					{
						value: "NOT_READY",
						label: "Not Ready",
					},
					{
						value: "SHOW_ALL",
						label: "Show All",
					},
				],
			},
			{
				label: "Max Duration",
				name: "durationMax",
				type: "number",
				min: 0,
				max: durationMax,
				default: durationMax,
			},
			{
				label: "Min Duration",
				name: "durationMin",
				type: "number",
				default: 0.4,
				min: 0.4,
				max: durationMax,
			},
			{
				label: "Has Watermark",
				name: "isWatermarked",
				type: "string",
				default: "SHOW_ALL",
				options: [
					{
						value: "WATERMARKED",
						label: "Watermarked",
					},
					{
						value: "NOT_WATERMARKED",
						label: "Not Watermarked",
					},
					{
						value: "SHOW_ALL",
						label: "Show All",
					},
				],
			},
			{
				label: "Original/Version",
				name: "isVersion",
				type: "string",
				default: "SHOW_ALL",
				options: [
					{
						value: "IS_PARENT",
						label: "Show Parent Content",
					},
					{
						value: "IS_VERSION",
						label: "Show Versions",
					},
					{
						value: "SHOW_ALL",
						label: "Show All",
					},
				],
			},
			{
				label: "Has Tags",
				name: "hasTags",
				type: "string",
				default: "SHOW_ALL",
				options: [
					{
						value: "HAS_TAGS",
						label: "Has Tags",
					},
					{
						value: "NO_TAGS",
						label: "No Tags",
					},
					{
						value: "SHOW_ALL",
						label: "Show All",
					},
				],
			},
			{
				label: "Has Versions",
				name: "hasVersions",
				type: "string",
				default: "SHOW_ALL",
				options: [
					{
						value: "HAS_VERSIONS",
						label: "Has Versions",
					},
					{
						value: "NO_VERSIONS",
						label: "No Versions",
					},
					{
						value: "SHOW_ALL",
						label: "Show All",
					},
				],
			},
			{
				label: "Min Submissions Count",
				name: "submissionsCountMin",
				type: "number",
			},
			{
				label: "Max Submissions Count",
				name: "submissionsCountMax",
				type: "number",
			},
			{
				label: "Max Versions Count",
				name: "versionsCountMax",
				type: "number",
			},
			{
				label: "Min Versions Count",
				name: "versionsCountMin",
				type: "number",
			},
			{
				label: "Shared to AI Chatbot",
				name: "isShared",
				type: "string",
				default: "SHOW_ALL",
				options: [
					{
						value: "SHARED",
						label: "Shared",
					},
					{
						value: "NOT_SHARED",
						label: "Not Shared",
					},
					{
						value: "SHOW_ALL",
						label: "Show All",
					},
				],
			},
			{
				label: "Includes Tag",
				name: "includesTags",
				type: "array",
				default: [],
			},
		];
	}, [durationMax]);

	return filters;
};
