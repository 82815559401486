import { useRequester } from "../../../../../../Apollo";
export default function usePopularSubreddits({ DEFAULT_INPUT_VALUE }) {
	const { useQuery, definitions } = useRequester();

	const { data, refetch, updateQuery, loading } = useQuery(
		definitions.integrations.query.reddit_getPopularSubreddits,
		{
			variables: {
				input: {
					query: DEFAULT_INPUT_VALUE,
				},
			},
			fetchPolicy: "cache-first",
			nextFetchPolicy: "cache-first",
		}
	);
	const popularSubreddits = data?.redditPopularSubreddits?.subreddits || [];

	const fetchMorePopular = async ({ query }) => {
		const { data } = await refetch({
			input: {
				query,
			},
		});
		updateQuery(prev => {
			return {
				...prev,
				redditPopularSubreddits: data.redditPopularSubreddits,
			};
		});
	};
	return {
		loading,
		subreddits: popularSubreddits,
		fetchMore: fetchMorePopular,
	};
}
