import { useRequester } from "@/Apollo";
import WorkspaceWidgetWrapper from "../Wrapper";
export default function WorkspaceWidgetStats() {
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.workspace.query.usedStorage, {
		fetchPolicy: "cache-first",
	});

	return (
		<WorkspaceWidgetWrapper
			title={"Used Storage"}
			href="/workspace/library"
			body={data?.workspaceUsedStorage?.gb + "GB"}
		/>
	);
}
