import { createContext, useContext, useState } from "react";
import ListViewIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/ViewComfy";
import CompactViewIcon from "@mui/icons-material/ViewAgenda";
import { StyledSquareIconButton } from "../../../Component/Styled/Button";
import { useCookies } from "../../../Cookies";
import GridViewHit from "../Hits/Hit/ViewModeHit/Grid";
import CompactViewHit from "../Hits/Hit/ViewModeHit/Compact";
const COOKIE_NAME = "library_view_mode";

const views = ["compact", "grid"];
const Context = createContext({
	drawerOpen: false,
	setDrawerOpen: () => {},
	views,
	view: views[0],
	ViewSwitchButton: () => <></>,
	setView: () => {},
	HitComponent: ({ hit, onContextMenu, selectListfromID }) => <></>,
});
export const useLibraryViewContext = () => useContext(Context);
export default function ViewSwitchContext({ children }) {
	const cookies = useCookies();
	const [view, setView] = useState(cookies.get(COOKIE_NAME) || views[0]);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const handleSetView = view => {
		if (!views.includes(view)) return;
		setView(view);
		cookies.set(COOKIE_NAME, view, {
			path: "/",
			// 1 year
			maxAge: 60 * 60 * 24 * 365,
		});
	};
	let HitComponent = GridViewHit;
	if (view === "compact") HitComponent = CompactViewHit;
	return (
		<Context.Provider
			value={{
				drawerOpen,
				setDrawerOpen,
				view,
				views,
				ViewSwitchButton: () => {
					let Icon;
					if (view === "grid") Icon = GridViewIcon;
					if (view === "list") Icon = ListViewIcon;
					if (view === "compact") Icon = CompactViewIcon;
					return (
						<StyledSquareIconButton
							title={view}
							onClick={() => {
								handleSetView(
									views[
										(views.indexOf(view) + 1) % views.length
									]
								);
							}}
							sx={{
								border: "none",
							}}
						>
							<Icon />
						</StyledSquareIconButton>
					);
				},
				setView: view => {
					if (views.includes(view)) setView(view);
				},
				HitComponent,
			}}
		>
			{children}
		</Context.Provider>
	);
}
