import { forwardRef } from "react";

import {
	List,
	ListItem,
	ListItemText,
	IconButton,
	ListSubheader,
	Avatar,
	ListItemAvatar,
	TextField,
	Box,
} from "@mui/material";
import LeftArrowHideIcon from "@mui/icons-material/ArrowLeft";
import UpArrowIcon from "@mui/icons-material/ArrowUpward";
import DownArrowIcon from "@mui/icons-material/ArrowDownward";

import TrashIcon from "@mui/icons-material/Delete";
import { useContentEditor } from "../../";
export const HideTrackListButton = ({ hidden, toggleHidden }) => {
	return (
		<IconButton aria-label="hide" onClick={toggleHidden}>
			<LeftArrowHideIcon
				sx={{
					transform: !hidden ? "rotate(180deg)" : null,
				}}
			/>
		</IconButton>
	);
};

function TrackList({ fullScreen, onScroll, hidden }, ref) {
	const {
		deleteTrack,
		moveTrack,
		variables: {
			input: { timeline },
		},
	} = useContentEditor();

	return (
		<List
			className="hidden-scrollbar"
			ref={ref}
			sx={{
				width: hidden ? "0rem" : "12rem",
				height: fullScreen ? "500px" : "212px",
				maxHeight: "90vh",
				overflowX: "hidden",
				overflowY: "auto",
				padding: "0px",
				margin: "0px",
				bgcolor: "background.paper",
			}}
			onScroll={e => {
				onScroll(e.target.scrollTop);
			}}
		>
			<ListSubheader
				sx={{
					color: theme => theme.palette.text.primary,
				}}
			>
				<ListItemText primary="Tracks" />
			</ListSubheader>
			{timeline.tracks.map((track, i) => {
				return (
					<ListItem
						key={track.id}
						disableGutters
						sx={{
							paddingLeft: "3px",
							height: "75px",
							marginTop: i === 0 ? "14px" : null,
							border: "1px solid",

							borderColor: theme => theme.palette.divider,
						}}
						secondaryAction={
							<>
								<IconButton
									aria-label="comment"
									onClick={() => {
										deleteTrack({
											id: track.id,
										});
									}}
								>
									<TrashIcon />
								</IconButton>
							</>
						}
					>
						<ListItemAvatar
							sx={{
								minWidth: "fit-content",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									height: "100%",
									width: "fit-content",
								}}
							>
								<IconButton
									disabled={i === 0}
									disableRipple
									size="small"
									onClick={() => {
										moveTrack({
											trackIndex: i,
											direction: "up",
										});
									}}
								>
									<UpArrowIcon
										sx={{
											fontSize: "1rem",
										}}
									/>
								</IconButton>
								<IconButton
									disabled={i === timeline.tracks.length - 1}
									disableRipple
									size="small"
									onClick={() => {
										moveTrack({
											trackIndex: i,
											direction: "down",
										});
									}}
								>
									<DownArrowIcon
										sx={{
											fontSize: "1rem",
										}}
									/>
								</IconButton>
							</Box>
						</ListItemAvatar>
						<ListItemText
							primary={
								<TextField
									variant="standard"
									sx={{
										width: "100%",
										height: "fit-content",
										// remove underline
										"& .MuiInput-underline:before": {
											border: "none",
										},
									}}
									defaultValue={track.name}
									placeholder="Track Name"
								/>
							}
							secondary={track.assetType}
							secondaryTypographyProps={{
								sx: {
									color: theme => theme.palette.text.primary,
								},
							}}
						/>
					</ListItem>
				);
			})}
		</List>
	);
}

export default forwardRef(TrackList);
