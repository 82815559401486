import { Typography, Box } from "@mui/material";
import { BodyHeight } from "../../../../Layout";
import { Footer } from "../../../../Navigation";
const Section = ({ title, body }) => {
	return (
		<>
			<Typography
				variant="h1"
				sx={{
					fontSize: "clamp(1.5rem, 5vw, 2.5rem)",
					wordWrap: "break-word",
					overflowWrap: "break-word",
					hyphens: "manual",
				}}
			>
				{title}
			</Typography>

			<Box
				sx={{
					marginTop: "1rem",
				}}
			>
				<Typography
					variant="body1"
					sx={{
						fontSize: "clamp(1rem, 2vw, 1.5rem)",
					}}
				>
					{body}
					<br />
					<br />
				</Typography>
			</Box>
		</>
	);
};
export default function PageAbout() {
	return (
		<BodyHeight
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					paddingX: "1rem",
					paddingTop: "1rem",
					flexGrow: 1,
				}}
			>
				<Section
					title={
						<>
							About Fan
							<wbr />
							&shy;Heat
						</>
					}
					body={`FanHeat is a content creation and automation platform for creators. We offer a suite of tools to help you manage
					your content, automate your marketing, and grow your
					fanbase.`}
				/>
				<Section
					title={"Our Mission"}
					body={
						<>
							We believe that content creators should be able to
							<br />
							<br />
							<ul>
								<li>
									Own their content and the relationship with
									their fans
								</li>
								<li>
									Have the tools to grow their fanbase and
									increase their revenue
								</li>

								<li>
									Have the freedom to create the content they
									want
								</li>
							</ul>
							<br />
							<br />
							We are committed to helping creators achieve these
							goals through our platform.
							<br />
							<br />
						</>
					}
				/>
			</Box>
			<Footer />
		</BodyHeight>
	);
}
