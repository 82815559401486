import { gql } from "@apollo/client";
import { ContentFragment } from "../fragment";
export const contentAdded = gql`
	${ContentFragment}
	subscription ContentAdded {
		contentAdded {
			content {
				...ContentFragment
			}
			timestamp
		}
	}
`;
