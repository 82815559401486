import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import WorkspaceInfoPopover from "../WorkspaceInfoPopover";
import { useRequester } from "@/Apollo";
export default function WorkspaceInfoButton() {
	const [anchorEl, setAnchorEl] = useState(null);
	const { definitions, useQuery, workspace_id } = useRequester();
	const { data } = useQuery(definitions.workspace.query.getWorkspace, {
		variables: {
			id: workspace_id,
		},
	});
	const workspace = data?.workspace;

	return (
		<>
			<Tooltip title="about">
				<IconButton
					size="small"
					onClick={e => setAnchorEl(e.currentTarget)}
				>
					<InfoIcon color="info" />
				</IconButton>
			</Tooltip>
			<WorkspaceInfoPopover
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				workspace={workspace}
			/>
		</>
	);
}
