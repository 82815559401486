import { useState, useEffect } from "react";
import { useSelectedContent } from "../../../Component/SelectedContentContext";
import { IconButton, Box } from "@mui/material";
import ContentDetailsTabs, {
	ManageContentTabsContext,
} from "../../../Component/Content/Tabs";
import { useRequester } from "../../../Apollo";
import CloseIcon from "@mui/icons-material/Close";
import CardContentActions from "../../../Component/Content/Card/Actions";
import EditableTitle from "../../../Component/Content/Card/Details/EditableTitle";
import ContentTags from "../../../Component/Content/Card/Tags";
import FullScreenPreview from "../../../Component/FullScreenPreview";

export default function ViewContentDetails() {
	const { id, setId } = useSelectedContent();
	const { definitions, useLazyQuery } = useRequester();
	const [getContent, { data, loading }] = useLazyQuery(
		definitions.content.query.getContent
	);
	useEffect(() => {
		if (!id) return;
		getContent({
			variables: {
				id,
			},
			fetchPolicy: "network-only",
		});
	}, [id]);
	const [content, setContent] = useState(data?.content ? data.content : null);
	useEffect(() => {
		if (loading) return;
		if (data?.content) setContent(data.content);
	}, [data]);

	const key = `${content?.id}`; // ensure that the component is re-rendered when the id changes
	const type = content?.source?.mimetype?.includes("image")
		? "image"
		: "video";
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				overflowY: "auto",
			}}
		>
			{content && (
				<>
					<Box
						sx={{
							width: "100%",
						}}
					>
						<IconButton
							onClick={() => {
								setId(null);
							}}
							sx={{
								position: "absolute",
								right: "0",
								top: "0",
								zIndex: "1",
							}}
						>
							<CloseIcon />
						</IconButton>
						<EditableTitle key={key} />
					</Box>
					<Box
						key={key}
						sx={{
							height: "100%",
							minHeight: "fit-content",
						}}
					>
						<ManageContentTabsContext>
							<Box
								sx={{
									width: "100%",
									jusityContent: "center",
									alignItems: "center",
									display: "flex",
									flexDirection: "column",
									objectFit: "contain",
									position: "relative",
								}}
							>
								<span
									style={{
										position: "absolute",
										zIndex: "1",
										top: "0",
										left: "0",
									}}
								>
									<FullScreenPreview
										type={type}
										src={content.source.url}
									/>
								</span>

								{type === "image" ? (
									<img
										src={content.source.url}
										alt={content.title}
										style={{
											marginY: "1rem",
											height: "10rem",
											maxWidth: "100%",
										}}
									/>
								) : (
									<video
										src={content.source.url}
										controls
										style={{
											marginY: "1rem",
											height: "10rem",
											maxWidth: "100%",
										}}
									/>
								)}
							</Box>
							<CardContentActions />
							<span
								className="manage-content-update-tags"
								style={{
									width: "100%",
								}}
							>
								<ContentTags />
							</span>

							<ContentDetailsTabs />
						</ManageContentTabsContext>
					</Box>
				</>
			)}
		</Box>
	);
}
