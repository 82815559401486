import OauthAppConnectionList from "@/Component/Oauth/ConnectionList";
import { BodyHeight } from "@/Layout";
import { Typography, Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import BackArrow from "@mui/icons-material/ArrowBack";

export default function PageOauthAppConnections() {
	return (
		<BodyHeight
			sx={{
				padding: "1rem",
			}}
		>
			<Box
				sx={{
					marginBottom: "12px",
				}}
			>
				<Typography variant="h5">Connections</Typography>

				<Stack
					sx={{
						marginY: "1rem",
					}}
				>
					<Link to="/api">
						<BackArrow fontSize="inherit" /> Oauth Clients
					</Link>
				</Stack>
			</Box>
			<Box
				sx={{
					display: "flex",
				}}
			>
				<OauthAppConnectionList />
			</Box>
		</BodyHeight>
	);
}
