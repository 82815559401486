import { Box } from "@mui/material";
import Discord from "./Discord";

import Hero from "../../../../Component/Hero";
import FeatureStack from "./FeatureStack";
import { CallToActdionCard } from "./FeatureStack/FeatureCard";
import { BodyHeight } from "../../../../Layout";
import { Footer } from "../../../../Navigation";
const Spacing = () => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "65px",
			}}
		/>
	);
};
export default function PageLanding() {
	return (
		<BodyHeight
			sx={{
				// smooth scrolling
				scrollBehavior: "smooth",
			}}
		>
			<Box
				sx={{
					width: "100%",

					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
				}}
			>
				<Hero />
			</Box>
			<Discord />
			<Spacing />
			<FeatureStack />
			<Spacing />
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<CallToActdionCard />
			</Box>

			<Spacing />

			<Footer />
		</BodyHeight>
	);
}
