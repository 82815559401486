import { useState, useContext, createContext } from "react";
import { useRequester } from "../../../../../Apollo";
import ReactJoyride from "react-joyride";

import { IconButton, Box } from "@mui/material";
import QuestionIcon from "@mui/icons-material/HelpOutline";
import { useSnackbar } from "notistack";
const Context = createContext({
	running: false,
});
export const useLibraryJoyride = () => useContext(Context);
const JOYRIDE_DOMAIN = "ACCOUNT";
export default function JoyrideContext() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useQuery, useMutation } = useRequester();
	const { data } = useQuery(definitions.user.query.onboardingProgress, {
		fetchPolicy: "cache-first",
	});
	const onStep =
		data?.onboardingProgress.progress[
			`on_step_${JOYRIDE_DOMAIN.toLowerCase()}`
		];
	const [run, setRun] = useState(onStep === 0);

	const [updateStepProgress] = useMutation(
		definitions.user.muation.onboardingProgress
	);
	const [reset, setReset] = useState(0);

	return (
		<>
			<Box
				sx={{
					position: "absolute",
					bottom: 12,
					right: 12,
					zIndex: 1000,
				}}
			>
				<IconButton
					onClick={() => {
						setRun(true);
						setReset(reset + 1);
						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 0,
								},
							},
						});
					}}
				>
					<QuestionIcon />
				</IconButton>
			</Box>
			<ReactJoyride
				run={!onStep && run}
				key={reset}
				continuous
				showProgress
				showSkipButton
				spotlightClicks
				scrollToFirstStep={false}
				callback={({ action, index, status }) => {
					if (action === "close" || status === "skipped") {
						setRun(false);
						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 1,
								},
							},
							onError: () => {
								const key = enqueueSnackbar(
									"Failed to update onboarding progress.",
									{
										variant: "error",
										onClick: () => closeSnackbar(key),
									}
								);
							},
						});
					}
				}}
				disableScrolling
				disableOverlayClose
				steps={[
					{
						target: ".account-user-card",
						content:
							"Details relating to your account can be found here.",
						placement: "bottom",
						disableBeacon: true,
					},
					{
						target: ".dark-mode-toggler",
						content: "Toggle between light and dark mode",
						placement: "bottom",
						disableBeacon: true,
					},
					{
						target: ".duplicate-content-detection-settings",
						content:
							"Manage your duplicate content detection configuration here.",

						disableBeacon: true,
					},
					{
						target: ".duplicate-content-visual-match-config",
						content:
							"When content is uploaded, we use a proprietary algorithm to scan for visually similar video or image files. Toggle this setting on to receive a notification when a visual match is detected.",

						disableBeacon: true,
					},
					{
						target: ".duplicate-content-exact-match-config-warn",
						content:
							"We also scan for hash matches, or exact file matches. If you accidentally upload the same file twice, we'll alert you if this setting is enabled.",

						disableBeacon: true,
					},
					{
						target: ".duplicate-content-exact-match-config-delete",
						content:
							"Toggle this setting on to automatically delete exact duplicate files on upload.",

						disableBeacon: true,
					},
					{
						target: ".take-down-piracy-config",
						content:
							"TakeDown Piracy is a service that helps you remove pirated content from the internet. This service is only available to premium subscribers.",

						disableBeacon: true,
					},
					{
						target: ".account-subscription-card",
						content:
							"View and manage your subscription here. Each subscription tier has a greater number of features available to you. You may wish to increase the level of your subscription as your brand grows.",

						disableBeacon: true,
					},
				]}
			/>
		</>
	);
}
