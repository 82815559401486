import SettingsPanel from "@/Component/Settings";
import { useRequester } from "@/Apollo";

// TODO: https://rsuitejs.com/components/radio-tile/ switch to using this
export default function DuplicateContentDetectionConfig() {
	const { definitions, useQuery, useMutation } = useRequester();
	const { data, refetch } = useQuery(
		definitions.common.query.assetMatchConfig,
		{
			fetchPolicy: "cache-first",
		}
	);
	const [updateAssetMatchConfig] = useMutation(
		definitions.common.mutation.assetMatchConfig
	);
	return (
		<SettingsPanel
			className={"duplicate-content-detection-settings"}
			options={[
				{
					className: "duplicate-content-visual-match-config",
					label: <>Warn on visual match detected</>,
					value: data?.assetMatchConfig?.should_warn_visual,
					onChange: async () => {
						await updateAssetMatchConfig({
							variables: {
								input: {
									should_warn_visual:
										!data?.assetMatchConfig
											?.should_warn_visual,
								},
							},
						});
						await refetch();
					},
				},
				{
					className: "duplicate-content-exact-match-config-warn",
					label: <>Warn on exact duplicate detected</>,
					value: data?.assetMatchConfig?.should_warn,

					onChange: async () => {
						await updateAssetMatchConfig({
							variables: {
								input: {
									should_warn:
										!data?.assetMatchConfig?.should_warn,
								},
							},
						});
						await refetch();
					},
				},
				{
					className: "duplicate-content-exact-match-config-delete",
					label: <>Delete exact duplicates</>,
					value: !data?.assetMatchConfig?.should_keep,
					onChange: async () => {
						await updateAssetMatchConfig({
							variables: {
								input: {
									should_keep:
										!data?.assetMatchConfig?.should_keep,
								},
							},
						});
						await refetch();
					},
				},
			]}
		/>
	);
}
