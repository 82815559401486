import InstantSearchView from "../../../../../InstantSearch/view";
import { useIsSSR } from "react-aria";
import LoadingScreen from "../../../../../Component/LoadingScreen";
import LibraryViewContext from "../../../../../InstantSearch/view/Context";
import MultiselectContentContextProvider from "../../../../../MultiselectContent";
import ContentSearchContext from "@/ContentSearch/Context";
import { useRequester } from "@/Apollo";
import SelectedContentContext, {
	useSelectedContent,
} from "../../../../../Component/SelectedContentContext";
import { useRef } from "react";
import Joyride from "../../Library/Joyride";
const JoyrideComponent = () => {
	const { id } = useSelectedContent();
	if (id) return null;
	return <Joyride />;
};
export const WorkspaceContentSearchContextProvider = ({ children }) => {
	const { workspace_id } = useRequester();
	const multiSelectRef = useRef(null);
	if (!workspace_id) return children; // allow app to load if user is not logged in.
	return (
		<ContentSearchContext
			defaultLimit={200}
			onStateChange={() => multiSelectRef?.current?.deselectAll()}
		>
			<MultiselectContentContextProvider ref={multiSelectRef}>
				<SelectedContentContext>
					<LibraryViewContext>{children}</LibraryViewContext>
				</SelectedContentContext>
			</MultiselectContentContextProvider>
		</ContentSearchContext>
	);
};
export default function LibraryPage() {
	const isSSR = useIsSSR();

	if (isSSR) return <></>;

	return (
		<LoadingScreen loading={false} message={`Loading Library`}>
			<WorkspaceContentSearchContextProvider>
				<JoyrideComponent />
				<InstantSearchView />
			</WorkspaceContentSearchContextProvider>
		</LoadingScreen>
	);
}
