import {
	Box,
	Grid,
	Card,
	CardMedia,
	CardContent,
	Typography,
	Chip,
	Stack,
	IconButton,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import formatSeconds from "../../../../../../util/formatSeconds";
import FolderIcon from "@mui/icons-material/Folder";
import ViewDetailsIcon from "@mui/icons-material/ManageSearch";

import { useMultiselectContent } from "../../../../../../MultiselectContent";
import useLibraryMediaQuery from "../../../../useIsWidescreen";

import { useSelectedContent } from "../../../../../../Component/SelectedContentContext";

import useMobileContextMenu from "../../../../../../util/useMobileContextMenu";
import FullScreenPreview from "../../../../../../Component/FullScreenPreview";

const CardWrapper = ({ children, content }) => {
	// multi-select context causes each card to re-render on select
	// so we wrap the card body in order to keep re-renders minimal
	const {
		toggleSelect: toggleMultiSelect,
		setContextMenuEvent,
		selectListFromID,
	} = useMultiselectContent();

	const mobileContextMenuHandlers = useMobileContextMenu({
		onLongPress: e =>
			setContextMenuEvent({
				event: e,
				content: content,
				open: true,
			}),
	});
	return (
		<Card
			sx={{
				width: "100%",
				borderBottomLeftRadius: 0,

				borderBottomRightRadius: 0,
			}}
			onContextMenu={e =>
				setContextMenuEvent({ event: e, content, open: true })
			}
			onClick={e => {
				if (e.shiftKey) return selectListFromID(content.id);
				toggleMultiSelect(content);
			}}
			{...mobileContextMenuHandlers}
		>
			{children}
		</Card>
	);
};
export default function GridViewHit({ content, onContextMenu }) {
	const { setId } = useSelectedContent();
	const { isXXL } = useLibraryMediaQuery();

	let type = "IMAGE";
	if (content?.source?.mimetype?.includes("video")) type = "VIDEO";

	return (
		<Grid
			id={`content-list-${content.id}`}
			component="li"
			key={content.id}
			item
			xs={12}
			sm={6}
			md={4}
			lg={3}
			xl={isXXL ? 1 : 2}
			sx={{
				border: "1px solid #red",
				paddingY: ".5rem",
				paddingX: ".25rem",
				// remove li bullet points
				listStyle: "none",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				width: "fit-content",
				position: "relative",
			}}
		>
			<CardWrapper content={content}>
				<div
					style={{
						position: "relative",
					}}
				>
					<span
						style={{
							position: "relative",
						}}
					>
						<Box
							sx={{
								position: "absolute",
								top: 0,
								right: 0,
								backgroundColor: theme =>
									theme.palette.background.default,
							}}
						>
							<IconButton
								onClick={e => {
									e.stopPropagation();
									setId(content.id);
								}}
							>
								<ViewDetailsIcon />
							</IconButton>
						</Box>
						{type === "VIDEO" && (
							<>
								<Box
									sx={{
										border: "1px solid #red",
										borderRadius: "50%",

										position: "absolute",
										// CENTER
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										// END CENTER
										display: "flex",
										justifyContent: "center",
										alignItems: "center",

										width: "3rem",
										height: "3rem",
										backgroundColor: theme =>
											theme.palette.background.default,
										// background alpha
										opacity: 0.8,
										// remove click
										pointerEvents: "none",
									}}
								>
									<VideoIcon
										sx={{
											borderRadius: "50%",
											backgroundColor: theme =>
												theme.palette.background
													.default,
										}}
									/>
								</Box>
								<Box
									sx={{
										position: "absolute",
										bottom: 0,
										right: 0,
										backgroundColor: theme =>
											theme.palette.background.default,
										opacity: 0.8,
									}}
								>
									<Typography>
										{formatSeconds(
											content?.source?.duration
										)}
									</Typography>
								</Box>
							</>
						)}

						<CardMedia
							component="img"
							height="140"
							image={content?.source?.thumbnailUrl}
							alt={content.title}
							sx={{
								objectFit: "contain",
								pointerEvents: "none",
								touchAction: "none",
							}}
						/>
					</span>
					<span
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							// zIndex: 999,
						}}
					>
						<FullScreenPreview
							src={content?.source?.url}
							type={type}
						/>
					</span>
				</div>
				<CardContent>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: ".5rem",
						}}
					>
						<FolderIcon
							sx={{
								color: theme => {
									return content._folderexists
										? theme.palette.text.primary
										: theme.palette.text.disabled;
								},
								opacity: theme => {
									return content._folderexists ? 1 : 0.5;
								},
							}}
						/>
						{content?.folder?.name}
					</Box>

					<Box
						sx={{
							height: "3rem",
							textOverflow: "ellipsis",
						}}
					>
						<Typography
							noWrap
							gutterBottom
							variant="h5"
							component="div"
						>
							{content.title}
						</Typography>
					</Box>
					<Stack
						className="hidden-scrollbar"
						direction="row"
						spacing={1}
						sx={{
							height: "2rem",
							width: "100%",
							overflowX: "auto",
							overflowY: "hidden",
						}}
					>
						{content.metadataTags.map(({ value }) => (
							<Chip
								key={value}
								label={value}
								variant="outlined"
							/>
						))}
						{
							// if there are no tags, display a message
							content.metadataTags.length === 0 && (
								<Typography
									color="textSecondary"
									variant="body2"
								>
									No tags
								</Typography>
							)
						}
					</Stack>
				</CardContent>
			</CardWrapper>
		</Grid>
	);
}
