import { useRequester } from "@/Apollo";
import {
	Stack,
	Box,
	Select,
	MenuItem,
	TextField,
	Typography,
	IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { throttle } from "lodash";

const SIZES = [
	{
		name: "EXTRASMALL",
		value: 22,
	},
	{
		name: "SMALL",
		value: 32,
	},
	{
		name: "MEDIUM",
		value: 48,
	},
	{
		name: "LARGE",
		value: 60,
	},
	{
		name: "EXTRALARGE",
		value: 84,
	},
];
function parseFont(fontString) {
	if (!fontString) return null;
	const regex = /^(.+?)\s+\((\w+)\)$/; // Updated regular expression to match any sequence of characters for font name
	const matches = fontString.match(regex); // Match the string against the regex

	if (matches && matches.length === 3) {
		return {
			name: matches[1].trim(), // Extracted font name (trim to remove leading/trailing whitespace)
			weight: matches[2], // Extracted font weight
		};
	} else {
		// If the format doesn't match, return null or handle the error accordingly
		return null;
	}
}

export default function WatermarkEditor() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useQuery, useMutation } = useRequester();
	const { data, loading } = useQuery(definitions.common.query.fonts);
	const fonts = data?.applicationConfig?.fonts || [];

	const [textValue, setTextValue] = useState("Watermark Text");
	const [selectedFont, setSelectedFont] = useState();
	useEffect(() => {
		if (loading) return;
		if (selectedFont) return;
		setSelectedFont(fonts[0]);
	}, [loading]);
	const [selectedSize, setSelectedSize] = useState(SIZES[2]);
	const [selectedColor, setSelectedColor] = useState("#000000");

	const cssString = useMemo(() => {
		if (!fonts) return "";
		return fonts.reduce(
			(acc, font) => {
				const parsed = parseFont(font);
				if (!parsed) return acc;
				return `
                @font-face {
                    font-family: "${parsed.name}";
                    src: url("https://cdn.dev.fanheat.com/fonts/${parsed.name.replace(
						/ /g,
						"-"
					)}-${parsed.weight}.ttf");
                    font-weight: ${parsed.weight};
                    font-style: normal;
                }
                ${acc}
            `;
			},
			`
    
        `
		);
	}, [fonts]);

	const parsed = parseFont(selectedFont);
	const fontFamily = parsed?.name;

	const throttledSelectColor = useCallback(
		throttle(e => setSelectedColor(e.target.value), 100),
		[]
	);
	const [createWatermark, { loading: creatingWatermark }] = useMutation(
		definitions.assets.mutation.CREATE_WATERMARK
	);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				maxWidth: "100vw",
				height: "fit-content",
			}}
		>
			<style>{cssString}</style>

			<Typography
				variant="h5"
				key={selectedFont}
				sx={{
					marginY: "1rem",
					fontFamily: `${fontFamily} !important`,
					fontWeight: parsed?.weight,
				}}
			>
				Watermark Editor
			</Typography>
			<Stack spacing={2} direction="column">
				{!loading && selectedFont && (
					<>
						<Select
							value={selectedFont}
							onChange={e => setSelectedFont(e.target.value)}
						>
							{fonts.map(font => {
								const parsed = parseFont(font);
								return (
									<MenuItem
										key={font}
										value={font}
										sx={{
											//target the menu item font family
											fontFamily: `${parsed?.name} !important`,
											fontWeight: `${parsed?.weight} !important`,
										}}
									>
										{font}
									</MenuItem>
								);
							})}
						</Select>
					</>
				)}

				<Select
					value={selectedSize}
					onChange={e => setSelectedSize(e.target.value)}
				>
					{SIZES.map(size => (
						<MenuItem key={size.name} value={size}>
							{size.name}
						</MenuItem>
					))}
				</Select>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<input
						type="color"
						value={selectedColor}
						onChange={throttledSelectColor}
					/>
				</Box>
			</Stack>

			<TextField
				variant="filled"
				className="watermark-editor-text-field"
				rows={4}
				InputProps={{
					inputProps: {
						maxLength: 16,
					},
				}}
				value={textValue}
				onChange={e => setTextValue(e.target.value)}
				sx={{
					marginY: "1rem",
					// target the input element
					"& .MuiInputBase-input": {
						fontFamily: `${fontFamily} !important`,
						fontSize: `${selectedSize.value}px !important`,
						color: `${selectedColor} !important`,
						textAlign: "center",
					},
				}}
			/>
			<IconButton
				size="large"
				aria-label="save"
				color="primary"
				disabled={creatingWatermark}
				onClick={() => {
					createWatermark({
						onCompleted: () => {
							const key = enqueueSnackbar("Watermark Created", {
								variant: "success",
								onClick: () => closeSnackbar(key),
							});
						},
						variables: {
							input: {
								name: "Text Watermark",
								type: "TEXT",
								text: {
									color: selectedColor,
									font: selectedFont,
									fontSize: selectedSize.name,
									text: textValue,
								},
							},
						},
					});
				}}
			>
				<SaveIcon />
			</IconButton>
		</Box>
	);
}
