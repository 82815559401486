import { Button } from "@mui/material";
import { useRequester } from "@/Apollo";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

export default function AddPaymentMethodButton() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation } = useRequester();
	const [manageCustomer] = useMutation(
		definitions.billing.mutation.manageCusomter,
		{
			onCompleted: ({ manageCustomer }) => {
				window.open(manageCustomer.url, "_blank");
			},
			onError: error => {
				enqueueSnackbar(error.message, {
					variant: "error",
				});
			},
		}
	);

	return (
		<Button
			onClick={manageCustomer}
			component={Link}
			variant="text"
			sx={{
				width: "fit-content",
			}}
		>
			{`Manage Customer Account`}
		</Button>
	);
}
