import { Box } from "@mui/material";
import DesktopLayout from "./Desktop";
import MobileLayout from "./Mobile";
import { useContentEditor } from "../common/context";
export default function ResponsiveLayout() {
	const { mobile } = useContentEditor();
	const layout = mobile ? <MobileLayout /> : <DesktopLayout />;
	return (
		<Box
			sx={{
				position: "relative",
				width: "100%",
				height: "100%",
				overflow: "hidden",
				background: theme => theme.palette.background.default,
			}}
		>
			{layout}
		</Box>
	);
}
