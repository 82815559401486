import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
	Button,
	InputLabel,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import useEmailVerify from "./useEmailVerify";
import depthEffect from "../../Theme/sx/depth-effect";
import { useRequester } from "../../Apollo";
import calculateTimeSince from "../../util/calculateTimeSince";
import EditableDisplayName from "./EditableDisplayName";

import EditableAvatar from "../EditableAvatar";
export default function User() {
	const { useQuery, definitions } = useRequester();
	const { data, loading } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "cache-first",
	});
	const { user } = useAuth0();
	const { verifyEmail } = useEmailVerify();

	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const isEmailVerified = user?.email_verified;

	const section = [
		[
			{
				label: "User Created",
				value: loading
					? ""
					: calculateTimeSince(data?.user?.createdAt).agoString,
			},

			{
				label: "Last Login",
				value: calculateTimeSince(user?.updated_at).agoString,
			},
		],
		[
			{
				label: "Email Verified",
				value: isEmailVerified ? (
					"Yes"
				) : (
					<Button
						sx={theme => {
							return {
								color: theme.palette.action.active,
								...depthEffect(theme),
							};
						}}
						onClick={verifyEmail}
					>
						Click to verify
					</Button>
				),
			},
			{
				label: "Time Zone",
				value: timeZone,
			},
		],
	];

	return (
		<>
			<Card
				className="account-user-card"
				sx={{
					...depthEffect(),
					maxWidth: 500,
					height: "fit-content",
				}}
			>
				<CardHeader
					avatar={<EditableAvatar type={"USER"} />}
					subheader={
						<Typography color="textPrimary" variant="h6">
							{user?.email}
						</Typography>
					}
					title={<EditableDisplayName />}
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={2}>
						{section.map((section, i) => (
							<Grid item key={i} xs={12} sm={6}>
								<Grid container spacing={2}>
									{section.map((item, i) => (
										<Grid item key={i} xs={12}>
											<InputLabel>
												{item.label}
											</InputLabel>

											<Typography
												color="textPrimary"
												variant="h6"
											>
												{item.value}
											</Typography>
										</Grid>
									))}
								</Grid>
							</Grid>
						))}
					</Grid>
				</CardContent>
			</Card>
		</>
	);
}
