import { useParams } from "react-router-dom";
import { WorkspaceWrapper } from "@/Layout";
import { Typography, Avatar, Box } from "@mui/material";
import RoleAutocomplete from "@/Component/VirtualAssistant/Assistants/RoleAutocomplete";
import { makeCDNPath } from "@/util/makeCDNPath";
import { useRequester } from "@/Apollo";
import LoadingScreen from "@/Component/LoadingScreen";
export default function PageWorkspaceAssistantsAssistant() {
	const { id } = useParams();
	const { useQuery, definitions } = useRequester();

	const { data, loading } = useQuery(
		definitions.virtualAssistant.query.assistant,

		{
			fetchPolicy: "cache-and-network",
			variables: {
				input: {
					assistant: id,
				},
			},
		}
	);
	const assistant = data?.virtualAssistant?.assistant;
	return (
		<WorkspaceWrapper>
			<LoadingScreen loading={loading} message={"Fetching assistant"}>
				{!assistant && <Typography>Assistant not found</Typography>}
				{assistant && (
					<>
						<Typography variant="h5">Assistant</Typography>
						<Avatar
							sx={{
								width: "4rem",
								height: "4rem",
								marginBottom: "1rem",
							}}
							src={makeCDNPath(
								`avatar/user/${assistant?.user_id}`
							)}
						>
							{assistant?.public_display_name[0]}
						</Avatar>
						<Typography variant="h6">
							{assistant?.public_display_name}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								marginBottom: "1rem",
							}}
						>
							Joined at:{" "}
							{new Date(assistant?.joined_at).toLocaleString()}
						</Typography>
						<Box
							sx={{
								marginTop: "1rem",
							}}
						>
							<RoleAutocomplete assistant={assistant} />
						</Box>
					</>
				)}
			</LoadingScreen>
		</WorkspaceWrapper>
	);
}
