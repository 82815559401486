import { usePublishContentForm } from "..";
import ImgurOptions from "./Imgur";
import RedgifsOptions from "./Redgifs";
import RedditOptions from "./Reddit";
import TwitterOptions from "./Twitter";
import OnlyfansOptions from "./Onlyfans";
const DestinationOptionsForm = destination => {
	switch (destination.toUpperCase()) {
		case "IMGUR":
			return ImgurOptions;
		case "ONLYFANS":
			return OnlyfansOptions;
		case "REDDIT":
			return RedditOptions;
		case "REDGIFS":
			return RedgifsOptions;

		case "TWITTER":
			return TwitterOptions;

		default:
			return () => <>Missing options component for{destination}</>;
	}
};
export default function DestinationOptions() {
	const { destination } = usePublishContentForm();
	const Form = DestinationOptionsForm(destination);
	return <Form />;
}
