import { useRequester } from "../../../../../../Apollo";
import { useSelectedContent } from "../../../../../SelectedContentContext";
import { useSnackbar } from "notistack";

export default function useCreateLink() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation, useQuery } = useRequester();
	const { id } = useSelectedContent();
	const { data } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});

	const [mutate] = useMutation(definitions.common.mutation.getSignedURL);

	const expires = 60 * 10; // 10 minutes
	const exiprationDate = new Date();
	exiprationDate.setSeconds(exiprationDate.getSeconds() + expires);

	const title = data?.content?.title;
	const fileId = data?.content?.source?.id;
	const copyToClipboard = async text => {
		try {
			await window.navigator.clipboard.writeText(text);

			let key = enqueueSnackbar(
				`Link copied to clipboard. Expires at ${exiprationDate.toLocaleString()}`,
				{
					variant: "success",
					onClick: () => {
						closeSnackbar(key);
					},
				}
			);
		} catch (e) {
			// !!DO NOT DELETE THIS CONSOLE LOG!! It's for the user to copy their link.
			console.log(
				`|One-Time-Link(expires at ${exiprationDate.toLocaleString()})|\n|Content ID:${id}|\n|Content Title:${title}|\n${text}`
			);
			let key = enqueueSnackbar(
				`Failed to copy to clipboard. Check your console for the link. Link expires at ${exiprationDate.toLocaleString()}`,
				{
					variant: "error",
					onClick: () => {
						closeSnackbar(key);
					},
				}
			);
		}
	};

	const create = async download => {
		const { data } = await mutate({
			variables: {
				input: {
					dl: !!download,
					expires,
					fileId,
				},
			},
		});

		console.log("data: ", data);
		return data.getSignedUrl;
	};

	return {
		create: async () => {
			const url = await create();
			console.log("url: ", url);
			copyToClipboard(url);
		},
		download: async () => {
			const url = await create(true);
			// download
			window.open(url, "_blank");
		},
		nativeShare: async () => {
			try {
				navigator.share({
					title: "Shared from FanHeat",
					text: "You'd better open this quickly, the link will expire soon!",
					url: await create(true),
				});
			} catch (e) {
				console.log("e: ", e);
				let key = enqueueSnackbar(
					"Native share not available! Please update your browser to the latest version or try copying the link instead.",
					{
						variant: "error",
						onClick: () => {
							closeSnackbar(key);
						},
					}
				);
			}
		},
	};
}
