import { usePublishContentForm } from "../..";
import {
	TextField,
	Stack,
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { HotTitles } from "../../../HotTitles";
import NextButton from "../../NextButton";

export default function ImgurOptions({ disabled = false, hideNext }) {
	const {
		imgur: {
			album,
			description,
			disable_audio,
			name,
			nsfw,
			hidden,
			setAlbum,
			setDescription,
			setDisable_audio,
			setName,
			setNsfw,
			setHidden,
			validate,
		},

		title,
		nextStep,
	} = usePublishContentForm();

	return (
		<FormControl
			component={"form"}
			fullWidth
			sx={{
				width: "100%",
				height: "100%",
				paddingTop: 2,
			}}
			onSubmit={e => {
				e.preventDefault();
				nextStep();
			}}
		>
			<Stack spacing={1}>
				<HotTitles
					defaultQuery={name || title}
					onChange={title => {
						if (disabled) return;
						setName(title);
					}}
					onSuggest={suggestion => {
						if (disabled) return;
						if (name) return;
						setName(suggestion);
					}}
					disabled={disabled}
				/>
				<TextField
					label="Description"
					value={description || ""}
					onChange={e => setDescription(e.target.value)}
					disabled={disabled}
				/>
				<TextField
					label="Album"
					value={album || ""}
					onChange={e => setAlbum(e.target.value)}
					disabled={disabled}
				/>
			</Stack>

			<FormControlLabel
				checked={disable_audio}
				onChange={e => setDisable_audio(e.target.checked)}
				control={<Checkbox />}
				label="Disable Audio"
				labelPlacement="start"
				disabled={disabled}
			/>
			{/* <FormControlLabel
				checked={nsfw}
				onChange={e => setNsfw(e.target.checked)}
				control={<Checkbox />}
				label="NSFW"
				labelPlacement="start"
				disabled={disabled}
			/>
			<FormControlLabel
				checked={hidden}
				onChange={e => setHidden(e.target.checked)}
				control={<Checkbox />}
				label="Hidden"
				labelPlacement="start"
				disabled={disabled}
			/> */}
			{!hideNext && <NextButton />}
		</FormControl>
	);
}
