import { StyledSquareIconButton } from "../../../Component/Styled/Button";
import useDropboxImport from "../../../Dropbox/useDropbox";
export default function ImportDropboxButton() {
	const { importContent, Icon } = useDropboxImport();
	return (
		<StyledSquareIconButton
			className="library-import-dropbox"
			title="Upload Content"
			onClick={importContent}
			sx={{
				border: "none",
			}}
		>
			<Icon />
		</StyledSquareIconButton>
	);
}
