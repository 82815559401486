import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import { useRequester } from "../../../Apollo";
import { useSnackbar } from "notistack";
import { useLocale } from "react-aria";
import depthEffect from "@/Theme/sx/depth-effect";

const parseAmountToDollars = ({ language, amount, currency }) => {
	return (amount / 100).toLocaleString(language, {
		style: "currency",
		currency: currency.toUpperCase(),
	});
};

const IMG_HEIGHT = 140;
const IMG_WIDTH = 345;

// takes camelCase and returns Camel Case
const formatCase = str => {
	return str
		.split(/(?=[A-Z])/)
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};
export default function SubscriptionTierCard({
	product,
	plan,
	mySubscription,
}) {
	const features = Object.entries(product.quota).reduce(
		(acc, [key, value]) => {
			if (key === "__typename") return acc;
			acc.push({
				name: key,
				unit: value.unit,
				value: value.allotted,
			});

			return acc;
		},
		[]
	);

	const selectedPlan = mySubscription?.product.plans.find(
		plan => plan.selected
	);
	const isSubscribedToPlan = plan.id === selectedPlan.id;

	const { locale } = useLocale();

	const { id, amount, currency, interval } = plan;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation } = useRequester();

	const [updateSubscription] = useMutation(
		definitions.billing.mutation.updateSubscription,
		{
			onError: error => {
				enqueueSnackbar(error.message, { variant: "error" });
			},
		}
	);

	const handleUpdateSubscription = () => {
		console.log("updating subscription");
		updateSubscription({
			variables: {
				input: {
					plan: id,
				},
			},
			onError: error => {
				console.log("error updating subscription", error);
				const key = enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 3000,
					onClick: () => closeSnackbar(key),
				});
			},
			onCompleted: ({ updateSubscription }) => {
				console.log("updated subscription", updateSubscription);
				window.open(updateSubscription.url, "_blank");
			},
		});
	};

	const [manageSubscription] = useMutation(
		definitions.billing.mutation.manageSubscription
	);
	const handleManageSubscription = () => {
		console.log("managing subscription");
		manageSubscription({
			variables: {
				input: {
					plan: id,
				},
			},
			onError: error => {
				console.log("error updating subscription");
				enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
			},
			onCompleted: ({ manageSubscription }) => {
				const goToURL = manageSubscription.url;
				window.open(goToURL, "_blank");
			},
		});
	};

	const ActionAreaText = () => {
		if (isSubscribedToPlan) {
			return (
				<Typography
					variant="button"
					color={theme => theme.palette.warning.main}
				>
					Manage Subscription
				</Typography>
			);
		}

		return (
			<Typography
				variant="button"
				color={theme => theme.palette.info.main}
			>
				Subscribe to plan
			</Typography>
		);
	};

	return (
		<Card
			sx={{
				height: "fit-content",
				width: IMG_WIDTH,
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				...depthEffect(),
				position: "relative",
			}}
		>
			<CardContent>
				<Typography gutterBottom variant="h5" component="div">
					{product.name.split(" - ")[1]}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{parseAmountToDollars({
						language: locale,
						amount,
						currency,
					})}
					/{interval}
				</Typography>
				<CardMedia
					sx={{
						marginY: 2,
						objectFit: "cover",
						...depthEffect(),
					}}
					component="img"
					width={IMG_WIDTH}
					height={IMG_HEIGHT}
					image={product.image}
					alt="random image"
				/>
				<Box>
					<TableContainer
						component={({ children }) => {
							return (
								<Paper sx={{ width: "100%" }}>{children}</Paper>
							);
						}}
					>
						<Table sx={{ minWidth: 200 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									{features.map((feature, i) => {
										return (
											<TableCell
												key={i}
												sx={{
													fontWeight: "bold",
												}}
												align="left"
											>
												{formatCase(feature.name)}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									{features.map((feature, i) => {
										return (
											<TableCell key={i} align="right">
												{feature.value} {feature.unit}
											</TableCell>
										);
									})}
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</CardContent>
			<CardActionArea
				sx={{
					textAlign: "center",
				}}
				onClick={() => {
					if (isSubscribedToPlan) return handleManageSubscription();
					handleUpdateSubscription();
				}}
			>
				<CardContent>
					<ActionAreaText />
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
