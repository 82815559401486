import { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import {
	Dialog,
	DialogTitle,
	DialogActions,
	IconButton,
	Tooltip,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Divider,
	Box,
	DialogContent,
	Typography,
} from "@mui/material";
import OnlyfansImportButton from "@/Component/OnlyfansImport";
import GoogleDrivePicker from "@/GoogleDrive";
import CloseIcon from "@mui/icons-material/Close";
import UploadContentButton from "../../Upload";
import ImportDropboxButton from "../../DropboxImport";

const actions = [
	{
		icon: <ImportDropboxButton />,
		name: "Dropbox",
	},
	{
		icon: <OnlyfansImportButton />,
		name: "Onlyfans",
	},
	{
		icon: <GoogleDrivePicker />,
		name: "Google Drive",
	},
];
export default function UploadAndImportContentButton() {
	const [open, setOpen] = useState(false);

	return (
		<>
			<IconButton onClick={() => setOpen(true)}>
				<UploadIcon />
			</IconButton>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>
					<IconButton onClick={() => setOpen(false)}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<DialogActions>
						{actions.map(({ icon, name }, index) => {
							return (
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}
									key={index}
									title={name}
								>
									{icon}
									<Typography>{name}</Typography>
								</Box>
							);
						})}
					</DialogActions>
				</DialogContent>
				<Divider />
				<DialogContent>
					<DialogActions>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",

								width: "100%",
							}}
						>
							<UploadContentButton />
							<Typography>Upload from device</Typography>
						</Box>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
}
