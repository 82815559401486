import { gql } from "@apollo/client";
import { WORKSPACE_ROLE_FIELDS } from "../fragment";

export const workspaceRoles = gql`
	${WORKSPACE_ROLE_FIELDS}
	query WorkspaceRoles {
		workspaceRoles {
			roles {
				...WorkspaceRoleFields
			}
		}
	}
`;
export const workspaceRole = gql`
	${WORKSPACE_ROLE_FIELDS}
	query WorkspaceRole($input: WorkspaceRoleInput!) {
		workspaceRole(input: $input) {
			role {
				...WorkspaceRoleFields
			}
		}
	}
`;
