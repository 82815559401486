import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRequester } from "@/Apollo";
import { useConfirm } from "@/Confirm";
import { useOauthClientContext } from "@/Component/Oauth/OauthClientList/Client";
export default function DeleteClientButton() {
	const { client } = useOauthClientContext();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { confirm } = useConfirm();

	const { useMutation, definitions } = useRequester();
	const [deleteOauthClient, { client: apolloClient }] = useMutation(
		definitions.oauth.mutation.deleteClient
	);
	return (
		<IconButton
			onClick={async () => {
				const isConfirmed = await confirm(
					"Are you sure you want to delete this client?",
					"This is a destructive action and cannot be undone."
				);
				if (!isConfirmed) return;
				deleteOauthClient({
					variables: {
						input: {
							client_id: client.id,
						},
					},
					onCompleted: data => {
						const key = enqueueSnackbar("Client deleted", {
							variant: "success",
							onClick: () => closeSnackbar(key),
						});
						// remove client from cache
						apolloClient.writeQuery({
							query: definitions.oauth.query.listOauthClients,
							data: {
								oauthClients: {
									clients: [
										...apolloClient
											.readQuery({
												query: definitions.oauth.query
													.listOauthClients,
											})
											.oauthClients.clients.filter(
												client => {
													return (
														client.id !==
														data.deleteOauthClient
															.client_id
													);
												}
											),
									],
								},
							},
						});
					},
				});
			}}
		>
			<DeleteIcon />
		</IconButton>
	);
}
