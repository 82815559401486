import React from "react";
import { Button } from "@mui/material";
import depthEffect from "@/Theme/sx/depth-effect";
import { useRequester } from "@/Apollo";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

export default function AddPaymentMethodButton() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation } = useRequester();
	const [addPaymentMethod] = useMutation(
		definitions.billing.mutation.addPaymentMethod,
		{
			onCompleted: ({ addPaymentMethod }) => {
				window.open(addPaymentMethod.url, "_blank");
			},
			onError: error => {
				enqueueSnackbar(error.message, {
					variant: "error",
				});
			},
		}
	);

	return (
		<Button
			variant="text"
			component={Link}
			onClick={addPaymentMethod}
			sx={{
				width: "fit-content",
			}}
		>
			Manage Payment Method
		</Button>
	);
}
