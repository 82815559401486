import { createContext, useContext, useState, useEffect } from "react";
const Context = createContext({
	id: null,
	setId: () => {},
});
export const useSelectedContent = () => useContext(Context);
export default function SelectedContentContext({ children, id: defaultID }) {
	const [id, setId] = useState(defaultID);
	useEffect(() => {
		setId(defaultID);
	}, [defaultID]);

	return (
		<Context.Provider value={{ id, setId }}>{children}</Context.Provider>
	);
}
