import { Typography, Card, CardActionArea } from "@mui/material";

import { useUploadType } from "../../../../../Upload";
import CloudUpload from "@mui/icons-material/CloudUpload";
import useDropboxImport from "../../../../../Dropbox/useDropbox";

const ButtonBase = ({ onClick, label, Icon }) => {
	return (
		<>
			<Card
				sx={{
					border: "1px dashed #ccc",
					marginY: "1rem",
					marginX: ".4rem",
				}}
			>
				<CardActionArea
					onClick={() => onClick()}
					sx={{
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						paddingY: "1.4rem",
					}}
				>
					<Typography
						variant="button"
						sx={{
							textTransform: "inherit",
							fontWeight: "inherit",
						}}
					>
						{label}
					</Typography>
					{Icon}
				</CardActionArea>
			</Card>
		</>
	);
};
export const DropboxImportButton = () => {
	const { importContent, Icon } = useDropboxImport();
	return (
		<ButtonBase
			label={"Import from Dropbox"}
			onClick={importContent}
			Icon={<Icon />}
		/>
	);
};
export default function UploadButton({ type }) {
	const { uploadType } = useUploadType();
	return (
		<ButtonBase
			label={"Upload a file"}
			onClick={() => {
				uploadType(type);
			}}
			Icon={<CloudUpload />}
		/>
	);
}
