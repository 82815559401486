import { AppBar, Toolbar, Typography, Box, Stack } from "@mui/material";
import WorkspaceInfoButton from "./InfoButton";
import WorkspaceSelector from "./Selector";
import { useRequester } from "@/Apollo";
import AddNewWorkspaceButton from "./AddNewButton";
import MatchIconButton from "./MatchIconButton";
import { useContentAdded } from "@/Component/ContentAdded";
export default function WorkspaceStatusBar() {
	const { Widget: ContentAddedWidget } = useContentAdded();
	const { definitions, useQuery, workspace_id } = useRequester();
	const { data } = useQuery(definitions.workspace.query.getWorkspace, {
		variables: {
			id: workspace_id,
		},
	});
	const { data: userData } = useQuery(definitions.user.query.getUser);
	if (!data || !userData) return null;

	const workspace = data?.workspace;
	const user = userData?.user;

	const assistant = workspace?.assistants.find(
		assistant => assistant.user_id === user.id
	);
	const role = assistant?.roles[0]?.name || "Member";

	return (
		<>
			<AppBar
				position="static"
				variant="elevation"
				sx={{
					height: "var(--workspace-toolbar-height)",
				}}
			>
				<Toolbar>
					<WorkspaceSelector />

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
						}}
					>
						<Typography variant="overline" component="div">
							{workspace?.name}
						</Typography>
						<Typography
							sx={{
								margin: 0,
								marginBottom: 0.5,
								lineHeight: 0,
								fontStyle: "italic",
							}}
							variant="caption"
							component="div"
						>
							{workspace?.isAdmin ? "👑Admin" : role}
						</Typography>

						<Stack direction="row">
							<WorkspaceInfoButton />
							<AddNewWorkspaceButton />
						</Stack>
					</Box>

					<Box
						sx={{
							flexGrow: 1,
						}}
					/>

					<Stack spacing={1} direction="row">
						<MatchIconButton />
						<ContentAddedWidget />
					</Stack>
				</Toolbar>
			</AppBar>
		</>
	);
}
