import { useSnackbar } from "notistack";
import { useRequester } from "../../../../../Apollo";
export default function useRetrySubmission() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation } = useRequester();
	const [mutate] = useMutation(definitions.submissions.mutation.retry);
	return {
		retry: async ({ id }) => {
			await mutate({
				variables: {
					input: {
						submissionId: id,
					},
				},
				onCompleted: () => {
					let key = enqueueSnackbar("Submission Retried", {
						variant: "success",
						onClick: () => closeSnackbar(key),
					});
				},
				onError: error => {
					let key = enqueueSnackbar(error.message, {
						variant: "error",
						onClick: () => closeSnackbar(key),
					});
				},
			});
		},
	};
}
