import {
	Box,
	Grid,
	Card,
	CardMedia,
	Typography,
	IconButton,
	CardActionArea,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import ViewDetailsIcon from "@mui/icons-material/ManageSearch";

import { useMultiselectContent } from "../../../../../../MultiselectContent";
import useLibraryMediaQuery from "../../../../useIsWidescreen";
import formatSeconds from "../../../../../../util/formatSeconds";
import { useSelectedContent } from "../../../../../../Component/SelectedContentContext";
import useMobileContextMenu from "../../../../../../util/useMobileContextMenu";

import FullScreenPreview from "../../../../../../Component/FullScreenPreview";

const CardWrapper = ({ children, content }) => {
	// multi-select context causes each card to re-render on select
	// so we wrap the card body in order to keep re-renders minimal
	const { toggleSelect, selectListFromID, setContextMenuEvent } =
		useMultiselectContent();
	const mobileContextMenuHandlers = useMobileContextMenu({
		onLongPress: e => setContextMenuEvent(e, content),
	});
	return (
		<Card
			sx={{
				width: 300,
				maxWidth: "90vw",
				position: "relative",
			}}
			onContextMenu={e =>
				setContextMenuEvent({
					event: e,
					content,
					open: true,
				})
			}
			onClick={e => {
				if (e.shiftKey) return selectListFromID(content.id);
				toggleSelect(content);
			}}
			{...mobileContextMenuHandlers}
		>
			{children}
		</Card>
	);
};
export default function CompactViewHit({ content }) {
	const { setId } = useSelectedContent();
	const { isXXL } = useLibraryMediaQuery();
	const thumbnailPath = content.source.thumbnailUrl;
	const filePath = content.source.url;

	let type = "IMAGE";
	if (content?.source?.mimetype?.includes("video")) type = "VIDEO";

	return (
		<Grid
			id={`content-list-${content.id}`}
			component="li"
			key={content.id}
			item
			xs={4}
			md={3}
			xl={isXXL ? 1 : 2}
			sx={{
				paddingY: ".5rem",
				paddingX: ".25rem",
				// remove li bullet points
				listStyle: "none",
				display: "flex",
				justifyContent: "center",
				position: "relative",
				width: 300,
				maxWidth: "50vw",
			}}
		>
			<CardWrapper content={content}>
				<div
					style={{
						position: "relative",
						zIndex: 1,
					}}
				>
					<span
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							// zIndex: 999,
						}}
					>
						<FullScreenPreview src={filePath} type={type} />
					</span>
					<Box
						sx={{
							position: "absolute",
							top: 0,
							right: 0,
							backgroundColor: theme =>
								theme.palette.background.default,
						}}
					>
						<IconButton
							onClick={e => {
								e.stopPropagation();
								setId(content.id);
							}}
						>
							<ViewDetailsIcon />
						</IconButton>
					</Box>

					{type === "VIDEO" && (
						<span
							style={{
								pointerEvents: "none",
							}}
						>
							<Box
								sx={{
									border: "1px solid #red",
									borderRadius: "50%",

									position: "absolute",
									// CENTER
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									// END CENTER
									display: "flex",
									justifyContent: "center",
									alignItems: "center",

									width: "3rem",
									height: "3rem",
									backgroundColor: theme =>
										theme.palette.background.default,
									// background alpha
									opacity: 0.8,
								}}
							>
								<VideoIcon
									sx={{
										borderRadius: "50%",
										backgroundColor: theme =>
											theme.palette.background.default,
									}}
								/>
							</Box>
							<Box
								sx={{
									position: "absolute",
									bottom: 0,
									right: 0,
									backgroundColor: theme =>
										theme.palette.background.default,
									opacity: 0.8,
								}}
							>
								<Typography>
									{formatSeconds(content?.source?.duration)}
								</Typography>
							</Box>
						</span>
					)}

					<CardMedia
						component="img"
						height="140"
						image={thumbnailPath}
						alt={content.title}
						sx={{
							objectFit: "contain",
							pointerEvents: "none",
							touchAction: "none",
						}}
					/>
				</div>
				<CardActionArea
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						padding: ".5rem",
						width: "100%",
					}}
					disableTouchRipple
				>
					<Typography
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						}}
					>
						{content.title}
					</Typography>
				</CardActionArea>
			</CardWrapper>
		</Grid>
	);
}
