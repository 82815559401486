import DarkModeSettings from "./DarkMode";
import TakeDownPiracySettings from "./TakeDownPiracy";
import ToastPermissions from "./Toast";

export default function Settings() {
	return (
		<>
			<TakeDownPiracySettings />
			<DarkModeSettings />
			<ToastPermissions />
		</>
	);
}
