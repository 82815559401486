import { Divider } from "@mui/material";
export default function CustomDivider() {
	return (
		<Divider
			variant="fullWidth"
			sx={{
				marginTop: "1rem",
			}}
		/>
	);
}
