import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Stack,
	TextField,
} from "@mui/material";
import { usePublishContentForm } from "../..";
import { useSnackbar } from "notistack";
import NextButton from "../../NextButton";
export default function OnlyfansOptions({
	disabled = false,
	hideNext = false,
}) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const {
		onlyfans: {
			description,
			setDescription,
			dm_recipient,
			setDm_recipient,
			fundraising_target,
			setFundraising_target,
			setVault,
			vault,
		},
		nextStep,
	} = usePublishContentForm();
	return (
		<FormControl
			component={"form"}
			fullWidth
			sx={{
				width: "100%",
				height: "100%",
				paddingTop: 2,
			}}
			onSubmit={e => {
				e.preventDefault();
				nextStep();
			}}
		>
			<Stack spacing={1}>
				<TextField
					type="text"
					value={description}
					onChange={e => setDescription(e.target.value)}
					disabled={disabled}
					placeholder="Description"
				/>
				<TextField
					type="text"
					value={dm_recipient}
					onChange={e => setDm_recipient(e.target.value)}
					disabled={disabled}
					placeholder="DM Recipient"
				/>
				<FormControlLabel
					control={
						<TextField
							type="number"
							value={fundraising_target}
							onChange={e =>
								setFundraising_target(e.target.value)
							}
						/>
					}
					labelPlacement="top"
					label="Fundraising Target"
					disabled={disabled}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={vault}
							onChange={e => setVault(e.target.checked)}
						/>
					}
					labelPlacement="start"
					label="Send to Vault"
					disabled={disabled}
				/>
			</Stack>
			{!hideNext && <NextButton />}
		</FormControl>
	);
}
