import { useClearFilters } from "@/ContentSearch/hooks/useClearFilters";
import { useActiveFilters } from "@/ContentSearch/hooks/useActiveFilters";
import { Button } from "@mui/material";
export default function ClearFiltersButton() {
	const clear = useClearFilters();
	const activeFilters = useActiveFilters();
	return (
		<Button
			disabled={!activeFilters.length}
			fullWidth
			variant={"outlined"}
			color="info"
			onClick={clear}
		>
			Clear Filters
		</Button>
	);
}
