import { useRequester } from "../../../Apollo";
import { useMultiselectContent } from "../..";
import { useSnackbar } from "notistack";
export default function useApproveMultiple() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { selected, getSelection } = useMultiselectContent();
	const { definitions, useMutation } = useRequester();
	const [approveMultiple] = useMutation(
		definitions.common.mutation.approvalStatus
	);
	return {
		setApprovedMultiple: async shouldSetApproved => {
			await approveMultiple({
				variables: {
					input: {
						options: getSelection().map(content => {
							return {
								id: content.id,
								status: shouldSetApproved
									? "READY"
									: "NOT_READY",
							};
						}),
					},
				},
			});
			let key = enqueueSnackbar(
				`Successfully ${
					shouldSetApproved ? "approved" : "unapproved"
				} ${selected.size} items.`,
				{
					variant: "success",
					onClick: () => closeSnackbar(key),
				}
			);

			return true;
		},
	};
}
