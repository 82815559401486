import { useContentEditor } from "../common/context";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { ASPECT_RATIOS } from "../common/constants";
export default function AspectRatioSelector() {
	const {
		variables: {
			input: { aspectRatio, timeline },
		},
		updateAspectRatio,
	} = useContentEditor();
	const trackCount = timeline.tracks.length;
	return (
		<SpeedDial
			defaultValue={aspectRatio}
			direction="up"
			ariaLabel="Aspect Ratio Selector"
			sx={{
				position: "absolute",
				bottom: 16,
				right: 16,
				zIndex: 1400 + trackCount + 1,
			}}
			icon={<AspectRatioIcon />}
		>
			{ASPECT_RATIOS.map(option => {
				return (
					<SpeedDialAction
						sx={{
							zIndex: 99999999,
						}}
						key={option}
						icon={option}
						tooltipTitle={option}
						onClick={() => updateAspectRatio(option)}
					/>
				);
			})}
		</SpeedDial>
	);
}
