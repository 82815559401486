import { createContext, useContext, useEffect, useState } from "react";
import { useRequester } from "@/Apollo";
import LoadingScreen from "../LoadingScreen";
const useAvatar = ({ entity, type = "workspace" }) => {
	const [avatarState, setAvatarState] = useState(new Date().getTime());
	if (!entity) return { src: "", updateCache: () => {} };
	const src = `https://${process.env.REACT_APP_CDN_HOST}/avatar/${type}/${entity.id}`;
	return {
		src: src + `${avatarState ? `?key=${avatarState}` : ""}`,
		updateCache: () => setAvatarState(new Date().getTime()),
	};
};
const Context = createContext({
	workspaceAvatar: "",
	userAvatar: "",
	updateCacheWorkspace: () => {},
	updateCacheUser: () => {},
});
export const useAvatarCacheContext = () => {
	return useContext(Context);
};

export default function AvatarCacheContext({ children }) {
	const { definitions, useQuery, workspace_id } = useRequester();
	const { data: workspaceData, loading: workspaceLoading } = useQuery(
		definitions.workspace.query.getWorkspace,
		{
			fetchPolicy: "cache-and-network",
			variables: {
				id: workspace_id,
			},
		}
	);

	const { data: userData, userLoading } = useQuery(
		definitions.user.query.getUser,
		{
			fetchPolicy: "cache-and-network",
		}
	);

	const { src: workspaceAvatar, updateCache: updateCacheWorkspace } =
		useAvatar({
			entity: workspaceData?.workspace,
			type: "workspace",
		});
	const { src: userAvatar, updateCache: updateCacheUser } = useAvatar({
		entity: userData?.user,
		type: "user",
	});

	return (
		<Context.Provider
			value={{
				workspaceAvatar,
				updateCacheWorkspace,
				userAvatar,
				updateCacheUser,
			}}
		>
			<LoadingScreen
				loading={workspaceLoading || userLoading}
				message={`fetching avatars`}
			/>
			{children}
		</Context.Provider>
	);
}
