import { Box, Typography } from "@mui/material";
import { useContentEditor } from "../../..";
import ContentView from "../Content";
import WatermarkView from "../Watermark";
import BumpersView from "../Bumpers";
import AudioView from "../AudioOverlay";
import ProjectView from "../Project";
function TabPanel({ children, value, index }) {
	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				pointerEvents: value === index ? "all" : "none",
				opacity: value === index ? 1 : 0,
				width: "100%",
				height: "100%",
				transition: "opacity .2s ease-in-out",
			}}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			<>{children}</>
		</div>
	);
}

export default function ActionsView() {
	const { onView } = useContentEditor();

	return (
		<Box
			className="options-scroller"
			sx={{
				overflowY: "auto",
				padding: ".2rem",
				paddingBottom: "4rem",
				position: "relative",
				height: "100%",
			}}
		>
			<TabPanel value={onView} index={"content"}>
				<ContentView />
			</TabPanel>
			<TabPanel value={onView} index={"watermark"}>
				<WatermarkView />
			</TabPanel>
			<TabPanel value={onView} index={"bumpers"}>
				<BumpersView />
			</TabPanel>
			<TabPanel value={onView} index={"audio"}>
				<AudioView />
			</TabPanel>
			<TabPanel value={onView} index={"project"}>
				<ProjectView />
			</TabPanel>
		</Box>
	);
}
