import { gql } from "@apollo/client";

export const create = gql`
	mutation ($input: CreateWorkspaceInput!) {
		createWorkspace(input: $input) {
			workspace {
				id
				name
				createdAt
				updatedAt
			}
		}
	}
`;

export const update = gql`
	mutation ($input: UpdateWorkspaceInput!) {
		updateWorkspace(input: $input) {
			workspace {
				avatar {
					id
					url
					thumbnailUrl
				}
				id
				name
				updatedAt
			}
		}
	}
`;
