import { useRequester } from "../../../../../../Apollo";
import { useSelectedContent } from "../../../../../SelectedContentContext";
import { useSnackbar } from "notistack";
export default function useMoveToFolder() {
	const { id } = useSelectedContent();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation } = useRequester();
	const [mutate] = useMutation(definitions.content.mutation.folderAddContent);
	return {
		moveToFolder: async folderId => {
			return mutate({
				variables: {
					input: {
						contentIds: [id],
						folderId,
					},
				},
				onCompleted: data => {
					let key = enqueueSnackbar(
						`Moved content to folder "${data.folderAddContent.folder.name}"`,
						{
							variant: "success",
							onClick: () => {
								closeSnackbar(key);
							},
						}
					);
				},
				onError: () => {
					let key = enqueueSnackbar(
						"Failed to move content into folder",
						{
							variant: "error",
							onClick: () => {
								closeSnackbar(key);
							},
						}
					);
				},
			});
		},
	};
}
