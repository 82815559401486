import deriveAssetTimeOptions from "./deriveTimeOptions";
import { TimelineTrack, TimelineAsset } from "../Definitions";

export const parseMontageToTimeline = timelineInput => {
	const tracks = timelineInput.tracks.map((track, i) => {
		return new TimelineTrack({
			id: `${track.assetType}-${i}`,
			assetType: track.assetType,
			name: track.name,
			actions: track.assets.map((asset, i) => {
				delete asset.prev; // remove prev from asset to avoid circular reference
				switch (asset.assetType) {
					case "CONTENT":
						return new TimelineAsset({
							start: asset.start,
							minStart: track.assets[i - 1]?.end || 0,
							maxEnd: track.assets[i + 1]?.start || null,
							end: asset.end,
							movable: true,
							flexible: true,
							timeline: asset.file?.timelineThumbnailUrls || [
								asset.file?.thumbnailUrl,
							],
							file: asset.file,
							prev: asset,
							data: asset,
							selected: asset.selected,
							id: `${asset.trackIndex}-${asset.assetIndex}`,
							trim: asset.trim,
							transition: asset.transition,
						});
					case "WATERMARK":
						return new TimelineAsset({
							id: `${asset.trackIndex}-${asset.assetIndex}`,
							minStart: 0,
							start: asset.start,
							end: asset.end,
							movable: true,
							flexible: true,
							timeline: [asset.file?.thumbnailUrl],
							file: asset.file,
							prev: asset,
							data: asset,
							selected: asset.selected,
							trim: asset.trim,
							transition: asset.transition,
						});
					case "AUDIO":
						return new TimelineAsset({
							id: `${asset.trackIndex}-${asset.assetIndex}`,
							minStart: 0,

							start: asset.start,
							end: asset.end,
							movable: true,

							flexible: true,
							timeline: [asset.file?.thumbnailUrl],
							file: asset.file,
							prev: asset,
							data: asset,
							selected: asset.selected,
							trim: asset.trim,
							transition: asset.transition,
						});
					case "BUMPER":
						return new TimelineAsset({
							id: `${asset.trackIndex}-${asset.assetIndex}`,
							minStart: 0,
							start: asset.start,
							end: asset.end,
							movable: true,
							flexible: true,
							timeline: [asset.file?.thumbnailUrl],
							file: asset.file,
							prev: asset,
							data: asset,
							selected: asset.selected,
							trim: asset.trim,
							transition: asset.transition,
						});
					default:
						break;
				}
			}),
		});
	});
	return tracks;
};
export const formatTracks = data => {
	// convert timeline video tracks to match content api formatting

	const newTracks = data.map((track, i) => {
		const actions = track.actions;
		const assets = actions.map((action, i) => {
			const { start, end, trim } = deriveAssetTimeOptions({
				start: action.start,
				end: action.end,
				fileDuration: action.data.file.duration,
				prevEnd: action.prev.end,
				prevStart: action.prev.start,
				prevTrim: action.prev.trim,
				mimeType: action.data.file.mimetype,
			});
			return {
				...action.data,
				start,
				end,
				trim,
				selected: action.selected,
				assetType: action.data.assetType,
			};
		});

		return {
			id: track.id,
			name: track.name,
			assets,
			assetType: track.assetType,
		};
	});
	return newTracks;
};
