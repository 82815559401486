import { useCookies } from "../../../../Cookies";
import { useSnackbar } from "notistack";
export default function useOnlyFansExtension() {
	const cookies = useCookies();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	return {
		runExtension: async () => {
			if (typeof chrome === "undefined") {
				const key = enqueueSnackbar(
					"A chrome browser is required for OnlyFans integration",
					{
						variant: "error",
						onClick: () => closeSnackbar(key),
					}
				);
				return;
			}

			return new Promise(async resolve => {
				try {
					await window.chrome.runtime.sendMessage(
						process.env.REACT_APP_ONLYFANS_EXTENSION_ID,
						{
							token: cookies.get("token"),
							workspaceId: cookies.get("workspace"),
						},
						function (response) {
							resolve(true);
						}
					);
				} catch (e) {
					resolve(false);
				}
			});
		},
	};
}
