import { Box, Grid, Stack } from "@mui/material";
import useLibraryMediaQuery from "../../useIsWidescreen";
import RefinementAccordion from "../Refinements/RefinementAccordion";
import ContentSearchTagsFilter from "@/ContentSearch/Component/Tags";
import ContentSearchRadioGroup from "@/ContentSearch/Component/RadioGroup";
import ContentSearchSlider from "@/ContentSearch/Component/DurationSlider";
import ClearFiltersButton from "@/ContentSearch/Component/ClearFiltersButton";
import ActiveFilters from "@/ContentSearch/Component/ActiveFilters";
import RefineButton from "@/ContentSearch/Component/Refine";

import { useRecallScrollPosition } from "@/util/useRecallScrollPosition";
export default function SwipeableEdgeDrawer() {
	const { elementRef } = useRecallScrollPosition(
		"content-search-refinements-drawer"
	);
	const { isMD } = useLibraryMediaQuery();
	const Facets = [
		{
			name: "Include Tags",
			Component: () => (
				<ContentSearchTagsFilter attribute="includesTags" />
			),
		},
		{
			name: "Parent Content",
			Component: () => (
				<ContentSearchRadioGroup attribute={"isVersion"} />
			),
		},
		{
			name: "Tags",
			Component: () => <ContentSearchRadioGroup attribute={"hasTags"} />,
		},
		{
			name: "Approval Status",
			Component: () => (
				<ContentSearchRadioGroup attribute={"approvalStatus"} />
			),
		},
		{
			name: "Shared to AI Chatbot",
			Component: () => <ContentSearchRadioGroup attribute={"isShared"} />,
		},
		{
			name: "File Type",
			Component: () => <ContentSearchRadioGroup attribute={"fileType"} />,
		},
		{
			name: "Watermarked",
			Component: () => (
				<ContentSearchRadioGroup attribute={"isWatermarked"} />
			),
		},
		{
			name: "Has Versions",
			Component: () => (
				<ContentSearchRadioGroup attribute={"hasVersions"} />
			),
		},
		{
			name: "Duration",
			Component: () => <ContentSearchSlider />,
		},

		// { name: "Has Versions", Component: HasVersions },
		// { name: "Versions Count", Component: VersionsCount },
		// { name: "Submissions Count", Component: PublishedCount },
		// { name: "Created At", Component: CreatedAtFacet },
	];

	return (
		<Grid
			ref={elementRef}
			container
			sx={{
				border: theme => `1px solid ${theme.palette.divider}`,
				overflowY: "auto",
				padding: theme => theme.spacing(1),
				backgroundColor: theme => theme.palette.background.default,
				height: "100%",
				paddingTop: theme => theme.spacing(2),
				display: "flex",
				alignItems: "flex-start",
				width: !isMD ? "100%" : "18rem",
				paddingBottom: "128px",
			}}
		>
			<Stack
				spacing={2}
				sx={{
					width: "100%",
				}}
			>
				<Box
					sx={{
						width: "100%",
						display: isMD ? "flex" : "block",
						justifyContent: "space-between",
						flexDirection: !isMD ? "row" : "column",
					}}
				>
					<Box
						sx={{
							width: "100%",
							flexGrow: 1,
							padding: "1rem",
						}}
					>
						<Stack direction="column" spacing={1}>
							<RefineButton />
							<ClearFiltersButton />
						</Stack>
					</Box>
					<Box
						sx={{
							width: "100%",
							flexGrow: 1,
						}}
					>
						<ActiveFilters />
					</Box>
					<Box
						sx={{
							marginLeft: "1rem",
						}}
					>
						{/* <SortBy /> */}
					</Box>
				</Box>

				<Box>
					{Facets.map(({ name, Component }, i) => {
						return (
							<RefinementAccordion key={i} title={name}>
								<Component />
							</RefinementAccordion>
						);
					})}
				</Box>
			</Stack>
		</Grid>
	);
}
