import { useRequester } from "@/Apollo";
import WorkspaceWidgetWrapper from "../Wrapper";
export default function WorkspaceWidgetMatches() {
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.common.query.countMatches, {
		variables: {
			input: {},
		},
	});

	return (
		<WorkspaceWidgetWrapper
			title={"Duplicate Content"}
			href="/workspace/match"
			body={data?.matchesCount?.count}
		/>
	);
}
