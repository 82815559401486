import { memo } from "react";
import { Handle, Position } from "reactflow";

import { useTheme, Typography, Box } from "@mui/material";

export default memo(({ data, isConnectable }) => {
	const { label } = data;
	const theme = useTheme();
	return (
		<Box
			sx={{
				backgroundColor: theme.palette.background.default,
				borderRadius: "5px",
				padding: "1rem",
				border: theme => `1px solid ${theme.palette.primary.main}`,
			}}
		>
			<Typography
				sx={{
					color: theme => theme.palette.text.primary + " !important",
				}}
			>
				{label}
			</Typography>
			<Handle
				type="source"
				position={Position.Bottom}
				id="a"
				style={{ background: theme.palette.primary.main }}
				isConnectable={isConnectable}
			/>
		</Box>
	);
});
