import { Box, Stack, Typography } from "@mui/material";
import { BodyHeight } from "@/Layout";
import Assistants from "@/Component/VirtualAssistant/Assistants";
import RightArrowIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import InvitationButton from "@/Component/VirtualAssistant/Assistants/InvitationButton";
import { WorkspaceWrapper } from "@/Layout";
export default function PageVirtualAssistants() {
	return (
		<WorkspaceWrapper>
			<Stack spacing={2}>
				<Typography variant="h5">Virtual Assistants</Typography>
				<Link to="/workspace/assistants/roles">
					<RightArrowIcon fontSize="inherit" />
					Manage Roles And Permissions
				</Link>

				<Box
					sx={{
						width: "100%",
					}}
				>
					<InvitationButton />
				</Box>
				<Assistants />
			</Stack>
		</WorkspaceWrapper>
	);
}
