export const TOOLBAR_ACTIONS_WIDTH = 100;
export const TOOLBAR_VIEW_WIDTH = 400;
export const TOOLBAR_ACTIONS_VIEW_DEFAULT_EXPANDED = true;
export const DIALOG_DEFAULT_OPEN = false;
// TODO: make these objects and include labels for each tab
export const TRANSITION_TYPES = [
	"STATIC",
	"fade",
	"reveal",
	"wipeLeft",
	"wipeRight",
	"slideLeft",

	"slideRight",
	"slideUp",
	"slideDown",

	"carouselLeft",

	"carouselRight",
	"carouselUp",
	"carouselDown",
	"shuffleTopRight",
	"shuffleRightTop",
	"shuffleRightBottom",
	"shuffleBottomLeft",
	"shuffleLeftBottom",
	"shuffleLeftTop",
	"shuffleTopLeft",
	"zoom",
];
export const TRANSITION_SPEEDS = ["Normal", "Fast", "Slow"];
export const POSITIONS = [
	{ name: "bottom", bottom: 0, left: "50%", transform: "translateX(-50%)" },
	{ name: "bottomLeft", bottom: 0, left: 0 },
	{
		name: "bottomRight",
		bottom: 0,
		right: 0,
	},
	{
		name: "center",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	{
		name: "left",
		top: "50%",
		left: 0,
		transform: "translateY(-50%)",
	},
	{
		name: "right",
		top: "50%",

		right: 0,
		transform: "translateY(-50%)",
	},
	{ name: "top", top: 0, left: "50%", transform: "translateX(-50%)" },
	{ name: "topLeft", top: 0, left: 0 },
	{ name: "topRight", top: 0, right: 0 },
	{ name: "DYNAMIC", top: 0, left: 0, transform: "translateX(0)" },
];

export const TOOLBAR_VIEWS = [
	"project",
	"watermark",
	"audio",
	"bumpers",
	"content",
];
export const ASPECT_RATIOS = ["16:9", "9:16", "1:1", "4:5", "4:3"];
export const FITS = ["crop", "cover", "contain", "none"];
export const RESOLUTION = ["preview", "mobile", "sd", "hd", "fhd"];
export const FPS = [12, 15, 24, 23.976, 25, 29.97, 30, 48, 50, 59.94, 60];
export const QUALITY = ["low", "medium", "high"];
