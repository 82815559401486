import { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { useContentEditor } from "../../";
import formatSeconds from "../../../../util/formatSeconds";
export default function TimeRenderer() {
	const { timelineEngine } = useContentEditor();

	const [time, setTime] = useState(0);
	useEffect(() => {
		if (!timelineEngine?.current) return;
		const listener = timelineEngine.current?.listener;

		listener.on("setTimeByTick", ({ time }) => {
			setTime(time);
		});
		listener.on("afterSetTime", ({ time }) => setTime(time));
	}, [timelineEngine]);

	return (
		<Box
			sx={{
				maxWidth: "200px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "center",
			}}
		>
			<Typography
				sx={{
					color: theme => theme.palette.text.primary,
				}}
				variant="caption"
			>
				{formatSeconds(time)} seconds
			</Typography>
		</Box>
	);
}
