import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import CopyIcon from "@mui/icons-material/FileCopy";
import { useOauthClientContext } from "@/Component/Oauth/OauthClientList/Client";
import { InputAdornmentPadding } from "../Style";
export default function ClientId() {
	const { client } = useOauthClientContext();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	return (
		<TextField
			value={client.id}
			sx={{
				overflow: "visible",
				"& .MuiInputBase-root": {
					width: "fit-content",
				},

				"& .MuiInputLabel-root": {
					visibility: "visible",
				},
			}}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				readOnly: true,
				endAdornment: (
					<InputAdornment position="end">
						<InputAdornmentPadding />
						<IconButton
							onClick={async () => {
								try {
									navigator.clipboard.writeText(client.id);
									const key = enqueueSnackbar(
										"Copied to clipboard",
										{
											variant: "success",
											onClick: () => closeSnackbar(key),
										}
									);
								} catch (e) {
									const key = enqueueSnackbar(
										"Failed to copy to clipboard",
										{
											variant: "error",
											onClick: () => closeSnackbar(key),
										}
									);
								}
							}}
						>
							<CopyIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
			label="Client Id"
		/>
	);
}
