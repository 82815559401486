import UploadButton from "../UploadButton";
import WatermarkList from "./List";
import { FormLabel } from "@mui/material";
import Divider from "../Common/Divider";
import WatermarkEditor from "@/Component/WatermarkEditor";
import { useRequester } from "@/Apollo";

export default function WatermarkView() {
	const { useSubscription, definitions, client } = useRequester();
	useSubscription(definitions.assets.subscription.newWatermark, {
		shouldResubscribe: true,
		onError: error => {
			console.log("Error subscribing to new watermark", error);
		},
		onData: data => {
			console.log("new watermark data: ", data);
			client.refetchQueries({
				include: [definitions.assets.query.watermarks],
			});
		},
	});
	return (
		<>
			<UploadButton type="WATERMARK" />

			<Divider />
			<FormLabel>Text Watermarks</FormLabel>
			<WatermarkList type={"TEXT"} />
			<FormLabel>Image Watermarks</FormLabel>
			<WatermarkList type={"IMAGE"} />
			<Divider />
			<WatermarkEditor />
		</>
	);
}
