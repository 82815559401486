import { gql } from "@apollo/client";
export const WORKFLOW_CREATE = gql`
	mutation DeleteWatermark($input: DeleteWatermarkInput!) {
		deleteWatermark(input: $input) {
			isDeleted
		}
	}
`;
// export const WORKFLOW_CREATE = gql`
// mutation WorkflowCreate($input: WorkflowCreateInput!) {
//   workflowCreate(input: $input) {
//     workflow {
//       id
//       createdAt
//       updatedAt
//       title
//       description
//       submissionTemplate {
//         schedule {
//           weekday
//           hour
//           minute
//         }
//         destinations
//         config {
//           reddit {
//             integration {
//               destination
//               username
//               avatar
//               status
//               is_default

//             }
//             subreddits
//             useImgur
//             useRedgifs
//           }
//           imgur {
//             album
//             description
//             name
//             disable_audio
//           }
//           redgifs {
//             keepAudio
//             private
//             tags
//           }
//           onlyfans {
//             integration {
//               avatar
//               destination
//               is_default
//               status
//               username
//             }

//           }
//         }
//       }
//       renderTemplate {
//         bumper {
//           intro {
//             bumper {
//               id
//               name
//             }
//           }
//           outro {

//           }
//         }
//         watermark {
//           id
//           name
//           type
//           file {
//             exif {
//               gps_latitude
//               gps_longitude
//               data
//               create_date
//             }
//             assetType
//             createdAt
//             extension
//             hash
//             id
//             isProcessed
//             mimetype
//             name
//             redgifsConnection {
//               edges {

//               }
//               pageInfo {

//               }
//               totalCount
//             }
//             importFile {
//               id
//               import_id
//               file_id
//               created_at
//               completed_at
//               status
//               key
//               uploaded_at
//             }
//             size
//             url
//           }
//           position
//           transition {
//             in {
//               speed
//               type
//             }
//             out {
//               speed
//               type
//             }
//           }
//           opacity
//           status
//         }
//       }
//     }
//   }
// }
// `;
