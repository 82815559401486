import { useRequester } from "../../../../Apollo";
import { useState, useMemo, createContext, useContext } from "react";
import { useIsSSR } from "react-aria";
const Context = createContext({
	loading: true,
	folders: [],
	fetchFolderContent: async () => {},

	expandItemValues: [],
	updateExpanded: () => {},
	selectedFolder: null,
	setSelectedFolder: () => {},
	selectedId: null,
	setSelectedId: () => {},
	addNew: () => {},
	updateName: () => {},
});

export const useFolderContent = () => useContext(Context);
export default function FolderContext({ children }) {
	const isSSR = useIsSSR();
	const [selectedFolder, setSelectedFolder] = useState(null);
	const [selectedId, setSelectedId] = useState(null);
	const { definitions, useQuery, useMutation, client } = useRequester();
	const { data: foldersData, loading: foldersLoading } = useQuery(
		definitions.content.query.folders,
		{
			fetchPolicy: "cache-first",
		}
	);

	const { fetchMore: fetchContent } = useQuery(
		definitions.content.query.folder,
		{
			// using standby while in ssr will cause the server to hang
			fetchPolicy: isSSR ? "cache-only" : "standby",
			nextFetchPolicy: "cache-first",
		}
	);
	const [addFolder, { loading: addFolderLoading }] = useMutation(
		definitions.content.mutation.folderCreate
	);
	const [updateName, { loading: updateNameLoading }] = useMutation(
		definitions.content.mutation.folderUpdate
	);

	const [expandItemValues, setExpandItemValues] = useState([]);

	const buildContentObject = (content, folder) => {
		return {
			label: content.title,
			value: content.id,
			data: {
				parent: {
					label: folder.name,
					value: folder.id,
				},
			},

			children: content.type === "FOLDER" ? [] : null,
			draggable: true,
			type: "content",
			contentType: content.source.mimetype.startsWith("image")
				? "image"
				: "video",
		};
	};

	const folders = useMemo(() => {
		return [
			{
				label: "root",
				value: "0",
				loading: foldersLoading,
				type: "folder",
				children: [],
			},

			...(foldersData?.allFolders?.edges.map(({ node: folder }) => {
				const cached = client.readQuery({
					query: definitions.content.query.folder,
					variables: {
						folderId: folder.id,
					},
				});
				const cachedChildren =
					cached?.folder?.contentConnection?.edges.map(
						({ node: content }) =>
							buildContentObject(content, folder)
					) || [];

				const expand = expandItemValues.includes(folder.id);
				return {
					label: folder.name,
					value: folder.id,
					expand,
					// children: [],
					children: expand ? cachedChildren : [],
					type: "folder",
				};
			}) || []),
		];
	}, [expandItemValues, foldersData]);

	return (
		<Context.Provider
			value={{
				loading: foldersLoading,
				folders,
				fetchFolderContent: async ({ value }) => {
					if (value === "0") return;
					const { data } = await fetchContent({
						variables: {
							folderId: value,
						},
						fetchPolicy: "cache-first",
						errorPolicy: "all",
					});

					return data.folder.contentConnection?.edges.map(
						({ node: content }) =>
							buildContentObject(content, data.folder)
					);
				},
				expandItemValues,
				updateExpanded: setExpandItemValues,
				selectedFolder,
				setSelectedFolder,
				selectedId,
				setSelectedId,
				addNew: async () => {
					await addFolder({
						variables: {
							input: {
								name: "New Folder",
							},
						},
						errorPolicy: "all",
						refetchQueries: [
							{
								query: definitions.content.query.folders,
								errorPolicy: "all",
							},
						],
						awaitRefetchQueries: true,
					});
				},
				updateName: async ({ name, id }) => {
					await updateName({
						variables: {
							input: {
								name,
								id,
							},
						},
						errorPolicy: "all",
						refetchQueries: [
							{
								query: definitions.content.query.folders,
								errorPolicy: "all",
							},
						],
						awaitRefetchQueries: true,
					});
				},
			}}
		>
			{children}
		</Context.Provider>
	);
}
