import { useCallback } from "react";
import { FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { useRequester } from "@/Apollo";
import { debounce } from "lodash";
export default function RBACConfigColor({ role }) {
	const { definitions, useMutation } = useRequester();

	const [updateRoleColor] = useMutation(
		definitions.rbac.mutation.updateRoleColor
	);

	const handleUpdate = useCallback(
		debounce(color => {
			updateRoleColor({
				variables: {
					input: {
						id: role.id,
						color,
					},
				},
			});
		}, 500),
		[]
	);

	return (
		<FormControl component="fieldset">
			<FormGroup row>
				<FormControlLabel
					name="color"
					value={role.color}
					onChange={e => handleUpdate(e.target.value)}
					control={<input type={"color"} />}
					labelPlacement={"top"}
					label="color"
				/>
			</FormGroup>
		</FormControl>
	);
}
