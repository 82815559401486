import { useRef, useState, useEffect, createContext, useContext } from "react";
import { IconButton, Dialog, useMediaQuery } from "@mui/material";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useRequester } from "../../Apollo";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";
import { clamp, cloneDeep } from "lodash";

import { POSITIONS } from "./common/constants";
import ComponentsLayout from "./Layout";

import {
	TOOLBAR_VIEWS,
	TOOLBAR_ACTIONS_WIDTH,
	TOOLBAR_VIEW_WIDTH,
	TOOLBAR_ACTIONS_VIEW_DEFAULT_EXPANDED,
	DIALOG_DEFAULT_OPEN,
	TRANSITION_SPEEDS,
	TRANSITION_TYPES,
	RESOLUTION,
	QUALITY,
} from "./common/constants";
import { Asset, Track, Project, Options } from "./common/types";
import { Context } from "./common/context";
import { useCreateVersion } from "./hooks/useCreateVersion";
import Joyride from "./Joyride";
import AutoComplete, {
	ContentAutocompleteSearchProvider,
} from "../ContentAutoComplete";
import ContentSearch from "@/ContentSearch/Context";
export { useContentEditor } from "./common/context";

function ContentEditorContextProvider({ children }) {
	const { useMutation, definitions } = useRequester();
	const [updateStepProgress] = useMutation(
		definitions.user.muation.onboardingProgress
	);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { createVersion: mutateCreateVersion, loading } = useCreateVersion();

	const timelineEngine = useRef();

	const [open, setOpen] = useState(DIALOG_DEFAULT_OPEN);
	const [toolbarViewExpanded, setToolbarViewExpanded] = useState(
		TOOLBAR_ACTIONS_VIEW_DEFAULT_EXPANDED
	);
	const [onView, setOnView] = useState(TOOLBAR_VIEWS[0]);
	const [drawerOpen, setDrawerOpen] = useState(false);

	const breakpoint = useMediaQuery(theme => theme.breakpoints.down("md"));
	const handleSetOnView = view => {
		if (!TOOLBAR_VIEWS.includes(view)) return;
		setOnView(view);
	};

	const [project, setProject] = useState(new Project());
	const joyrideRef = useRef();

	useEffect(() => {
		const timer = setTimeout(() => {
			joyrideRef?.current?.run(!!open);
		}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [open]);

	const validateInput = () => {
		const notReady = (reason = "Not ready") => {
			return {
				isValid: false,
				reason,
				variables: project,
			};
		};
		if (!project.input.contentId) {
			return notReady("Missing parent content");
		}

		const fixedVariables = cloneDeep(project);

		// const aspectRatioToEnum = {
		// 	"9:16": "NINE_SIXTEEN",
		// 	"16:9": "SIXTEEN_NINE",
		// 	"1:1": "ONE_ONE",
		// 	"4:5": "FOUR_FIVE",
		// 	"4:3": "FOUR_THREE",
		// };

		// fixedVariables.input.options.aspectRatio =
		// 	aspectRatioToEnum[fixedVariables.input.options.aspectRatio];
		fixedVariables.input.timeline.tracks =
			fixedVariables.input.timeline.tracks.map(track => {
				const fitToEnum = {
					crop: "CROP",
					cover: "COVER",
					contain: "CONTAIN",
					none: "NONE",
				};
				return {
					assetType: track.assetType,
					assets: track.assets.map(asset => {
						return {
							volume: asset.volume,
							id: asset.id,
							start: asset.start,
							opacity: asset.opacity,
							scale: asset.scale,
							position: asset.position,
							assetType: asset.assetType,
							fit: fitToEnum[asset.fit],
							transition: {
								in: {
									type: asset.transition.in.type,
									speed: asset.transition.in.speed,
								},
								out: {
									type: asset.transition.out.type,
									speed: asset.transition.out.speed,
								},
							},
							trim: {
								from: asset.trim.from,
								to: asset.trim.to,
							},
						};
					}),
				};
			});
		if (!fixedVariables.input.timeline.tracks) {
			return notReady("Missing tracks");
		}
		return {
			isValid: true,
			reason: null,
			variables: fixedVariables,
		};
	};

	const [shakeIcon, setShakeIcon] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setShakeIcon(false);
		}, 3000);
	}, [shakeIcon]);

	const createNewTrack = ({ assetType, file, id, name }) => {
		let bumperType;
		if (assetType === "bumper") {
			const existingBumperTracks = project.input.timeline.tracks.filter(
				track => track.assetType === assetType
			);
			switch (existingBumperTracks.length) {
				case 0:
					bumperType = "intro";
					break;
				case 1:
					bumperType = "outro";
					break;
				default:
					enqueueSnackbar(
						"Only 1 intro and 1 ourtro bumpers are allowed.",
						{
							variant: "error",
						}
					);

					return;
			}
		}

		return new Track({
			id: uuid(),
			assetType,
			bumperType,
			name,
			assets: [
				new Asset({
					name,
					assetType,
					bumperType,

					trackIndex: project.input?.timeline?.tracks.length,
					assetIndex: 0,

					selected: false,

					volume: 1,
					opacity: assetType === "WATERMARK" ? 0.5 : 1,
					scale: 1,
					position: "center",
					id,
					start: 0,
					end: file.duration,
					trim: {
						from: 0,
						to: file.duration,
					},
					file,
					fit: "contain",
					transition: {
						in: {
							type: "STATIC",
							speed: "Normal",
						},
						out: {
							type: "STATIC",
							speed: "Normal",
						},
					},
				}),
			],
		});
	};

	useEffect(() => {
		if (!project.input.timeline.tracks.length) return;
		if (!project.input.contentId) {
			const isContentInTracks = project.input.timeline.tracks.find(
				track => track.assetType === "CONTENT"
			);
			if (isContentInTracks) {
				setProject(variables => {
					return {
						...variables,
						input: {
							...variables.input,
							contentId: isContentInTracks.assets[0].id,
						},
					};
				});
			}
		}
	}, [project.input.timeline.tracks.length]);

	const addTrack = ({ assetType, file, id, name }) => {
		const newTrack = createNewTrack({ assetType, file, id, name });
		setShakeIcon(true);
		setProject(variables => {
			return {
				...variables,

				input: {
					...variables.input,
					timeline: {
						...variables.input?.timeline,
						tracks: [
							...variables.input?.timeline?.tracks,
							newTrack,
						],
					},
				},
			};
		});
	};

	const addTracks = contentItems => {
		const tracks = contentItems.map(content => createNewTrack(content));
		setShakeIcon(true);
		setProject(variables => {
			const newTracks = [...variables.input.timeline.tracks, ...tracks];
			return {
				...variables,

				input: {
					...variables.input,
					timeline: {
						...variables.input?.timeline,
						tracks: newTracks,
					},
				},
			};
		});
	};

	return (
		<Context.Provider
			value={{
				run: (domain, run = true) => {
					return joyrideRef?.current.run(run);
				},
				setParentContentId: id => {
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								contentId: id,
							},
						};
					});
				},

				projectId: project.projectId,
				projectTitle: project.input.title,
				timelineEngine,

				open,
				setOpen,
				drawerOpen,
				setDrawerOpen,
				toolbarActionsWidth: TOOLBAR_ACTIONS_WIDTH,
				toolbarViewWidth: TOOLBAR_VIEW_WIDTH,
				toolbarTotalWidth: toolbarViewExpanded
					? TOOLBAR_ACTIONS_WIDTH + TOOLBAR_VIEW_WIDTH
					: TOOLBAR_ACTIONS_WIDTH,
				toolbarViewExpanded,
				setToolbarViewExpanded,
				toolbarViews: TOOLBAR_VIEWS,
				onView,
				setOnView: handleSetOnView,
				IconButton: () => {
					return (
						<IconButton
							onClick={() => setOpen(open => !open)}
							size="small"
							sx={{
								color: "primary.contrastText",
								// shake on new version
								animation: shakeIcon
									? "shakeBell 0.5s ease-in-out infinite"
									: "none",
								"@keyframes shakeBell": {
									"0%": {
										transform: "rotate(0deg)",
									},
									"25%": {
										transform: "rotate(10deg)",
									},
									"50%": {
										transform: "rotate(0deg)",
									},
									"75%": {
										transform: "rotate(-10deg)",
									},
									"100%": {
										transform: "rotate(0deg)",
									},
								},
							}}
						>
							<VideocamOutlinedIcon />
						</IconButton>
					);
				},
				variables: project,
				setQuality: quality => {
					if (!QUALITY.includes(quality)) return;
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								options: {
									...variables.input.options,
									quality,
								},
							},
						};
					});
				},
				setResolution: resolution => {
					if (!RESOLUTION.includes(resolution)) return;
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								options: {
									...variables.input.options,
									resolution,
								},
							},
						};
					});
				},
				setFPS: fps => {
					const f = parseInt(fps);
					if (isNaN(f)) return;
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								options: {
									...variables.input.options,
									fps: clamp(f, 0, 60),
								},
							},
						};
					});
				},

				updateAspectRatio: aspectRatio => {
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								options: {
									...variables.input.options,
									aspectRatio,
								},
							},
						};
					});
				},
				updateProjectTitle: title => {
					setProject(variables => {
						return {
							...variables,
							projectTitle: title,
						};
					});
				},

				updateTitle: title => {
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								title,
							},
						};
					});
				},
				moveTrack: ({ trackIndex, direction }) => {
					if (trackIndex === 0 && direction === "up") return;
					if (
						trackIndex ===
							project.input.timeline.tracks.length - 1 &&
						direction === "down"
					)
						return;
					const newTracks = [...project.input.timeline.tracks];
					const track = newTracks.splice(trackIndex, 1)[0];
					const newIndex =
						direction === "up" ? trackIndex - 1 : trackIndex + 1;
					newTracks.splice(newIndex, 0, track);
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									...variables.input.timeline,
									tracks: newTracks.map((track, index) => {
										return {
											...track,
											assets: track.assets.map(
												(asset, assetIndex) => {
													return {
														...asset,
														trackIndex: index,
													};
												}
											),
										};
									}),
								},
							},
						};
					});
				},

				addTrack,
				addTracks,
				deleteTrack: ({ id }) => {
					const tracks = [...project.input.timeline.tracks];
					const trackIndex = tracks.findIndex(
						track => track.id === id
					);
					if (trackIndex === -1) return;

					tracks.splice(trackIndex, 1);

					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks: tracks.map((track, index) => {
										return {
											...track,
											assets: track.assets.map(
												(asset, assetIndex) => {
													return {
														...asset,
														trackIndex: index,
														assetIndex,
													};
												}
											),
										};
									}),
								},
							},
						};
					});
				},

				selectTrackAsset: ({ trackIndex, assetIndex }) => {
					const tracks = [...project.input.timeline.tracks];

					const track = tracks[trackIndex];
					const assets = track.assets;
					const asset = assets[assetIndex];
					asset.selected = !asset.selected;

					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				muteTrackAsset: ({ trackIndex, assetIndex }) => {
					const tracks = [...project.input.timeline.tracks];

					const track = tracks[trackIndex];
					const assets = track.assets;
					const asset = assets[assetIndex];
					asset.volume = asset.volume === 0 ? 1 : 0;

					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				updateTrackAssetPosition: ({
					assetIndex,
					trackIndex,
					position,
				}) => {
					return setProject(variables => {
						const tracks = [...variables.input.timeline.tracks];

						const track = tracks[trackIndex];
						const assets = track.assets;
						const asset = assets[assetIndex];

						asset.position = position;

						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				updateTrackAssetTransitionType: ({
					assetIndex,
					trackIndex,
					which,
					type,
				}) => {
					return setProject(variables => {
						const tracks = [...variables.input.timeline.tracks];

						const track = tracks[trackIndex];
						const assets = track.assets;
						const asset = assets[assetIndex];

						const transition = asset.transition[which];

						transition.type = type;

						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				updateTrackAssetTransitionSpeed: ({
					assetIndex,
					trackIndex,
					which,
					speed,
				}) => {
					return setProject(variables => {
						const tracks = [...variables.input.timeline.tracks];

						const track = tracks[trackIndex];
						const assets = track.assets;
						const asset = assets[assetIndex];

						const transition = asset.transition[which];

						transition.speed = speed;

						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				updateTrackAssetScale: ({ assetIndex, trackIndex, scale }) => {
					return setProject(variables => {
						const tracks = [...variables.input.timeline.tracks];

						const track = tracks[trackIndex];
						const assets = track.assets;
						const asset = assets[assetIndex];

						asset.scale = scale;

						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				updateTrackAssetOpacity: ({
					trackIndex,
					assetIndex,
					opacity,
				}) => {
					return setProject(variables => {
						const tracks = [...variables.input.timeline.tracks];

						const track = tracks[trackIndex];
						const assets = track.assets;
						const asset = assets[assetIndex];

						asset.opacity = opacity;

						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				updateTrackAssetFit: ({ assetIndex, trackIndex, fit }) => {
					return setProject(variables => {
						const tracks = [...variables.input.timeline.tracks];

						const track = tracks[trackIndex];
						const assets = track.assets;
						const asset = assets[assetIndex];

						asset.fit = fit;

						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				splitTrackAsset: ({ trackIndex, assetIndex, time }) => {
					setProject(variables => {
						const tracks = [...variables.input.timeline.tracks];

						const track = tracks[trackIndex];
						const assets = track.assets;
						const asset = assets[assetIndex];

						const leftAsset = {
							...asset,

							start: asset.start,
							end: time,
							trim: {
								from: asset.trim.from,
								to: asset.trim.from + (time - asset.start),
							},
						};

						const rightAsset = {
							...asset,

							start: time,
							end: asset.end,
							trim: {
								from: leftAsset.trim.to,

								to: asset.trim.to,
							},
						};
						if (asset.file.mimetype.startsWith("image")) {
							rightAsset.trim = {
								from: 0,
								to: Math.abs(rightAsset.end - rightAsset.start),
							};
						}

						track.assets = [
							...track.assets.slice(0, assetIndex),
							leftAsset,
							rightAsset,
							...track.assets.slice(assetIndex + 1),
						];
						track.assets.forEach((asset, index) => {
							asset.key = `${trackIndex}-${index}`;
							asset.trackIndex = trackIndex;
							asset.assetIndex = index;
						});

						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},
				removeAssetFromTrack: ({ trackIndex, assetIndex }) => {
					setProject(variables => {
						const tracks = [...variables.input?.timeline?.tracks];
						const track = tracks[trackIndex];
						const assets = track.assets;

						const updatedAssets = assets.reduce(
							(acc, asset, index) => {
								if (index === assetIndex) return acc;
								if (index > assetIndex) {
									asset.assetIndex = index - 1;
								}
								return [...acc, asset];
							},
							[]
						);

						const updatedTrack = {
							...track,
							assets: updatedAssets,
						};

						const newTracks = tracks.reduce((acc, t, index) => {
							let track = t;
							if (index === trackIndex) track = updatedTrack;

							// if track assetType is CONTENT and track is empty, remove track
							if (
								// track.assetType === "CONTENT" &&
								track.assets.length === 0
							) {
								return acc;
							} else {
								return [...acc, track];
							}
						}, []);

						if (
							newTracks.length <
							variables.input.timeline.tracks.length
						) {
							// ensure keys are correct in case of removal
							newTracks.forEach((track, index) => {
								track.assets.forEach((asset, assetIndex) => {
									asset.key = `${index}-${assetIndex}`;
									asset.trackIndex = index;
									asset.assetIndex = assetIndex;
								});
							});
						}

						const newVariables = {
							...variables,
							input: {
								...variables.input,
								timeline: {
									...variables.input?.timeline,
									tracks: newTracks,
								},
							},
						};
						return newVariables;
					});
				},
				updateTimeline: tracks => {
					setProject(variables => {
						return {
							...variables,
							input: {
								...variables.input,
								timeline: {
									tracks,
								},
							},
						};
					});
				},

				validateInput,
				loading,
				transitionTypes: TRANSITION_TYPES,
				transitionSpeeds: TRANSITION_SPEEDS,
				positions: POSITIONS,
				newProjectId: (id = uuid()) => {
					setProject(variables => {
						return {
							...variables,
							projectId: id,
						};
					});
				},
				loadProject: project => {
					setProject(
						new Project({
							...project,
							projectId: project.id,
							input: {
								title: project.title,
								contentId: project.content.id,
								options: new Options(project.options),
								timeline: {
									tracks: project.timeline.tracks.map(
										(track, trackIndex) => {
											return new Track({
												id: uuid(),
												name: track.name,
												assetType: track.assetType,
												assets: track.assets.map(
													(asset, assetIndex) => {
														return new Asset({
															assetIndex,
															trackIndex,
															assetType:
																track.assetType,
															bumperType:
																asset.bumperType,
															start: asset.start,
															end:
																asset.start +
																Math.abs(
																	asset.trim
																		.to -
																		asset
																			.trim
																			.from
																),
															file: asset.file,
															id: asset.id,
															name: asset.name,
															opacity:
																asset.opacity,
															position:
																asset.position,
															scale: asset.scale,
															selected: false,
															transition:
																asset.transition,
															trim: asset.trim,
															volume: asset.volume,
															fit: asset.fit,
														});
													}
												),
											});
										}
									),
								},
							},
						})
					);
				},
				createVersion: () => {
					const {
						isValid,
						reason,
						variables: fixedVariables,
					} = validateInput(project);
					if (!isValid) {
						let key = enqueueSnackbar(reason, {
							variant: "error",
							onClick: () => {
								closeSnackbar(key);
							},
						});
						return;
					}

					mutateCreateVersion(fixedVariables);
				},
				joyride: {
					ref: joyrideRef,
					run: () => joyrideRef.current.run(),
					resetProgress: async domain => {
						// this will automatically reset the apollo client
						// cache and the domain joyride component will re-render
						// it will NOT show the component if show is currently set to false (show must be called separately)
						await updateStepProgress({
							variables: {
								input: {
									domain: "CONTENT_EDITOR",
									progress: 0,
								},
							},
						});
					},
					nextStep: () => {
						if (!joyrideRef.current) return;
						joyrideRef.current.next();
					},
				},
				mobile: breakpoint,
				restartProject: () => {
					setProject(new Project());
				},
			}}
		>
			<Joyride key={breakpoint} />
			<Dialog open={open} fullScreen>
				<ComponentsLayout />
			</Dialog>
			{children}
		</Context.Provider>
	);
}
const AutoCompleteContext = createContext({
	setOnSelectContent: () => {},
	VideoSearch: <></>,
	ImageSearch: <></>,
});
export const useAutoComplete = () => useContext(AutoCompleteContext);
export default function ContentEditor({ children }) {
	const [onSelectContent, setOnSelectContent] = useState(() => {});

	const VideoSearch = (
		<ContentSearch
			defaultLimit={25}
			defaultFilters={{
				fileType: "VIDEO",
			}}
		>
			<AutoComplete
				onSelect={onSelectContent}
				label="Search for a video"
			/>
		</ContentSearch>
	);
	const ImageSearch = (
		<ContentSearch
			defaultLimit={25}
			defaultFilters={{
				fileType: "IMAGE",
			}}
		>
			<AutoComplete
				onSelect={onSelectContent}
				label="Search for an image"
			/>
		</ContentSearch>
	);

	return (
		<AutoCompleteContext.Provider
			value={{
				VideoSearch,
				ImageSearch,
				setOnSelectContent: cb => setOnSelectContent(() => cb),
			}}
		>
			<ContentEditorContextProvider>
				{children}
			</ContentEditorContextProvider>
		</AutoCompleteContext.Provider>
	);
}
