import React, { useCallback } from "react";
import { Menu, MenuList, MenuItem, ListItemIcon } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useReactFlow } from "reactflow";

export default function ContextMenu({ nodeId, anchorPosition, onClose, open }) {
	const { setNodes, setEdges, deleteElements } = useReactFlow();

	const deleteNode = useCallback(() => {
		setNodes(nodes => nodes.filter(node => node.id !== nodeId));
		setEdges(edges => {
			// remove all edges that have the nodeId as a target OR source
			return edges.filter(
				edge => edge.target !== nodeId && edge.source !== nodeId
			);
		});
		onClose();
	}, [nodeId, setNodes, setEdges]);

	return (
		<Menu
			anchorReference="anchorPosition"
			anchorPosition={anchorPosition}
			open={open}
			onClose={onClose}
		>
			<MenuList>
				<MenuItem disabled={nodeId === "0"} onClick={deleteNode}>
					<ListItemIcon>
						<DeleteIcon />
					</ListItemIcon>
					Delete Node
				</MenuItem>
			</MenuList>
		</Menu>
	);
}
