import {
	Avatar,
	DialogContent,
	DialogActions,
	IconButton,
	Box,
	Card,
	CardMedia,
	DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitIcon from "@mui/icons-material/ExitToApp";
import { useState } from "react";

import {
	ImageListItem,
	ImageListItemBar,
	ImageList,
	Dialog,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Visibility";
import ContextMenu from "../ContextMenu";
import EllipsesIcon from "@mui/icons-material/MoreVert";

const IMG_WIDTH = 120;
const IMG_WIDTH_PX = `${IMG_WIDTH}px`;
const IMG_HEIGHT = 120;
const IMG_HEIGHT_PX = `${IMG_HEIGHT}px`;

export const AssetList = ({ assets, onClick, onDelete }) => {
	const [contextMenuOptions, setContextMenuOptions] = useState({
		event: null,
		options: [],
	});
	const [showPreview, setShowPreview] = useState(null);
	return (
		<>
			<Dialog
				fullScreen
				open={!!showPreview}
				sx={{
					zIndex: 1600,
				}}
			>
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					{showPreview?.name || "Untitled"}
					<IconButton
						onClick={() => {
							setShowPreview(null);
						}}
					>
						<ExitIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<CardMedia
						security="true"
						sx={{
							height: "100%",
							objectFit: "contain",
						}}
						component={
							showPreview?.file?.mimetype?.includes("video")
								? "video"
								: "img"
						}
						src={showPreview?.file?.url}
						alt={showPreview?.name}
						controls
					/>
				</DialogContent>
			</Dialog>
			<ContextMenu
				clickEvent={contextMenuOptions.event}
				options={[
					{
						endIcon: (
							<Avatar
								sx={{
									marginRight: "0.5rem",
								}}
								src={contextMenuOptions.asset?.file?.url}
							/>
						),

						label: contextMenuOptions.asset?.name || "Untitled",
					},
					{
						label: "Delete",
						endIcon: <DeleteIcon />,

						onClick: async () => {
							await onDelete(contextMenuOptions.asset);
							setContextMenuOptions({
								event: null,
								options: [],
							});
						},
					},
					{
						label: "Preview",
						endIcon: <PreviewIcon />,

						onClick: async () => {
							setShowPreview(contextMenuOptions.asset);
							setContextMenuOptions({
								event: null,
								options: [],
							});
						},
					},
				]}
			/>
			<ImageList
				cols={3}
				sx={{
					paddingX: "6px",
				}}
			>
				<>
					{assets.map(({ node: asset }, i) => {
						return (
							<ImageListItem key={asset.id}>
								<img
									style={{
										width: IMG_WIDTH_PX,
										height: IMG_HEIGHT_PX,
									}}
									src={asset?.file?.thumbnailUrl}
									alt={asset.name}
									loading="lazy"
									onClick={() => onClick(asset)}
								/>
								<ImageListItemBar
									title={asset.name || "Untitled"}
									actionIcon={
										<IconButton
											onClick={e => {
												e.preventDefault();
												setContextMenuOptions({
													event: e,
													asset,
												});
											}}
										>
											<EllipsesIcon />
										</IconButton>
									}
								/>
							</ImageListItem>
						);
					})}
				</>
			</ImageList>
		</>
	);
};
