import React, { useEffect, useState, useRef, useMemo } from "react";
import { Box } from "@mui/material";
import usePlayerOptions from "../hooks/usePlayerOptions";
import usePositions from "../hooks/usePositions";
import { useContentEditor } from "@/Component/ContentEditor";

const MediaPlayer = ({
	type,
	url,
	playing,
	startFrom,
	fit,
	endAt,
	startAt,
	width,
	height,
	scale,
	position,
	asset,
}) => {
	const { positions } = usePositions();
	const foundPosition = positions.find(({ name }) => name === position);
	const { top, left, right, bottom, transform } = foundPosition || {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		transform: ``,
	};

	const ref = useRef(null);
	useEffect(() => {
		if (type === "image") return;
		ref.current.currentTime = startFrom;
	}, [startFrom]);

	useEffect(() => {
		if (type === "image") return;
		if (playing) {
			ref.current.play();
		} else {
			ref.current.pause();
		}
	}, [playing]);

	if (type === "audio") return <audio ref={ref} src={url} />;
	if (type === "video")
		return (
			<video
				style={{
					width: `${scale * 100}%`,
					height: `${scale * 100}%`,
					top,
					left,
					right,
					bottom,
					transform,
					objectFit: fit,
					position: "absolute",
				}}
				ref={ref}
				src={url}
			/>
		);
	if (type === "image")
		return (
			<img
				style={{
					width: `${scale * 100}%`,
					height: `${scale * 100}%`,
					top,
					left,
					right,
					bottom,
					transform,
					objectFit: fit,
					position: "absolute",
				}}
				src={url}
				alt={"timeline preview"}
			/>
		);
};
const Wrapper = ({ children }) => {
	const { width, height } = usePlayerOptions();

	return (
		<Box
			sx={{
				width: "100%",
				// maxWidth: `${1920 * 0.5}px`,
				// maxHeight: `${1080 * 0.5}px`,
				// maxHeight: getHeight(),
				maxWidth: width,
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "transparent",
				// maxHeight: "400px",
				maxHeight: height,
				objectFit: "contain",
				overflow: "hidden",
				border: "1px solid #ccc",
				position: "relative",
			}}
		>
			{children}
		</Box>
	);
};
export default function ReactPlayerPreview() {
	const { isPlaying, playerOptions } = usePlayerOptions();
	return (
		<Wrapper>
			{playerOptions.map(
				({
					url,
					startFrom,
					endAt,
					type,
					zIndex,
					opacity,
					key,
					fit,
					scale,
					position,
					asset,
				}) => {
					return (
						<Box
							key={key}
							sx={{
								width: "100%",
								height: "100%",
								top: 0,
								left: 0,
								flexGrow: 1,
								position: "absolute",
								backgroundColor: "transparent",
								zIndex,
								opacity,
							}}
						>
							<MediaPlayer
								url={url}
								startFrom={startFrom}
								endAt={endAt}
								playing={isPlaying}
								type={type}
								fit={fit}
								scale={scale}
								position={position}
								asset={asset}
							/>
						</Box>
					);
				}
			)}
		</Wrapper>
	);
}
