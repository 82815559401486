import React from "react";
import { useNotifications } from "@/Notifications";
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	OutlinedInput,
	Checkbox,
	ListItemText,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export default function WorkspaceLogsEntityTypeFilter() {
	const { entityTypeFilter, setEntityTypesFilter, entityTypes } =
		useNotifications();

	return (
		<FormControl sx={{ m: 1, flexGrow: 1, maxWidth: "500px" }}>
			<InputLabel>Entity Type</InputLabel>
			<Select
				multiple
				value={entityTypeFilter || []}
				onChange={e => {
					console.log(e.target.value);
					setEntityTypesFilter(e.target.value);
				}}
				input={<OutlinedInput label="Entity Type" />}
				renderValue={selected => selected.join(", ")}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
							width: 250,
						},
					},
				}}
			>
				{entityTypes.map(entityType => (
					<MenuItem key={entityType} value={entityType}>
						<Checkbox
							checked={entityTypeFilter?.indexOf(entityType) > -1}
						/>
						<ListItemText primary={entityType} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
