import { v4 as uuid } from "uuid";

export class Options {
	constructor({
		aspectRatio = "16:9",
		quality = "medium",
		resolution = "sd",
		fps = 30,
	} = {}) {
		this.aspectRatio = aspectRatio;
		this.quality = quality;
		this.resolution = resolution;
		this.fps = fps;
	}
}
export class Input {
	constructor({
		contentId = uuid(),
		title = "New Project",
		timeline = {
			tracks: [],
		},
		options = new Options(),
	} = {}) {
		this.contentId = contentId;
		this.title = title;
		this.timeline = timeline;
		this.options = options;
	}
}
export class Project {
	constructor({
		projectId,
		input = {
			contentId: null,
			title: "New Project",
			timeline: {
				tracks: [],
			},
			options: new Options(),
		},
	} = {}) {
		this.projectId = projectId || uuid();
		this.input = input;
	}
}
export class Track {
	constructor({ id, assetType, bumperType, name, assets }) {
		this.id = id;
		this.assetType = assetType;
		this.bumperType = bumperType;
		this.name = name;
		this.assets = assets;
	}
}

export class Asset {
	constructor({
		trackIndex,
		assetIndex,
		assetType,
		bumperType,
		selected,
		volume,
		opacity,
		scale,
		position,
		id,
		start,
		end,
		file,
		trim,
		name,
		transition,
		fit = "cover",
	}) {
		this.assetIndex = assetIndex;
		this.trackIndex = trackIndex;
		this.assetType = assetType;
		this.bumperType = bumperType;
		this.end = end;
		this.file = file;
		this.id = id;
		this.name = name;
		this.opacity = opacity;
		this.position = position;
		this.scale = scale;
		this.selected = selected;
		this.start = start;

		this.volume = volume;

		this.transition = transition;

		this.trim = trim;
		this.fit = fit;
	}
}
