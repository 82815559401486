import { PublishContentDialog } from "../../../Component/Publish";
import { useMultiselectContent } from "../..";
import { useMemo } from "react";
export default function PublishMultiple() {
	const { showingPublishDialog, setShowingPublishDialog, getSelection } =
		useMultiselectContent();

	// when right clicking a single item, the menu should show up without the item selected
	// due to the way the menu is rendered, we need to keep track of the selection state
	// const selection = useMemo(() => {
	// 	if (!showingPublishDialog) return [];
	// 	return getSelection().map(s => s.id);
	// }, [showingPublishDialog]);
	const selection = !showingPublishDialog
		? []
		: getSelection().map(s => s.id);

	return (
		<PublishContentDialog
			open={!!selection.length} // will only be true when the menu is open
			onClose={() => setShowingPublishDialog(false)}
			contentIds={selection}
		/>
	);
}
