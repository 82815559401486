import { Box, Typography, Link } from "@mui/material";
export default function Discord() {
	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				textAlign: "center",
				// don't break words in the middle
				wordBreak: "keep-all",
			}}
		>
			<iframe
				title="Join the discord"
				src="https://discord.com/widget?id=1121160067312533554&theme=dark"
				width="350"
				height="500"
				allowtransparency="true"
				frameBorder="0"
				sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
			/>

			<Box
				sx={{
					marginTop: 6,
				}}
			>
				<Typography
					component="h2"
					sx={{
						marginBottom: 3,
						fontWeight: "bold",
						fontSize: "2rem",
					}}
				>
					Join our discord to get the latest updates and
					announcements.
				</Typography>
				<Typography
					component="h2"
					sx={{
						marginBottom: 3,
						fontWeight: "bold",
						fontSize: "1.2rem",
					}}
				>
					The first 50 users to report a bug or suggest a feature will
					receive a permanent{" "}
					<span
						style={{
							color: "#EE82EE",
							wordSpacing: "-.2rem",
							backgroundColor: "#000",
							padding: "5px",

							paddingLeft: "1rem",
							paddingRight: "1rem",

							borderRadius: "25px",

							whiteSpace: "nowrap",
						}}
					>
						Beta&nbsp;Tester
					</span>{" "}
					role.
				</Typography>
				<Typography>
					<Link
						color="inherit"
						target="_blank"
						href="https://discord.gg/gU4qgQyGbS"
					>
						https://discord.gg/gU4qgQyGbS
					</Link>
				</Typography>
			</Box>
		</Box>
	);
}
