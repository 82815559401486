import { Link } from "react-router-dom";
import { IconButton, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SignInIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import SignOutIcon from "@mui/icons-material/Logout";
import AuthenticatedAvatar from "./AccountAvatar";
import Menu, { Divider, ListItemIcon, MenuItem } from "../../Component/Menu";
import { useLocation } from "react-router-dom";
import KeyIcon from "@mui/icons-material/VpnKey";
import { useAuth0 } from "@auth0/auth0-react";
import { useRequester } from "@/Apollo";

const UserListItem = () => {
	const navigate = useNavigate();
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "cache-first",
	});
	console.log("user list item:", { data });
	const user = data?.user;

	return (
		<MenuItem onClick={() => navigate("/account")}>
			<AuthenticatedAvatar />
			{user?.publicDisplayName}
		</MenuItem>
	);
};
export default function AccountMenu() {
	const location = useLocation();
	const navigate = useNavigate();
	const [anchorEl, setMenuAnchorEl] = useState(null);
	const { user, logout, isAuthenticated, loginWithRedirect } = useAuth0();

	const handleSignIn = async ({ screen_hint }) => {
		await loginWithRedirect({
			redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
		});
		setMenuAnchorEl(null);
		navigate("/account");
	};
	const handleSetAnchorEl = e => {
		if (!anchorEl) return setMenuAnchorEl(e.currentTarget);
		setMenuAnchorEl(null);
	};

	useEffect(() => {
		if (anchorEl) setMenuAnchorEl(null);
	}, [location]);

	const SignInButton = () => {
		return (
			<Button
				variant="contained"
				disableRipple
				startIcon={<KeyIcon />}
				sx={{
					width: "fit-content",
					// fix margin collapse
					marginTop: "0px",
					marginBottom: "0px",
				}}
				onClick={() => handleSignIn({ screen_hint: "login" })}
			>
				Sign In
			</Button>
		);
	};

	if (!isAuthenticated) return <SignInButton />;

	return (
		<>
			<IconButton onClick={handleSetAnchorEl}>
				<AuthenticatedAvatar />
			</IconButton>

			<Menu
				key={user?.toString()}
				anchorEl={anchorEl}
				onClose={() => {
					setMenuAnchorEl(null);
				}}
			>
				<div>
					<div>
						{!user ? (
							<>
								<MenuItem
									onClick={() => {
										handleSignIn({ screen_hint: "login" });
									}}
								>
									<ListItemIcon>
										<SignInIcon fontSize="small" />
									</ListItemIcon>
									Sign In
								</MenuItem>
								<MenuItem
									onClick={() => {
										handleSignIn({ screen_hint: "signup" });
									}}
								>
									<ListItemIcon>
										<PersonAddIcon fontSize="small" />
									</ListItemIcon>
									Create Account
								</MenuItem>
							</>
						) : (
							<>
								<UserListItem />

								<Divider />
							</>
						)}
					</div>

					{user && (
						<>
							<Link to="/account">
								<MenuItem>
									<ListItemIcon>
										<SettingsIcon fontSize="small" />
									</ListItemIcon>
									Account
								</MenuItem>
							</Link>
							<MenuItem
								onClick={() =>
									logout({
										logoutParams: {
											returnTo:
												window.location.origin +
												"/signout",
										},
									})
								}
							>
								<ListItemIcon>
									<SignOutIcon fontSize="small" />
								</ListItemIcon>
								Sign Out
							</MenuItem>
						</>
					)}
				</div>
			</Menu>
		</>
	);
}
