import { useContentSearchFilter } from "@/ContentSearch/hooks/useContentSearchFilter";
import { Autocomplete, TextField } from "@mui/material";
import { useRequester } from "@/Apollo";

export default function ContentSearchTagsFilter({
	attribute = "includesTags",
}) {
	const { currentValue, refine } = useContentSearchFilter({
		attribute,
	});

	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.content.query.usedTags);

	return (
		<Autocomplete
			multiple
			options={(data?.usedTags?.tags || [])
				.map(tag => tag.value)
				// remove duplicates
				.filter((value, index, self) => self.indexOf(value) === index)}
			defaultValue={currentValue}
			value={currentValue}
			onChange={(_, value) => refine(value)}
			renderInput={params => (
				<TextField
					{...params}
					variant="standard"
					placeholder={
						currentValue.length > 0
							? `${currentValue.length} tags selected`
							: "Tags"
					}
				/>
			)}
		/>
	);
}
