import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
	useImperativeHandle,
	useRef,
	forwardRef,
} from "react";
import { useContentSearch } from "@/ContentSearch";
import PublishContentForm from "./hooks/publish";
import PreviewContent from "./hooks/preview";
import MultiSelectMenu from "./Menu/Actions";
import useDeleteMultiple from "./hooks/delete";
import useApproveMultiple from "./hooks/approve";
import useAddToContentEditor from "./hooks/addToContentEditor";
import AddToFolderMenu from "./hooks/addToFolder";
import useShareToChatApp from "./hooks/shareToChatApp";
import { useTheme } from "@emotion/react";
import ContextMenu from "./Menu";

const Context = createContext({
	selected: new Map(),
	getSelection: () => [],
	toggleSelect: content => {},
	setSelectedList: list => {},
	deselect: ({ id }) => {
		return [];
	},
	deselectAll: () => {},
	isSelected: ({ id }) => {
		return false;
	},
	MenuDialog: MultiSelectMenu,
	showMenu: () => {},
	showingMenu: false,
	showingAddtoFolderMenu: false,
	setShowingAddToFolderMenu: () => {},
	showingPublishDialog: false,
	setShowingPublishDialog: () => {},
	showingPreviewDialog: false,
	setShowingPreviewDialog: () => {},
	ContextMenu: () => <></>,
	setContextMenuEvent: () => {},
	closeContextMenu: () => {},
	selectListFromID: id => {},
});
const ActionsContext = createContext({
	deleteMultiple: async () => {},
	deselectAll: async () => {},
	setApprovedMultiple: async shouldSetApproved => {},
	addToContentEditor: async () => {},
	shareToChatApp: async () => {},
	addToFolder: async () => {},
	publish: async () => {},
	preview: async () => {},
});
const ActionsContextProvider = ({ children }) => {
	const { deleteMultiple } = useDeleteMultiple();
	const { setApprovedMultiple } = useApproveMultiple();
	const { addToContentEditor } = useAddToContentEditor();
	const { setShared } = useShareToChatApp();
	const {
		setShowingAddToFolderMenu,
		setShowingPublishDialog,
		setShowingPreviewDialog,
		closeContextMenu,
		deselectAll: deselect,
	} = useMultiselectContent();
	return (
		<ActionsContext.Provider
			value={{
				deleteMultiple: async () => {
					deleteMultiple();
					closeContextMenu();
				},
				setApprovedMultiple: async shouldSetApproved => {
					setApprovedMultiple(shouldSetApproved);
					closeContextMenu();
				},
				addToContentEditor: async () => {
					addToContentEditor();
					closeContextMenu();
				},
				addToFolder: () => {
					setShowingAddToFolderMenu(true);
					closeContextMenu();
				},
				publish: () => setShowingPublishDialog(true),
				preview: () => setShowingPreviewDialog(true),
				shareToChatApp: share => {
					setShared(share);
					closeContextMenu();
				},
				deselectAll: () => {
					deselect();
					closeContextMenu();
				},
			}}
		>
			{children}
		</ActionsContext.Provider>
	);
};

export const useMultiselectContent = () => useContext(Context);
export const useMultiselectContentActions = () => useContext(ActionsContext);
function MultiselectContentContextProvider({ children }, parentRef) {
	const { content } = useContentSearch();

	const theme = useTheme();
	const [selected, setSelected] = useState(new Map());

	const cssString = useMemo(() => {
		let css = "";

		Array.from(selected.values()).forEach(({ id }) => {
			css += `[id^="content-list-${id}"] {
				border: 1px solid ${theme.palette.action.active};

			}`;
		});
		return css;
	}, [selected]);

	const [showingMenu, setShowingMenu] = useState(false);
	const [showingAddToFolderMenu, setShowingAddToFolderMenu] = useState(false);
	const [showingPublishDialog, setShowingPublishDialog] = useState(false);
	const [showingPreviewDialog, setShowingPreviewDialog] = useState(false);
	const [shouldDeselect, setShouldDeselect] = useState(false);

	const [contextMenuEvent, setContextMenuEvent] = useState({
		event: null,
		content: null,
		open: false,
	});
	// auto-deslect content if context menu is closed and content was auto-selected on context menu event
	useEffect(() => {
		if (contextMenuEvent.open) return;
		if (shouldDeselect) {
			setSelected(new Map());
			setShouldDeselect(false);
		}
	}, [contextMenuEvent]);

	const toggleSelect = content => {
		if (selected.has(content.id)) {
			const newList = new Map(selected);
			newList.delete(content.id);
			return setSelected(newList);
		}
		const newList = new Map(selected);
		newList.set(content.id, {
			extension: content.source.extension,
			id: content.id,
			name: content.title,
			thumbnail: content.source.thumbnailUrl,
		});
		setSelected(newList);
	};

	// automatically select content if right clicked and no content is already selected
	const handleSetContextMenuEvent = useCallback(
		({ content, event, open }) => {
			event.preventDefault();
			event.stopPropagation();
			if (!selected.size) {
				toggleSelect(content);
				setShouldDeselect(true);
			}
			setContextMenuEvent({ event, open });
		},
		[contextMenuEvent, selected.size]
	);

	const closeContextMenu = () => {
		setContextMenuEvent({
			...contextMenuEvent,
			content: null,
			open: false,
		});
	};
	const setSelectedList = list => {
		setSelected(new Map(list.map(content => [content.id, content])));
	};

	const deselectAll = () => setSelected(new Map());
	const ref = useRef();
	useImperativeHandle(ref, () => ({
		deselectAll,
	}));
	useEffect(() => {
		parentRef.current = ref.current;
	}, [ref]);
	return (
		<Context.Provider
			ref={ref}
			value={{
				selected,
				getSelection: () => Array.from(selected.values()),
				deselect: ({ id }) => {
					const newList = new Map(selected);
					newList.delete(id);
					setSelected(newList);
				},
				deselectAll,
				isSelected: ({ id }) => selected.has(id),
				toggleSelect,

				setSelectedList,
				MenuDialog: MultiSelectMenu,
				showingMenu,
				showMenu: show => setShowingMenu(show),
				showingAddToFolderMenu,
				setShowingAddToFolderMenu: show =>
					setShowingAddToFolderMenu(!!show),
				showingPublishDialog,
				setShowingPublishDialog: show =>
					setShowingPublishDialog(!!show),
				showingPreviewDialog,
				setShowingPreviewDialog: show =>
					setShowingPreviewDialog(!!show),
				setContextMenuEvent: handleSetContextMenuEvent,
				closeContextMenu,
				selectListFromID: id => {
					// find index of hit with id
					const indexOfClicked = content.findIndex(
						hit => hit.id === id
					);

					// find first hit with id existing in the selected list
					let indexOfFirstSelected = 0;
					if (selected.size) {
						for (let i = 0; i < content.length; i++) {
							if (selected.get(content[i].id)) {
								indexOfFirstSelected = i;
								break;
							}
						}
					}

					// slice the hits array from the index of the clicked hit to the index of the first selected hit
					const selectedList = content
						.slice(
							Math.min(indexOfClicked, indexOfFirstSelected),
							Math.max(indexOfClicked, indexOfFirstSelected) + 1
						)
						.map(content => {
							return {
								extension: content?.file?.extension,
								id: content.id,
								name: content.title,
								thumbnail: content.source.thumbnailUrl,
							};
						});
					setSelectedList(selectedList);
				},
			}}
		>
			<style>{cssString}</style>
			<ActionsContextProvider>
				<ContextMenu
					open={contextMenuEvent.open}
					onClose={closeContextMenu}
					event={contextMenuEvent?.event}
					content={contextMenuEvent?.content}
				/>
				<AddToFolderMenu />
				<PublishContentForm />
				<PreviewContent />
				{children}
			</ActionsContextProvider>
		</Context.Provider>
	);
}
export default forwardRef(MultiselectContentContextProvider);
