import React from "react";
import { useNotifications } from "@/Notifications";
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Checkbox,
	ListItemText,
} from "@mui/material";

export default function WorkspaceLogsLevelTypeFilter() {
	const { levelsFilter, levels, setLevelsFilter } = useNotifications();

	const handleChange = event => {
		const {
			target: { value },
		} = event;
		setLevelsFilter(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	return (
		<FormControl>
			<InputLabel>Level</InputLabel>
			<Select
				variant="standard"
				sx={{
					minWidth: "100px",
					maxWidth: "100%",

					maxHeight: "100%",
				}}
				multiple
				placeholder="Level"
				value={levelsFilter}
				label="Level"
				onChange={handleChange}
				renderValue={selected => {
					// iterate selected levels and find the index of each level in the levels array
					// then map the index to the level name
					// then find the highest index and return the level name
					const highestLevel = selected
						.map(level => levels.indexOf(level))
						.sort((a, b) => b - a)[0];
					return levels[highestLevel];
				}}
			>
				{levels.map((type, i) => (
					<MenuItem
						key={i}
						selected={type === levelsFilter}
						value={type}
					>
						<Checkbox checked={levelsFilter.indexOf(type) > -1} />
						<ListItemText primary={type} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
