import { gql } from "@apollo/client";
import { VIRTUAL_ASSISTANT_FRAGMENT } from "../fragment";
export const requests = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	query Requests {
		virtualAssistantRequests {
			requests {
				...VirtualAssistantFragment
			}
		}
	}
`;
export const invitations = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	query VirtualAssistantInvitations {
		virtualAssistantInvitations {
			invitations {
				...VirtualAssistantFragment
			}
		}
	}
`;
export const assistants = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	query VirtualAssistants {
		virtualAssistants {
			assistants {
				...VirtualAssistantFragment
			}
		}
	}
`;
export const assistant = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	query VirtualAssistant($input: VirtualAssistantInput) {
		virtualAssistant(input: $input) {
			assistant {
				...VirtualAssistantFragment
			}
		}
	}
`;
