import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Dialog,
	List,
	Tooltip,
	styled,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import PreviewIcon from "@mui/icons-material/Visibility";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import VideoIcon from "@mui/icons-material/VideoCall";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DeSelectIcon from "@mui/icons-material/Close";

import { useMultiselectContentActions } from "../..";
import { useMultiselectContent } from "../..";

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}));
export const useActions = () => {
	const { selected } = useMultiselectContent();
	const {
		deleteMultiple,
		setApprovedMultiple,
		addToContentEditor,
		addToFolder,
		publish,
		preview,
		shareToChatApp,
		deselectAll,
	} = useMultiselectContentActions();
	const actions = [
		{
			value: "preview",
			description: "Preview selected items",
			Icon: () => <PreviewIcon />,
			text: `Preview ${selected.size} item${
				selected.size > 1 ? "s" : ""
			}`,
			onClick: preview,
		},
		{
			value: "deselect",
			description: "Deselect all items",
			Icon: () => <DeSelectIcon />,
			text: "Deselect all",
			onClick: async () => deselectAll(),
		},
		{
			value: "delete",
			description: "Delete selected items",
			Icon: () => <DeleteIcon />,
			text: "Delete",
			onClick: async () => {
				const hasBeenDeleted = await deleteMultiple();
				if (!hasBeenDeleted) return;

				deselectAll();
			},
		},
		{
			value: "share",
			description: "Share selected items to the AI Chatbot",
			Icon: () => <PeopleIcon />,
			text: "Share to chat app",
			onClick: async () => {
				await shareToChatApp(true);
			},
		},
		{
			value: "unshare",
			description: "Un-share selected items to the AI Chatbot",
			Icon: () => <PeopleOutlineIcon />,
			text: "Un-share to chat app",
			onClick: async () => {
				await shareToChatApp(false);
			},
		},

		{
			value: "approve",
			description: "Approve content for publishing to social media",
			Icon: () => <CheckCircleOutlineRoundedIcon />,
			text: "Set approved",
			onClick: async () => {
				await setApprovedMultiple(true);
			},
		},
		{
			value: "unapprove",
			description: "Un-approve content for publishing to social media",
			Icon: () => <RemoveCircleOutlineOutlinedIcon />,
			text: "Set not approved",
			onClick: async () => {
				await setApprovedMultiple(false);
			},
		},
		{
			value: "publish",
			description: "Publish selected items to social media",
			Icon: () => <ShareIcon />,
			text: "Publish",
			onClick: () => publish(),
		},
		{
			value: "add-to-media-editor",
			description:
				"Add selected items to the media editor to create a new video render",
			Icon: () => <VideoIcon />,
			text: "Add to media editor",
			onClick: async () => {
				await addToContentEditor();
			},
		},
		{
			value: "add-to-folder",
			description:
				"Organize your content by adding all selected items to a folder",
			Icon: () => <FolderIcon />,
			text: "Add to folder",
			onClick: async () => {
				await addToFolder();
			},
		},
	];
	return actions;
};
export default function MultiselectActions() {
	const { showingMenu, showMenu, getSelection } = useMultiselectContent();
	const actions = useActions();
	const selection = showingMenu ? getSelection() : [];

	return (
		<Dialog
			open={showingMenu}
			onClose={() => showMenu(false)}
			fullWidth
			maxWidth="xs"
		>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
			>
				{selection.length ? (
					actions.map(
						({ Icon, text, onClick, value, description }, i) => {
							return (
								<ListItem key={i}>
									<HtmlTooltip arrow title={description}>
										<ListItemButton onClick={onClick}>
											<ListItemIcon>
												<Icon />
											</ListItemIcon>
											<ListItemText primary={text} />
										</ListItemButton>
									</HtmlTooltip>
								</ListItem>
							);
						}
					)
				) : (
					<ListItem>
						<ListItemText primary="No items selected" />
					</ListItem>
				)}
			</List>
		</Dialog>
	);
}
