import { gql } from "@apollo/client";
import { ContentFragment } from "../../content/fragment";
// TODO: [CRTRDR-122] using FileFragment in the asset file is causing no data to be returned for the timeline track asset
export const projects = gql`
	${ContentFragment}
	query Projects($input: ProjectsInput!) {
		projects(input: $input) {
			projects {
				id
				title
				content {
					...ContentFragment
				}
				options {
					aspectRatio
					fps
					quality
					resolution
				}
				timeline {
					tracks {
						name
						assetType
						assets {
							id
							assetType
							start
							volume
							name
							opacity
							position
							scale
							file {
								... on ImageFile {
									id
									mimetype
									assetType
									url
									thumbnailUrl
									duration
									name
									isProcessed
									width
									height
									size
									aspectRatio
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
									extension
								}
								... on VideoFile {
									id
									mimetype
									assetType
									url
									name
									isProcessed
									width
									height
									size
									thumbnailUrl
									framecaptureUrl
									timelineThumbnailUrls
									duration
									aspectRatio
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
									extension
								}
								... on AudioFile {
									id
									assetType
									url
									name
									isProcessed
									duration
									extension
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
								}
							}
							transition {
								in {
									type
									speed
								}
								out {
									type
									speed
								}
							}
							trim {
								from
								to
							}
						}
					}
				}
			}
		}
	}
`;

export const folder = gql`
	${ContentFragment}
	query Folder($folderId: ID!) {
		folder(id: $folderId) {
			contentConnection {
				totalCount
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
				edges {
					cursor
					node {
						...ContentFragment
					}
				}
			}
			createdAt
			id
			name
		}
	}
`;
export const folders = gql`
	query AllFolders($after: Cursor, $before: Cursor, $first: Int, $last: Int) {
		allFolders(after: $after, before: $before, first: $first, last: $last) {
			edges {
				cursor
				node {
					createdAt
					id
					name
				}
			}
		}
	}
`;
export const allContent = gql`
	${ContentFragment}
	query AllContent(
		$after: Cursor
		$before: Cursor
		$first: Int
		$last: Int
		$tags: [String!]
		$title: String
		$assetStatus: AssetStatusEnum
		$folderId: ID
	) {
		allContent(
			after: $after
			before: $before
			first: $first
			last: $last
			tags: $tags
			title: $title
			assetStatus: $assetStatus
			folderId: $folderId
		) {
			edges {
				cursor
				node {
					...ContentFragment
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			totalCount
		}
	}
`;

export const getContent = gql`
	${ContentFragment}
	query getContent($id: ID!) {
		content(id: $id) {
			...ContentFragment
		}
	}
`;
export const getContents = gql`
	${ContentFragment}
	query getContents($ids: [ID!]!) {
		contents(ids: $ids) {
			...ContentFragment
		}
	}
`;

export const getContentVersions = gql`
	${ContentFragment}
	query getContentVersions($contentId: ID!, $cursor: Cursor, $first: Int) {
		content(id: $contentId) {
			id
			versionsConnection(first: $first, after: $cursor) {
				pageInfo {
					endCursor
					hasNextPage
				}
				edges {
					cursor
					node {
						...ContentFragment
					}
				}
			}
		}
	}
`;

export const getTags = gql`
	query MetadataTags($input: AllMetadataTagsInput!) {
		metadataTags(input: $input) {
			tags {
				id
				isWorkspaceTag
				source
				value
			}
		}
	}
`;

export const search = gql`
	${ContentFragment}
	query ContentSearch($input: ContentSearchInput) {
		contentSearch(input: $input) {
			totalCount
			responseTimeMS
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			edges {
				cursor
				node {
					...ContentFragment
				}
			}
		}
	}
`;

export const importBatch = gql`
	${ContentFragment}
	query Import($input: ImportQueryInput!) {
		import(input: $input) {
			id
			source
			status
			created_at
			completed_at
			importFilesConnection {
				edges {
					cursor
					node {
						id
						import_id
						file_id
						created_at
						completed_at
						status
						key
						uploaded_at
						content {
							...ContentFragment
						}
					}
				}
				pageInfo {
					hasNextPage
					hasPreviousPage
					startCursor
					endCursor
				}
				totalCount
			}
		}
	}
`;

export const usedTags = gql`
	query UsedTags {
		usedTags {
			tags {
				id
				isWorkspaceTag
				source
				value
			}
		}
	}
`;
