import { Grid, CardMedia, Card, CardActionArea } from "@mui/material";
import useMobileContextMenu from "../../../util/useMobileContextMenu";
import FolderIcon from "@mui/icons-material/Folder";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useFolderContent } from "../TreeView/hook/useFolderContent";
import { useDraggable, useDroppable } from "@dnd-kit/core";

import { useFoldersContext } from "../ContextMenu";
import useLibraryMediaQuery from "../../../InstantSearch/view/useIsWidescreen";
const WHITESPACE_CHARACTER = "\u200B"; // ensure that the card has a set height
const GridItem = ({ children, onClick = () => {} }) => {
	return (
		<Grid
			onClick={onClick}
			item
			xs={6}
			md={2}
			xl={1}
			sx={{
				/// text overflow
				overflow: "hidden",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis",
				// child text overflow

				"& > *": {
					overflow: "hidden",
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
				},
			}}
		>
			{children}
		</Grid>
	);
};
const isDisabled = () => {
	// is touch enabled
	const isTouchEnabled =
		"ontouchstart" in window ||
		navigator.maxTouchPoints > 0 ||
		navigator.msMaxTouchPoints > 0;
	return isTouchEnabled;
};

export const CleanContentCard = ({ content }) => {
	return (
		<Card>
			<CardMedia component="img" image={content.source.thumbnailUrl} />
		</Card>
	);
};
export const ContentCard = ({ content, onClick = () => {} }) => {
	const { setClickEvent, setItem } = useFoldersContext();
	const handleContextMenu = e => {
		setItem({
			type: "content",
			data: content,
		});
		setClickEvent(e);
	};
	const longPressProps = useMobileContextMenu({
		onLongPress: handleContextMenu,
	});

	const { isMD } = useLibraryMediaQuery();
	const { setSelectedId } = useFolderContent();

	const { setNodeRef, listeners, attributes } = useDraggable({
		id: content.id,
		data: {
			index: content.id,
			type: "content",
			content,
		},
		// disable if touch is enabled
		disabled: isDisabled(),
	});

	const title = content.title;

	return (
		<GridItem key={content.id}>
			<CardActionArea
				title={
					title && title !== WHITESPACE_CHARACTER ? title : "Untitled"
				}
				onClick={async e => {
					e.stopPropagation();
					setSelectedId(content.id);
				}}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					position: "relative",
				}}
			>
				{title}
				<EyeIcon
					sx={{
						// keep right of the card
						display: isMD ? "block" : "none",
						position: "absolute",
						right: 0,
						backgroundColor: theme =>
							theme.palette.background.default,
					}}
				/>
			</CardActionArea>
			<Card
				ref={setNodeRef}
				{...attributes}
				{...listeners}
				{...longPressProps}
				onContextMenu={e => {
					e.preventDefault();
					e.stopPropagation();
					handleContextMenu(e);
				}}
			>
				<CardMedia
					sx={{
						zIndex: 0,
						pointerEvents: "none",
					}}
					component="img"
					image={content.source.thumbnailUrl}
				/>
			</Card>
		</GridItem>
	);
};

export const FolderCard = ({ folder }) => {
	const { setClickEvent, setItem } = useFoldersContext();
	const handleContextMenu = e => {
		setItem({
			type: "folder",
			data: folder,
		});
		setClickEvent(e);
	};
	const longPressProps = useMobileContextMenu({
		onLongPress: handleContextMenu,
	});
	const { setSelectedFolder } = useFolderContent();
	const { setNodeRef, listeners, attributes } = useDroppable({
		id: folder.id,
		data: {
			index: folder.id,
			type: "folder",
		},
		disabled: isDisabled(),
	});

	const title = folder.name || WHITESPACE_CHARACTER;

	return (
		<GridItem key={folder.id}>
			<CardActionArea disableRipple>{title}</CardActionArea>
			<Card
				ref={setNodeRef}
				{...attributes}
				{...listeners}
				{...longPressProps}
				onClick={() => setSelectedFolder(folder.id)}
				onContextMenu={e => {
					e.preventDefault();
					e.stopPropagation();
					handleContextMenu(e);
				}}
			>
				<CardMedia
					component={() => (
						<FolderIcon
							sx={{
								height: "100%",
								width: "100%",
							}}
						/>
					)}
				/>
			</Card>
		</GridItem>
	);
};
