import { useState, useContext, createContext, useEffect } from "react";
import { useRequester } from "../../../../../Apollo";
import ReactJoyride from "react-joyride";

import { IconButton, Box } from "@mui/material";
import QuestionIcon from "@mui/icons-material/HelpOutline";
import { useSnackbar } from "notistack";
const Context = createContext({
	running: false,
});
export const useLibraryJoyride = () => useContext(Context);
const JOYRIDE_DOMAIN = "SCHEDULE";

export default function JoyrideContext() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useQuery, useMutation } = useRequester();
	const { data } = useQuery(definitions.user.query.onboardingProgress, {
		fetchPolicy: "cache-only",
	});
	const onStep =
		data?.onboardingProgress.progress[
			`on_step_${JOYRIDE_DOMAIN.toLowerCase()}`
		];
	const [run, setRun] = useState(onStep === 0);

	const [updateStepProgress] = useMutation(
		definitions.user.muation.onboardingProgress
	);
	const [reset, setReset] = useState(0);

	return (
		<>
			<Box
				sx={{
					position: "absolute",
					bottom: 12,
					right: 12,
					zIndex: 1000,
				}}
			>
				<IconButton
					onClick={() => {
						setRun(true);
						setReset(reset + 1);
						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 0,
								},
							},
						});
						updateStepProgress({
							variables: {
								input: {
									domain: "PUBLISH",
									progress: 0,
								},
							},
						});
					}}
				>
					<QuestionIcon />
				</IconButton>
			</Box>
			<ReactJoyride
				run={!onStep && run}
				key={reset}
				continuous
				showProgress
				showSkipButton
				spotlightClicks
				scrollToFirstStep={false}
				callback={({ action, index, status }) => {
					if (action === "close" || status === "skipped") {
						setRun(false);
						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 1,
								},
							},
							onError: () => {
								const key = enqueueSnackbar(
									"Failed to update onboarding progress.",
									{
										variant: "error",
										onClick: () => closeSnackbar(key),
									}
								);
							},
						});
					}
				}}
				disableScrolling
				disableOverlayClose
				steps={[
					{
						target: ".schedule-page-calendar",
						content: "Click on a day to schedule a post.",
						disableBeacon: true,
						disableOverlay: true,
					},
				]}
			/>
		</>
	);
}
