import { gql } from "@apollo/client";
export const SET_ADDITIONAL_STORAGE = gql`
	mutation SetAdditionalStorage($input: SetAdditionalStorageInput) {
		setAdditionalStorage(input: $input) {
			user {
				additionalStorage
				id
			}
		}
	}
`;
export const SET_CHATBOT_ENABLED = gql`
	mutation SetChatbotEnabled($input: SetChatbotEnabledInput) {
		setChatbotEnabled(input: $input) {
			workspace {
				id
				chatbotEnabled
			}
		}
	}
`;

export const SET_API_ENABLED = gql`
	mutation SetApiEnabled($input: SetApiEnabledInput) {
		setApiEnabled(input: $input) {
			user {
				id
				apiEnabled
			}
		}
	}
`;
