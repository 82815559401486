const definitions = {
	admin: {
		query: require("./admin/query"),
		mutation: require("./admin/mutation"),
	},
	algolia: {
		query: require("./algolia/query"),
	},
	automation: {
		mutation: require("./automation/mutation"),
	},
	assets: {
		query: require("./assets/query"),
		mutation: require("./assets/mutation"),
		subscription: require("./assets/subscription"),
	},
	user: {
		query: require("./user/query"),
		muation: require("./user/mutation"),
	},
	workspace: {
		query: require("./workspace/query"),
		mutation: require("./workspace/mutation"),
	},
	billing: {
		query: require("./billing/query"),
		mutation: require("./billing/mutation"),
	},
	common: {
		query: require("./common/query"),
		mutation: require("./common/mutation"),
	},
	content: {
		query: require("./content/query"),
		mutation: require("./content/mutation"),
		fragment: require("./content/fragment"),

		subscription: require("./content/subscription"),
	},
	integrations: {
		query: require("./integrations/query"),
		mutation: require("./integrations/mutation"),
	},
	submissions: {
		query: require("./submissions/query"),
		mutation: require("./submissions/mutation"),
	},
	notifications: {
		query: require("./notifications/query"),
		subscription: require("./notifications/subscription"),
		mutation: require("./notifications/mutation"),
	},
	oauth: {
		query: require("./oauth/query"),
		mutation: require("./oauth/mutation"),
	},
	file: {
		query: require("./file/query"),
		mutation: require("./file/mutation"),
	},
	virtualAssistant: {
		fragment: require("./virtualAssistant/fragment"),
		query: require("./virtualAssistant/query"),
		mutation: require("./virtualAssistant/mutation"),
		subscription: require("./virtualAssistant/subscription"),
	},
	stripe: {
		query: require("./stripe/query"),
		mutation: require("./stripe/mutation"),
	},
	rbac: {
		query: require("./rbac/query"),
		mutation: require("./rbac/mutation"),
	},
};

export default definitions;
