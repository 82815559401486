import { useRequester } from "../../../../../../Apollo";
import SelectorBase from "../Base";
export default function BumperSelector({
	onSelect = () => {},
	defaultBumper,
	type = "Intro",
}) {
	const { definitions, useQuery } = useRequester();
	const { data: watermarksData } = useQuery(
		definitions.assets.query.bumpers,
		{
			variables: {},
		}
	);
	const options = watermarksData?.allBumpers?.edges?.map(({ node }) => node);

	return (
		<SelectorBase
			defaultAsset={defaultBumper}
			onSelect={onSelect}
			options={options}
			type={type}
			label={`${type} Bumper`}
		/>
	);
}
