import { useActiveFilters } from "@/ContentSearch/hooks/useActiveFilters";
import { Stack, Chip, Box } from "@mui/material";
export default function ActiveFilters() {
	const currentFilters = useActiveFilters();
	return (
		<Box
			sx={{
				width: "100%",
				overflowX: "auto",
				whiteSpace: "nowrap",
				height: "fit-content",
			}}
		>
			<Stack
				direction={"column"}
				spacing={1}
				sx={{
					overflowX: "auto",
					whiteSpace: "nowrap",
				}}
			>
				{currentFilters.map(filter => {
					const FilterChip = ({ filter, valueLabel, clear }) => {
						return (
							<Chip
								key={filter}
								label={`${filter}: ${valueLabel}`}
								onDelete={clear}
							/>
						);
					};
					if (filter.filter === "includesTags") {
						return filter.value.map(tag => {
							return (
								<FilterChip
									key={tag}
									filter={filter.filterLabel}
									valueLabel={tag}
									clear={() => filter.clear(tag)}
								/>
							);
						});
					}
					return (
						<FilterChip
							key={filter.filter}
							filter={filter.filterLabel}
							valueLabel={filter.valueLabel}
							clear={filter.clear}
						/>
					);
				})}
			</Stack>
		</Box>
	);
}
