import { useState, useEffect } from "react";
import { useContentEditor } from "../..";
import { debounce } from "lodash";
export const usePlayTime = () => {
	const { timelineEngine } = useContentEditor();
	const [selectedTime, setSelectedTime] = useState(
		timelineEngine?.current?.getTime() || 0
	);
	const [isPlaying, setIsPlaying] = useState(
		timelineEngine?.current?.isPlaying
	);
	const handleSelectedTime = debounce(
		time => {
			setSelectedTime(time);
		},
		500,
		{
			maxWait: 1000,
		}
	);

	useEffect(() => {
		if (!timelineEngine?.current) return;

		timelineEngine.current?.listener.on("play", data => {
			setSelectedTime(timelineEngine.current?.getTime());
			setIsPlaying(true);
		});
		timelineEngine.current?.listener.on("paused", () => {
			setSelectedTime(timelineEngine.current?.getTime());
			setIsPlaying(false);
		});
		timelineEngine.current?.listener.on("afterSetTime", data => {
			handleSelectedTime(data.time);
		});
	}, [timelineEngine]);

	return {
		time: timelineEngine.current?.getTime(),
		isPlaying,
		selectedTime,
		listener: timelineEngine?.current?.listener,
		activeActionIds: timelineEngine?.current?._activeActionIds,
	};
};
