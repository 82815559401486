import { useRequester } from "../../../../../../Apollo";
import { useContentEditor } from "../../../..";
import { AssetList } from "../../../../../AssetList";
export default function BumpersList() {
	const { addTrack } = useContentEditor();
	const { useQuery, definitions, useMutation, client } = useRequester();
	const [deleteBumper] = useMutation(
		definitions.assets.mutation.DELETE_BUMPER
	);

	const { data } = useQuery(definitions.assets.query.bumpers, {
		fetchPolicy: "cache-and-network",
		variables: {
			first: 100,
			status: "ENABLED",
			type: "IMAGE",
		},
	});

	return (
		<AssetList
			assets={data?.allBumpers?.edges || []}
			onClick={bumper => {
				addTrack({
					assetType: "BUMPER",
					file: bumper.file,
					id: bumper.id,
					name: bumper.name,
				});
			}}
			onDelete={async bumper => {
				await deleteBumper({
					variables: {
						input: {
							id: bumper.id,
						},
					},
				});
				// remove from cache
				client.cache.evict({
					id: client.cache.identify(bumper),
				});
			}}
		/>
	);
}
