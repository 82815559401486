import { useRequester } from "../../Apollo";
import { useRequestPreSend, useAbortAll, useAbortItem } from "@rpldy/uploady";
import { useSnackbar } from "notistack";
export const useRequestPresendHook = async () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const abortAll = useAbortAll();
	const abortItem = useAbortItem();
	const { definitions, useMutation } = useRequester();
	const [createUploadAsset] = useMutation(
		definitions.file.mutation.uploadFile
	);
	const [createUploadAvatar] = useMutation(
		definitions.file.mutation.uploadAvatar
	);
	useRequestPreSend(async ({ items, options }) => {
		const assetType = options.assetType;
		const upload = await new Promise((resolve, reject) => {
			const item = items[0];
			const file = item?.file;

			let createUpload;
			if (assetType.includes("AVATAR")) {
				createUpload = createUploadAvatar;
			} else {
				createUpload = createUploadAsset;
			}
			createUpload({
				variables: {
					input: {
						assetType,
						filename: file.name,
					},
				},
				onCompleted: response => {
					console.log("response", response);

					let presignedURL;
					if (assetType.includes("AVATAR")) {
						presignedURL = response.uploadAvatar.presignedURL;
					} else {
						presignedURL = response.upload.presignedURL;
					}

					resolve({
						options: {
							params: Object.entries(presignedURL.fields).reduce(
								(prev, [k, v]) => {
									if (k === "__typename") return prev;
									prev[k.replace(/_/g, "-")] = v;
									return prev;
								},
								{}
							),

							destination: {
								url: presignedURL.url,

								method: presignedURL.method,
							},
							sendWithFormData: true,
						},
					});
				},
				onError: error => {
					if (error.message === "Not a valid filetype!") {
						const key = enqueueSnackbar(
							`${item.file.type} is not a supported file type`,
							{
								variant: "error",
								autoHideDuration: 10000,
								onClick: () => closeSnackbar(key),
							}
						);
						abortItem(item.id);
						resolve(null);
						return;
					}
					const key = enqueueSnackbar(error.message, {
						variant: "error",
						autoHideDuration: 10000,
						onClick: () => closeSnackbar(key),
					});
					abortAll();
					resolve(null);
				},
			});
		});
		if (!upload) return console.log("no upload");
		return upload;
	});
};
export default function RequestPresend({ assetType }) {
	useRequestPresendHook({ assetType });
	return <></>;
}
