import { BodyHeight } from "../../../../../Layout";
import Submission from "../../../../../Component/Submission";
import { useParams } from "react-router-dom";
export default function PageSubmission() {
	const { id } = useParams();
	return (
		<BodyHeight
			sx={{
				padding: "1rem",
			}}
		>
			<Submission id={id} />
		</BodyHeight>
	);
}
