import { useRequester } from "../../../Apollo";
import { useMultiselectContent } from "../..";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { useMemo } from "react";
import { useSnackbar } from "notistack";
export default function Menu() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { showingAddToFolderMenu, setShowingAddToFolderMenu, getSelection } =
		useMultiselectContent();
	const { useQuery, definitions, useMutation } = useRequester();
	const { data } = useQuery(definitions.content.query.folders, {
		fetchPolicy: "cache-first",
	});
	const [folderAddContent] = useMutation(
		definitions.content.mutation.folderAddContent
	);

	const selectedItems = useMemo(
		() => getSelection(),
		[showingAddToFolderMenu]
	); // memoize selected items until the menu is closed

	return (
		<Dialog
			open={showingAddToFolderMenu}
			onClose={() => setShowingAddToFolderMenu(false)}
		>
			<DialogContent>
				<DialogContentText>
					Move {selectedItems.length} items to folder
				</DialogContentText>

				{data?.allFolders.edges.map(({ node }, i) => {
					return (
						<DialogContentText
							key={i}
							onClick={() => {
								folderAddContent({
									variables: {
										input: {
											folderId: node.id,
											contentIds: [
												...selectedItems.map(
													item => item.id
												),
											],
										},
									},
									onCompleted: data => {
										const folderName =
											data.folderAddContent.folder.name;
										setShowingAddToFolderMenu(false);
										let key = enqueueSnackbar(
											`Moved ${
												selectedItems.length
											} item${
												selectedItems.length > 1
													? "s"
													: ""
											} to "${folderName}"`,
											{
												variant: "success",
												onClick: () => {
													closeSnackbar(key);
												},
											}
										);
									},
									onError: error => {
										let key = enqueueSnackbar(
											`Error moving to folder`,
											{
												variant: "error",
												onClick: () => {
													closeSnackbar(key);
												},
											}
										);
									},
								});
							}}
							sx={{
								display: "flex",
								alignItems: "center",
								cursor: "pointer",
								"&:hover": {
									backgroundColor: theme =>
										theme.palette.action.hover,
								},
							}}
						>
							<FolderIcon
								sx={{
									marginRight: "0.5rem",
								}}
							/>

							{node.name}
						</DialogContentText>
					);
				})}
			</DialogContent>
		</Dialog>
	);
}
