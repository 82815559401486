import { memo } from "react";
import { Handle, Position, useReactFlow } from "reactflow";

import { useTheme, Typography, Card, Stack, CardContent } from "@mui/material";
import WatermarkSelector from "./Selector/Watermark";
import BumperSelector from "./Selector/Bumper";
import Toolbar from "../Toolbar";

export default memo(function RenderNode(props) {
	const { data, isConnectable, nodeId } = props;
	const theme = useTheme();
	const { setNodes, getNodes } = useReactFlow();
	return (
		<>
			<Toolbar {...props} />
			<Handle
				type="target"
				position={Position.Left}
				style={{ background: theme.palette.primary.main }}
				onConnect={params => console.log("handle onConnect", params)}
				isConnectable={isConnectable}
			/>
			<Card
				sx={{
					backgroundColor: theme.palette.background.default,
					borderRadius: "5px",
					padding: "1rem",
					border: "1px solid black",
					maxWidth: "100vw",
					width: "fit-content",
				}}
			>
				<CardContent
					sx={{
						// center content
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{ fontSize: 14 }}
						color="text.secondary"
						gutterBottom
					>
						Render Node
					</Typography>
					<WatermarkSelector
						defaultWatermark={data?.watermark}
						onSelect={data => {
							setNodes(
								getNodes().map(node => {
									if (node.id === nodeId) {
										return {
											...node,
											data: {
												...node.data,
												watermark: data,
											},
										};
									}
									return node;
								})
							);
						}}
					/>
					<Stack direction={"row"} spacing={2}>
						<BumperSelector
							type="Intro"
							defaultBumper={data?.intro}
							onSelect={data => {
								setNodes(
									getNodes().map(node => {
										if (node.id === nodeId) {
											return {
												...node,
												data: {
													...node.data,
													intro: data,
												},
											};
										}
										return node;
									})
								);
							}}
						/>
						<BumperSelector
							type="Outro"
							defaultBumper={data?.outro}
							onSelect={data => {
								setNodes(
									getNodes().map(node => {
										if (node.id === nodeId) {
											return {
												...node,
												data: {
													...node.data,
													outro: data,
												},
											};
										}
										return node;
									})
								);
							}}
						/>
					</Stack>
				</CardContent>
			</Card>
			<Handle
				type="source"
				position={Position.Right}
				id="a"
				style={{ background: theme.palette.primary.main }}
				isConnectable={isConnectable}
			/>
		</>
	);
});
