import { AppBar, Box, Drawer, Toolbar } from "@mui/material";

import TimelineEditor from "../../TimelineEditor";
import PreviewPane from "../../PreviewPane";
import TitleBar from "../../TitleBar";

import ActionsToolbar from "../../Toolbar/Actions/Desktop";
import EditorScroller from "../../Toolbar/View/Scroller";
import { useContentEditor } from "../..";
export default function ResponsiveDrawer() {
	const { toolbarActionsWidth, toolbarViewWidth } = useContentEditor();
	return (
		<Box
			sx={{
				display: "flex",
				width: "100vw",
				height: "var(--app-height)",
				overflow: "hidden",
			}}
		>
			<AppBar
				position="fixed"
				sx={{
					width: { sm: `calc(100% - ${toolbarActionsWidth}px)` },
					ml: { sm: `${toolbarActionsWidth}px` },
				}}
			>
				<TitleBar />
			</AppBar>
			<Box
				component="nav"
				sx={{
					width: { sm: toolbarActionsWidth },
					flexShrink: { sm: 0 },
				}}
				aria-label="mailbox folders"
			>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: toolbarActionsWidth,
							overflowX: "hidden",
						},
					}}
					open
				>
					<ActionsToolbar />
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					width: { sm: `calc(100% - ${toolbarActionsWidth}px)` },
					height: "var(--app-height)",
					boxSizing: "border-box !important",
					display: "flex",
					flexDirection: "column-reverse",
				}}
			>
				<Box
					sx={{
						// position at the bottom of the container
						alignSelf: "flex-end",
						width: "100%",
						height: "fit-content",
					}}
				>
					<TimelineEditor />
				</Box>

				<Box
					sx={{
						display: "flex",
						height: "100%",
						overflow: "hidden",
					}}
				>
					<Box
						sx={{
							width: `${toolbarViewWidth}px`,
							height: "100%",
							overflow: "auto",
						}}
					>
						<EditorScroller />
					</Box>
					<Box
						sx={{
							width: `calc(100% - ${toolbarViewWidth}px)`,
							display: "flex",
							alignItems: "center",
						}}
					>
						<PreviewPane />
					</Box>
				</Box>
				<Toolbar // blank toolbar to push content down below the app bar
				/>
			</Box>
		</Box>
	);
}
