import { Drawer } from "@mui/material";

import { useContentEditor } from "../../..";
import Scroller from "../Scroller";

const drawerWidth = "100vw";
export default function ActionsDrawer() {
	const { drawerOpen, setDrawerOpen } = useContentEditor();

	return (
		<Drawer
			sx={{
				marginBottom: "var(--nav-height)",
				width: drawerWidth,
				flexShrink: 0,

				// z-index above dialog
				zIndex: 1400,

				"& .MuiDrawer-paper": {
					width: drawerWidth,
					boxSizing: "border-box",
					height: "60vh",
					marginBottom: "var(--nav-height)",
					paddingTop: "12px",
					borderTopLeftRadius: "12px",
					borderTopRightRadius: "12px",
				},
				"& .MuiBackdrop-root": {
					marginBottom: "var(--nav-height)",
				},
			}}
			variant="temporary"
			anchor="bottom"
			open={drawerOpen}
			onClose={() => {
				setDrawerOpen(false);
			}}
		>
			<Scroller />
		</Drawer>
	);
}
