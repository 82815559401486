import {
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useContentEditor } from "../../../..";
import { useRequester } from "../../../../../../Apollo";
import ContextMenu from "../../../../../ContextMenu";
import { useConfirm } from "../../../../../../Confirm";
import EllipsesIcon from "@mui/icons-material/MoreVert";

import { useState } from "react";
const Project = ({ project }) => {
	const { confirm } = useConfirm();
	const [contextMenu, setContextMenu] = useState(null);

	const { useMutation, definitions, client } = useRequester();
	const [deleteProject] = useMutation(
		definitions.content.mutation.deleteProject,
		{
			variables: {
				input: {
					id: project.id,
				},
			},
			onCompleted: () => {
				// clear projects from cache
				client.cache.evict({
					fieldName: "projects",
				});
			},
		}
	);

	const {
		loadProject,
		projectId,
		variables: {
			input: { contentId },
		},
	} = useContentEditor();
	const isSelected = projectId === project.id;
	return (
		<>
			<span
				style={{
					position: "absolute",
				}}
			>
				<ContextMenu
					clickEvent={contextMenu}
					options={[
						{
							label: "Delete project",
							onClick: async () => {
								if (contentId) {
									const confirmed = await confirm(
										"Are you sure you want to delete this project?"
									);
									if (!confirmed) return;
								}
								deleteProject();
								setContextMenu(null);
							},
							endIcon: <DeleteIcon />,
						},
						{
							label: "Load project",
							onClick: async () => {
								if (contentId) {
									const confirmed = await confirm(
										"Are you sure you want to load this project?",
										"Looks like you were already working on a project. Your progress will be lost if you continue."
									);
									if (!confirmed) return;
								}
								loadProject(project);
								setContextMenu(null);
							},
							endIcon: <EditIcon />,
						},
					]}
				/>
			</span>
			<ImageListItem
				key={project.id}
				sx={{
					// add highlight if project is selected
					border: theme => {
						if (!isSelected) return;

						return `1px solid ${theme.palette.action.active}`;
					},
				}}
			>
				<ImageListItemBar
					actionIcon={
						<IconButton onClick={e => setContextMenu(e)}>
							<EllipsesIcon />
						</IconButton>
					}
					title={project.title || "untitled project"}
				/>

				<img
					src={project.content.source.thumbnailUrl}
					alt={project.name}
				/>
			</ImageListItem>
		</>
	);
};
export default function ProjectList() {
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.content.query.projects, {
		variables: {
			input: {},
		},
		fetchPolicy: "cache-first",
	});

	return (
		<ImageList cols={3}>
			{(data?.projects.projects || []).map(project => {
				return <Project key={project.id} project={project} />;
			})}
		</ImageList>
	);
}
