import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import LoadingScreen from "../../../../Component/LoadingScreen";

export default function PageAuthorize() {
	const { search } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		// authentication is handled in the auth provider.
		// this component is simply used to redirect the user when
		// withAuthenticationRequired fires from a protected route
		const params = new URLSearchParams(search);
		const returnTo = params.get("returnTo");
		if (returnTo === "/authorize") return navigate("/home"); // prevent infinite loop
		navigate(returnTo || "/home");
	}, []);
	return <LoadingScreen loading message={`Authorizing`} />;
}
