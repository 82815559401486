import { useContentEditor } from "../..";
import formatSeconds from "../../../../util/formatSeconds";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import StarIcon from "@mui/icons-material/Star";
const ParentContentSelector = ({ asset }) => {
	const {
		setParentContentId,
		variables: {
			input: { contentId },
		},
	} = useContentEditor();
	if (asset.data.assetType !== "CONTENT") return <></>;
	let isParentContent;
	if (asset.data.assetType === "CONTENT" && asset.data.id === contentId) {
		isParentContent = true;
	}

	return (
		<Box
			className="parent-content-star"
			sx={{
				position: "absolute",
				top: 0,
				left: 0,
				zIndex: 1401,
			}}
		>
			<IconButton
				disableFocusRipple
				onClick={() => {
					setParentContentId(asset.data.id);
				}}
			>
				<StarIcon
					sx={{
						color: isParentContent ? "yellow" : "transparent",

						stroke: isParentContent ? "yellow" : "white",
						strokeWidth: isParentContent ? 0 : 1,
					}}
				/>
			</IconButton>
		</Box>
	);
};
export default function Timeline(props) {
	const trim = {
		// leave only 2 decimal places
		from: Math.round(props.data.trim.from * 100) / 100,
		to: Math.round(props.data.trim.to * 100) / 100,
	};

	const selectedBorder = {
		border: "1px dashed yellow",
	};
	const unselectedBorder = {
		border: ".4px groove white",
		borderStyle: "groove dashed",
	};

	let timeline = props.timeline;
	if (props.data.file.mimetype.includes("image")) {
		const duration = Math.abs(props.data.trim.to - props.data.trim.from);
		// watermarks have only one timeline image, so we need to multiply the thumbnails to fill the duration
		timeline = Array.from(
			{ length: duration * 2 },
			() => props.timeline[0]
		);
	}

	return (
		<Box
			className={`timeline-action-${props.data.assetType.toLowerCase()}`}
			sx={{
				width: "100%",
				height: "100%",
				...(props.selected ? selectedBorder : unselectedBorder),
				position: "relative",
			}}
		>
			<ParentContentSelector asset={props} />

			<Stack
				direction={"row"}
				sx={{
					width: "100%",
					height: "100%",
					overflowX: "hidden",
					position: "relative",
				}}
			>
				{props.data.volume === 0 && (
					<Box
						sx={{
							position: "absolute",
							bottom: 4,
							right: 4,
							color: "white",
						}}
					>
						<VolumeOffIcon />
					</Box>
				)}
				<Box
					sx={{
						position: "absolute",
						width: "100%",
						height: "100%",
						overflow: "hidden",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						userSelect: "none",
						pointerEvents: "none",
					}}
				>
					<Box
						sx={{
							flexDirection: "column",
							justifyContent: "center",
							backgroundColor: "rgba(0,0,0,0.5)",
							borderRadius: 1,
							padding: ".4rem",
							display: "flex",
							alignItems: "center",
							"& > *": {
								fontSize: "0.8rem",
								color: "white",
							},
						}}
					>
						<Typography>{props.data.name || "untitled"}</Typography>
						<Typography>
							{trim.from} - {trim.to}
						</Typography>
						<Typography>
							{formatSeconds(props.end - props.start)}
						</Typography>
					</Box>
				</Box>
				{timeline.reduce((acc, url, index) => {
					const scale = props.scale;
					if (index % scale === 0)
						acc.push(
							<img
								key={index}
								src={url}
								alt={`timeline-${index}_${props.data.assetType}`}
								style={{
									height: "100%",
									width: 82,
									objectFit: "cover",
									opacity: props.data.opacity,
									userSelect: "none",
									pointerEvents: "none",
								}}
							/>
						);

					return acc;
				}, [])}
			</Stack>
		</Box>
	);
}
