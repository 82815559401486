import React, { memo } from "react";
import { Handle, Position } from "reactflow";

import { useTheme, Typography, Box } from "@mui/material";

export default function ColorSelectorNode({ data, isConnectable }) {
	const theme = useTheme();
	return (
		<Box>
			<Handle
				type="target"
				position={Position.Left}
				style={{ background: theme.palette.primary.main }}
				onConnect={params => console.log("handle onConnect", params)}
				isConnectable={isConnectable}
			/>

			<Typography
				sx={{
					color: theme => theme.palette.text.primary + " !important",
				}}
			>
				Custom Color Picker Node: <strong>{data.color}</strong>
			</Typography>

			<input
				className="nodrag"
				type="color"
				onChange={data.onChange}
				defaultValue={data.color}
			/>
			<Handle
				type="source"
				position={Position.Right}
				id="a"
				style={{ top: 10, background: theme.palette.primary.main }}
				isConnectable={isConnectable}
			/>
			<Handle
				type="source"
				position={Position.Right}
				id="b"
				style={{
					bottom: 10,
					top: "auto",
					background: theme.palette.primary.main,
				}}
				isConnectable={isConnectable}
			/>
		</Box>
	);
}
