import { useRequester } from "@/Apollo";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useOauthClientContext } from "@/Component/Oauth/OauthClientList/Client";
import { useSnackbar } from "notistack";
import { InputAdornmentPadding } from "../Style";
import SaveIcon from "@mui/icons-material/Save";

export default function OauthClientName() {
	const { client } = useOauthClientContext();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useMutation, definitions } = useRequester();

	const [updateName] = useMutation(
		definitions.oauth.mutation.updateClientName
	);

	return (
		<form
			onSubmit={async e => {
				e.preventDefault();
				const name = e.target.name.value;
				await updateName({
					variables: {
						input: {
							client_id: client.id,
							name,
						},
					},
					onError: e => {
						console.log("error updating name:");
						const key = enqueueSnackbar(e.message, {
							variant: "error",
							onClick: () => closeSnackbar(key),
						});
					},
				});
			}}
		>
			<TextField
				label="Name"
				type="text"
				name="name"
				defaultValue={client.name}
				sx={{
					"& .MuiInputBase-root": {
						width: "fit-content",
					},

					"& .MuiInputLabel-root": {
						visibility: "visible",
					},
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<InputAdornmentPadding />
							<IconButton type="submit">
								<SaveIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</form>
	);
}
