import { useRequester } from "@/Apollo";
import { useState } from "react";

import LoadingScreen from "@/Component/LoadingScreen";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@mui/material";
import { WideLogoPNG } from "@/Logo/Wide";
import { useEffect } from "react";
const getText = status => {
	switch (status) {
		case "ONLINE":
			return "Online";
		case "OFFLINE":
			return "Server Offline";
		case "MAINTENANCE":
			return "Down for maintenance. We'll be back soon.";
		case "ERROR":
		default:
			return `Error communicating with server. Please try again later.`;
	}
};
export default function ServerStatusContextProvider({ children }) {
	const { useQuery, definitions } = useRequester();
	const [serverDown, setServerDown] = useState(false);
	const { data, loading, error, previousData, called } = useQuery(
		definitions.common.query.serverStatus,
		{
			// 30 seconds
			pollInterval: 30000,
			onError: e => {
				console.log("error fetching server status", e);
				setServerDown(true);
			},
		}
	);
	const status = error ? "ERROR" : data?.serverStatus?.status || "ERROR";

	useEffect(() => {
		const previousStatus = previousData?.serverStatus?.status;
		const currentStatus = data?.serverStatus?.status;
		// handle server was down and now is up
		if (serverDown && currentStatus === "ONLINE") {
			console.log("reloading...");
			window?.location?.reload();
		}
		if (
			previousStatus &&
			previousStatus !== "ONLINE" &&
			currentStatus === "ONLINE"
		) {
			window?.location?.reload();
		}
	}, [status]);

	if (loading) return <LoadingScreen message={`Checking server status`} />;

	if (status === "ONLINE") return children;

	return (
		<Dialog open fullWidth>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<WideLogoPNG />
			</DialogTitle>
			<DialogContent
				sx={{
					paddingY: "5rem",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<DialogContentText>{getText(status)}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => window.location.reload()}>Reload</Button>
			</DialogActions>
		</Dialog>
	);
}
