import { gql } from "@apollo/client";
export const FileFragment = gql`
	fragment FileFragment on File {
		... on ImageFile {
			id
			mimetype
			assetType
			url
			thumbnailUrl
			duration
			name
			isProcessed
			width
			height
			size
			aspectRatio
			exif {
				create_date
				data
				gps_latitude
				gps_longitude
			}
			extension
		}
		... on VideoFile {
			id
			mimetype
			assetType
			url
			name
			isProcessed
			width
			height
			size
			thumbnailUrl
			framecaptureUrl
			timelineThumbnailUrls
			duration
			aspectRatio
			exif {
				create_date
				data
				gps_latitude
				gps_longitude
			}
			extension
		}
		... on AudioFile {
			id
			assetType
			url
			name
			isProcessed
			duration
			extension
			exif {
				create_date
				data
				gps_latitude
				gps_longitude
			}
		}
	}
`;
export const ContentFragment = gql`
	fragment ContentFragment on Content {
		approvalStatus
		assetStatus
		createdAt
		id
		parent_id
		isMetadataReviewed
		title
		updatedAt
		watermarkPosition
		watermarkType
		folderId
		shared
		searchHighlight
		source {
			... on ImageFile {
				id
				mimetype
				assetType
				url
				thumbnailUrl
				duration
				name
				isProcessed
				width
				height
				size
				aspectRatio
				exif {
					create_date
					data
					gps_latitude
					gps_longitude
				}
				extension
			}
			... on VideoFile {
				id
				mimetype
				assetType
				url
				name
				isProcessed
				width
				height
				size
				thumbnailUrl
				framecaptureUrl
				timelineThumbnailUrls
				duration
				aspectRatio
				exif {
					create_date
					data
					gps_latitude
					gps_longitude
				}
				extension
			}
			... on AudioFile {
				id
				assetType
				url
				name
				isProcessed
				duration
				extension
				exif {
					create_date
					data
					gps_latitude
					gps_longitude
				}
			}
		}
		matchesConnection {
			matches {
				exact_match
				hashset_match
				asset {
					url
					assetType
					name
				}
			}
		}
		metadata {
			montage
			overlayAudio
			overlayText
			publishedToReddit
			publishedToRedditTimestamp
			publishedToRedgifs
			publishedToRedgifsTimestamp
			titleRecommended
			trimmed
			watermarkCSExists
			watermarkOGExists
		}
		metadataTags {
			id
			isWorkspaceTag
			source
			value
		}
	}
`;
