import { Box } from "@mui/material";

export default function LayoutSPA({ children }) {
	return (
		<Box
			// must be main for accessibility and SEO
			// some browsers don't support the main component and otheres don't support the role attribute
			component={"main"}
			role="main"
			sx={{
				height: "var(--body-height)",
				backgroundColor: "background.default",
				color: "text.primary",

				width: "100vw",
				overflow: "hidden",
			}}
		>
			{children}
		</Box>
	);
}
