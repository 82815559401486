import { gql } from "@apollo/client";
export const getUser = gql`
	query getUser {
		user {
			admin
			publicDisplayName
			city
			country
			createdAt
			email
			emailVerified
			id
			customerID
			isApproved
			phoneNumber
			proxySupportEnabled
			state
			tz
			tz_offset
			updatedAt
			takedownPiracyConnected
		}
	}
`;
export const onboardingProgress = gql`
	query OnboardingProgress {
		onboardingProgress {
			id
			progress {
				on_step_account
				on_step_automations
				on_step_content_editor
				on_step_integrations
				on_step_library
				on_step_notifications
				on_step_publish
				on_step_schedule
				on_step_workspaces
				on_step_manage_content
			}
		}
	}
`;
