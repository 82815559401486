import destinationList from "@/Destinations";
const DefaultThumbnail = () => {
	return (
		<img
			src={"/logo/small.png"}
			alt="notification thumbnail"
			style={{
				maxHeight: "100%",
				maxWidth: "100%",
			}}
		/>
	);
};
export default function NotificationThumbnail({ notification }) {
	try {
		switch (notification.entity_type) {
			// import, integration, submission, content, match
			case "integration": {
				const src = notification.integration?.avatar;
				if (!src) break;
				return (
					<img
						src={src}
						onError={e => {
							e.target.src = "/logo/small.png";
						}}
						alt="notification thumbnail"
						style={{
							maxHeight: "100%",
							maxWidth: "100%",
						}}
					/>
				);
			}
			case "submission": {
				const found = destinationList.find(
					({ destination }) =>
						destination.toUpperCase() ===
						notification.submission.integration.destination.toUpperCase()
				);

				if (!found) break;
				return <found.Icon />;
			}
			case "import": {
				const source = notification.import.source;
				if (!source) break;
				switch (source) {
					case "DROPBOX": {
						return (
							<img
								src="/icon/dropbox.svg"
								alt="dropbox logo"
								style={{
									maxHeight: "100%",
									maxWidth: "100%",
								}}
							/>
						);
					}
					case "GOOGLE_DRIVE": {
						return (
							<img
								src="/icon/googledrive.png"
								alt="google drive logo"
								style={{
									maxHeight: "100%",
									maxWidth: "100%",
								}}
							/>
						);
					}
					default: {
						break;
					}
				}
				break;
			}
			case "match": {
				const thumbnailUrl = notification.match.source.thumbnailUrl;
				if (!thumbnailUrl) break;
				return (
					<img
						src={thumbnailUrl}
						alt="notification thumbnail"
						// on fail to load, use default thumbnail
						onError={e => {
							e.target.src = "/logo/small.png";
						}}
						style={{
							maxHeight: "100%",
							maxWidth: "100%",
						}}
					/>
				);
			}
			case "content": {
				const thumbnailUrl = notification.content.source.thumbnailUrl;
				if (!thumbnailUrl) break;
				return (
					<img
						src={thumbnailUrl}
						alt="notification thumbnail"
						onError={e => {
							e.target.src = "/logo/small.png";
						}}
						style={{
							maxHeight: "100%",
							maxWidth: "100%",
						}}
					/>
				);
			}

			default: {
				return <DefaultThumbnail />;
			}
		}
		return <DefaultThumbnail />;
	} catch (e) {
		return <DefaultThumbnail />;
	}
}
