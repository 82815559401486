import { useRequester } from "@/Apollo";
import { Navigate } from "react-router-dom";
import Users from "@/Component/Admin/Users";
export default function PageAdmin() {
	const { useQuery, definitions } = useRequester();
	const { data, loading } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "network-only",
	});
	if (loading) return <></>;
	const isAdmin = data?.user?.admin;
	if (!isAdmin) return <Navigate to="/" />;
	return <Users />;
}
