import { useEffect, useRef } from "react";
export const useRecallScrollPosition = localStorageKey => {
	const elementRef = useRef(null);
	const existingPosition = localStorage.getItem(localStorageKey);

	const scrollTop = useRef(existingPosition || 0);

	const handleScroll = () => {
		const element = elementRef.current;
		if (!element) return;
		scrollTop.current = element.scrollTop;
	};

	useEffect(() => {
		const element = elementRef.current;

		if (element) {
			// Set the initial scroll position (for example, scroll to the bottom)
			// element.scrollTop = element.scrollHeight;
			element.scrollTop = scrollTop.current;

			// Add scroll event listener to the specific element when the component mounts
			element.addEventListener("scroll", handleScroll);

			// Remove the event listener when the component unmounts
			return () => {
				element.removeEventListener("scroll", handleScroll);
				// Save the scroll position when the component unmounts

				const data = element?.scrollTop || 0;
				localStorage.setItem(localStorageKey, data);
			};
		}
	}, []);

	return {
		elementRef,
		position: scrollTop,
	};
};
