import { gql } from "@apollo/client";
import { WORKSPACE_ROLE_FIELDS } from "../fragment";

export const createRole = gql`
	${WORKSPACE_ROLE_FIELDS}
	mutation CreateRole($input: CreateRoleInput!) {
		createRole(input: $input) {
			role {
				...WorkspaceRoleFields
			}
		}
	}
`;
export const updateRoleName = gql`
	${WORKSPACE_ROLE_FIELDS}
	mutation UpdateRoleName($input: UpdateRoleNameInput!) {
		updateRoleName(input: $input) {
			role {
				...WorkspaceRoleFields
			}
		}
	}
`;
export const updateRoleColor = gql`
	${WORKSPACE_ROLE_FIELDS}
	mutation UpdateRoleColor($input: UpdateRoleColorInput!) {
		updateRoleColor(input: $input) {
			role {
				...WorkspaceRoleFields
			}
		}
	}
`;
export const updateRolePermissions = gql`
	${WORKSPACE_ROLE_FIELDS}
	mutation UpdateRolePermissions($input: UpdateRolePermissionsInput!) {
		updateRolePermissions(input: $input) {
			role {
				...WorkspaceRoleFields
			}
		}
	}
`;

export const deleteRole = gql`
	mutation DeleteRole($input: DeleteRoleInput!) {
		deleteRole(input: $input) {
			id
		}
	}
`;
