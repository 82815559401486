import { Stack } from "@mui/material";
import User from "../../../../../../Component/User";
import Settings from "./Settings";

export default function SettingsPageAccountTab() {
	return (
		<Stack>
			<User />
			<Settings />
		</Stack>
	);
}
