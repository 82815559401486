import {
	Box,
	IconButton,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { useSelectedContent } from "../../../../SelectedContentContext";
import { useRequester } from "../../../../../Apollo";
import { useSnackbar } from "notistack";
import { useCanNavigateAway } from "../../../../../util/CanNavigateAway";
export default function EditableTitle() {
	const { decrementChanges, incrementChanges } = useCanNavigateAway();
	const [hasChanges, setHasChanges] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useQuery, useMutation } = useRequester();
	const { id } = useSelectedContent();
	const { data } = useQuery(definitions.content.query.getContent, {
		variables: {
			id,
		},
		fetchPolicy: "cache-only",
	});
	const originalValue = data?.content?.title;
	const [title, setTitle] = useState(originalValue);

	const [updateContent] = useMutation(definitions.content.mutation.update);
	const content = data?.content;
	const handleUpdateTitle = () => {
		updateContent({
			variables: {
				input: {
					contentId: content.id,
					title,
				},
			},
			errorPolicy: "all",
			onCompleted: () => {
				setHasChanges(false);
				decrementChanges();
				let key = enqueueSnackbar("Updated title", {
					variant: "success",
					onClick: () => {
						closeSnackbar(key);
					},
				});
			},
		});
	};
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<FormControl
				className="manage-content-update-title"
				sx={{ m: 1 }}
				variant="outlined"
				onSubmit={e => {
					e.preventDefault();
					handleUpdateTitle();
				}}
			>
				<InputLabel htmlFor="outlined-adornment-password">
					Title
				</InputLabel>
				<OutlinedInput
					placeholder={"untitled"}
					id="update-title-input"
					type={"text"}
					name={"title"}
					defaultValue={data?.content?.title}
					onChange={e => {
						const newValue = e.target.value;
						if (originalValue !== newValue && !hasChanges) {
							setHasChanges(true);
							incrementChanges();
						}
						setTitle(e.target.value);
					}}
					onSubmit={() => handleUpdateTitle()}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								edge="end"
								onClick={() => handleUpdateTitle(title)}
							>
								<SaveIcon />
							</IconButton>
						</InputAdornment>
					}
					label="Password"
				/>
			</FormControl>
		</Box>
	);
}
