import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { IconButton } from "@mui/material";
import usePruneDuplicates from "../usePruneDuplicates";
export default function PruneDuplicatesIconButton({ hash }) {
	const { prune } = usePruneDuplicates();
	const handlePrune = () => prune({ hash });

	return (
		<IconButton aria-label="prune duplicates" onClick={handlePrune}>
			<DeleteSweepIcon />
		</IconButton>
	);
}
