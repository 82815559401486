import { useRequester } from "../../../../../../Apollo";
import { useSelectedContent } from "../../../../../SelectedContentContext";
import { useSnackbar } from "notistack";

export default function useShareToChatApp() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation, useQuery } = useRequester();
	const { id } = useSelectedContent();
	const { data, client } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});

	const [share] = useMutation(definitions.content.mutation.shareToChatApp);
	const isShared = data?.content?.shared;
	return {
		isShared,
		toggle: async () => {
			await share({
				variables: {
					input: {
						contentIds: [id],
						share: !isShared,
					},
				},
				// update cache to reflect change
				onCompleted: () => {
					client.writeQuery({
						query: definitions.content.query.getContent,
						variables: {
							id,
						},
						data: {
							content: {
								...data.content,
								shared: !isShared,
							},
						},
					});
					const key = enqueueSnackbar(
						`Successfully ${
							!isShared ? "shared" : "un-shared"
						} item.`,
						{
							variant: "success",
							onClick: () => closeSnackbar(key),
						}
					);
				},
				onError: e => {
					const key = enqueueSnackbar(
						`Failed to ${!isShared ? "share" : "un-share"} item. ${
							e.message
						}`,
						{
							variant: "error",
							onClick: () => closeSnackbar(key),
						}
					);
				},
			});
		},
	};
}
