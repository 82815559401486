import { Box } from "@mui/material";

import ReactPlayer from "./Player/react-player";
import AspectRatioSelector from "../AspectRatioSelector";
export default function PreviewPane() {
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "relative",

				backgroundColor: "transparent",
			}}
		>
			<ReactPlayer />
			<AspectRatioSelector />
		</Box>
	);
}
