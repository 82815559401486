import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useRequester } from "@/Apollo";

export default function ManageSubscriptionButton() {
	const { definitions, useQuery } = useRequester();
	const { data, loading } = useQuery(
		definitions.billing.query.getMySubscription,
		{
			fetchPolicy: "cache-first",
		}
	);

	if (loading) return null;
	const isSubscribed = data?.mySubscription?.product?.tier > 0;

	return (
		<Button
			sx={{
				width: "fit-content",
			}}
			variant="text"
			component={Link}
			to="/subscribe"
		>
			Choose Plan
		</Button>
	);
}
