import LinkIcon from "@mui/icons-material/Link";
import NavigateIcon from "@mui/icons-material/NavigateNext";
import {
	Dialog,
	IconButton,
	Typography,
	CardMedia,
	Stack,
	Card,
	CardContent,
	CardActions,
	Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";

import { useRequester } from "../../../../Apollo";
import destinationList from "../../../../Destinations";
import dateFromIso from "../../../../util/dateFromISO";

export default function SubmissionPreview({ submission, open, onClose }) {
	const navigate = useNavigate();
	const { closeSnackbar, enqueueSnackbar } = useSnackbar();
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.content.query.getContent, {
		variables: {
			id: submission.contentID,
		},
	});

	const thumbnail = data?.content?.source.thumbnailUrl;
	const username = submission?.integration?.username;
	const { Icon } = destinationList.find(
		({ destination }) =>
			destination.toUpperCase() === submission?.integration?.destination
	);
	const submitDate = dateFromIso(submission?.submittedAt * 1000);
	const link = submission.link;
	return (
		<Dialog open={open} onClose={onClose}>
			<Card sx={{ maxWidth: 500 }}>
				<CardMedia
					sx={{ height: 140 }}
					image={thumbnail}
					title="green iguana"
				/>
				<CardContent>
					<Stack
						direction="row"
						spacing={1}
						sx={{
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Avatar
							sx={{
								backgroundColor: "transparent",
							}}
						>
							<Icon />
						</Avatar>
						<Typography>{submitDate}</Typography>
					</Stack>
					<Typography
						sx={{
							color:
								submission.status === "SUCCESS"
									? "success.main"
									: submission.status === "PENDING"
									? "warning.main"
									: submission.status === "FAILED"
									? "error.main"
									: "text.primary",
						}}
						variant="body2"
						color="text.secondary"
					>
						{submission.status}
					</Typography>
					<Typography gutterBottom variant="h5" component="div">
						{username}
					</Typography>

					<Typography variant="body2" color="text.secondary">
						Title: {submission.title}
					</Typography>
				</CardContent>
				<CardActions>
					<IconButton
						title="Navigate To Post"
						aria-label="share"
						onClick={() => {
							if (link) return window.open(link, "_blank");

							let key = enqueueSnackbar(
								"Link not available for this submission",
								{
									variant: "error",

									onClick: () => {
										closeSnackbar(key);
									},
								}
							);
						}}
					>
						<LinkIcon
							sx={{
								color: link ? "primary.main" : "error.main",
							}}
						/>
					</IconButton>
					<IconButton
						title="Navigate To Content"
						aria-label="share"
						onClick={() => {
							navigate(`/workspace/submission/${submission.id}`);
						}}
					>
						<NavigateIcon />
					</IconButton>
				</CardActions>
			</Card>
		</Dialog>
	);
}
