import { Button } from "@mui/material";
import { useRequester } from "@/Apollo";
import { useSnackbar } from "notistack";
export default function CreateOauthClientbutton() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useMutation, definitions } = useRequester();
	const [createOauthClient, { client }] = useMutation(
		definitions.oauth.mutation.createClient
	);
	return (
		<Button
			sx={{
				margin: "1rem",
			}}
			variant="contained"
			onClick={() =>
				createOauthClient({
					onCompleted: data => {
						// add new client to cache
						client.writeQuery({
							query: definitions.oauth.query.listOauthClients,
							data: {
								oauthClients: {
									clients: [
										...client.readQuery({
											query: definitions.oauth.query
												.listOauthClients,
										}).oauthClients.clients,
										data.createOauthClient.client,
									],
								},
							},
						});
					},
					onError: error => {
						console.log("error creating client:", error);
						const key = enqueueSnackbar(error.message, {
							variant: "error",
							onClick: () => closeSnackbar(key),
						});
					},
				})
			}
		>
			Create new Client
		</Button>
	);
}
