import { useEffect } from "react";
import { useRequester } from "../../../../../Apollo";
import { IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
export default function IntegrationSelector({
	destination,
	onSelect = () => {},
	selected = null,
}) {
	const { definitions, useQuery } = useRequester();
	const { data, fetchMore } = useQuery(
		definitions.integrations.query.integrations,
		{
			variables: {
				input: {
					destination: destination.destination.toUpperCase(),
				},
			},
			fetchPolicy: "cache-first",
		}
	);
	useEffect(() => {
		// ensure the appropriate integrations are fetched when the destination changes
		fetchMore({
			variables: {
				input: {
					destination: destination.destination.toUpperCase(),
				},
			},
		});
	}, [destination]);

	return (
		<>
			{data?.integrations.map((Integration, i) => {
				const { username, status, avatar } = Integration;
				return (
					<Fragment key={i}>
						<IconButton
							disableRipple
							sx={{
								backgroundColor:
									status !== "CONNECTED" ? "red" : "",
							}}
							onClick={() => onSelect(Integration)}
						>
							<img
								style={{
									borderRadius: "50%",
									width: "50px",
									height: "50px",
								}}
								src={avatar}
								alt={username}
							/>
						</IconButton>
						<Typography
							color={
								selected?.username === username
									? "success.main"
									: "text.secondary"
							}
						>
							{username}
						</Typography>
					</Fragment>
				);
			})}
		</>
	);
}
