import { gql } from "@apollo/client";

export const page = gql`
	query ($input: SubmissionsQueryInput!) {
		submissions(input: $input) {
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			edges {
				cursor
				node {
					id
					contentID
					createdAt
					deferUntil
					config {
						imgur {
							album
							description
							disable_audio
							name
						}
						reddit {
							subreddit
						}
						redgifs {
							keepAudio
							private
							tags
						}
					}
					integration {
						destination
						status
						username
					}
					status
					link
					publicationDestination
					submittedAt
					title
				}
			}
		}
	}
`;

export const submission = gql`
	query Submission($submissionId: ID!) {
		submission(id: $submissionId) {
			id
			createdAt
			title
			deferUntil
			submittedAt
			link
			publicationDestination
			config {
				reddit {
					parentSubmission {
						id
						createdAt
						title
						deferUntil
						submittedAt
						link
						publicationDestination
						contentID
						status
					}
					subreddit
				}
				imgur {
					name
					album
					description
					disable_audio
				}
				redgifs {
					private
					keepAudio
					tags
				}
				onlyfans {
					fundraising_target
					description
					dm_recipient
				}
			}
			integration {
				destination
				username
				avatar
				status
				is_default
			}
			contentID
			file {
				exif {
					gps_latitude
					gps_longitude
					data
					create_date
				}
				assetType
				createdAt
				extension
				hash
				id
				isProcessed
				mimetype
				name
				importFile {
					id
					import_id
					file_id
					created_at
					completed_at
					status
					key
					uploaded_at
				}
				size
				url
			}
			status
		}
	}
`;
