import { useState, useRef, useImperativeHandle, useEffect } from "react";
import { useRequester } from "../../../Apollo";
import ReactJoyride from "react-joyride";

import { IconButton } from "@mui/material";
import QuestionIcon from "@mui/icons-material/HelpOutline";
import { usePublishContentForm } from "..";
const JOYRIDE_DOMAIN = "PUBLISH";
export default function Joyride() {
	const { joyrideRef: parentRef } = usePublishContentForm();
	const ref = useRef(null);
	const [reset, setReset] = useState(0);

	useEffect(() => {
		parentRef.current = ref.current;
	}, [ref]);

	// useImperativeHandle(parentRef, () => ref.current, []);
	useImperativeHandle(parentRef, () => ({
		reset: () => setReset(reset + 1),
		helpers: {
			go: index => {
				ref.current?.helpers.go(index);
			},
		},
	}));
	const { definitions, useQuery, useMutation } = useRequester();
	const { data } = useQuery(definitions.user.query.onboardingProgress);
	const onStep =
		data?.onboardingProgress.progress[
			`on_step_${JOYRIDE_DOMAIN.toLowerCase()}`
		];

	const [run, setRun] = useState(onStep === 0);

	const [updateStepProgress] = useMutation(
		definitions.user.muation.onboardingProgress
	);
	const shouldRun = !onStep && run;

	return (
		<>
			<ReactJoyride
				ref={ref}
				run={shouldRun}
				continuous
				key={reset}
				hideBackButton
				// continuous
				showProgress
				showSkipButton
				spotlightClicks
				scrollToFirstStep={false}
				callback={({ action, index, status }) => {
					if (action === "close" || status === "skipped") {
						setRun(false);

						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 1,
								},
							},
						});
						updateStepProgress({
							variables: {
								input: {
									domain: "SCHEDULE",
									progress: 1,
								},
							},
						});
					}
					if (action === "next") {
						switch (index) {
							case 8: {
								break;
							}

							default:
								break;
						}
					}
				}}
				disableScrolling
				disableOverlayClose
				// debug
				styles={{
					overlay: {
						position: "fixed",
					},
					options: {
						zIndex: 1500,
					},
					buttonNext: {
						display: "none",
					},
				}}
				disableOverlay
				steps={[
					{
						title: "Choose a Hot Title",
						target: ".hot-titles",
						content:
							"These titles have been selected by our algorithm for being very successful. You can modify them as you wish, or input your own.",
						placement: "top",
						disableBeacon: true,
					},
					{
						title: "Select Content",
						target: ".content-publish-select-content",
						content:
							"Selct content from this menu. If you arrived here from the content page, this will be pre-selected for you.",

						placement: "top",
					},
					{
						title: "Select Destination",
						target: ".content-publish-select-destination",
						content:
							"Selct the destination where your content will be published.",

						placement: "top",
					},
					{
						title: "Select Integration",
						target: ".content-publish-select-integration",
						content:
							"Selct the integration account who will publish the content.",
						placement: "top",
					},
					{
						title: "Configure Post Options",
						target: ".content-publish-destination-options",
						content:
							"The destination you have chosen will have its own set of options. Reddit and Twitter require a seperate connection with either Imgur or Redgifs in order to post videos or images to their platforms.",
						placement: "top",
					},
					{
						title: "Configure Schedule",
						target: ".content-publish-scheduler-date-picker",
						content: "Now choose a date.",
						placement: "top",
					},
					{
						title: "Configure Schedule",
						target: ".content-publish-scheduler-time-picker",
						content: "Choose a time.",
						placement: "top",
					},
					{
						title: "Review Your Post",
						target: ".content-publish-review-options",
						content:
							"Review your selected options. If all looks good, click publish and it will be posted at the time and date you selected. If you would like to go back to a previous step, click the pencil icon to edit that step. Feel free to close this dialog if its in your way!",

						placement: "top",
					},
				]}
			/>
			<IconButton
				onClick={() => {
					updateStepProgress({
						variables: {
							input: {
								domain: "PUBLISH",
								progress: 0,
							},
						},
						onCompleted: () => {
							setRun(true);
							setReset(reset + 1);
						},
					});
				}}
				sx={{
					position: "absolute",
					bottom: 0,
					left: 0,
					zIndex: 1500,
				}}
			>
				<QuestionIcon />
			</IconButton>
		</>
	);
}
