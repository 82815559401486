import Calendar from "../../../../../Component/Schedule/Calendar";
import { BodyHeight } from "../../../../../Layout";
import Joyride from "../../Schedule/Joyride";

export default function SchedulePage() {
	return (
		<>
			<Calendar />
			<Joyride />
		</>
	);
}
