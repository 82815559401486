export default function formatSeconds(seconds) {
	try {
		const date = new Date(0);
		date.setSeconds(seconds); // specify value for SECONDS here
		const timeString = date.toISOString().substr(11, 8);
		// add ms to the end of the time string
		const ms = seconds.toString().split(".")[1]?.substr(0, 3);
		return `${timeString}.${ms ? ms : "0"}`;
	} catch (e) {}
	return "--:--:--";
}
