import { AppBar, Box, Toolbar } from "@mui/material";

import TimelineEditor from "../../TimelineEditor";
import PreviewPane from "../../PreviewPane";
import TitleBar from "../../TitleBar";

import BottomNavigationActions from "../../Toolbar/Actions/Mobile";
import EditorDrawer from "../../Toolbar/View/Drawer";

export default function ResponsiveDrawer() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100vw",
				height: "var(--app-height)",
				overflow: "hidden",
			}}
		>
			<AppBar
				position="fixed"
				sx={{
					width: "100%",
				}}
			>
				<TitleBar />
			</AppBar>
			<Toolbar />
			<Box
				sx={{
					flexGrow: 1,
					display: "flex",
					alignItems: "center",
					width: "100vw",
					overflow: "hidden",
				}}
			>
				<PreviewPane />
			</Box>
			<TimelineEditor />
			<EditorDrawer />
			<Box
				sx={{
					width: "100%",
					overflow: "hidden",
				}}
			>
				<BottomNavigationActions />
			</Box>
		</Box>
	);
}
