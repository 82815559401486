import { useRequester } from "@/Apollo";
import { useSnackbar } from "notistack";
export default function useSetChatbotEnabled() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useMutation, definitions } = useRequester();
	const [mutateSetChatbotEnabled] = useMutation(
		definitions.admin.mutation.SET_CHATBOT_ENABLED
	);
	return {
		setChatbotEnabled: async ({ workspaceId, enabled }) => {
			return mutateSetChatbotEnabled({
				variables: {
					input: {
						workspaceId,
						enabled,
					},
				},
				onError: error => {
					const key = enqueueSnackbar(error.message, {
						variant: "error",
						onClick: () => closeSnackbar(key),
					});
				},
				onCompleted: data => {
					const key = enqueueSnackbar(
						`Chatbot ${enabled ? "enabled" : "disabled"} `,
						{
							variant: "success",
							onClick: () => closeSnackbar(key),
						}
					);
				},
			});
		},
	};
}
