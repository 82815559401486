import { useState } from "react";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import RestoreIcon from "@mui/icons-material/Restore";
import SaveDialog from "./Dialog/Save";
export default function AutomationsSpeedDial() {
	const [open, setOpen] = useState(false);
	const actions = [
		{ icon: <SaveIcon />, name: "Save" },
		{
			icon: <RestoreIcon />,
			name: "Load",
		},
	];

	const handleClose = () => setOpen(null);
	return (
		<>
			<SaveDialog open={open === "Save"} onClose={handleClose} />

			<SpeedDial
				ariaLabel="automation actions"
				sx={{ position: "absolute", bottom: 16, right: 16 }}
				icon={<SpeedDialIcon />}
			>
				{actions.map(action => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={() => setOpen(action.name)}
					/>
				))}
			</SpeedDial>
		</>
	);
}
