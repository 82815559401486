import { useCallback } from "react";
import { NodeToolbar, useReactFlow } from "reactflow";
export default function Toolbar(props) {
	const { data, id } = props;
	const { setNodes, setEdges } = useReactFlow();
	const deleteNode = useCallback(() => {
		setNodes(nodes => nodes.filter(node => node.id !== id));
		setEdges(edges => {
			// remove all edges that have the nodeId as a target OR source
			return edges.filter(
				edge => edge.target !== id && edge.source !== id
			);
		});
	}, [setNodes, setEdges]);
	return (
		<NodeToolbar isVisible={data?.toolbarVisible} position={"bottom"}>
			<button onClick={deleteNode}>delete</button>
		</NodeToolbar>
	);
}
