import { useTheme } from "@mui/material";
export default function ConnectionLine({
	fromX,
	fromY,
	fromPosition,
	toX,
	toY,
	toPosition,
	connectionLineType,
	connectionLineStyle,
}) {
	const theme = useTheme();
	return (
		<g>
			<path
				fill="none"
				stroke={theme.palette.divider}
				strokeWidth={1.5}
				className="animated"
				d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
			/>
			<circle
				cx={toX}
				cy={toY}
				fill="#fff"
				r={3}
				stroke="#222"
				strokeWidth={1.5}
			/>
		</g>
	);
}
