import { gql } from "@apollo/client";

export const listOauthClients = gql`
	query OauthClients {
		oauthClients {
			clients {
				id
				name
				secret
				createdAt
				updatedAt
				redirectUris {
					id
					uri
					createdAt
				}
			}
		}
	}
`;
export const getOauthClient = gql`
	query OauthClient($input: OauthClientInput!) {
		oauthClient(input: $input) {
			client {
				id
				name
				secret
				createdAt
				updatedAt
				redirectUris {
					id
					uri
					createdAt
				}
			}
		}
	}
`;

export const clientSecret = gql`
	query ClientSecret($input: OauthClientInput!) {
		clientSecret(input: $input) {
			secret
		}
	}
`;

export const connections = gql`
	query OauthAppConnections {
		oauthAppConnections {
			connections {
				name
				client_id
				createdAt
				updatedAt
			}
		}
	}
`;
