import { gql } from "@apollo/client";

export const createCheckoutSession = gql`
	mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
		createCheckoutSession(input: $input) {
			id
			url
		}
	}
`;
