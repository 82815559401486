import { useRequester } from "../../../../Apollo";
import ContentTaggingInput from "../../../TaggingInput";
import { useSelectedContent } from "../../../SelectedContentContext";

export default function ContentTags() {
	const { definitions, useQuery } = useRequester();
	const { id } = useSelectedContent();

	const { data, loading } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});
	if (loading) return;

	return (
		<ContentTaggingInput
			contentId={id}
			defaultValue={data?.content?.metadataTags || []}
		/>
	);
}
