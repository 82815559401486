import { Button } from "@mui/material";
import { useContentSearch } from "@/ContentSearch/Context";
export default function RefineButton() {
	const { refine, displayName } = useContentSearch();
	if (displayName !== "ContentSearch.WithHistory") return;
	return (
		<Button variant={"outlined"} fullWidth color={"info"} onClick={refine}>
			Refine
		</Button>
	);
}
