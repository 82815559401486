import {
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemButton,
	Divider,
	Avatar,
} from "@mui/material";
import destinations from "../../../Destinations";
import { useRequester } from "../../../Apollo";
import { useState } from "react";
import AddNewIntegration from "../../Integration/AddNew";
import NextButton from "../NextButton";
export default function IntegrationSelector({
	destination,
	onSelect,
	defaultSelected,
	disabled = false,
}) {
	const [selected, setSelected] = useState(defaultSelected);
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.integrations.query.integrations, {
		variables: {
			input: {
				destination: destination.toUpperCase(),
			},
		},
		fetchPolicy: "cache-first",
	});

	const integrations = data?.integrations || [];
	const DestinationIcon = destinations.find(
		dest => dest.destination === destination
	).Icon;

	return (
		<>
			<List>
				<ListItem>
					<ListItemAvatar>
						<DestinationIcon />
					</ListItemAvatar>
					<ListItemText primary={destination} />
				</ListItem>
				<Divider />

				{integrations.map(integration => {
					const { avatar, username } = integration;
					return (
						<ListItemButton
							disabled={disabled}
							key={username}
							selected={selected?.username === username}
							onClick={() => {
								setSelected(username);
								onSelect?.(integration);
							}}
						>
							<ListItemAvatar>
								<Avatar
									sx={{
										backgroundColor: "transparent",
									}}
								>
									<Avatar src={avatar} alt={username} />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={username} />
						</ListItemButton>
					);
				})}
				{!integrations.length && (
					<AddNewIntegration destination={destination} />
				)}
			</List>
			<NextButton
				disabled={!selected}
				onClick={() => {
					onSelect?.(selected);
				}}
			/>
		</>
	);
}
