import { useState, useCallback, useRef } from "react";
import ReactFlow, {
	useNodesState,
	useEdgesState,
	addEdge,
	Background,
} from "reactflow";
import "reactflow/dist/style.css";

import AutomationsSpeedDial from "./SpeedDial";

import Controls from "./Controls";
import NodeContextMenu from "./ContextMenu/Node";
import PaneContextMenu from "./ContextMenu/Pane";
import ConnectionLine from "./ConnectionLine";

import ColorSelectorNode from "./ColorSelectorNode";
import RenderNode from "./NodeType/Render";
import PublishNode from "./NodeType/Publish";
import EventNode from "./NodeType/Event";

import DeletableEdge from "./EdgeType/Deletable";

import "./Styles/index.css";

const nodeTypes = {
	selectorNode: ColorSelectorNode,
	renderNode: RenderNode,
	publishNode: PublishNode,
	eventNode: EventNode,
};
const edgeTypes = {
	deletableEdge: DeletableEdge,
};
export default function CustomNodeFlow() {
	const [nodes, setNodes, onNodesChange] = useNodesState([
		{
			id: "0",
			type: "eventNode",
			position: { x: 175, y: 0 },
			data: { label: "Start" },
		},
	]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [nodeMenu, setNodeMenu] = useState({
		nodeId: null,
		position: { top: 0, left: 0 },
		open: false,
	});
	const [paneMenu, setPaneMenu] = useState({
		position: { top: 0, left: 0 },
		open: false,
	});
	const reactflowRef = useRef(null);

	const onNodeContextMenu = useCallback(
		(event, node) => {
			// Prevent native context menu from showing
			event.preventDefault();

			// Calculate position of the context menu. We want to make sure it
			// doesn't get positioned off-screen.

			setNodeMenu({
				nodeId: node.id,
				position: {
					top: event.clientY,
					left: event.clientX,
				},
				open: true,
			});
		},
		[setNodeMenu]
	);
	const onPaneContextMenu = useCallback(
		event => {
			// show pane menu
			event.preventDefault();
			setPaneMenu({
				position: {
					top: event.clientY,
					left: event.clientX,
				},
				open: true,
			});
		},
		[setPaneMenu]
	);

	const onPaneClick = useCallback(() => {
		// Close open context menus
		setNodeMenu({
			position: { ...nodeMenu.position },
			open: false,
		});
		setPaneMenu({
			position: { ...paneMenu.position },
			open: false,
		});
	}, [setNodeMenu, setPaneMenu]);

	const onConnect = useCallback(
		params => {
			console.log("onConnect", params);
			if (!params.target) return; // don't allow null connection
			if (params.source === params.target) return; // don't allow connect to self
			const existingEdge = edges.find(
				edge =>
					edge.source === params.source ||
					edge.target === params.target
			);
			if (existingEdge) return; // don't allow multiple connections to same node

			setEdges(els =>
				addEdge(
					{
						...params,
						type: "deletableEdge",
					},
					els
				)
			);
		},
		[edges]
	);

	return (
		<ReactFlow
			ref={reactflowRef}
			style={{
				userSelect: "none",
			}}
			nodeTypes={nodeTypes}
			edgeTypes={edgeTypes}
			nodes={nodes}
			edges={edges}
			fitView
			connectionLineComponent={ConnectionLine}
			onNodesChange={onNodesChange}
			onEdgesChange={onEdgesChange}
			onConnect={onConnect} // handle connection creation
			onPaneContextMenu={onPaneContextMenu} // show pane menu
			onNodeContextMenu={onNodeContextMenu} // show node menu
			onPaneClick={onPaneClick} // close all open menus on pane click
		>
			<Background />
			{nodeMenu.open && (
				<NodeContextMenu
					open={nodeMenu.open}
					anchorPosition={nodeMenu.position}
					onClose={onPaneClick}
					nodeId={nodeMenu.nodeId}
				/>
			)}
			{reactflowRef.current && (
				<>
					<PaneContextMenu
						open={paneMenu.open}
						anchorPosition={paneMenu.position}
						onClose={onPaneClick}
						currentReactFlowRef={reactflowRef.current}
					/>
					<Controls currentReactFlowRef={reactflowRef.current} />
				</>
			)}

			<AutomationsSpeedDial />
		</ReactFlow>
	);
}
