import { useRequester } from "@/Apollo";
import { Grid, Button, Box, Pagination } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import depthEffect from "@/Theme/sx/depth-effect";
import { useState, createContext, useContext } from "react";
import MatchItem from "./MatchItem";
import usePruneDuplicates from "./usePruneDuplicates";
const LIMIT = 100;
const totalCountToPageCount = totalCount => {
	return Math.floor(totalCount / LIMIT) || 1;
};
const Context = createContext({
	hash: null,
	data: null,
	page: 1,
	count: 0,
	setPage: () => {},
	refetch: () => {},
});
export default function MatchesList({ hash }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const page = parseInt(searchParams.get("page") || 1);
	const setPage = page => setSearchParams({ page });

	const { definitions, useQuery, client } = useRequester();

	const [count, setCount] = useState(
		client.cache.readQuery({
			query: definitions.common.query.matches,
		})?.matches?.distinctCount || 0
	);
	const {
		data: _data,
		previousData,
		refetch,
	} = useQuery(
		definitions.common.query.matches,

		{
			fetchPolicy: "network-only",
			variables: {
				input: {
					page,
					hash,
					limit: LIMIT,
				},
			},
			onCompleted: data => setCount(data?.matches?.distinctCount),
		}
	);
	// no flashing on page change
	const data = _data || previousData;
	// const data = _data;

	return (
		<Context.Provider
			value={{
				hash,
				data,
				page,
				count,
				setPage,
				refetch,
			}}
		>
			<MatchesListInner hash={hash} />
		</Context.Provider>
	);
}
export const useMatchesListContext = () => useContext(Context);
function MatchesListInner() {
	const { hash, data, setPage, page, count, setCount } =
		useMatchesListContext();
	const navigate = useNavigate();

	const { prune } = usePruneDuplicates();
	const handlePrune = () => prune({ hash });
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				height: "100%",
			}}
		>
			<Box
				sx={{
					width: "100%",
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: theme => theme.palette.background.paper,
				}}
			>
				{hash && (
					<>
						<Button
							sx={{
								color: theme => theme.palette.text.primary,
							}}
							onClick={() => navigate(`/workspace/match`)}
						>
							Show All
						</Button>
						<Button
							sx={{
								color: theme => theme.palette.text.primary,
							}}
							onClick={handlePrune}
						>
							Prune Duplicates
						</Button>
					</>
				)}
			</Box>

			<Grid
				container
				sx={{
					padding: "1rem",
				}}
			>
				{data?.matches?.edges?.map(({ node: content }, i) => (
					<MatchItem key={i} content={content} />
				))}
			</Grid>
			<Box
				sx={{
					flexGrow: 1,
				}}
			/>
			<Box
				sx={{
					position: "sticky",
					bottom: 0,
					left: 0,
					// width: "100%",
					height: "fit-content",
					width: "fit-content",
					alignSelf: "center",

					zIndex: 1,
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Pagination
					count={totalCountToPageCount(count)}
					sx={{
						padding: "4px",
						backgroundColor: "background.paper",
						...depthEffect(4),
					}}
					page={page}
					showLastButton
					showFirstButton
					size="small"
					onChange={(e, page) => {
						setPage(page);
					}}
				/>
			</Box>
		</Box>
	);
}
