import { createContext, useContext, useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useRequester } from "../Apollo";
import { useSnackbar } from "notistack";
import { throttle } from "lodash";
import "./style.css";

export { NotificationsWidget } from "./Widget";
const dedupe = arr => {
	return arr.filter((v, i) => arr.findIndex(t => t.id === v.id) === i);
};
const PAGE_SIZE = 10;
const ENTITY_TYPES = [
	"content",
	"import",
	"integration",
	"submission",
	"match",
];
const LEVELS = ["ADMIN", "ERROR", "WARNING", "INFO", "SUCCESS"];
const Context = createContext({
	notifications: [],
	loading: false,
	fetchMore: () => {},

	acknowledgeNotification: notification => {},
	acknowledgeAllNotifications: () => {},

	hideNotification: id => {},
	hideAllNotifications: () => {},

	pageSize: PAGE_SIZE,
	onPage: 0,
	totalCount: 0,
	countNew: 0,
	getNextPage: async () => {},
	showAcknowledged: true,
	toggleShowAcknowledged: () => {},
	entityTypesFilter: null,
	setEntityTypesFilter: () => {},
	entityTypes: ENTITY_TYPES,
	levelsFilter: [],
	setLevelsFilter: () => {},
	levels: LEVELS,
	showingNotification: null,
	setShowingNotification: () => {},
});

export const useNotifications = () => useContext(Context);
function NotificationsContextProvider({ children }) {
	/* Subscribe To Notifications */
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const {
		definitions,
		useSubscription,
		useMutation,
		useQuery,
		workspace_id,
		client,
	} = useRequester();

	const [notifications, setNotifications] = useState([]);
	const [showingNotification, setShowingNotification] = useState(null);
	const [entityTypesFilter, setEntityTypesFilter] = useState(ENTITY_TYPES);
	const [levelsFilter, setLevelsFilter] = useState([]);
	const { data: countNewData, refetch: recountNotifications } = useQuery(
		definitions.notifications.query.countNewNotifications
	);
	const countNew = countNewData?.countNewNotifications?.count;

	const handleUpdateNotifications = newNotifications => {
		setNotifications((notifications = []) => {
			const notif = newNotifications.map(edge => edge.node);
			const deduped = dedupe([...notifications, ...notif]);
			return deduped;
		});
	};
	const handlePrependNotifications = newNotifications => {
		setNotifications((notifications = []) => {
			const deduped = dedupe([...newNotifications, ...notifications]);
			return deduped;
		});
	};
	const [showAcknowledged, setShowAcknowledged] = useState(true);
	const toggleShowAcknowledged = () => {
		setNotifications([]);
		setShowAcknowledged(show => !show);
	};

	const pageRef = useRef(1);
	const { loading, data, fetchMore, variables } = useQuery(
		definitions.notifications.query.pageNotifications,
		{
			variables: {
				workspaceId: workspace_id,
				page: 1,
				limit: PAGE_SIZE,
				showAcknowledged: showAcknowledged,
				showHidden: true,
				entityTypes: entityTypesFilter,
				levels: levelsFilter,
			},
			// filter out missing permission errors
			errorPolicy: "all",
			fetchPolicy: "network-only",
			onCompleted: data => {
				const notificationsData = data.notifications.edges;
				handleUpdateNotifications(notificationsData);
			},
			onError: error => {
				if (error.message.includes("Missing required permission:")) {
					// this is handled globally. need to filter out missing permission toasts
					return;
				}

				const key = enqueueSnackbar("Error fetching notifications", {
					variant: "error",
					autoHideDuration: 5000,
					onClick: () => closeSnackbar(key),
				});
			},
		}
	);

	useSubscription(definitions.notifications.subscription.notifications, {
		fetchPolicy: "cache-first",
		shouldResubscribe: true,
		onData: ({ data }) => {
			client.refetchQueries({
				include: [
					definitions.notifications.query.countNewNotifications,
				],
			});
			const notificationsData = data.data?.notifications?.notifications;
			const count = notificationsData.length;
			if (count === 0) return;
			// refetch countNewNotifications
			recountNotifications();
			// prepend new notifications to the infinite scroll list
			handlePrependNotifications(notificationsData);
			const key = enqueueSnackbar(
				`New notification${count > 1 ? "s" : ""}`,
				{
					autoHideDuration: 5000,
					variant: "info",
					onClick: () => closeSnackbar(key),
				}
			);
		},
	});

	const [acknowledge] = useMutation(
		definitions.notifications.mutation.acknowledge,
		{
			onError: error => {
				enqueueSnackbar("Error acknowledging notification", {
					variant: "error",
					autoHideDuration: 5000,
				});
			},
			onCompleted: data => {
				enqueueSnackbar("Notification acknowledged", {
					variant: "success",
					autoHideDuration: 5000,
				});
			},
		}
	);
	const [acknowledgeAll] = useMutation(
		definitions.notifications.mutation.acknowledgeAll,
		{
			onError: error => {
				enqueueSnackbar("Error acknowledging notifications", {
					variant: "error",
					autoHideDuration: 5000,
				});
			},
			onCompleted: data => {
				setNotifications((notifications = []) => {
					return notifications.map(n => {
						if (n.acknowledged_at) return n;
						return {
							...n,
							acknowledged_at: new Date(),
						};
					});
				});
				recountNotifications();
				const key = enqueueSnackbar("Notifications acknowledged", {
					variant: "success",

					autoHideDuration: 5000,
					onClick: () => closeSnackbar(key),
				});
			},
		}
	);

	const [hide] = useMutation(definitions.notifications.mutation.hide, {
		onError: error => {
			enqueueSnackbar("Error acknowledging notification", {
				variant: "error",
				autoHideDuration: 5000,
			});
		},
		onCompleted: data => {
			enqueueSnackbar("Notification acknowledged", {
				variant: "success",
				autoHideDuration: 5000,
			});
		},
	});

	const [hideAll] = useMutation(definitions.notifications.mutation.hideAll, {
		onError: error => {
			enqueueSnackbar("Error acknowledging notifications", {
				variant: "error",

				autoHideDuration: 5000,
			});
		},
		onCompleted: data => {
			const key = enqueueSnackbar("Notifications acknowledged", {
				variant: "success",
				autoHideDuration: 5000,
				onClick: () => closeSnackbar(key),
			});
		},
	});

	const handleGetNextPage = async () => {
		const page = pageRef.current + 1;
		pageRef.current = page;
		const res = await fetchMore({
			variables: {
				...variables,
				page,
			},
		});
		const newNotifications = res?.data?.notifications?.edges;
		if (!newNotifications) return;
		handleUpdateNotifications(newNotifications);
	};

	const throttledSetEntityTypesFilter = throttle(setEntityTypesFilter, 1000);

	return (
		<Context.Provider
			value={{
				entityTypes: ENTITY_TYPES,
				entityTypeFilter: entityTypesFilter,
				levelsFilter: levelsFilter,
				showingNotification,
				setShowingNotification,
				setLevelsFilter: levels => {
					// add all levels which exist in LEVELS array

					// ensure only unique levels
					const newLevels = [
						...new Set(
							levels.filter(level => LEVELS.includes(level))
						),
					];

					setNotifications([]);
					setLevelsFilter(newLevels);
				},
				levels: LEVELS,
				setEntityTypesFilter: entityTypes => {
					const newTypes = entityTypes.filter(type =>
						ENTITY_TYPES.includes(type)
					);

					setNotifications([]);
					throttledSetEntityTypesFilter(newTypes);
				},
				hideNotification: async id => {
					// TODO: call API to hide notification
					await hide({
						variables: {
							input: {
								id,
							},
						},

						onError: error => {
							let key = enqueueSnackbar(
								"Error acknowledging notification",
								{
									variant: "error",
									autoHideDuration: 5000,
									onClick: () => {
										closeSnackbar(key);
									},
								}
							);
						},
					});
				},
				showAcknowledged,
				toggleShowAcknowledged,
				acknowledgeNotification: async notification => {
					if (notification.acknowledged_at) return;
					const { id } = notification;

					// TODO: call API to acknowledge notification
					await acknowledge({
						variables: {
							input: {
								id,
							},
						},
						onCompleted: data => {
							const notification =
								data.notificationAcknowledge.notification;
							setNotifications(notifications => {
								const found = notifications.find(
									notification => notification.id === id
								);
								if (!found) return notifications;
								return notifications.map(n => {
									if (n.id !== id) return n;
									return {
										...n,
										acknowledged_at: new Date(),
									};
								});
							});
							recountNotifications();
						},

						onError: error => {
							console.log("error: ", error);
							let key = enqueueSnackbar(
								"Error acknowledging notification",
								{
									variant: "error",
									autoHideDuration: 5000,
									onClick: () => {
										closeSnackbar(key);
									},
								}
							);
						},
					});
				},
				hideAllNotifications: async () => {
					// TODO: call API to clear all notifications
					await hideAll();
				},
				acknowledgeAllNotifications: async () => {
					acknowledgeAll();
				},
				getNextPage: () => handleGetNextPage(notifications),
				pageSize: PAGE_SIZE,
				loading,
				totalCount:
					data?.workspace?.notificationsConnection?.totalCount,
				countNew,
				notifications,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export default function AuthenticatedNotifications({ children }) {
	const { isAuthenticated } = useAuth0();

	if (!isAuthenticated) return children;
	return (
		<NotificationsContextProvider>{children}</NotificationsContextProvider>
	);
}
