import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
const Logo512 = "/logo512.png";

export default function Head() {
	const { pathname } = useLocation();

	const url = `${process.env.REACT_APP_SITE_URL}${pathname}`;

	const Logo = `${process.env.REACT_APP_SITE_URL}${Logo512}`;

	const keywords = process.env.REACT_APP_SITE_KEYWORDS || "";
	const creator = process.env.REACT_APP_SITE_CREATOR || "@BangoBotto";

	const title =
		"🔥 FanHeat - Production content management automation for creators.";
	const description =
		"FanHeat is a content creation and automation platform for creators. We offer a suite of tools to help you manage your content, automate your marketing, and grow your fanbase.";

	return (
		<Helmet prioritizeSeoTags>
			{/* Basic Tags */}
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords} />
			<link rel="canonical" href={""} />
			{/* Open Graph Tags */}
			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:url" content={url} />

			<meta property="og:image" content={Logo} />
			<meta property="og:image:secure_url" content={Logo} />
			<meta property="og:image:type" content="image/png" />
			<meta property="og:image:width" content="512" />
			<meta property="og:image:height" content="512" />
			<meta property="og:image:alt" content={title} />
			<meta property="og:site_name" content={title} />

			<meta property="og:locale" content="en_US" />

			{/* Twitter Tags */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content={creator} />
			<meta name="twitter:description" content={description} />

			<meta name="twitter:creator" content={creator} />
			<meta name="twitter:title" content={title} />

			<meta name="twitter:image" content={Logo} />
			<meta name="twitter:image:alt" content={title} />
		</Helmet>
	);
}
