import {
	CardMedia,
	Card,
	CardHeader,
	CardActions,
	IconButton,
	CardActionArea,
	Typography,
} from "@mui/material";
import GoToIcon from "@mui/icons-material/ArrowForwardIos";
import { useRequester } from "../../../../Apollo";
import SelectedContentContext from "../../../SelectedContentContext";

import { useFolderContent } from "../hook/useFolderContent";
import CardContentActions from "../../../Content/Card/Actions";
import { useNavigate } from "react-router";
import { useEffect } from "react";
export default function ViewContentDetails() {
	const navigate = useNavigate();
	const { selectedId: id } = useFolderContent();

	const { definitions, useLazyQuery } = useRequester();
	const [getContent, { data }] = useLazyQuery(
		definitions.content.query.getContent
	);
	useEffect(() => {
		if (!id) return;
		getContent({
			variables: {
				id,
			},
			fetchPolicy: "network-only",
		});
	}, [id]);

	const content = data?.content;

	let title = content ? content.title : "No Content Selected";
	if (title.length > 50) title = title.slice(0, 50) + "...";

	const thumbnail = content?.source?.thumbnailUrl;
	const url = content?.source?.url;
	const isVideo = content?.source?.mimetype?.includes("video");

	return (
		<Card sx={{ maxWidth: "22rem" }}>
			<CardActionArea
				sx={{
					width: "100%",
					textAlign: "right",
					display: "flex",
					paddingY: "0.5rem",
					justifyContent: "space-between",
				}}
				disabled={!id}
				onClick={() => id && navigate(`/workspace/content/${id}`)}
			>
				<Typography
					sx={{
						marginLeft: "1rem",
						color: theme => {
							return !id
								? theme.palette.action.disabled
								: "inherit";
						},
					}}
				>
					Navigate to Content
				</Typography>

				<GoToIcon />
			</CardActionArea>
			<CardHeader title={title} />

			<CardMedia
				sx={{
					height: 140,
					width: "auto",
					objectFit: "contain",
					// center image
					marginX: "auto",

					visibility: thumbnail ? "visible" : "hidden",
				}}
				image={isVideo ? url : thumbnail}
				title={title}
				component={isVideo ? "video" : thumbnail ? "img" : "div"}
				controls={isVideo}
			/>

			<CardActions
				sx={{
					width: "100%",
					overflowX: "scroll",
				}}
			>
				<SelectedContentContext id={id}>
					<CardContentActions />
				</SelectedContentContext>
			</CardActions>
		</Card>
	);
}
