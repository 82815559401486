import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { IconButton } from "@mui/material";
export default function FullScreenButton({ onClick = () => {}, isFullScreen }) {
	return (
		<IconButton onClick={onClick}>
			{isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
		</IconButton>
	);
}
