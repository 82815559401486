import NextButton from "../../NextButton";
import { FormControl } from "@mui/material";
import { usePublishContentForm } from "../..";
import { useSnackbar } from "notistack";
import SourceAccountSelector from "./SourceAccountSelector";
export default function TwitterOptions({ hideNext, disabled = false }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const {
		nextStep,
		twitter: { sourceAccount },
	} = usePublishContentForm();
	return (
		<FormControl
			component={"form"}
			fullWidth
			onSubmit={e => {
				e.preventDefault();
				if (!sourceAccount) {
					let key = enqueueSnackbar(
						"Please select a source account",
						{
							variant: "error",
							onClick: () => {
								closeSnackbar(key);
							},
						}
					);
					return;
				}
				nextStep();
			}}
		>
			<SourceAccountSelector disabled={disabled} />
			{!hideNext && <NextButton />}
		</FormControl>
	);
}
