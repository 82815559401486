import { Fragment } from "react";
import {
	Box,
	Avatar,
	List,
	ListItem,
	ListItemText,
	IconButton,
	ListItemAvatar,
	Divider,
	Chip,
	Stack,
	ListItemButton,
} from "@mui/material";
import { useAssistantContext } from "..";
import EditIcon from "@mui/icons-material/AdminPanelSettings";
import { useRequester } from "../../../../Apollo";
import { makeCDNPath } from "@/util/makeCDNPath";

export default function AssistantsList() {
	const { setSelectedAssistant, setOpen } = useAssistantContext();
	const handleSelect = assistant => {
		setSelectedAssistant(assistant);
		setOpen();
	};
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.virtualAssistant.query.assistants, {
		fetchPolicy: "cache-and-network",
	});
	const assistants = data?.virtualAssistants?.assistants || [];
	return (
		<List dense>
			{assistants.map(assistant => {
				return (
					<Fragment key={JSON.stringify(assistant)}>
						<ListItem disableGutters>
							<ListItemButton
								onClick={() => handleSelect(assistant)}
							>
								<ListItemAvatar>
									<Avatar
										alt={assistant.public_display_name}
										src={makeCDNPath(
											`avatar/user/${assistant.user_id}`
										)}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={assistant.public_display_name}
									secondary={
										<Box
											className="hidden-scrollbar"
											sx={{
												overflowX: "auto",
											}}
										>
											<Stack direction="row" spacing={1}>
												{assistant.roles.map(role => {
													return (
														<Chip
															key={role.id}
															label={role.name}
															sx={{
																backgroundColor:
																	role.color,
															}}
														/>
													);
												})}
											</Stack>
										</Box>
									}
								/>
								<IconButton>
									<EditIcon />
								</IconButton>
							</ListItemButton>
						</ListItem>
						<Divider variant="inset" component="li" />
					</Fragment>
				);
			})}
		</List>
	);
}
