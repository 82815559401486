import { useRequester } from "@/Apollo";
import {
	Popover,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Tooltip,
	IconButton,
	ListItemSecondaryAction,
	Divider,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ViewRoleIcon from "@mui/icons-material/ViewList";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
export default function WorkspaceInfoPopover({
	anchorEl,
	setAnchorEl,
	workspace,
}) {
	const navigate = useNavigate();
	const { definitions, useQuery } = useRequester();
	const { data } = useQuery(definitions.user.query.getUser, {
		fetchPolicy: "network-only",
	});

	const getRoleString = () => {
		const user = data?.user;
		if (!user) return "Loading...";
		if (!workspace) return "Loading...";
		if (workspace.admin.user_id === data?.user.id) return "👑Admin";
		console.log("User data: ", data);
		console.log("finding assistant: ", data?.user.id, workspace.assistants);
		const assistant = workspace.assistants.find(
			assistant => assistant.user_id === data?.user.id
		);
		console.log({ assistant });
		const roleString = assistant?.roles.reduce((acc, role) => {
			return acc + role.name + ", ";
		}, "");
		return roleString?.slice(0, -2) || "Member";
	};
	const list = useMemo(() => {
		return [
			{
				primary: `${workspace?.name}`,
				secondary: `Created: ${new Date(
					workspace?.createdAt
				).toDateString()}`,
				icon: (
					<Avatar
						src={
							`https://${process.env.REACT_APP_CDN_HOST}/avatar/workspace/` +
							workspace?.id
						}
					/>
				),
				onClick: () => {},
			},
			{
				primary: "Admin:",
				secondary: `@${workspace?.admin.public_display_name}`,
				icon: (
					<Avatar
						src={
							`https://${process.env.REACT_APP_CDN_HOST}/avatar/user/` +
							workspace?.admin.id
						}
					/>
				),
				action: {
					title: "Send Message",
					icon: <EmailIcon />,
					onClick: () => {
						console.log("send message");
					},
				},
			},
			{
				primary: "Your Role:",
				secondary: getRoleString(),
				icon: (
					<Avatar
						src={
							`https://${process.env.REACT_APP_CDN_HOST}/avatar/user/` +
							data?.user.id
						}
					/>
				),

				action: {
					title: "View your role",
					icon: <ViewRoleIcon />,
					onClick: () =>
						navigate(`/workspace/assistants/${data?.user.id}`),
				},
			},
		];
	}, [data, workspace]);

	const items = list.reduce((acc, item, i) => {
		acc.push(
			<ListItem key={i}>
				<ListItemAvatar>{item.icon}</ListItemAvatar>
				<ListItemText
					primary={item.primary}
					secondary={item.secondary}
				/>
				{!item.action ? (
					<></>
				) : (
					<>
						<Tooltip title={item.action.title}>
							<ListItemSecondaryAction>
								<IconButton onClick={item.action.onClick}>
									{item.action.icon}
								</IconButton>
							</ListItemSecondaryAction>
						</Tooltip>
					</>
				)}
			</ListItem>
		);

		if (i < list.length - 1) acc.push(<Divider key={`divider-${i}`} />);
		return acc;
	}, []);
	return (
		<Popover
			open={!!anchorEl}
			anchorEl={anchorEl}
			onClose={() => setAnchorEl(null)}
		>
			<List>{items}</List>
		</Popover>
	);
}
