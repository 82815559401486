import { Button } from "@mui/material";
import FHTooltip from "../../../../../FHTooltip";
import CheckIcon from "@mui/icons-material/Check";
import { useContentEditor } from "../../../..";

export default function RenderContent() {
	const { createVersion, validateInput, variables, loading } =
		useContentEditor();

	const { isValid, reason } = validateInput(variables);
	return (
		<FHTooltip title={isValid ? "Create a new version" : reason}>
			<Button
				className="render-version-btn"
				disabled={!isValid || loading}
				title={reason}
				variant="contained"
				onClick={() => {
					createVersion();
				}}
				sx={{
					width: "fit-content",
					height: "fit-content",
				}}
				endIcon={<CheckIcon />}
			>
				Render
			</Button>
		</FHTooltip>
	);
}
