import {
	Box,
	TextField,
	DialogTitle,
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useRequester } from "../../../Apollo";

import ClickableAvatar from "./ClickableAvatar";

export default function WorkspaceUpdateDialog({ show, handleClose }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { definitions, useMutation, useQuery, workspace_id } = useRequester();

	const { data } = useQuery(definitions.workspace.query.getWorkspace, {
		fetchPolicy: "cache-and-network",
		variables: {
			id: workspace_id,
		},
	});

	const workspace = data?.workspace;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [updateWorkspace] = useMutation(
		definitions.workspace.mutation.update
	);

	return (
		<Dialog open={show} onClose={handleClose} fullScreen={isMobile}>
			<Box
				component="form"
				onSubmit={e => {
					e.preventDefault();
					const name = e.target.name.value;
					updateWorkspace({
						variables: {
							input: {
								id: workspace?.id,
								name,
							},

							refetchQueries: [
								{
									query: definitions.workspace.query
										.getWorkspace,
								},
							],
						},
						onCompleted: () => {
							let key = enqueueSnackbar("Workspace renamed", {
								variant: "success",
								onClick: () => {
									closeSnackbar(key);
								},
							});
						},
						onError: () => {
							let key = enqueueSnackbar(
								"Failed to rename workspace",
								{
									variant: "error",
									onClick: () => {
										closeSnackbar(key);
									},
								}
							);
						},
					});
				}}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					maxWidth: "100%",
				}}
			>
				<DialogTitle>Update Workspace</DialogTitle>
				<DialogContent>
					<ClickableAvatar />
				</DialogContent>
				<TextField
					label="Name"
					name="name"
					defaultValue={workspace?.name}
				/>
				<DialogActions>
					<Button
						sx={{
							width: "100%",
							color: theme =>
								theme.palette.getContrastText(
									theme.palette.primary.main
								),
						}}
						type="submit"
					>
						Update
					</Button>
					<Button
						sx={{
							width: "100%",
							color: theme =>
								theme.palette.getContrastText(
									theme.palette.primary.main
								),
						}}
						onClick={handleClose}
					>
						Done
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}
