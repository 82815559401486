// get the data past the # in the url
import { useLocation } from "react-router-dom";
import { BodyHeight } from "@/Layout";
import { QueryClientProvider, QueryClient, useQuery } from "react-query";
import axios from "axios";
import {
	Typography,
	Button,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from "@mui/material";
import qs from "qs";

const SuccessContent = ({ clientName, authURL }) => {
	return (
		<>
			<CardContent>
				<Typography variant="h5">
					{clientName} would like to connect to your account.
				</Typography>

				<Typography
					variant="body1"
					sx={{
						marginTop: "12px",
					}}
				>
					This will allow {clientName} to access your account
					information.
				</Typography>
			</CardContent>
			<CardActions>
				<Button variant="contained" color="primary" href={authURL}>
					Confirm
				</Button>
				<Button variant="contained" color="secondary" href={"/account"}>
					Deny
				</Button>
			</CardActions>
		</>
	);
};
const LoadingContent = () => {
	return (
		<CardContent>
			<Typography>Loading...</Typography>
		</CardContent>
	);
};
const ErrorContent = ({ error }) => {
	return (
		<CardContent>
			<Typography>Error: {error.message}</Typography>
		</CardContent>
	);
};
const requester = axios.create({
	baseURL: `${process.env.REACT_APP_OAUTH_API_ENDPOINT}/v1/`,
});
export default function PageApiConfirmConnect() {
	const queryClient = new QueryClient();
	return (
		<QueryClientProvider client={queryClient}>
			<ApiConfirmConnect />
		</QueryClientProvider>
	);
}

function ApiConfirmConnect() {
	const location = useLocation();
	const query = qs.parse(location.search.slice(1));
	const state = decodeURIComponent(query.state);

	const { data, error } = useQuery(
		`oauth/state`,
		async ({ queryKey, signal }) => {
			const res = await requester.get(queryKey + `?state=${state}`, {
				signal,
			});
			return res.data;
		},
		{
			retry: false,
			cacheTime: 0,
		}
	);

	let Content = <LoadingContent />;

	if (error) {
		Content = <ErrorContent error={error} />;
	}
	if (data) {
		Content = (
			<SuccessContent
				clientName={data.oauthClientName}
				authURL={data.authURL}
			/>
		);
	}

	return (
		<BodyHeight>
			<Card
				sx={{
					maxWidth: 500,
					margin: "auto",
					marginTop: "12px",
				}}
			>
				<CardHeader title="Confirm Connection" />
				{Content}
			</Card>
		</BodyHeight>
	);
}
