import SettingsPanel from "../../../../../../../../Component/Settings";
import useLocalStorage from "@/util/useLocalStorage";
import { useState } from "react";
const KEY = {
	missingPermission: `showToast:missingPermission`,
	contentAdded: `shouldDisplayContentAddedSnackbar`,
};
export default function ToastTogler() {
	const localStorage = useLocalStorage();

	const defaultMissingPermission = localStorage.get(KEY.missingPermission);
	const [showMissingPermission, setShowMissingPermission] = useState(
		defaultMissingPermission === undefined ? true : defaultMissingPermission
	);
	const defaultContentAdded = localStorage.get(KEY.contentAdded);
	const [showContentAdded, setShowContentAdded] = useState(
		defaultContentAdded === undefined ? true : defaultContentAdded
	);

	return (
		<SettingsPanel
			className={"toast-toggler"}
			options={[
				{
					label: "Show VA missing permissions toast",
					value: showMissingPermission,
					onChange: () => {
						setShowMissingPermission(prev => {
							localStorage.set(
								KEY.missingPermission,
								!showMissingPermission
							);
							return !prev;
						});
					},
				},
				{
					label: "Display content added popup",
					value: showContentAdded,
					onChange: () => {
						setShowContentAdded(prev => {
							localStorage.set(
								KEY.contentAdded,
								!showContentAdded
							);
							return !prev;
						});
					},
				},
			]}
		/>
	);
}
