import { useNavigate } from "react-router-dom";
import { useSelectedContent } from "../../../SelectedContentContext";
import { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButton, Stack, Box } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ExternalShareIcon from "@mui/icons-material/InsertLinkOutlined";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import AddToVideoBuilderIcon from "@mui/icons-material/VideoCallOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import PeopleIcon from "@mui/icons-material/People";
import MoveToFolderIcon from "@mui/icons-material/DriveFileMoveOutlined";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import useApprovalStatusToggle from "./hooks/useApprovalStatusToggle";
import useDeleteContent from "./hooks/useDelete";
import useCreateLink from "./hooks/createLink";
import useMoveToFolder from "./hooks/useMoveToFolder";
import useShareToChatApp from "./hooks/shareToChatApp";
import { PublishContentDialog } from "../../../Publish";
import { useContentEditor } from "../../../ContentEditor";
import ContextMenu from "../../../ContextMenu";
import { useRequester } from "../../../../Apollo";
import { useSnackbar } from "notistack";

export default function CardContentActions() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { addTrack, IconButton: ContentEditorIconButton } =
		useContentEditor();
	const { id } = useSelectedContent();
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.content.query.getContent, {
		variables: {
			id,
		},
		fetchPolicy: "cache-only",
	});
	const { toggle, status } = useApprovalStatusToggle();
	const { delete: deleteContent } = useDeleteContent();
	const { toggle: toggleShareToChatApp, isShared: isSharedToChatApp } =
		useShareToChatApp();
	const { data: foldersData } = useQuery(definitions.content.query.folders);
	const { moveToFolder } = useMoveToFolder();
	const { create: createLink, download, nativeShare } = useCreateLink();
	const navigate = useNavigate();
	const [contentFormOpen, setContentFormOpen] = useState(false);
	const [contextMenuOptions, setContextMenuOptions] = useState({
		event: null,
		options: [],
	});

	const actions = [
		{
			icon:
				status === "READY" ? (
					<CheckCircleOutlineRoundedIcon
						className="content-action-set-approved"
						color={"success"}
					/>
				) : (
					<RemoveCircleOutlineOutlinedIcon
						className="content-action-set-approved"
						color={"error"}
					/>
				),
			name: "Toggle Approval Status",
			onClick: () => {
				toggle();
			},
		},
		{
			icon: (
				<PeopleIcon
					className="content-action-share-to-chat-app"
					color={isSharedToChatApp ? "success" : "inherit"}
				/>
			),
			name: "Share to chat app",
			onClick: () => {
				toggleShareToChatApp();
			},
		},
		{
			icon: <ScheduleSendIcon className="content-action-publish" />,
			name: "Publish",
			onClick: () => {
				setContentFormOpen(true);
			},

			disabled: status !== "READY",
		},
		{
			icon: <IosShareIcon className="content-action-native-share" />,
			name: "Share to external app",
			onClick: () => {
				nativeShare();
			},
		},
		{
			icon: <FileDownloadIcon className="content-action-download" />,
			name: "Download",
			onClick: () => {
				download();
			},
		},

		{
			icon: <ExternalShareIcon className="content-action-get-link" />,
			name: "Get Link",
			onClick: () => {
				createLink();
			},
		},
		{
			icon: (
				<AddToVideoBuilderIcon className="content-action-add-to-content-editor" />
			),
			name: "Add To Medaia Editor",
			onClick: () => {
				addTrack({
					assetType: "CONTENT",
					file: data.content.source,
					id: data.content.id,
					name: data.content.title,
				});
				let key = enqueueSnackbar(
					<>
						Added to media editor
						<ContentEditorIconButton />
					</>,
					{
						variant: "success",
						autoHideDuration: 2000,
						onClick: () => {
							closeSnackbar(key);
						},
					}
				);
			},
		},

		{
			icon: (
				<DeleteOutlineOutlinedIcon className="content-action-delete" />
			),
			name: "Delete",
			onClick: () => {
				console.log("deleting");

				deleteContent();
			},
		},
		{
			icon: <FolderIcon className="content-action-move" />,
			name: "Move",
			onClick: e => {
				setContextMenuOptions({
					event: e,
					options:
						foldersData?.allFolders?.edges.map(
							({ node: folder }) => {
								return {
									name: folder.name,
									label: folder.name,
									endIcon: <MoveToFolderIcon />,
									onClick: async () => {
										await moveToFolder(folder.id);
										setContextMenuOptions({
											event: null,
											options: [],
										});
									},
								};
							}
						) || [],
				});
			},
		},
	];

	return (
		<>
			<ContextMenu
				clickEvent={contextMenuOptions.event}
				options={contextMenuOptions.options}
			/>

			<Stack direction="row">
				{actions.map(action => {
					return (
						<IconButton
							key={action.name}
							title={action.name}
							onClick={action.onClick}
							disabled={!id || !!action.disabled}
						>
							{action.icon}
						</IconButton>
					);
				})}
			</Stack>
			<PublishContentDialog
				open={contentFormOpen}
				onClose={() => setContentFormOpen(false)}
				contentIds={[id]}
			/>
		</>
	);
}
