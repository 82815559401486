import { useState } from "react";
import { useRequester } from "../../../../../Apollo";
import ReactJoyride from "react-joyride";

import { IconButton, Box, Card } from "@mui/material";
import QuestionIcon from "@mui/icons-material/HelpOutline";
import { useManageContentTabs } from "../../../../../Component/Content/Tabs";
const JOYRIDE_DOMAIN = "MANAGE_CONTENT";

export default function Joyride() {
	const { setTabValue } = useManageContentTabs();
	const { definitions, useQuery, useMutation } = useRequester();
	const { data } = useQuery(definitions.user.query.onboardingProgress, {});
	const onStep =
		data?.onboardingProgress.progress[
			`on_step_${JOYRIDE_DOMAIN.toLowerCase()}`
		];
	const [run, setRun] = useState(onStep === 0);

	const [updateStepProgress] = useMutation(
		definitions.user.muation.onboardingProgress
	);
	const [reset, setReset] = useState(0);

	return (
		<>
			<Box
				sx={{
					position: "absolute",
					bottom: 12,
					right: 12,
				}}
			>
				<IconButton
					onClick={() => {
						setRun(true);
						setReset(reset + 1);
						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 0,
								},
							},
						});
					}}
				>
					<QuestionIcon />
				</IconButton>
			</Box>
			<ReactJoyride
				run={!onStep && run}
				key={reset}
				hideBackButton
				continuous
				showProgress
				showSkipButton
				spotlightClicks
				scrollToFirstStep={false}
				callback={({ action, index, status }) => {
					if (action === "close" || status === "skipped") {
						setRun(false);

						updateStepProgress({
							variables: {
								input: {
									domain: JOYRIDE_DOMAIN,
									progress: 1,
								},
							},
						});
					}
					if (action === "next") {
						switch (index) {
							case 9: {
								setTabValue("submissions");
								break;
							}
							case 10: {
								setTabValue("related");
								break;
							}
							case 11: {
								setTabValue("details");
								break;
							}
							case 12: {
								setTabValue("exif");
								break;
							}
							default:
								break;
						}
					}
				}}
				disableScrolling
				disableOverlayClose
				steps={[
					{
						target: ".content-action-set-approved",
						content:
							"Your content can not be published until it is approved. Click here to set your content approved.",
						// placement: "top",

						disableBeacon: true,
						disableScrolling: false,
					},
					{
						target: ".content-action-publish",
						content:
							"Once your content is approved, you can publish it using your integrations.",
						// placement: "top",
					},
					{
						target: ".content-action-native-share",
						content:
							"Share your content to an external app on your device without the need to integrate. This is useful for some apps which we don't yet have a connector for.",
						// placement: "top",
					},
					{
						target: ".content-action-download",
						content:
							"Click here to download a copy of your content to your device.",
						// placement: "top",
					},
					{
						target: ".content-action-get-link",
						content:
							"Generate a link to your content and copy it to the clipboard. It will remain viewable for up to 7 days. This is useful if your device doesn't support native sharing.",
						// placement: "top",
					},
					{
						target: ".content-action-add-to-content-editor",
						content:
							"Adding content to the editor allows you to make a related content version. You may add transition effects, watermarks, audio overlays, and bumpers.",
						// placement: "top",
					},
					{
						target: ".content-action-delete",
						content:
							"Deleting your content will remove it from your workspace. You may choose to keep or delete any related content made using the media editor.",
						// placement: "top",
					},
					{
						target: ".content-action-move",
						content:
							"Organize your content by adding it to a folder. View your folders by navigating to the folders page.",
						// placement: "top",
					},
					{
						target: ".manage-content-update-title",
						content:
							"Update the title of your content. Don't forget to hit save!",
						// placement: "top",
					},
					{
						target: ".manage-content-update-tags",
						content:
							"Add or remove tags. You may create your own tags viewable only under this workspace. Don't forget to hit save!",
						// placement: "top",
					},

					{
						target: ".manage-content-tab-submissions",

						content:
							"If this content was published using any of your integrations, you may view the submission details, and navigate to the post using this tab.",
						// placement: "top",
						disableScrolling: false,
					},
					{
						target: ".manage-content-tab-related-content",

						content:
							"Related content created using the editor will appear here when it is done processing.",
						// placement: "top",
						disableScrolling: false,
					},
					{
						target: ".manage-content-tab-file-details",
						content:
							"File details are pulled during upload. This may be useful to you if you need to know the file size or type.",
						// placement: "top",
						disableScrolling: false,
					},
					{
						target: ".manage-content-tab-exif-data",
						content:
							"Exif data is extracted from the file if it exists. This may be useful to you if you need to know the date or geolocation the file was created.",
						// placement: "top",
						disableScrolling: false,
					},
				]}
			/>
		</>
	);
}
