import { useCallback } from "react";
import { TextField } from "@mui/material";
import { useRequester } from "@/Apollo";
import { debounce } from "lodash";
export default function RBACConfigColor({ role }) {
	const { definitions, useMutation } = useRequester();

	const [updateRoleColor] = useMutation(
		definitions.rbac.mutation.updateRoleName
	);

	const handleUpdate = useCallback(
		debounce(name => {
			updateRoleColor({
				variables: {
					input: {
						id: role.id,
						name,
					},
				},
			});
		}, 500),
		[]
	);

	return (
		<TextField
			onChange={e => handleUpdate(e.target.value)}
			sx={{
				"& .MuiInputBase-root": {
					color: role.color,
				},
				//remove underline
				"& .MuiInput-underline:before": {
					borderBottom: "none",
				},
			}}
			label="name"
			variant="standard"
			defaultValue={role.name}
		/>
	);
}
