import {
	Menu as MuiMenu,
	MenuItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { useActions } from "./Actions";
import calculateLocation from "../../util/calculateEventLocation";
export default function MultiSelectContextMenu({ open, onClose, event }) {
	return (
		<>
			<MuiMenu
				anchorReference="anchorPosition"
				anchorPosition={calculateLocation(event)}
				variant="menu"
				open={open}
				onClose={onClose}
				onContextMenu={e => {
					e.preventDefault();
					e.stopPropagation();
					onClose();
				}} // keep native context menu from opening while this one is open
			>
				{useActions().map(({ Icon, text, onClick }) => (
					<MenuItem key={text} onClick={onClick}>
						<ListItemIcon>
							<Icon />
						</ListItemIcon>
						<ListItemText primary={text} />
					</MenuItem>
				))}
			</MuiMenu>
		</>
	);
}
