import { useContext, createContext, useState, useEffect, useMemo } from "react";
import { useIsSSR } from "react-aria";
import { useSnackbar } from "notistack";

import { useAuth0 } from "@auth0/auth0-react";
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	useQuery,
	useLazyQuery,
	useMutation,
	useSubscription,
	useApolloClient,
	ApolloLink,
} from "@apollo/client";
import ServerStatusContextProvider from "@/Component/ServerStatus";
import { setContext } from "@apollo/client/link/context";
import { split, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import ws from "ws";
import fetch from "cross-fetch";
import { useLogger } from "@/Logger";
import definitions from "./definitions";

import { useCookies } from "../Cookies";
import LoadingScreen from "../Component/LoadingScreen";
import { useReferralCode } from "../Auth";
import useLocalStorage from "@/util/useLocalStorage";
export const createApolloClient = ({
	Authorization,
	Workspace,
	Referral,
	name,
	debug = false,
	enqueueSnackbar,
	closeSnackbar,
	localStorage,
}) => {
	let activeSocket, timedOut;
	const errorLink = onError(
		({ graphQLErrors, networkError, forward, operation }) => {
			if (!graphQLErrors && !networkError) return forward(operation);
			if (networkError) {
				const key = enqueueSnackbar(
					`Network Status: ${networkError.statusCode}\nMessage: ${networkError.message}`,
					{
						variant: "error",
						onClick: () => closeSnackbar(key),
					}
				);
				return;
			}

			if (localStorage.get("showToast:missingPermission") === false) {
				return;
			}

			let isMissingPermission = false;
			graphQLErrors?.forEach(({ message }) => {
				if (message.includes("Missing required permission:")) {
					isMissingPermission = true;
					const key = enqueueSnackbar(message, {
						variant: "error",
						onClick: () => closeSnackbar(key),
					});
				}
			});
			if (!isMissingPermission) {
				console.log("unknown error", { graphQLErrors, networkError });
				const key = enqueueSnackbar(
					"An unknown error occurred. If the problem persists, please report to an administrator.",
					{
						variant: "error",
						onClick: () => closeSnackbar(key),
					}
				);
			}
		}
	);
	return new ApolloClient({
		cache: new InMemoryCache(),
		link: new ApolloLink.from([
			setContext((_, { headers }) => {
				return {
					headers: {
						...headers,
						...{
							Authorization,
							Workspace,
							Referral,
						},
					},
				};
			}),
			errorLink,
			split(
				({ query }) => {
					const definition = getMainDefinition(query);
					return (
						definition.kind === "OperationDefinition" &&
						definition.operation === "subscription"
					);
				},
				new GraphQLWsLink(
					createClient({
						url: process.env.REACT_APP_SUBSCRIPTION_ENDPOINT,
						shouldRetry: () => false,
						keepAlive: 10_000,
						connected: socket => (activeSocket = socket),
						ping: received => {
							debug && console.log("ping", { received });
							if (!received)
								// sent
								timedOut = setTimeout(() => {
									if (
										activeSocket.readyState ===
										WebSocket.OPEN
									)
										activeSocket.close(
											4408,
											"Request Timeout"
										);
								}, 5_000); // wait 5 seconds for the pong and then close the connection
						},
						pong: received => {
							debug && console.log("pong", { received });
							if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
						},
						on: {
							connecting: () => {
								debug && console.log("ws connecting");
							},
							connected: () => {
								debug && console.log("ws connected");
							},
							message: message => {
								debug &&
									console.log("ws message:", { message });
							},
							opened: () => {
								debug && console.log("ws opened");
							},
							ping: () => {
								debug && console.log("ws ping");
							},
							pong: () => {
								debug && console.log("ws pong");
							},
							closed: () => {
								debug && console.log("ws closed");
							},
							error: error => {
								debug && console.log("ws error:", { error });
							},
						},
						connectionParams: (
							operation,
							previousConnectionParams
						) => {
							return {
								...previousConnectionParams,
								...{
									Authorization,
									Workspace,
									Referral,
								},
							};
						},
						webSocketImpl:
							typeof window === "undefined" ? ws : null,
					})
				),
				new HttpLink({
					uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
					fetch,
				})
			),
		]),
		queryDeduplication: true,
		name,
	});
};
const Context = createContext({
	user: null,
	token: null,
	definitions,
	useQuery,
	useLazyQuery,
	useMutation,
	useSubscription,
	useApolloClient,
	selectWorkspace: () => {},
	clientState: {
		Authorization: null,
		workspace: {
			id: null,
			cdnCookies: [],
			algoliaToken: null,
		},
	},
	workspace_id: null,
	loading: true,
	client: createApolloClient({
		Authorization: null,
		Workspace: null,
		Referral: null,
	}),
});

const getCDNCookies = cookies => {
	return Object.entries(cookies.allKeys()).filter(
		key => key[0].indexOf("CloudFront") > -1
	);
};

export default function ApolloAppContextProvider({ children }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const localStorage = useLocalStorage();
	const { code } = useReferralCode();
	const { isAuthenticated, isLoading: auth0IsLoading, user } = useAuth0();
	const isSSR = useIsSSR();
	// const navigate = useNavigate();
	const cookies = useCookies();

	const token = cookies.get("token");
	const clearWorkspace = () => {
		cookies.remove("workspace");
		cookies.remove("token_algolia");
		getCDNCookies(cookies).forEach(key => {
			cookies.remove(key[0]);
		});
	};
	const { setUser } = useLogger();
	const [workspace_id, setWorkspaceId] = useState(cookies.get("workspace"));
	useEffect(() => {
		if (!isAuthenticated) return;
		setUser({
			user_id: user.email,
			workspace_id,
		});
	}, [workspace_id]);
	const handleSetWorkspaceID = id => {
		setWorkspaceId(id);
		cookies.set("workspace", id, {
			path: "/",
			sameSite: "none",
			secure: true,
		});
	};

	const [loading, setLoading] = useState(isAuthenticated);

	// create the client before passing it to the provider so
	// it may be used to query the api for keys to the workspace

	const apolloClient = createApolloClient({
		Authorization: token,
		Workspace: workspace_id,
		Referral: code,
		name: isAuthenticated ? user.email : "anonymous",
		enqueueSnackbar,
		closeSnackbar,
		localStorage,
	});

	useEffect(() => {
		if (auth0IsLoading) return;
		if (!token) return;
		if (!isAuthenticated) return;
		const authenticateUser = async () => {
			setLoading(true);
			let data;
			try {
				let id = workspace_id;
				if (id === undefined) {
					id = null;
				}
				const res = await apolloClient.query({
					query: definitions.workspace.query.getWorkspace,
					variables: {
						id: workspace_id,
					},
					fetchPolicy: "network-only",
				});
				data = res.data;
			} catch (e) {
				console.log("error getting workspace: ", e);
				if (e.message.includes("User does not have a role")) {
					// user doesn't have a role in the workspace
					// remove the workspace from the cookies
					clearWorkspace();
					// reload page
					window.location.reload();
				}

				return;
			}

			// set cloudfront cdn cookies for the current workspace

			await apolloClient
				.query({
					query: definitions.common.query.getSignedCookies,
					errorPolicy: "all",
				})
				.then(({ data, loading, networkStatus, error }) => {
					if (!data) return;
					Object.entries(data.getSignedCookies).forEach(
						([cookieName, cookieValue]) => {
							if (cookieName === "__typename") return;
							cookies.set(
								cookieName.replace(/_/g, "-"),
								cookieValue,
								{
									// allow cookies to be sent to the cdn
									path: "/",
									domain: process.env.REACT_APP_CDN_DOMAIN,
									expires: new Date(
										Date.now() + 1000 * 60 * 60 * 24 * 365
									),
								}
							);
						}
					);
				})
				.catch(e => {
					// maybe user isn't allowed to view assets in the workspace, we still want to continue
					console.log("caught error fetching workspace cookies: ", e);
				});

			// update the client state
			// update the workspace
			if (data.workspace.id != workspace_id) {
				handleSetWorkspaceID(data.workspace.id);
			}
			// remove the loading screen
			setLoading(false);
		};
		authenticateUser();
	}, [token, isAuthenticated, auth0IsLoading]);

	const isLoading = !isSSR && loading;

	return (
		<ApolloProvider client={apolloClient}>
			<Context.Provider
				value={{
					token,
					definitions,
					useQuery,
					useLazyQuery,
					useMutation,
					useSubscription,
					useApolloClient,
					selectWorkspace: ({ workspace }) => {
						const id = workspace.id;
						cookies.set("workspace", id);
						cookies.remove("token_algolia");
						getCDNCookies(cookies).forEach(key => {
							cookies.remove(key[0]);
						});
						window.location.href = `/workspace`;
					},
					workspace_id,
					loading: isLoading,
					client: apolloClient,
					clearWorkspace,
				}}
			>
				<ServerStatusContextProvider>
					<LoadingScreen
						loading={isLoading}
						message={`Preparing workspace`}
					>
						{children}
					</LoadingScreen>
				</ServerStatusContextProvider>
			</Context.Provider>
		</ApolloProvider>
	);
}

export const useRequester = () => useContext(Context);
export const SubscriptionPlanProvider = ({ children }) => {
	const { useLazyQuery, useMutation, token } = useRequester(Context);
	const [createCustomer] = useMutation(
		definitions.billing.mutation.createCustomer
	);
	const [getUser, { data }] = useLazyQuery(definitions.user.query.getUser, {
		fetchPolicy: "cache-first",
		onCompleted: ({ user }) => {
			if (user.customerID) return;
			console.log("creating new customer");
			createCustomer({
				onError: error => {
					console.log("error creating customer:", error);
				},
				refetchQueries: [
					{
						query: definitions.user.query.getUser,
					},
				],
			});
		},
	});
	useEffect(() => {
		if (!token) return;
		getUser();
	}, [token]);

	if (!token) return children;
	return (
		<LoadingScreen
			loading={!data?.user?.customerID}
			message={`preparing customer account`}
		>
			{children}
		</LoadingScreen>
	);
};
