import { Box, Stack, Typography, ImageList } from "@mui/material";

import Workspace from "@/Component/Workspace/Card";
import { useRequester } from "@/Apollo";

import WorkspaceWidgetStorage from "@/Component/Workspace/Widget/Storage";
import WorkspaceWidgetSubmissions from "@/Component/Workspace/Widget/Submissions";
import WorkspaceWidgetAssistants from "@/Component/Workspace/Widget/Assistants";
import WorkspaceWidgetLogs from "@/Component/Workspace/Widget/Notifications";
import WorkspaceWidgetMatches from "@/Component/Workspace/Widget/Matches";
import { WorkspaceWrapper } from "@/Layout";
const WidgetBox = () => {
	return (
		<ImageList variant="quilted">
			<WorkspaceWidgetStorage />
			<WorkspaceWidgetSubmissions />
			<WorkspaceWidgetAssistants />
			<WorkspaceWidgetLogs />
			<WorkspaceWidgetMatches />
		</ImageList>
	);
};

export default function PageVirtualAssistants() {
	const { workspace_id, useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.workspace.query.getWorkspace, {
		fetchPolicy: "cache-only",
		variables: {
			id: workspace_id,
		},
	});

	return (
		<WorkspaceWrapper>
			<Stack spacing={2}>
				<Typography variant="h5">Workspace</Typography>
				<Workspace workspace={data?.workspace} />
				<Box
					sx={{
						maxWidth: "500px",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<WidgetBox />
				</Box>
			</Stack>
		</WorkspaceWrapper>
	);
}
