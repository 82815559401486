import SettingsPanel from "../../../../../../../../Component/Settings";
import { useDarkMode } from "../../../../../../../../Theme";
const MoonEmoji = () => <span>🌙</span>;
const SunEmoji = () => <span>☀️</span>;
export default function DarkModeSettings() {
	const { mode, toggleDarkMode } = useDarkMode();

	return (
		<SettingsPanel
			className={"dark-mode-toggler"}
			options={[
				{
					label: (
						<>
							{mode === "dark" ? <MoonEmoji /> : <SunEmoji />}
							{mode === "dark" ? "Dark" : "Light"} Mode
						</>
					),
					value: mode !== "dark",
					onChange: toggleDarkMode,
				},
			]}
		/>
	);
}
