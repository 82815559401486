import { useEffect, useState } from "react";
import { Box, Stack, TextField, Autocomplete, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useRequester } from "../../Apollo";
import { throttle } from "lodash";
import { useSnackbar } from "notistack";
import { useCanNavigateAway } from "../../util/CanNavigateAway";

export default function ContentTaggingInput({ defaultValue = [], contentId }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [tags, setTags] = useState(defaultValue);
	const defaultTags = JSON.stringify(defaultValue);
	const [hasChanges, setHasChanges] = useState(false);
	useEffect(() => {
		if (hasChanges) return;
		let valuesMatch = JSON.stringify(tags) === defaultTags;
		if (!valuesMatch) {
			console.log("Tags have changed");
			setHasChanges(true);
			incrementChanges();
		}
	}, [tags]);

	const { decrementChanges, incrementChanges } = useCanNavigateAway();
	const [inputValue, setInputValue] = useState("");
	const { definitions, useQuery, useMutation } = useRequester();
	const { data, fetchMore } = useQuery(definitions.content.query.getTags, {
		variables: {
			input: {
				includeWorkspaceTags: true,
				query: "",
			},
		},
	});
	const [updateTags] = useMutation(definitions.content.mutation.update);
	const [addNewTag] = useMutation(definitions.common.mutation.addTag);

	const handleFetchMoreTags = throttle((query = "", callback) => {
		fetchMore({
			variables: {
				input: {
					includeWorkspaceTags: true,
					query,
				},
			},
		});
	}, 1500);

	return (
		<Stack
			direction="row"
			spacing={1}
			className="manage-content-update-tags"
		>
			<Autocomplete
				fullWidth
				multiple
				options={data?.metadataTags?.tags || []}
				getOptionLabel={option => option.value}
				isOptionEqualToValue={(option, value) => {
					return option.value === value.value;
				}}
				defaultValue={defaultValue}
				value={tags}
				renderInput={params => {
					return (
						<TextField
							{...params}
							variant="standard"
							label="Content Tags"
							onKeyDown={event => {
								const isEnter = event.key === "Enter";
								if (!isEnter) return;
								event.preventDefault();
								event.stopPropagation();

								addNewTag({
									variables: {
										input: {
											value: inputValue,
										},
									},
									onError: error => {
										console.log({
											error,
										});
									},
									onCompleted: data => {
										console.log("Added new tag:", {
											data,
										});
										setTags([
											...tags,
											data.addWorkspaceTag.tag,
										]);
									},
								});
							}}
						/>
					);
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
					handleFetchMoreTags(newInputValue);
				}}
				onChange={(event, newValue) => {
					setTags(newValue);
				}}
			/>
			<Box
				sx={{
					display: "flex",
					height: "100%",
					alignItems: "center",
				}}
			>
				<IconButton
					title={"Save Tags"}
					aria-label="Save Tags"
					onClick={() => {
						console.log({
							tags,
						});
						updateTags({
							errorPolicy: "all",
							variables: {
								input: {
									contentId,
									metadataTags: tags.map(tag => {
										return {
											tagId: tag.id,
											isWorkspaceTag: tag.isWorkspaceTag,
										};
									}),
								},
							},
							refetchQueries: [
								{
									query: definitions.content.query.usedTags,
								},
							],
							onCompleted: data => {
								setHasChanges(false);
								decrementChanges();

								let key = enqueueSnackbar(
									"Successfully updated tags.",
									{
										variant: "success",
										onClick: () => closeSnackbar(key),
									}
								);
							},
						});
					}}
				>
					<SaveIcon />
				</IconButton>
			</Box>
		</Stack>
	);
}
