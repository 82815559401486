import { gql } from "@apollo/client";
import { VIRTUAL_ASSISTANT_FRAGMENT } from "../fragment";
export const assistantJoinedWorkspace = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	subscription AssistantJoinedWorkspace {
		assistantJoinedWorkspace {
			assistant {
				...VirtualAssistantFragment
			}
		}
	}
`;
export const workspaceInvitationReceived = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	subscription WorkspaceInvitationReceived {
		workspaceInvitationReceived {
			assistant {
				...VirtualAssistantFragment
			}
		}
	}
`;
