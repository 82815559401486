import { useMultiselectContent } from "../..";
import { useRequester } from "../../../Apollo";
import { useConfirm } from "../../../Confirm";
import { useSnackbar } from "notistack";

export default function useDeleteMultiple() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { confirm } = useConfirm();
	const { definitions, useMutation, client } = useRequester();
	const { selected, getSelection } = useMultiselectContent();
	const [deleteMultiple] = useMutation(
		definitions.common.mutation.deleteContent
	);

	return {
		deleteMultiple: async () => {
			const deleteChildren = await confirm(
				"Delete children?",
				"If any content has been created using these items as a source, they will be deleted as well."
			);
			const confirmDelete = await confirm(
				`Delete ${selected.size} items?`,
				"This cannot be undone!"
			);
			if (!confirmDelete) return;
			await deleteMultiple({
				variables: {
					input: {
						contentIds: getSelection().map(s => s.id),
						deleteChildren,
					},
				},

				onCompleted: data => {
					const deletedIds = data.deleteContent.contentIds;
					// remove the content with the deleted ids from the cache
					client.cache.modify({
						fields: {
							contentSearch: existing => {
								const edges = existing.edges.filter(edge => {
									return !deletedIds.includes(
										edge.node.__ref.split(":")[1]
									);
								});
								return {
									...existing,
									edges,
								};
							},
						},
					});
				},
			});
			let key = enqueueSnackbar(`Content successfully deleted.`, {
				variant: "success",
				onClick: () => closeSnackbar(key),
			});
			return true;
		},
	};
}
