import { SvgIcon, Box } from "@mui/material";

export const Icon = ({ width = "48px", height = "48px" }) => {
	return (
		<Box
			sx={{
				width,
				height,
				position: "relative",
			}}
		>
			<SvgIcon
				viewBox="0 0 181 140"
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
				}}
			>
				<g transform="matrix(1, 0, 0, 1, -71, -52.5)">
					<g transform="matrix(1, 0, 0, 1, 9, -0.000004)">
						<linearGradient
							id="LETTER_R"
							gradientUnits="userSpaceOnUse"
							x1="-239.194412"
							y1="85.000244"
							x2="118.402481"
							y2="85.000244"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_R)"
							d="M 113.504 116.5 C 112.017 116.5 110.562 115.764 109.703 114.416 L 97.03 94.5 L 86.878 94.5 C 83.085 94.5 80 91.415 80 87.623 L 80 112 C 80 114.485 77.985 116.5 75.5 116.5 C 73.015 116.5 71 114.485 71 112 L 71 60.377 C 71 56.585 74.085 53.5 77.878 53.5 L 101.5 53.5 C 109.532 53.5 116 62.526 116 70 C 116 76.962 110.611 85.125 103.229 87.688 C 103.512 87.993 103.769 88.326 103.997 88.684 L 117.297 109.584 C 118.631 111.681 118.013 114.462 115.916 115.796 C 115.167 116.273 114.331 116.5 113.504 116.5 Z M 80 62.5 L 80 86.377 C 80 82.585 83.085 79.5 86.878 79.5 L 98.5 79.5 C 102.883 79.5 107 73.574 107 70 C 107 67.039 104.05 62.5 101.5 62.5 L 80 62.5 Z"
						/>
						<linearGradient
							id="LETTER_E_TOP"
							gradientUnits="userSpaceOnUse"
							x1="-123.502808"
							y1="67.5"
							x2="235.413544"
							y2="67.5"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_E_TOP)"
							d="M 135.5 81.5 C 133.015 81.5 131 79.485 131 77 L 131 60.377 C 131 56.585 134.085 53.5 137.878 53.5 L 170.5 53.5 C 172.985 53.5 175 55.515 175 58 C 175 60.485 172.985 62.5 170.5 62.5 L 140 62.5 L 140 77 C 140 79.485 137.985 81.5 135.5 81.5 Z"
						/>
						<linearGradient
							id="LETTER_E_BOTTOM"
							gradientUnits="userSpaceOnUse"
							x1="-122.812668"
							y1="98"
							x2="235.373764"
							y2="98"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_E_BOTTOM)"
							d="M 170.5 116.5 L 137.878 116.5 C 134.085 116.5 131 113.415 131 109.623 L 131 86.377 C 131 82.585 134.085 79.5 137.878 79.5 L 170.5 79.5 C 172.985 79.5 175 81.515 175 84 C 175 86.485 172.985 88.5 170.5 88.5 L 140 88.5 L 140 107.5 L 170.5 107.5 C 172.985 107.5 175 109.515 175 112 C 175 114.485 172.985 116.5 170.5 116.5 Z"
						/>
						<linearGradient
							id="LETTER_D"
							gradientUnits="userSpaceOnUse"
							x1="-7.867053"
							y1="84.5"
							x2="350.117798"
							y2="84.5"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_D)"
							d="M 215.206 116.5 L 197.5 116.5 C 195.015 116.5 193 114.485 193 112 C 193 111.307 193.157 110.649 193.438 110.063 C 192.851 110.343 192.193 110.5 191.5 110.5 C 189.015 110.5 187 108.485 187 106 L 187 59.377 C 187 55.585 190.085 52.5 193.878 52.5 L 215.5 52.5 C 226.088 52.5 234 64.379 234 75 L 234 95 C 234 105.313 227.099 113.878 216.827 116.313 C 216.297 116.437 215.754 116.5 215.206 116.5 Z M 196 61.5 L 196 106 C 196 106.693 195.843 107.351 195.563 107.937 C 196.149 107.657 196.807 107.5 197.5 107.5 L 214.976 107.5 C 221.071 105.962 225 101.079 225 95 L 225 75 C 225 68.565 220.028 61.5 215.5 61.5 L 196 61.5 Z"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 113.5 112 L 100.201 91.101 C 99.764 90.415 99.008 90 98.195 90 L 86.878 90 C 85.565 90 84.5 88.935 84.5 87.622 L 84.5 86.378 C 84.5 85.065 85.565 84 86.878 84 L 98.5 84 C 105.5 84 111.5 76 111.5 70 C 111.5 65 107.027 58 101.5 58 L 77.878 58 C 76.565 58 75.5 59.065 75.5 60.378 L 75.5 112"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 170.5 58 L 137.878 58 C 136.565 58 135.5 59.065 135.5 60.378 L 135.5 77"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 170.5 112 L 137.878 112 C 136.565 112 135.5 110.935 135.5 109.622 L 135.5 86.378 C 135.5 85.065 136.565 84 137.878 84 L 170.5 84"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 197.5 112 L 215.206 112 C 215.401 112 215.599 111.979 215.789 111.934 C 224.607 109.844 229.5 102.655 229.5 95 L 229.5 75 C 229.5 66.709 223.238 57 215.5 57 L 193.878 57 C 192.565 57 191.5 58.065 191.5 59.378 L 191.5 106"
						/>
					</g>
					<g transform="matrix(1, 0, 0, 1, -177, 76)">
						<linearGradient
							id="LETTER_G"
							gradientUnits="userSpaceOnUse"
							x1="111.930855"
							y1="84.5"
							x2="470.096313"
							y2="84.5"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_G)"
							d="M 270.5 116.5 C 258.094 116.5 248 108.201 248 98 L 248 71 C 248 60.972 258.304 52.5 270.5 52.5 C 282.696 52.5 293 60.972 293 71 L 293 73 C 293 75.485 290.985 77.5 288.5 77.5 C 286.015 77.5 284 75.485 284 73 L 284 71 C 284 66.607 278.103 61.5 270.5 61.5 C 262.897 61.5 257 66.607 257 71 L 257 98 C 257 103.149 263.183 107.5 270.5 107.5 C 277.817 107.5 284 103.149 284 98 L 284 88.5 L 270.5 88.5 C 268.015 88.5 266 86.485 266 84 C 266 81.515 268.015 79.5 270.5 79.5 L 286.122 79.5 C 289.915 79.5 293 82.585 293 86.377 L 293 98 C 293 108.201 282.906 116.5 270.5 116.5 Z"
						/>
						<linearGradient
							id="LETTER_I"
							gradientUnits="userSpaceOnUse"
							x1="197.869156"
							y1="84.5"
							x2="549.83551"
							y2="84.5"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_I)"
							d="M 312.5 116.5 C 310.015 116.5 308 114.485 308 112 L 308 57 C 308 54.515 310.015 52.5 312.5 52.5 C 314.985 52.5 317 54.515 317 57 L 317 112 C 317 114.485 314.985 116.5 312.5 116.5 Z"
						/>
						<linearGradient
							id="LETTER_F_TOP"
							gradientUnits="userSpaceOnUse"
							x1="273.52774"
							y1="67"
							x2="632.445557"
							y2="67"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_F_TOP)"
							d="M 334.5 81.5 C 332.015 81.5 330 79.485 330 77 L 330 59.377 C 330 55.585 333.085 52.5 336.878 52.5 L 368.5 52.5 C 370.985 52.5 373 54.515 373 57 C 373 59.485 370.985 61.5 368.5 61.5 L 339 61.5 L 339 77 C 339 79.485 336.985 81.5 334.5 81.5 Z"
						/>
						<linearGradient
							id="LETTER_F_BOTTOM"
							gradientUnits="userSpaceOnUse"
							x1="273.614227"
							y1="98.5"
							x2="632.531982"
							y2="98.5"
							gradientTransform="matrix(1, 0, 0, 0.973684, 0, 2.09209)"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_F_BOTTOM)"
							d="M 334.5 116.5 C 332.015 116.5 330 114.538 330 112.118 L 330 86.196 C 330 82.504 333.085 79.5 336.878 79.5 L 368.5 79.5 C 370.985 79.5 373 81.462 373 83.882 C 373 86.301 370.985 88.263 368.5 88.263 L 339 88.263 L 339 112.118 C 339 114.538 336.985 116.5 334.5 116.5 Z"
						/>
						<linearGradient
							id="LETTER_S"
							gradientUnits="userSpaceOnUse"
							x1="386.670807"
							y1="84.5"
							x2="745.634155"
							y2="84.5"
						>
							<stop offset="0" stopColor="#8C18F1" />
							<stop offset="1" stopColor="#E5194D" />
						</linearGradient>
						<path
							fill="url(#LETTER_S)"
							d="M 408.5 116.5 C 397.646 116.5 391.555 113.172 387.475 105.013 C 386.363 102.79 387.265 100.086 389.487 98.975 C 391.714 97.863 394.413 98.766 395.525 100.987 C 397.432 104.801 399.479 107.5 408.5 107.5 C 414.054 107.5 420 104.084 420 99 C 420 94.061 415.637 91.875 408.451 88.864 C 407.868 88.62 407.291 88.378 406.728 88.136 C 406.13 87.88 405.517 87.624 404.896 87.364 C 397.766 84.382 388 80.299 388 70 L 388 67 C 388 58.462 396.43 52.5 408.5 52.5 C 419.047 52.5 427 57.874 427 65 C 427 67.485 424.985 69.5 422.5 69.5 C 420.015 69.5 418 67.485 418 65 C 418 64.129 414.631 61.5 408.5 61.5 C 401.944 61.5 397 63.864 397 67 L 397 70 C 397 74.007 401.501 76.189 408.368 79.061 C 409.014 79.331 409.65 79.597 410.272 79.864 C 410.814 80.096 411.369 80.329 411.931 80.564 C 418.728 83.413 429 87.718 429 99 C 429 109.809 418.359 116.5 408.5 116.5 Z"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 288.5 73 L 288.5 71 C 288.5 63.814 280.449 57 270.5 57 C 260.551 57 252.5 63.814 252.5 71 L 252.5 98 C 252.5 105.738 260.551 112 270.5 112 C 280.449 112 288.5 105.738 288.5 98 L 288.5 86.378 C 288.5 85.065 287.435 84 286.122 84 L 270.5 84"
						/>
						<line
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							x1="312.5"
							y1="57"
							x2="312.5"
							y2="112"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 334.5 77 L 334.5 59.378 C 334.5 58.065 335.565 57 336.878 57 L 368.5 57"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 368.5 84 L 336.878 84 C 335.565 84 334.5 85.065 334.5 86.378 L 334.5 107.176 L 334.5 112"
						/>
						<path
							fill="none"
							stroke="#FFFFFF"
							strokeWidth="2"
							strokeMiterlimit="10"
							d="M 391.5 103 C 394.5 109 398.551 112 408.5 112 C 416.238 112 424.5 107 424.5 99 C 424.5 99 424.5 99 424.5 99 C 424.5 90 415.5 87 408.5 84 C 401.5 81 392.5 78 392.5 70 C 392.5 70 392.5 68 392.5 67 C 392.5 61 399.104 57 408.5 57 C 416.238 57 422.5 60.578 422.5 65"
						/>
					</g>
				</g>
			</SvgIcon>
		</Box>
	);
};
