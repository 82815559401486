/* global BigInt */

import { useRequester } from "@/Apollo";
import {
	Box,
	InputBase,
	FormControl,
	Typography,
	IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import useSetAdditionalStorage from "./Hook/useSetAdditionalStorage";
import useSetApiEnabled from "./Hook/useSetApiEnabled";
import useSetChatbotEnabled from "./Hook/useSetChatbotEnabled";
import ContextMenu from "@/Component/ContextMenu";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Workspaces from "../Workspace";
import { convertBytes } from "@/util/convertBytes";

const Cell = ({ userId, value, handleSetContextMenu }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				// border: "1px solid #ccc",
				justifyContent: "center",
				alignItems: "left",
				textAlign: "left",
				width: "100%",
				height: "100%",
				objectFit: "contain",
			}}
			onContextMenu={event => {
				event.preventDefault();
				event.stopPropagation();
				handleSetContextMenu(event, userId);
			}}
		>
			{value}
		</Box>
	);
};
export default function AdminPanelUsers() {
	const { setAdditionalStorage } = useSetAdditionalStorage();
	const { setChatbotEnabled } = useSetChatbotEnabled();

	const { setApiEnabled } = useSetApiEnabled();
	const [contextMenuOptions, setContextMenuOptions] = useState({
		event: null,
		user: null,
		options: [],
	});

	const [showWorkspaces, setShowWorkspaces] = useState(null);
	const { useQuery, definitions } = useRequester();
	const { data } = useQuery(definitions.admin.query.getUsers, {
		fetchPolicy: "cache-first",
		variables: {
			input: {
				limit: null,
				offset: null,
				filters: null,
			},
		},
	});

	const handleSetContextMenu = (event, userId) => {
		const user = data.getUsers.users.find(user => user.id === userId);
		const workspaces = user.workspacesConnection.edges;
		setContextMenuOptions({
			event,
			options: [
				{
					label: user.email,
					disabled: true,
				},
				{
					label: `Additional Storage`,
					options: [
						{
							label: (
								<FormControl
									component={"form"}
									onSubmit={e => {
										e.preventDefault();
										const setgb = e.target.setgb.value;
										setAdditionalStorage({
											userId,
											allotment: parseInt(setgb),
										});
									}}
									sx={{
										width: "100%",
										height: "100%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<InputBase
										name="setgb"
										sx={{
											width: "100%",
											height: "100%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
										defaultValue={user.additionalStorage}
										placeholder="Add GB"
									/>
								</FormControl>
							),
						},
					],
				},
				{
					label: "Manage Workspaces",
					options: workspaces.map(({ node: workspace }) => {
						return {
							label: workspace.name,
							options: [
								{
									label: "Chatbot",
									options: [
										{
											label: `BOT ${
												workspace.chatbotEnabled
													? "ENABLED"
													: "DISABLED"
											}`,
											endIcon:
												workspace.chatbotEnabled ? (
													<CheckIcon />
												) : (
													<ClearIcon />
												),
										},
										{
											label: "Enable Bot",
											onClick: () => {
												setChatbotEnabled({
													workspaceId: workspace.id,
													chatbotEnabled: true,
												});
											},
										},
										{
											label: "Disable Bot",
											onClick: () => {
												setChatbotEnabled({
													workspaceId: workspace.id,
													chatbotEnabled: false,
												});
											},
										},
									],
								},
							],
						};
					}),
				},
			],
		});
	};

	const renderCell = ({ value, id }) => {
		return (
			<Cell
				handleSetContextMenu={handleSetContextMenu}
				value={value}
				userId={id}
			/>
		);
	};

	return (
		<Box sx={{ height: `100%`, width: "100%", position: "relative" }}>
			<ContextMenu
				options={contextMenuOptions.options}
				clickEvent={contextMenuOptions.event}
				height={300}
			/>
			{!!showWorkspaces ? (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: 1000,
						backgroundColor: theme =>
							theme.palette.background.default,
					}}
				>
					<Workspaces
						userId={showWorkspaces}
						onClose={() => {
							setShowWorkspaces(null);
						}}
					/>
				</Box>
			) : null}

			<DataGrid
				editMode="cell"
				rows={(data?.getUsers.users || []).map(user => {
					const tier = user.subscription?.tier || 0;

					let subscribedStorageAllotment = 0;
					switch (tier) {
						case 0:
							// 5 gigs (in bytes)
							subscribedStorageAllotment = 5368709120;
							break;
						case 1:
							// 50 gigs (in bytes)
							subscribedStorageAllotment = 53687091200;
							break;
						case 2:
							// 100 gigs (in bytes)
							subscribedStorageAllotment = 107374182400;
							break;
						case 3:
							// 500 gigs (in bytes)
							subscribedStorageAllotment = 536870912000;
							break;
						default:
							break;
					}

					// what types x = 1gb in bytes

					const totalStorageAllotment =
						subscribedStorageAllotment +
						(user.additionalStorage * 1073741824 || 0);
					return {
						id: user.id,
						email: user.email,
						emailVerified: user.emailVerified === true ? 1 : 0,
						publicDisplayName: user.publicDisplayName,
						apiEnabled: user.apiEnabled,
						name: user.name,
						admin: user.admin,
						createdAt: user.createdAt,
						avatar: user.avatar,
						city: user.city,
						state: user.state,
						tz: user.tz,
						workspaces: user.workspacesConnection.edges.length,

						hasChatbotEnabledWorkspace:
							user.workspacesConnection.edges.some(
								({ node: workspace }) =>
									workspace.chatbotEnabled === true
							),
						lastLogin: user.lastLogin,
						// subscription and storage details
						subscriptionTier: user.subscription?.tier,
						subscribedStorageAllotment,
						additionalStorage: user.additionalStorage,
						totalStorageAllotment,
						usedStorage: user.usedStorage,
					};
				})}
				processRowUpdate={async (user, previousUser) => {
					// find different fields
					const fields = Object.keys(user).filter(
						key => user[key] !== previousUser[key]
					);
					// if there are no different fields, return
					if (fields.length === 0) return;
					// if there are different fields, update them
					for (const field of fields) {
						switch (field) {
							case "additionalStorage": {
								await setAdditionalStorage({
									userId: user.id,
									allotment: parseInt(user[field]),
								});
							}
							default:
								break;
						}
					}
					return;
				}}
				onProcessRowUpdateError={params => {
					console.log("error: ", params);
				}}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 100 },
					},
					sorting: {
						sortModel: [
							{
								field: "createdAt",
								sort: "desc",
							},
						],
					},
				}}
				pageSizeOptions={[5, 10]}
				onCellDoubleClick={params => {
					switch (params.field) {
						case "workspaces": {
							setShowWorkspaces(params.id);
							break;
						}
						default:
							break;
					}
				}}
				columns={[
					{
						field: "id",
						headerName: "ID",
						type: "number",
						align: "left",
						width: 75,
						editable: false,
						renderCell,
					},
					{
						field: "workspaces",
						headerName: "Workspaces",
						width: 100,

						editable: false,
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={value}
									userId={id}
								/>
							);
						},
					},
					{
						field: "apiEnabled",
						headerName: "API",
						type: "boolean",
						width: 100,
						editable: false,
						renderCell: ({ value, id }) => {
							const Button = () => {
								return (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
											height: "100%",
											objectFit: "contain",
										}}
									>
										<IconButton
											onDoubleClick={() => {
												setApiEnabled({
													userId: id,
													enabled: !value,
												});
											}}
										>
											{value ? (
												<CheckIcon color={"success"} />
											) : (
												<ClearIcon />
											)}
										</IconButton>
									</Box>
								);
							};
							return (
								<Cell
									handleSetContextMenu={() =>
										setApiEnabled({
											userId: id,
											enabled: !value,
										})
									}
									value={<Button />}
									userId={id}
								/>
							);
						},
					},
					{
						field: "email",
						headerName: "Email",
						width: 275,
						align: "left",
						editable: false,
						renderCell,
					},
					{
						field: "emailVerified",
						headerName: "Verified",

						align: "left",
						editable: false,
						type: "number",
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										value ? (
											<CheckIcon color={"success"} />
										) : (
											<ClearIcon />
										)
									}
									userId={id}
								/>
							);
						},
					},
					{
						field: "publicDisplayName",
						headerName: "Display Name",

						align: "left",
						editable: false,
						type: "number",
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={"@" + value}
									userId={id}
								/>
							);
						},
					},
					{
						field: "createdAt",
						headerName: "Created At",
						width: 125,
						editable: false,
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={new Date(value).toLocaleDateString()}
									userId={id}
								/>
							);
						},
					},
					{
						field: "lastLogin",
						headerName: "Last Login",
						width: 150,
						editable: false,
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										<Typography
											color={
												value > 0 ? "success.main" : ""
											}
										>
											{new Date(
												value
											).toLocaleDateString()}
										</Typography>
									}
									userId={id}
								/>
							);
						},
					},
					{
						field: "tz",
						headerName: "Timezone",
						width: 150,
						editable: false,
						renderCell,
					},
					{
						field: "admin",
						headerName: "Admin",
						width: 100,
						editable: false,
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										value ? (
											<CheckIcon color={"success"} />
										) : (
											<ClearIcon />
										)
									}
									userId={id}
								/>
							);
						},
					},

					{
						field: "name",
						headerName: "Name",
						width: 150,
						editable: false,
						renderCell,
					},

					{
						field: "avatar",
						headerName: "Avatar",
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										<img
											style={{
												width: "100%",
												height: "100%",
												objectFit: "contain",
											}}
											src={value}
										/>
									}
									userId={id}
								/>
							);
						},
					},

					{
						field: "hasChatbotEnabledWorkspace",
						headerName: "Chatbot",
						width: 150,
						editable: false,
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										value ? (
											<CheckIcon color={"success"} />
										) : (
											<ClearIcon />
										)
									}
									userId={id}
								/>
							);
						},
					},
					{
						field: "subscriptionTier",
						headerName: "Subscription Tier",
						type: "number",
						width: 150,
						editable: false,
						renderCell,
					},
					{
						field: "subscribedStorageAllotment",
						headerName: "Subscribed Storage",
						width: 150,
						editable: false,
						type: "number",
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										<Typography
											color={
												value > 0 ? "success.main" : ""
											}
										>
											{convertBytes(value)}
										</Typography>
									}
									userId={id}
								/>
							);
						},
					},
					{
						field: "additionalStorage",
						headerName: "Additional Storage",
						width: 150,
						editable: true,
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										<Typography
											color={
												value > 0 ? "success.main" : ""
											}
										>
											{value}
										</Typography>
									}
									userId={id}
								/>
							);
						},
					},
					{
						field: "totalStorageAllotment",
						headerName: "Total Storage",
						width: 150,

						editable: false,
						type: "number",
						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										<Typography
											color={
												value > 0 ? "success.main" : ""
											}
										>
											{convertBytes(value)}
										</Typography>
									}
									userId={id}
								/>
							);
						},
					},

					{
						field: "usedStorage",
						headerName: "Used Storage",
						width: 150,
						editable: false,
						type: "number",

						renderCell: ({ value, id }) => {
							return (
								<Cell
									handleSetContextMenu={handleSetContextMenu}
									value={
										<Typography
											color={
												value > 0 ? "success.main" : ""
											}
										>
											{convertBytes(value)}
										</Typography>
									}
									userId={id}
								/>
							);
						},
					},
				]}
			/>
		</Box>
	);
}
