import { Auth0Provider, withAuth0, useAuth0 } from "@auth0/auth0-react";
import { useCookies } from "../Cookies";
import { createContext, useContext, useEffect, useState, useRef } from "react";
import LoadingScreen from "../Component/LoadingScreen";
import { useIsSSR } from "react-aria";
import { useMixpanel } from "../Mixpanel";
import { useLogger } from "@/Logger";
// Using SSRUserContext - the server will decode the token generated at login and pass the user down to the client
const SSRUserContext = createContext({
	user: null,
});
export const useSSRUser = () => useContext(SSRUserContext);
export const Auth0SSRUserProvider = ({ children, user }) => {
	return (
		<SSRUserContext.Provider
			value={{
				user,
			}}
		>
			{children}
		</SSRUserContext.Provider>
	);
};

// In case we are on the server,
// check for a user then add it to auth0 before rendering the application
const WithAuth0 = withAuth0(({ auth0, children }) => {
	const { user } = useSSRUser();
	const isSSR = useIsSSR();

	if (!isSSR) {
		return (
			<LoadingScreen loading={auth0.isLoading} message={`Authenticating`}>
				{children}
			</LoadingScreen>
		);
	}

	auth0.user = user;
	auth0.isAuthenticated = !!user;
	return children;
});

// This component will re-render and persist a new token whenever the user changes
const Persistor = ({ children }) => {
	const { setUser: setUserLogging } = useLogger();
	const { user, getAccessTokenSilently, isLoading, isAuthenticated, logout } =
		useAuth0();
	const cookies = useCookies();
	const { setUser } = useMixpanel();
	const isSSR = useIsSSR();
	const [ready, setReady] = useState(false);
	useEffect(() => {
		if (!user || isLoading || !isAuthenticated) return;

		setUser({
			email: user.email,
		});
		setUserLogging({
			user_id: user.email,
			workspace_id: null,
		});
		const asyncEffect = async () => {
			setReady(false);

			let id_token;
			let expires_in;
			try {
				const res = await getAccessTokenSilently({
					detailedResponse: true,
					authorizationParams: {
						scope: "read:current_user offline_access",
					},
					cacheMode: "off",
				});
				id_token = res.id_token;
				expires_in = res.expires_in;
			} catch (e) {
				console.log("error getting token: ", e);
			}
			if (!id_token) {
				return logout({
					logoutParams: {
						returnTo: window.location.origin + "/signout",
					},
				});
			}

			// make cookie work on all subdomains of the current domain

			cookies.set("token", `Bearer ${id_token}`, {
				path: "/",
				sameSite: "strict",
				domain: process.env.REACT_APP_TOKEN_COOKIE_DOMAIN,
				secure: true,
				expires: new Date(Date.now() + expires_in * 1000),
			});
			setReady(true);
		};
		asyncEffect();
	}, [getAccessTokenSilently, user?.sub, isAuthenticated]);

	const waitingForToken = !isSSR && user && !ready;

	return (
		<LoadingScreen
			message="Authenticating"
			loading={
				// no need to show loading screen on the server,
				// the user is already decoded and passed down to the client
				waitingForToken
			}
		>
			<WithAuth0>{children}</WithAuth0>
		</LoadingScreen>
	);
};

const Context = createContext({
	code: null,
});
export const useReferralCode = () => useContext(Context);

export default function AuthContext({ children }) {
	const [code, setCode] = useState();

	return (
		<Context.Provider
			value={{
				code,
			}}
		>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN}
				clientId={process.env.REACT_APP_AUTH0_CLIENTID}
				authorizationParams={{
					redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
				}}
				cacheLocation="localstorage"
				sessionCheckExpiryDays={1}
				useRefreshTokens
				onRedirectCallback={state => {
					if (!state.referralCode) return;
					setCode(state.referralCode);
				}}
			>
				<Persistor>{children}</Persistor>
			</Auth0Provider>
		</Context.Provider>
	);
}
