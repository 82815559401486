import { grey } from "@mui/material/colors";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Root = styled("div")(({ theme }) => ({
	height: "fit-content",
	backgroundColor:
		theme.palette.mode === "light"
			? grey[100]
			: theme.palette.background.default,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

export const Puller = styled(Box)(({ theme }) => ({
	width: 38,
	height: 9,
	backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
	borderRadius: 3,
}));
export const ClickContainerWithPadding = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1),
	width: 300,
	height: 25,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));
