import { useRequester } from "@/Apollo";
import WorkspaceWidgetWrapper from "../Wrapper";
export default function WorkspaceWidgetStats() {
	const { definitions, useQuery, workspace_id } = useRequester();
	const { data } = useQuery(definitions.workspace.query.getWorkspace, {
		variables: {
			id: workspace_id,
		},
	});

	return (
		<WorkspaceWidgetWrapper
			title={"Virtual Assistants"}
			href="/workspace/assistants"
			body={data?.workspace.assistants.length}
		/>
	);
}
