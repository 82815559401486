import { useRequester } from "@/Apollo";
import { useEffect, useState, createContext, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { useCookies } from "@/Cookies";
import ContentIcon from "@mui/icons-material/ContentCopy";
import { StyledSquareIconButton } from "@/Component/Styled/Button";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Typography,
	Box,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	IconButton,
	useMediaQuery,
	Badge,
	styled,
	Tooltip,
	Card,
	CardContent,
	CardMedia,
} from "@mui/material";
import { useSnackbar } from "notistack";
import useLocalStorage from "@/util/useLocalStorage";
const Context = createContext({
	Widget: () => <></>,
});

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: -3,
		top: 13,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}));
export const useContentAdded = () => useContext(Context);
export default function ContentAdded({ children }) {
	const localStorage = useLocalStorage();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { get, set } = useCookies();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));
	const { useSubscription, definitions } = useRequester();
	const [content, setContent] = useState([]);
	let startMiminized = get("contentAddedMinimized");
	if (startMiminized === undefined) startMiminized = true;

	const [minimized, setMinimized] = useState(startMiminized);
	const [open, setOpen] = useState(false);

	useSubscription(definitions.content.subscription.contentAdded, {
		onData: ({ data }) => {
			const content = data.data.contentAdded.content;

			setContent(arr => {
				return [...arr, content];
			});
			const shouldEnqueue = localStorage.get(
				"shouldDisplayContentAddedSnackbar"
			);
			if (shouldEnqueue === false) return;
			const key = enqueueSnackbar(
				<Card>
					<CardContent>
						<Typography>Content Added</Typography>
						<CardMedia
							component="img"
							alt="New content"
							height="140"
							image={content.source.thumbnailUrl}
							title="New content"
						/>
					</CardContent>
				</Card>,
				{
					variant: "info",
					autoHideDuration: 5000,
					onClick: () => closeSnackbar(key),
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "right",
					},
				}
			);
		},
	});
	return (
		<Context.Provider
			value={{
				Widget: () => (
					<Tooltip title={"Recent uploads"}>
						<StyledSquareIconButton
							sx={{
								border: "none",
							}}
							onClick={() => {
								if (!content.length) {
									const key = enqueueSnackbar(
										"No new content",
										{
											variant: "info",
											// limit to 1
											preventDuplicate: true,

											autoHideDuration: 5000,
											onClick: () => {
												closeSnackbar(key);
											},
										}
									);

									return;
								}

								setOpen(true);
								setMinimized(false);
								set("contentAddedMinimized", false);
							}}
						>
							<StyledBadge badgeContent={content.length}>
								<ContentIcon />
							</StyledBadge>
						</StyledSquareIconButton>
					</Tooltip>
				),
			}}
		>
			{children}
			<Dialog
				open={open && !minimized}
				onClose={() => setOpen(false)}
				fullScreen={isMobile}
				fullWidth
			>
				<DialogTitle
					sx={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Typography>New Content Added</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<IconButton
							onClick={() => {
								setMinimized(true);
								set("contentAddedMinimized", true);
							}}
						>
							<MinimizeIcon />
						</IconButton>
						<IconButton
							onClick={() => {
								setContent([]);
								setOpen(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</DialogTitle>

				<DialogContent>
					<DialogContentText>
						<Typography variant="body1">
							The following content has been added to your
							workspace:
						</Typography>
					</DialogContentText>

					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<ImageList>
							{content.map((content, i) => {
								const file = content.source;
								return (
									<ImageListItem
										key={i}
										sx={{
											width: "150px",
											height: "150px",
										}}
									>
										<img
											src={file.thumbnailUrl}
											alt={file.name}
											style={{
												objectFit: "contain",
												width: "100%",
												height: "100%",
											}}
										/>
										<ImageListItemBar
											title={file.name}
											subtitle={file.type}
										/>
									</ImageListItem>
								);
							})}
						</ImageList>
					</Box>
				</DialogContent>
			</Dialog>
		</Context.Provider>
	);
}
