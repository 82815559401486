import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export default function ZoomFactorSelector({ setScale, scale }) {
	const options = [1, 2, 5, 10, 25, 50, 60];
	return (
		<Stack direction="row" spacing={1}>
			{options.map((option, index) => {
				return (
					<Box
						sx={{
							"&:hover": {
								cursor: "pointer",
								backgroundColor: theme =>
									theme.palette.action.selected,

								borderRadius: "5px",
							},
							backgroundColor: theme =>
								scale !== option
									? null
									: theme.palette.action.selected,
						}}
						key={index}
						onClick={() => setScale(option)}
					>
						<Typography
							sx={{
								"&:hover": {
									cursor: "pointer",

									borderRadius: "5px",
								},
								backgroundColor: theme =>
									scale !== option
										? null
										: theme.palette.action.selected,
								color: theme =>
									scale !== option
										? theme.palette.text.primary
										: theme.palette.text.secondary,
							}}
						>
							{option}
						</Typography>
					</Box>
				);
			})}
		</Stack>
	);
}
