import { Box, Tab, Tabs } from "@mui/material";

import CollapseIcon from "@mui/icons-material/ExpandLess";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import { useWorkspaceConfig } from "@/Component/Workspace/Context";

import { useNavigate } from "react-router-dom";
import { LabeledIcon } from "../Common/LabeledIcon/index";

const makeLabel = (expanded, label) => {
	if (expanded) return label;
	return <></>;
};
export default function WorkspaceNavigationDesktop() {
	const { expanded, setExpanded, useLocationValue, views } =
		useWorkspaceConfig();

	const value = useLocationValue();

	const navigate = useNavigate();

	return (
		<Box
			id="workspace-sidebar"
			sx={{
				width: expanded
					? "var(--workspace-sidebar-width-expanded)"
					: "var(--workspace-sidebar-width-collapsed)",
				// minWidth: expanded ? "fit-content" : null,
				display: "flex",
				flexDirection: "column",
				// animation on collapse
				transition: "width 1s ease-in-out",
				border: theme => `1px solid ${theme.palette.divider}`,
			}}
		>
			<Tabs
				orientation="vertical"
				variant="fullWidth"
				onChange={(e, value) => navigate(value)}
				value={value}
			>
				{views.map(({ label, icon: Icon, path }) => (
					<Tab
						sx={{
							width: "100%",
							justifyContent: "flex-start",
							color: theme =>
								value === path
									? theme.palette.action.active
									: theme.palette.action.disabled,
						}}
						key={path}
						icon={
							expanded ? (
								<Icon
									sx={{
										color: theme =>
											value === path
												? theme.palette.action.active
												: theme.palette.action.disabled,
									}}
								/>
							) : (
								<LabeledIcon
									label={label}
									Icon={Icon}
									path={path}
								/>
							)
						}
						iconPosition="start"
						label={makeLabel(expanded, label)}
						value={path}
					/>
				))}
			</Tabs>
			<Box
				sx={{
					display: "flex",
					justifyContent: "right",
					alignItems: "end",
					flexGrow: 1,
				}}
			>
				{expanded ? (
					<CollapseIcon
						sx={{
							rotate: "-90deg",
						}}
						onClick={() => setExpanded(!expanded)}
					/>
				) : (
					<ExpandIcon
						sx={{
							rotate: "-90deg",
						}}
						onClick={() => setExpanded(!expanded)}
					/>
				)}
			</Box>
		</Box>
	);
}
