import { gql } from "@apollo/client";
import { VIRTUAL_ASSISTANT_FRAGMENT } from "../fragment";
export const sendRequest = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	mutation VirtualAssistant_sendRequest(
		$input: VirtualAssistantSendRequestInput!
	) {
		virtualAssistant_sendRequest(input: $input) {
			request {
				...VirtualAssistantFragment
			}
		}
	}
`;
export const acceptInvitation = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	mutation VirtualAssistant_acceptInvitation(
		$input: VirtualAssistantHandleInvitationInput!
	) {
		virtualAssistant_acceptInvitation(input: $input) {
			assistant {
				...VirtualAssistantFragment
			}
		}
	}
`;
export const rejectInvitation = gql`
	mutation VirtualAssistant_rejectInvitation(
		$input: VirtualAssistantHandleInvitationInput!
	) {
		virtualAssistant_rejectInvitation(input: $input) {
			isRemoved
		}
	}
`;
export const removeFromWorkspace = gql`
	mutation VirtualAssistant_remove($input: VirtualAssistantRemoveInput!) {
		virtualAssistant_remove(input: $input) {
			isRemoved
		}
	}
`;

export const addToRole = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	mutation AddAssistantToRole($input: AddAssistantToRoleInput!) {
		addAssistantToRole(input: $input) {
			assistant {
				...VirtualAssistantFragment
			}
		}
	}
`;
export const removeFromRoles = gql`
	${VIRTUAL_ASSISTANT_FRAGMENT}
	mutation RemoveAssistantFromeRoles($input: RemoveAssistantFromRolesInput!) {
		removeAssistantFromRoles(input: $input) {
			assistant {
				...VirtualAssistantFragment
			}
		}
	}
`;
