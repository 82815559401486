import { useRequester } from "../Apollo";
import { useSnackbar } from "notistack";
import { getType } from "mime";
import { EXTENSIONS } from "@/util/allowedFiletypes";
const DopboxIcon = "/icon/dropbox.svg";

export default function useDropboxImport() {
	const { useMutation, definitions } = useRequester();

	const [importContent] = useMutation(
		definitions.content.mutation.importContent
	);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	return {
		Icon: () => {
			return (
				<img
					src={DopboxIcon}
					style={{
						height: "1.25rem",
						width: "auto",
						margin: ".2rem",
					}}
					alt="Dropbox Logo"
				/>
			);
		},
		importContent: () => {
			window.Dropbox.choose({
				multiselect: true,
				extensions: [...EXTENSIONS.IMAGE, ...EXTENSIONS.VIDEO],
				success: async res => {
					const importFiles = res.map(entry => {
						return {
							key: entry.link,
							mimetype: getType(entry.name),
						};
					});

					const { data } = await importContent({
						variables: {
							input: {
								source: "DROPBOX",

								config: {
									importFiles,
								},
							},
						},
						onCompleted: data => {},
						onError: err => {},
					});
					let key = enqueueSnackbar(
						"Importing " +
							data.import.allFiles.length +
							" files from Dropbox",
						{
							variant: "success",
							autoHideDuration: 3000,
							onClick: () => {
								closeSnackbar(key);
							},
						}
					);
				},
				folderselect: false,
				cancel: async res => {
					console.log("Cancelled: ", res);
				},
			});
		},
	};
}
