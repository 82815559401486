export const ALLOWED_FILETYPES = {
	VIDEO: {
		".mp4": "video/mp4",
		".avi": ["video/x-msvideo", "video/avi"],
		".mov": "video/quicktime",
		".mpeg": "video/mpeg",
		".webm": "video/webm",
		".ogv": "video/ogv",
		".mkv": "video/x-matroska",
	},
	IMAGE: {
		".png": "image/png",
		".jpg": "image/jpeg",
		".jpeg": "image/jpeg",
		".gif": "image/gif",
		".webp": "image/webp",
	},
	AUDIO: {
		".wav": "audio/wav",
		".mp3": ["audio/mpeg", "audio/mp3"],
		".weba": "audio/webm",
		".ogg": "audio/ogg",
		".oga": "audio/ogg",
		".flac": ["audio/x-flac", "audio/flac"],
	},
};

export const EXTENSIONS = {
	IMAGE: Object.keys(ALLOWED_FILETYPES.IMAGE),
	VIDEO: Object.keys(ALLOWED_FILETYPES.VIDEO),
	AUDIO: Object.keys(ALLOWED_FILETYPES.AUDIO),
};

export const MIMETYPES = {
	IMAGE: Object.values(ALLOWED_FILETYPES.IMAGE),
	VIDEO: Object.values(ALLOWED_FILETYPES.VIDEO).flatMap(mimetype => {
		if (Array.isArray(mimetype)) return mimetype;
		return [mimetype];
	}),
	AUDIO: Object.values(ALLOWED_FILETYPES.AUDIO).flatMap(mimetype => {
		if (Array.isArray(mimetype)) return mimetype;
		return [mimetype];
	}),
};
