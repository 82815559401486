import AddIcon from "@mui/icons-material/Add";
import {
	IconButton,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Input,
} from "@mui/material";
import SubmitIcon from "@mui/icons-material/Check";
import { useRequester } from "@/Apollo";
import { useState } from "react";
import { useSnackbar } from "notistack";
export default function AddNewWorkspaceButton() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [showDialog, setShowDialog] = useState(false);
	const handleCloseNavMenu = () => setShowDialog(false);

	const { definitions, useMutation, selectWorkspace } = useRequester();
	const [addNew] = useMutation(definitions.workspace.mutation.create);

	const [value, setValue] = useState("");
	return (
		<>
			<Tooltip title="add new">
				<IconButton size="small" onClick={e => setShowDialog(true)}>
					<AddIcon color="success" />
				</IconButton>
			</Tooltip>
			<Dialog open={showDialog} onClose={handleCloseNavMenu}>
				<DialogTitle>Add new workspace</DialogTitle>
				<DialogContent>
					<form
						onSubmit={e => {
							e.preventDefault();
							addNew({
								variables: {
									input: {
										name: e.target.name.value,
									},
								},
								onCompleted: ({
									createWorkspace: { workspace },
								}) => {
									selectWorkspace({ workspace });
								},
								onError: error => {
									enqueueSnackbar(error.message, {
										variant: "error",
									});
								},
							});
						}}
					>
						<DialogContentText>
							Enter the name of the new workspace.
						</DialogContentText>
						<Input
							name="name"
							placeholder="Name"
							inputProps={{
								maxLength: 35,
							}}
							autoFocus
							fullWidth
							required
							value={value}
							onChange={e => setValue(e.target.value)}
							endAdornment={
								<IconButton type="submit" disabled={!value}>
									<SubmitIcon />
								</IconButton>
							}
						/>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
}
