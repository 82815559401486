import {
	Avatar,
	Card,
	CardHeader,
	Typography,
	Divider,
	CardContent,
	Box,
	CardActions,
} from "@mui/material";

import depthEffect from "../../../Theme/sx/depth-effect";

import calculateTimeSince from "../../../util/calculateTimeSince";

import UpdateAction from "./Action/Update";

import EditableAvatar from "@/Component/EditableAvatar";

export default function WorkspaceCard({ workspace }) {
	return (
		<Card
			sx={{
				...depthEffect(),
				maxWidth: 500,
				height: "fit-content",
			}}
		>
			<CardHeader
				className="workspace-card-header"
				avatar={<EditableAvatar type="WORKSPACE" />}
				title={
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<UpdateAction />

						<Typography
							color="textPrimary"
							gutterBottom
							variant="h6"
						>
							{workspace?.name}
						</Typography>
					</Box>
				}
				subheader={
					<>
						<Typography color="textPrimary" variant="body1">
							Created{" "}
							{calculateTimeSince(workspace?.createdAt).agoString}
						</Typography>
						<Typography color="textPrimary" variant="body1">
							{workspace?.assistants.length} members
							{workspace?.assistants.length > 1 ? "s" : ""}
						</Typography>

						<Divider />
					</>
				}
			/>

			<CardContent>
				<Typography color="textPrimary" variant="body1">
					Admin
				</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Avatar
						src={`https://${process.env.REACT_APP_CDN_HOST}/avatar/user/${workspace?.admin.id}`}
					/>

					<Typography color="textPrimary" variant="body1">
						@{workspace?.admin?.public_display_name}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
}
