import { usePublishContentForm } from "../../..";
import {
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemAvatar,
	Stack,
	useMediaQuery,
	List,
	StepLabel,
} from "@mui/material";
import { useRequester } from "../../../../../Apollo";
import IntegrationSelector from "../../../IntegrationSelect";
export default function SourceAccountSelector({ disabled = false }) {
	const mobile = useMediaQuery(theme => theme.breakpoints.down("md"));
	const {
		twitter: { sourceAccount, setSourceAccount },
	} = usePublishContentForm();

	return (
		<Stack maxWidth spacing={2}>
			<StepLabel>Source Account</StepLabel>
			{/* <IntegrationSelector
				destination={"Redgifs"}
				defaultSelected={sourceAccount}
				onSelect={integration => {
					setSourceAccount(integration.username);
				}}
			/> */}
			<IntegrationSelector
				destination={"Imgur"}
				defaultSelected={sourceAccount}
				disabled={disabled}
				onSelect={integration => {
					setSourceAccount(integration.username);
				}}
			/>
		</Stack>
	);
}
