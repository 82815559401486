import React, { useCallback } from "react";
import AddItemIcon from "@mui/icons-material/AddCircleOutline";
import { Menu, MenuList, MenuItem, ListItemIcon } from "@mui/material";
import { useReactFlow } from "reactflow";
import { generateNodeID } from "../../generateNodeID";

export default function ContextMenu({
	anchorPosition,
	open,
	onClose,
	currentReactFlowRef,
}) {
	const { addNodes, project } = useReactFlow();
	const addRenderNode = useCallback(() => {
		const { top, left } = currentReactFlowRef.getBoundingClientRect();
		addNodes({
			sourcePosition: "right",
			position: project({
				x: anchorPosition.left - left - 75,
				y: anchorPosition.top - top,
			}),
			id: generateNodeID(),
			type: "renderNode",
			connectable: true,
		});
		onClose();
	}, [project, anchorPosition]);
	const addPublishNode = useCallback(() => {
		const { top, left } = currentReactFlowRef.getBoundingClientRect();
		addNodes({
			position: project({
				x: anchorPosition.left - left - 75,
				y: anchorPosition.top - top,
			}),
			id: generateNodeID(),
			type: "publishNode",
			connectable: true,
		});
		onClose();
	}, [project, anchorPosition]);
	return (
		<Menu
			anchorReference="anchorPosition"
			anchorPosition={anchorPosition}
			open={open}
			onClose={onClose}
		>
			<MenuList>
				<MenuItem onClick={addRenderNode}>
					<ListItemIcon>
						<AddItemIcon />
					</ListItemIcon>
					Render Node
				</MenuItem>

				<MenuItem onClick={addPublishNode}>
					<ListItemIcon>
						<AddItemIcon />
					</ListItemIcon>
					Publish Node
				</MenuItem>
			</MenuList>
		</Menu>
	);
}
