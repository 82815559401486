import { Card, CardContent, Typography } from "@mui/material";

import depthEffect from "@/Theme/sx/depth-effect";
import { Link } from "react-router-dom";
export default function WorkspaceWidgetWrapper({ title, body, href }) {
	return (
		<Card
			sx={{
				display: "flex",
				flexDirection: "column",
				maxWidth: "250px",
				...depthEffect(1),
			}}
		>
			<Link
				style={{
					width: "100%",
					padding: "1rem",
					fontSize: "1.5rem",
				}}
				to={href}
			>
				{title}
			</Link>

			<CardContent
				sx={{
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
				}}
			>
				<Typography variant="body2" color="text.secondary">
					{body}
				</Typography>
			</CardContent>
		</Card>
	);
}
