import { Stack, Typography } from "@mui/material";
import Joyride from "./Joyride";

import AccountTab from "./Tab/Account";

import SubscriptionQuota from "../../../../Component/SubscriptionQuota";
import { BodyHeight } from "../../../../Layout";

export default function PageSettings() {
	return (
		<BodyHeight
			overflowY="scroll"
			sx={{
				padding: "1rem",
			}}
		>
			<Stack spacing={2}>
				<Typography variant="h5">Account</Typography>
				<AccountTab />
				<Typography variant="h5">Subscription</Typography>
				<SubscriptionQuota />
			</Stack>
			<Joyride />
		</BodyHeight>
	);
}
