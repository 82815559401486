import { Box, Stack, Typography } from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const icons = {
	PENDING: PendingIcon,
	SUCCESS: SuccessIcon,
	FAILED: ErrorIcon,
};
export default function Event({
	continuesAfter,
	continuesPrior,
	event,
	isAllDay,
	localizer,
	slotEnd,
	slotStart,
	title,
}) {
	const Icon = () => {
		const Icon = icons[event.status] || icons.PENDING;
		return <Icon />;
	};

	return (
		<Box>
			<Stack direction="row">
				<Icon />
				<Typography>{event.title}</Typography>
			</Stack>
		</Box>
	);
}
