import { gql } from "@apollo/client";
export const addPaymentMethod = gql`
	mutation AddPaymentMethod {
		addPaymentMethod {
			url
		}
	}
`;

export const manageSubscription = gql`
	mutation ManageSubscription {
		manageSubscription {
			url
		}
	}
`;
export const manageCusomter = gql`
	mutation ManageCustomer {
		manageCustomer {
			url
		}
	}
`;

export const updateSubscription = gql`
	mutation UpdateSubscription($input: UpdateSubscriptionInput) {
		updateSubscription(input: $input) {
			url
		}
	}
`;

export const createCustomer = gql`
	mutation CreateCustomer {
		createCustomer {
			subscription {
				expires_at
				product {
					id
					tier
					name
					description
					image
					plans {
						id
						amount
						currency
						interval
						selected
					}
					quota {
						submissions {
							used
							percentUsed
							canUse
							allotted
							unit
						}
						storage {
							used
							percentUsed
							canUse
							allotted
							unit
						}
						renderHours {
							used
							percentUsed
							canUse
							allotted
							unit
						}
					}
				}
			}
		}
	}
`;
