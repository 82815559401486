import { useRequester } from "../../../../../../Apollo";
import { ContentCard } from "../../../../../../Component/Folders/Card";

import { useFolderContent } from "../../../../../../Component/Folders/TreeView/hook/useFolderContent";

export default function LibraryFolderView() {
	const { selectedFolder } = useFolderContent();

	const { definitions, useQuery } = useRequester();

	const { data } = useQuery(definitions.content.query.folder, {
		variables: {
			folderId: selectedFolder,
		},
	});
	const folderContents = data?.folder.contentConnection.edges || [];

	return (
		<>
			{folderContents.map(({ node: content }) => (
				<ContentCard key={content.id} content={content} />
			))}
		</>
	);
}
