import { styled } from "@mui/material/styles";
import { IconButton, Button } from "@mui/material";

export const StyledSquareIconButton = styled(IconButton)(({ theme }) => ({
	border: "none",
	borderRadius: "4px",
	height: "56px",
	width: "56px",
}));
export const StyledSquareButton = styled(Button)(({ theme }) => ({
	border: "1px solid #fff",
	borderRadius: "4px",
	height: "56px",
	width: "56px",
}));
