export default function calculateTimeSince(timestamp) {
	const now = new Date();
	const then = new Date(timestamp);

	const seconds = Math.floor((now - then) / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(days / 30);
	const years = Math.floor(days / 365);
	let agoString;
	if (years > 0) {
		agoString = `${years} years ago`;
	} else if (months > 0) {
		agoString = `${months} months ago`;
	} else if (weeks > 0) {
		agoString = `${weeks} weeks ago`;
	} else if (days > 0) {
		agoString = `${days} days ago`;
	} else if (days === 0 && hours > 0) {
		agoString = `${hours} hours ago`;
	} else if (days === 0 && hours === 0 && minutes > 0) {
		agoString = `${minutes} minutes ago`;
	} else if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
		agoString = `${seconds} seconds ago`;
	} else {
		agoString = "just now";
	}
	return {
		agoString,
	};
}
