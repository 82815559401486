import { useSnackbar } from "notistack";
import { useRequester } from "../../../../Apollo";
import { Button } from "@mui/material";
import { useAssistantContext } from "@/Component/VirtualAssistant/Assistants";

import { useConfirm } from "@/Confirm";

export default function AssistantRemoveButton() {
	const { confirm } = useConfirm();
	const { selectedAssistant: assistant, setClosed } = useAssistantContext();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useMutation, definitions } = useRequester();
	const [remove] = useMutation(
		definitions.virtualAssistant.mutation.removeFromWorkspace
	);
	return (
		<Button
			variant="contained"
			onClick={async () => {
				const confirmed = await confirm(
					`Are you sure you want to remove ${assistant.public_display_name} from your workspace?`,
					"Remove Assistant"
				);
				if (!confirmed) return;

				remove({
					variables: {
						input: {
							user_id: assistant.user_id,
						},
					},
					refetchQueries: [
						definitions.virtualAssistant.query.assistants,
					],
					onCompleted: () => {
						const key = enqueueSnackbar(
							`Removed ${assistant.public_display_name} from your workspace.`,
							{
								variant: "success",
								onClick: () => closeSnackbar(key),
							}
						);

						setClosed();
					},
					onError: error => {
						const key = enqueueSnackbar(
							"Failed to remove assistant from workspace. If the problem persists please contact support.",
							{
								variant: "error",
								onClick: () => closeSnackbar(key),
							}
						);
					},
				});
			}}
		>
			Remove
		</Button>
	);
}
