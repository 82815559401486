import { Button } from "@mui/material";
import FHTooltip from "../../../../../FHTooltip";
import { useConfirm } from "../../../../../../Confirm";
import { useContentEditor } from "../../../..";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";

export default function NewProjectbutton() {
	const { confirm } = useConfirm();
	const { restartProject } = useContentEditor();

	return (
		<FHTooltip title={"Start new project"}>
			<Button
				className="new-project-btn"
				variant="contained"
				sx={{
					width: "fit-content",
					height: "fit-content",
				}}
				onClick={async () => {
					const confirmed = await confirm(
						"Are you sure you want to start a new project?",
						"Any unsaved changes will be lost."
					);
					if (!confirmed) return;
					restartProject();
				}}
				endIcon={<VideocamOutlinedIcon />}
			>
				New
			</Button>
		</FHTooltip>
	);
}
