import {
	TextField,
	MenuItem,
	ListItemIcon,
	ListItem,
	IconButton,
	Avatar,
	Typography,
	Box,
} from "@mui/material";

import { usePublishContentForm } from "../../../";
import { Autocomplete } from "@mui/material";
import { useCallback } from "react";
import { debounce } from "lodash";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LoadingScreen from "../../../../LoadingScreen";
import PeopleIcon from "@mui/icons-material/People";
import addCommas from "../../../../../util/addCommas";

import useFavoriteSubreddits from "./hooks/useFavorites";
import usePopularSubreddits from "./hooks/usePopular";

import destinations from "../../../../../Destinations";
const Reddit = destinations.find(
	destination => destination.destination === "Reddit"
);
export default function SubredditSelect({ required = true, disabled = false }) {
	const {
		reddit: { subreddit, setSubreddit },
	} = usePublishContentForm();
	const DEFAULT_INPUT_VALUE = subreddit || "";

	// FAVORITE SUBREDDITS
	const {
		loading: favoritesLoading,
		subreddits: favoriteSubreddits,
		fetchMore: fetchMoreFavorites,
		setFavorite: setSubredditFavorite,
	} = useFavoriteSubreddits({
		DEFAULT_INPUT_VALUE,
	});

	// POPULAR SUBREDDITS
	const {
		fetchMore: fetchMorePopular,
		loading: popularLoading,
		subreddits: popularSubreddits,
	} = usePopularSubreddits({
		DEFAULT_INPUT_VALUE,
	});

	const fetchMoreOptions = useCallback(
		debounce(async (_, query) => {
			await Promise.all([
				fetchMoreFavorites({ query }),
				fetchMorePopular({ query }),
			]);
		}, 500),
		[]
	);

	const options = {
		favorites: favoriteSubreddits.map(subreddit => {
			return {
				...subreddit,
				isFavorite: true,
			};
		}),
		popular: popularSubreddits.map(subreddit => {
			return {
				...subreddit,
				isFavorite: false,
			};
		}),
	};

	// remove popular subreddits that are already in favorites
	const filteredPopular = options.popular.filter(
		popularSubreddit =>
			!options.favorites.find(
				favoriteSubreddit =>
					favoriteSubreddit.name === popularSubreddit.name
			)
	);
	const combinedOptions = [...options.favorites, ...filteredPopular];

	return (
		<Autocomplete
			sx={{
				position: "relative",
			}}
			loadingText={
				<LoadingScreen
					loading
					absolute
					transparent
					message={"finding popuplar subreddits"}
				/>
			}
			loading={favoritesLoading || popularLoading || false}
			disabled={disabled}
			fullWidth
			freeSolo
			openOnFocus
			options={combinedOptions || []}
			getOptionLabel={({ display_name }) => display_name}
			onInputChange={fetchMoreOptions}
			onChange={(_, value) => setSubreddit(value)}
			value={subreddit}
			renderInput={params => (
				<TextField
					{...params}
					variant="outlined"
					label="Select a pouplar subreddit"
					InputProps={{
						...params.InputProps,
						required,
						startAdornment: (
							<>
								<Reddit.Icon size={"32px"} />
								<Typography
									sx={{
										marginLeft: 1,
									}}
								>
									r/
								</Typography>
							</>
						),
					}}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<MenuItem
						{...props}
						key={option.id}
						value={option.value}
						sx={{ justifyContent: "space-between" }}
						disableTouchRipple
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "fit-content",
								flexGrow: 0,
							}}
						>
							<IconButton
								onClick={async e => {
									e.stopPropagation();
									await setSubredditFavorite({
										name: option.name,
										favorite: !option.isFavorite,
									});
								}}
							>
								{option.isFavorite ? (
									<StarIcon color="primary" />
								) : (
									<StarBorderIcon />
								)}
							</IconButton>
						</Box>
						<ListItemIcon>
							<Avatar
								variant="square"
								src={
									option.icon_img ||
									option.community_icon ||
									option.banner_img ||
									option.header_img
								}
							/>
						</ListItemIcon>
						<Typography
							sx={{
								color: theme => {
									return theme.palette.text.primary;
								},
							}}
						>
							{option.display_name} (<PeopleIcon />
							{addCommas(option.subscribers)})
						</Typography>
					</MenuItem>
				);
			}}
		/>
	);
}
