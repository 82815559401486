import { useRequester } from "../../../../../../Apollo";
import { useSelectedContent } from "../../../../../SelectedContentContext";
const getOppositeStatus = status => {
	if (status === "NOT_READY") return "READY";
	return "NOT_READY";
};
export default function useApprovalStatusToggle() {
	const { definitions, useMutation, useQuery } = useRequester();
	const { id } = useSelectedContent();
	const { data } = useQuery(definitions.content.query.getContent, {
		fetchPolicy: "cache-only",
		variables: {
			id,
		},
	});

	const [mutate] = useMutation(definitions.common.mutation.approvalStatus);
	return {
		status: data?.content?.approvalStatus,
		toggle: async () => {
			const opposite = getOppositeStatus(data?.content?.approvalStatus);
			await mutate({
				errorPolicy: "all",
				variables: {
					input: {
						options: {
							id,
							status: opposite,
						},
					},
				},
			});
		},
	};
}
