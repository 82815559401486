import { useIsSSR } from "react-aria";
import LibraryFolderView from "../../../../../Component/Folders";
import FoldersContextMenuContext from "../../../../../Component/Folders/ContextMenu";

export default function PageLibraryFolders() {
	const isSSR = useIsSSR();
	if (isSSR) return <></>;
	return (
		<FoldersContextMenuContext>
			<LibraryFolderView />
		</FoldersContextMenuContext>
	);
}
