import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
const NavigationButton = ({ onClick, children }) => {
	const theme = useTheme();

	return (
		<Button
			sx={{
				color: theme.palette.text.primary,
				backgroundColor: "transparent",
				"&:hover": {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.text.primary,
				},
			}}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};
export default function Toolbar({
	date,
	label,
	localizer,
	onNavigate,
	onView,
	view,
	views,
	children,
}) {
	return (
		<Grid
			container
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Grid item>
				<Stack direction="row">
					<NavigationButton onClick={() => onNavigate("PREV")}>
						Back
					</NavigationButton>
					<NavigationButton onClick={() => onNavigate("TODAY")}>
						Today
					</NavigationButton>

					<NavigationButton onClick={() => onNavigate("NEXT")}>
						Next
					</NavigationButton>
				</Stack>
			</Grid>
			<Grid item>
				<Typography>{label}</Typography>
			</Grid>

			<Grid item>
				<Stack direction={"row"}>
					{views.map(view => (
						<NavigationButton
							key={view}
							onClick={() => onView(view)}
						>
							{view}
						</NavigationButton>
					))}
				</Stack>
			</Grid>
		</Grid>
	);
}
