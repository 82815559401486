import { createContext, useContext } from "react";
import {
	DIALOG_DEFAULT_OPEN,
	TOOLBAR_ACTIONS_VIEW_DEFAULT_EXPANDED,
	TOOLBAR_ACTIONS_WIDTH,
	TOOLBAR_VIEWS,
	TOOLBAR_VIEW_WIDTH,
	TRANSITION_SPEEDS,
	TRANSITION_TYPES,
	POSITIONS,
} from "./constants";
import { Project } from "./types";

export const Context = createContext({
	joyride: {
		ref: null,
		nextStep: () => {},
		run: () => {},
		resetProgress: async () => {},
	},
	setParentContentId: id => {},
	previewStatus: {
		time: 0,
		isPlaying: false,
		action: null,
	},
	projectId: null,
	projectTitle: null,
	newProjectId: () => {},
	setQuality: () => {},
	setResolution: () => {},
	setFPS: () => {},

	setPreviewStatus: ({ time = 0, isPlaying = false, action = null }) => {},
	timelineEngine: null,
	open: DIALOG_DEFAULT_OPEN,
	setOpen: () => {},
	drawerOpen: false,
	setDrawerOpen: () => {},
	toolbarActionsWidth: TOOLBAR_ACTIONS_WIDTH,
	toolbarViewWidth: TOOLBAR_VIEW_WIDTH,
	toolbarTotalWidth: TOOLBAR_ACTIONS_WIDTH + TOOLBAR_VIEW_WIDTH,
	toolbarViewExpanded: TOOLBAR_ACTIONS_VIEW_DEFAULT_EXPANDED,
	setToolbarViewExpanded: () => {},
	toolbarViews: TOOLBAR_VIEWS,
	onView: TOOLBAR_VIEWS[0],
	setOnView: () => {},
	IconButton: () => {
		return <button>outside context</button>;
	},
	variables: new Project(),

	updateTitle: title => {},

	addTrack: ({ assetType, file, id, name }) => {},
	addTracks: ({ assets }) => {},
	moveTrack: ({ trackIndex, direction }) => {},
	deleteTrack: ({ id }) => {},
	updateAspectRatio: ({ aspectRatio }) => {},
	selectTrackAsset: ({ assetIndex, trackIndex }) => {},
	splitTrackAsset: ({ assetIndex, trackIndex, time }) => {},
	removeAssetFromTrack: ({ assetIndex, trackIndex }) => {},
	updateTrackAssetTransitionType: ({
		assetIndex,
		trackIndex,
		which,
		type,
	}) => {},
	updateTrackAssetTransitionSpeed: ({
		assetIndex,
		trackIndex,
		which,
		speed,
	}) => {},
	updateTrackAssetOpacity: ({ assetIndex, trackIndex, opacity }) => {},
	updateTrackAssetScale: ({ assetIndex, trackIndex, scale }) => {},
	updateTrackAssetPosition: ({ assetIndex, trackIndex, position }) => {},
	updateTrackAssetFit: ({ assetIndex, trackIndex, fit }) => {},

	muteTrackAsset: ({ assetIndex, trackIndex }) => {},
	updateTimeline: tracks => {},
	updateWatermarkType: type => {},
	updateWatermarkTransitionSpeed: (speed, inOut) => {},
	updateWatermarkTransitionType: (type, inOut) => {},
	updateWatermarkOpacity: opacity => {},
	updateWatermarkPosition: position => {},
	previewingAsset: null,
	setPreviewingAsset: asset => {},
	createVersion: () => {},
	validateInput: variables => {
		return {
			isValid: false,
			reason: "out of context",
			variables: null,
		};
	},
	loading: false,
	transitionTypes: TRANSITION_TYPES,
	transitionSpeeds: TRANSITION_SPEEDS,
	positions: POSITIONS,
	loadProject: project => {},
	updateProjectTitle: title => {},
	mobile: false,
	restartProject: () => {},
});

export const useContentEditor = () => useContext(Context);
