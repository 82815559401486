import { memo, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { useTheme, Typography, CardContent, Card, Box } from "@mui/material";
import DestinationSelector from "./DestinationSelector";
import IntegrationSelector from "./IntegrationSelector";
import Toolbar from "../Toolbar";

export default memo(props => {
	const { data, isConnectable, nodeId } = props;
	const theme = useTheme();
	const [destination, setDestination] = useState(data?.publish?.destination);
	const [integration, setIntegration] = useState(data?.publish?.integration);
	const { setNodes, getNodes } = useReactFlow();

	useEffect(() => {
		const publishOptions = {};
		if (integration) {
			publishOptions.destination = destination.destination.toUpperCase();
			publishOptions.integration = integration;
		}
		setNodes(
			getNodes().map(node => {
				if (node.id === nodeId) {
					return {
						...node,
						data: {
							...node.data,
							publish: publishOptions,
						},
					};
				}
				return node;
			})
		);
	}, [integration]);

	return (
		<>
			<Handle
				type="target"
				position={Position.Left}
				style={{ background: theme.palette.primary.main }}
				onConnect={params => console.log("handle onConnect", params)}
				isConnectable={isConnectable}
			/>
			<Toolbar {...props} />
			<Card
				sx={{
					backgroundColor: theme.palette.background.default,
					borderRadius: "5px",
					padding: "1rem",
					border: "1px solid black",
					maxWidth: "100vw",
					width: "fit-content",
				}}
			>
				<CardContent>
					<Typography
						sx={{ fontSize: 14 }}
						color="text.secondary"
						gutterBottom
					>
						Publish
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<DestinationSelector
							selected={destination}
							onSelect={destination => {
								setDestination(destination);
								setIntegration(null);
							}}
						/>
						{destination && (
							<IntegrationSelector
								selected={integration}
								onSelect={setIntegration}
								destination={destination}
							/>
						)}
					</Box>
				</CardContent>
			</Card>
			<Handle
				type="source"
				position={Position.Right}
				id="a"
				style={{ background: theme.palette.primary.main }}
				isConnectable={isConnectable}
			/>
		</>
	);
});
