import { Toolbar, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useContentEditor } from "../";
import MenuIcon from "@mui/icons-material/Menu";

export default function ContentEditorTitleBar() {
	const { setOpen } = useContentEditor();
	const handleDrawerToggle = () => setOpen(open => !open);
	return (
		<Toolbar
			sx={{
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Typography variant="h6" noWrap component="div">
				Media Editor
			</Typography>
			<IconButton
				color="inherit"
				aria-label="open drawer"
				edge="end"
				onClick={handleDrawerToggle}
				sx={{
					ml: 2,
				}}
			>
				<CloseIcon />
			</IconButton>
		</Toolbar>
	);
}
