import { gql } from "@apollo/client";
import { WORKSPACE_ROLE_FIELDS } from "../../rbac/fragment";
export const VIRTUAL_ASSISTANT_FRAGMENT = gql`
	fragment VirtualAssistantFragment on VirtualAssistant {
		invited_at
		joined_at
		public_display_name
		roles {
			id
			workspace_id
			name
			color
			admin
			permissions {
				integrations {
					read
					write
					delete
				}
				assets {
					read
					write
					delete
				}
				render {
					read
					write
					delete
				}
				submission {
					read
					write
					delete
				}
				settings {
					read
					write
					delete
				}
				assistants {
					read
					write
					delete
				}
				projects {
					read
					write
					delete
				}
				automations {
					read
					write
					delete
				}
				folders {
					read
					write
					delete
				}
				notifications {
					read
					write
					delete
				}
			}
		}
		user_id
		workspace_id
		workspace_name
	}
`;
export const WORKSPACE_ADMIN_FRAGMENT = gql`
	fragment WorkspaceAdminFragment on WorkspaceAdmin {
		public_display_name
		user_id
		workspace_id
		workspace_name
	}
`;
