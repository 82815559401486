import { Card, CardHeader, CardActions, Avatar, Button } from "@mui/material";
import { useRequester } from "@/Apollo";
export default function Connection({ connection }) {
	const { client_id, createdAt, name, updatedAt } = connection;
	const { useMutation, definitions } = useRequester();
	const [remove] = useMutation(definitions.oauth.mutation.removeConnection, {
		variables: { input: { client_id } },
		refetchQueries: [{ query: definitions.oauth.query.connections }],
	});

	return (
		<Card
			sx={{
				width: "100%",
				maxWidth: "400px",
				margin: "1rem auto",
			}}
		>
			<CardHeader
				avatar={<Avatar>{name[0]}</Avatar>}
				title={name}
				subheader={new Date(createdAt).toLocaleDateString("en-US", {
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
				})}
			/>

			<CardActions>
				<Button
					size="small"
					variant={"outlined"}
					color={"secondary"}
					onClick={() => {
						console.log("disconnecting", connection);
						remove();
					}}
				>
					Disconnect
				</Button>
			</CardActions>
		</Card>
	);
}
