import {
	useMemo,
	useState,
	useCallback,
	useEffect,
	useContext,
	useRef,
} from "react";
import { useRequester } from "@/Apollo";
import { Context } from "@/ContentSearch/Context/createContext";
import { getValue } from "@/ContentSearch/Context/valueGetters";
import { createInput } from "@/ContentSearch/Context/createInput";
import { useAvailableFilters } from "@/ContentSearch/Context/filters";

import { debounce } from "lodash";
import LoadingScreen from "@/Component/LoadingScreen";
export const useContentSearch = () => useContext(Context);

const useInput = ({ defaultFilters, defaultQuery, defaultLimit }) => {
	const availableFilters = useAvailableFilters();

	const defaultInput = useMemo(() => {
		if (!availableFilters) return;
		return createInput({
			filters: defaultFilters,
			query: defaultQuery,
			limit: defaultLimit,
			availableFilters,
		});
	}, [defaultFilters, availableFilters]);
	const [input, setInput] = useState(null);
	useEffect(() => {
		if (!defaultInput) return;
		setInput(defaultInput);
	}, [defaultInput]);

	return { state: [input, setInput], availableFilters };
};
export default function ContentSearch({
	children,
	defaultFilters,
	defaultLimit,
	defaultQuery,
	onStateChange = async () => {},
}) {
	Context.displayName = "ContentSearch";
	const { definitions, useLazyQuery } = useRequester();

	const {
		state: [input, setInput],
		availableFilters,
	} = useInput({
		defaultFilters,
		defaultQuery,
		defaultLimit,
	});

	const [
		refetch,
		{ loading: _loading, data: _data, previousData, called, fetchMore },
	] = useLazyQuery(definitions.content.query.search, {
		fetchPolicy: "network-only",
		errorPolicy: "all",
		onCompleted: onStateChange,
		variables: {
			input,
		},
	});

	// use previous data while latest query is loading to prevent the UI from flashing
	const data = _data || previousData;
	const [loading, setLoading] = useState(_loading);

	const pageRef = useRef(input?.page || 1);
	useEffect(() => {
		pageRef.current = input?.page;
	}, [input]);

	const handleRefetch = async input => {
		setLoading(true);
		await refetch();
		await onStateChange();
		setLoading(false);
	};
	const debouncedRefetch = useCallback(
		debounce(() => {
			handleRefetch(input);
		}, 500),
		[input]
	);

	// Fetch data on mount, then again whenever the input is updated
	useEffect(() => {
		if (!input) return;
		debouncedRefetch();
	}, [input]);

	const totalCount = useMemo(() => {
		// only re-calculate the total count if we are on the first page
		return data?.contentSearch.totalCount || 0;
	}, [input?.page === 1, data]);
	return (
		<LoadingScreen
			loading={!called}
			transparent={true}
			message={`searching content library`}
		>
			{called && (
				<Context.Provider
					value={{
						displayName: Context.displayName,
						...getValue({
							input,
							setInput,
							data,
							loading,
							totalCount,
							defaultFilters,
							defaultQuery,
							defaultLimit,
							availableFilters,
							page: pageRef.current,
						}),
						fetchMore: async () => {
							if (data.contentSearch.edges.length >= totalCount)
								return;
							pageRef.current++;
							setLoading(true);
							await fetchMore({
								variables: {
									input: {
										...input,
										page: pageRef.current,
									},
								},
								updateQuery: (
									previousResult,
									{ fetchMoreResult }
								) => {
									if (!fetchMoreResult) return previousResult;
									return {
										contentSearch: {
											...fetchMoreResult.contentSearch,
											edges: [
												...previousResult.contentSearch
													.edges,
												...fetchMoreResult.contentSearch
													.edges,
											],
										},
									};
								},
							});
							setLoading(false);
						},
					}}
				>
					{children}
				</Context.Provider>
			)}
		</LoadingScreen>
	);
}
