import { isTypeOf } from "@/ContentSearch/util/isTypeOf";
import { createInput } from "../createInput";
export const handleUpdateInput = (
	{ filter, page, query },
	input,
	setInput,
	availableFilters
) => {
	const updatedInput = Object.assign({}, input);
	if (filter) {
		const [key, value] = filter;

		const found = availableFilters.find(f => f.name === key);

		if (!found) throw new Error(`Filter ${key} not found`);
		const typeofValue = typeof value;

		if (!isTypeOf(value, found.type)) {
			throw new Error(
				`Filter ${key} is of type ${typeofValue} but should be ${found.type}`
			);
		}
		updatedInput.filters[key] = value;
	}
	if (typeof query === "string") updatedInput.query = query;
	if (typeof page === "number") updatedInput.page = page;
	setInput(updatedInput);
};

const formatTime = time => {
	if (time > 60) {
		return `${Math.floor(time / 60)}m ${time % 60}s`;
	}
	return `${time} seconds`;
};
export const getActiveFilters = (input, setInput, availableFilters) => {
	return Object.entries(input.filters).reduce((acc, [key, value]) => {
		const found = availableFilters.find(af => af.name === key);

		if (!found) return acc;
		if (value === found.default) return acc;

		const clear = () => {
			handleUpdateInput(
				{
					filter: [key, found.default],

					page: 1,
				},
				input,
				setInput,
				availableFilters
			);
		};

		if (found.type === "number") {
			return [
				...acc,
				{
					filter: key,
					filterLabel: found.label,
					value,
					valueLabel: formatTime(value),
					clear,
				},
			];
		}
		if (found.type === "array") {
			if (!value.length) return acc;
			return [
				...acc,
				{
					filter: key,
					filterLabel: found.label,
					value,
					valueLabel: value.toString(),
					clear: item => {
						handleUpdateInput(
							{
								filter: [key, value.filter(v => v !== item)],
								page: 1,
							},
							input,
							setInput,
							availableFilters
						);
					},
				},
			];
		}
		if (found.type === "date") {
			return [
				...acc,
				{
					filter: key,
					filterLabel: found.label,
					value,
					valueLabel: value,
					clear,
				},
			];
		}

		return [
			...acc,
			{
				filter: key,
				filterLabel: found.label,
				value,
				valueLabel: found.options.find(option => option.value === value)
					.label,
				clear,
			},
		];
	}, []);
};

export const getValue = ({
	input,
	setInput,
	data,
	loading,
	totalCount,
	defaultFilters,
	defaultQuery,
	defaultLimit,
	availableFilters,
	page,
}) => {
	const value = {
		setPage: page => {
			handleUpdateInput(
				{
					page,
				},
				input,
				setInput
			);
		},
		setFilter: (key, value) => {
			handleUpdateInput(
				{
					filter: [key, value],
					page: 1,
				},
				input,
				setInput,
				availableFilters
			);
		},
		nextPage: () => {
			handleUpdateInput(
				{
					page: input.page + 1,
				},
				input,
				setInput,
				availableFilters
			);
		},
		goToPage: page => {
			handleUpdateInput(
				{
					page,
				},
				input,
				setInput,
				availableFilters
			);
		},
		search: value => {
			handleUpdateInput(
				{
					query: value,
					page: 1,
				},
				input,
				setInput,
				availableFilters
			);
		},
		clearFilters: () =>
			setInput(
				createInput({
					filters: defaultFilters,
					query: defaultQuery,
					limit: defaultLimit,
					availableFilters,
				})
			),
		activeFilters: getActiveFilters(input, setInput, availableFilters),
		availableFilters,
		filters: input.filters,
		query: input.query,
		loading,
		responseTimeMS: data?.contentSearch.responseTimeMS || 0,
		totalCount,
		page,
		totalPages:
			Math.ceil(data?.contentSearch.totalCount / input.limit) || 0,
		hasNextPage: data?.contentSearch.pageInfo.hasNextPage || false,
		hasPreviousPage: data?.contentSearch.pageInfo.hasPreviousPage || false,
		content: data?.contentSearch.edges
			.map(edge => edge.node)
			.filter(content => content !== null),
	};
	return value;
};
