import { gql } from "@apollo/client";
export const update = gql`
	mutation UpdateContent($input: UpdateContentInput!) {
		updateContent(input: $input) {
			content {
				id
				title
				metadataTags {
					id
				}
				watermarkPosition
				watermarkType
			}
		}
	}
`;

export const createVersion = gql`
	mutation ContentVersionCreate($input: ContentVersionCreateInput!) {
		contentVersionCreate(input: $input) {
			contentVersion {
				createdAt
				id
			}
		}
	}
`;

export const importContent = gql`
	mutation Import($input: ImportInput!) {
		import(input: $input) {
			import {
				id
				created_at
				completed_at
				source
				status
			}
			allFiles {
				id
				created_at
				completed_at
				status
				import_id
				key
				uploaded_at
			}
		}
	}
`;
export const deleteProject = gql`
	mutation DeleteProject($input: DeleteProjectInput!) {
		deleteProject(input: $input) {
			isDeleted
		}
	}
`;

export const saveProject = gql`
	mutation SaveProject($input: SaveProjectInput!) {
		saveProject(input: $input) {
			project {
				id
				title
				content {
					approvalStatus
					assetStatus
					createdAt
					id
					parent_id
					isMetadataReviewed
					title
					updatedAt
					watermarkPosition
					watermarkType
					source {
						... on ImageFile {
							id
							mimetype
							assetType
							url
							thumbnailUrl
							duration
							name
							isProcessed
							width
							height
							size
							aspectRatio
							exif {
								create_date
								data
								gps_latitude
								gps_longitude
							}
							extension
						}
						... on VideoFile {
							id
							mimetype
							assetType
							url
							name
							isProcessed
							width
							height
							size
							thumbnailUrl
							framecaptureUrl
							timelineThumbnailUrls
							duration
							aspectRatio
							exif {
								create_date
								data
								gps_latitude
								gps_longitude
							}
							extension
						}
						... on AudioFile {
							id
							assetType
							url
							name
							isProcessed
							duration
							extension
							exif {
								create_date
								data
								gps_latitude
								gps_longitude
							}
						}
					}
					matchesConnection {
						matches {
							exact_match
							hashset_match
							asset {
								url
								assetType
								name
							}
						}
					}
					metadata {
						montage
						overlayAudio
						overlayText
						publishedToReddit
						publishedToRedditTimestamp
						publishedToRedgifs
						publishedToRedgifsTimestamp
						titleRecommended
						trimmed
						watermarkCSExists
						watermarkOGExists
					}
					metadataTags {
						id
						isWorkspaceTag
						source
						value
					}
				}
				timeline {
					tracks {
						name
						assetType
						assets {
							id
							start
							volume
							transition {
								in {
									type
									speed
								}
								out {
									type
									speed
								}
							}
							trim {
								from
								to
							}
						}
					}
				}
			}
		}
	}
`;

export const folderCreate = gql`
	mutation FolderCreate($input: FolderCreateInput!) {
		folderCreate(input: $input) {
			folder {
				id
				name
				createdAt
			}
		}
	}
`;
export const folderUpdate = gql`
	mutation FolderUpdate($input: FolderUpdateInput!) {
		folderUpdate(input: $input) {
			folder {
				id
				name
				createdAt
			}
		}
	}
`;

export const folderDelete = gql`
	mutation FolderDelete($input: FolderDeleteInput!) {
		folderDelete(input: $input) {
			isDeleted
		}
	}
`;

export const folderAddContent = gql`
	mutation FolderAddContent($input: FolderAddContentInput!) {
		folderAddContent(input: $input) {
			folder {
				createdAt
				id
				name
				contentConnection {
					totalCount
					edges {
						cursor
						node {
							approvalStatus
							assetStatus
							createdAt
							id
							parent_id
							isMetadataReviewed
							title
							updatedAt
							watermarkPosition
							watermarkType
							source {
								... on ImageFile {
									id
									mimetype
									assetType
									url
									thumbnailUrl
									duration
									name
									isProcessed
									width
									height
									size
									aspectRatio
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
									extension
								}
								... on VideoFile {
									id
									mimetype
									assetType
									url
									name
									isProcessed
									width
									height
									size
									thumbnailUrl
									framecaptureUrl
									timelineThumbnailUrls
									duration
									aspectRatio
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
									extension
								}
								... on AudioFile {
									id
									assetType
									url
									name
									isProcessed
									duration
									extension
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
								}
							}
							matchesConnection {
								matches {
									exact_match
									hashset_match
									asset {
										url
										assetType
										name
									}
								}
							}
							metadata {
								montage
								overlayAudio
								overlayText
								publishedToReddit
								publishedToRedditTimestamp
								publishedToRedgifs
								publishedToRedgifsTimestamp
								titleRecommended
								trimmed
								watermarkCSExists
								watermarkOGExists
							}
							metadataTags {
								id
								isWorkspaceTag
								source
								value
							}
						}
					}
				}
			}
		}
	}
`;

export const folderRemoveContent = gql`
	mutation FolderRemoveContent($input: FolderRemoveContentInput!) {
		folderRemoveContent(input: $input) {
			folder {
				createdAt
				id
				name
				contentConnection {
					totalCount
					edges {
						cursor
						node {
							approvalStatus
							assetStatus
							createdAt
							id
							parent_id
							isMetadataReviewed
							title
							updatedAt
							watermarkPosition
							watermarkType
							source {
								... on ImageFile {
									id
									mimetype
									assetType
									url
									thumbnailUrl
									duration
									name
									isProcessed
									width
									height
									size
									aspectRatio
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
									extension
								}
								... on VideoFile {
									id
									mimetype
									assetType
									url
									name
									isProcessed
									width
									height
									size
									thumbnailUrl
									framecaptureUrl
									timelineThumbnailUrls
									duration
									aspectRatio
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
									extension
								}
								... on AudioFile {
									id
									assetType
									url
									name
									isProcessed
									duration
									extension
									exif {
										create_date
										data
										gps_latitude
										gps_longitude
									}
								}
							}
							matchesConnection {
								matches {
									exact_match
									hashset_match
									asset {
										url
										assetType
										name
									}
								}
							}
							metadata {
								montage
								overlayAudio
								overlayText
								publishedToReddit
								publishedToRedditTimestamp
								publishedToRedgifs
								publishedToRedgifsTimestamp
								titleRecommended
								trimmed
								watermarkCSExists
								watermarkOGExists
							}
							metadataTags {
								id
								isWorkspaceTag
								source
								value
							}
						}
					}
				}
			}
		}
	}
`;

export const shareToChatApp = gql`
	mutation ShareToChatApp($input: ShareToChatAppInput!) {
		shareToChatApp(input: $input) {
			timestamp
			updated {
				id
				shared
			}
		}
	}
`;
