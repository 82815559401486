import InstantSearchContextProvider from "../../InstantSearch";
import { Configure } from "react-instantsearch-hooks-web";
import SearchBox from "../../InstantSearch/view/SearchBox";

export const ContentAutocompleteSearchProvider = ({
	children,
	filters = `content.asset_status:ENABLED`,
}) => {
	return (
		<InstantSearchContextProvider>
			<Configure distinct filters={filters} />
			{children}
		</InstantSearchContextProvider>
	);
};

export default function ContentAutoComplete({
	defaultRefinement,
	onSelect,
	label = "Search For Content",
}) {
	return (
		<SearchBox
			label={label}
			defaultRefinement={defaultRefinement}
			onSelect={onSelect}
			searchAsYouType
		/>
	);
}
