import { useState } from "react";
import { useRequester } from "../../../../Apollo";
import { useNavigate } from "react-router-dom";
import {
	Grid,
	Typography,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Box,
	CardActionArea,
	Stack,
	Accordion,
	AccordionSummary,
} from "@mui/material";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import { useEffect } from "react";
import { useSelectedContent } from "../../../SelectedContentContext";
const ContentCard = ({ content }) => {
	const navigate = useNavigate();
	const thumbnail = content?.source?.thumbnailUrl;
	const name = content?.title;
	return (
		<Card
			sx={{
				height: "fit-content",
				width: "fit-content",
			}}
		>
			<CardHeader title={name} subheader={content?.source?.name} />
			<CardContent
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					// fit content
				}}
			>
				<CardActionArea
					onClick={() => navigate(`/content/${content.id}`)}
				>
					{thumbnail ? (
						<CardMedia
							component="img"
							image={thumbnail}
							sx={{
								objectFit: "contain",
								height: "12vh",
								width: "fit-content",
								maxWidth: "100%",
								marginX: "auto",
								borderRadius: 1,
							}}
						/>
					) : (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "12vh",
								width: "12vh",

								marginX: "auto",
								borderRadius: 1,
							}}
						>
							<BrokenImageOutlinedIcon fontSize={"large"} />
						</Box>
					)}
				</CardActionArea>
			</CardContent>
		</Card>
	);
};
export default function RelatedContentTab() {
	const { id } = useSelectedContent();
	const { definitions, useQuery, useLazyQuery } = useRequester();

	const { data: currentPageContentData } = useQuery(
		definitions.content.query.getContent,
		{
			fetchPolicy: "cache-only",
			variables: {
				id,
			},
		}
	);

	const { data: relatedContentData } = useQuery(
		definitions.content.query.getContentVersions,
		{
			fetchPolicy: "network-first",
			variables: {
				contentId: id,
			},
		}
	);

	const [getRelatedContent, { data: parentContentData }] = useLazyQuery(
		definitions.content.query.getContentVersions
	);

	useEffect(() => {
		if (currentPageContentData.content.parent_id) {
			getRelatedContent({
				variables: {
					contentId: currentPageContentData.content.parent_id,
				},
			});
		}
	}, [parentContentData]);

	const versions = relatedContentData?.content.versionsConnection.edges;
	const parent = parentContentData?.content;

	return (
		<Card>
			<Accordion defaultExpanded>
				<AccordionSummary>
					<Typography>Parent Content</Typography>
				</AccordionSummary>
				{parent ? (
					<ContentCard content={parent} />
				) : (
					<Typography>None</Typography>
				)}
			</Accordion>
			<Accordion defaultExpanded>
				<AccordionSummary>
					<Typography>
						Related Content {`(${versions?.length || "-"})`}
					</Typography>
				</AccordionSummary>
				<Stack direction="row">
					{versions?.map(({ node: content }) => {
						return (
							<Grid item key={content.id}>
								<ContentCard content={content} />
							</Grid>
						);
					})}
				</Stack>
			</Accordion>
		</Card>
	);
}
