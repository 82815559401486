import { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
	Button,
} from "@mui/material";
import { useRequester } from "../../../../Apollo";
import { useSnackbar } from "notistack";

export default function InvitationButton() {
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { useMutation, definitions } = useRequester();
	const [sendRequest] = useMutation(
		definitions.virtualAssistant.mutation.sendRequest
	);

	const onClose = () => setOpen(false);

	return (
		<>
			<Button
				className="virtual-assistant-invite-button"
				variant="contained"
				onClick={() => setOpen(true)}
			>
				Invite Member
			</Button>
			<Dialog
				open={open}
				onClose={onClose}
				component={"form"}
				onSubmit={e => {
					e.preventDefault();

					const display_name = e.target.display_name.value;
					sendRequest({
						variables: {
							input: {
								display_name,
							},
						},
						onCompleted: () => {
							let key = enqueueSnackbar(
								`Invited ${display_name} to be a virtual assistant`,
								{
									variant: "success",
									onClick: () => closeSnackbar(key),
								}
							);
							onClose();
						},
						refetchQueries: [
							definitions.virtualAssistant.query.requests,
						],
						onError: error => {
							console.log("error: ", error);
							let key = enqueueSnackbar(error.message, {
								variant: "error",
								onClick: () => closeSnackbar(key),
							});
							onClose();
						},
					});
				}}
			>
				<DialogTitle>Invite a Virtual Assistant</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Enter the display name of the virtual assistant you
						would like to invite.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						name="display_name"
						label="Display Name"
						type="text"
						required
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button variant={"contained"} onClick={onClose}>
						Cancel
					</Button>
					<Button variant={"contained"} type="submit">
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
