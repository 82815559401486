import { useMultiselectContent } from "../../../MultiselectContent";
import { StyledSquareButton } from "../../../Component/Styled/Button";
import useLibraryMediaQuery from "../useIsWidescreen";
export default function MultiSelectButton() {
	const { showMenu, selected, MenuDialog } = useMultiselectContent();
	const { isMD } = useLibraryMediaQuery();
	if (!isMD) return null;

	return (
		<>
			<MenuDialog />
			<StyledSquareButton
				className="library-multiselect-menu-button"
				title="Multiselect Options"
				onClick={() => showMenu(true)}
				disableFocusRipple
				color="inherit"
				variant="contained"
				sx={{
					mr: 1,
					width: "fit-content",
				}}
			>
				{`${selected.size} selected`}
			</StyledSquareButton>
		</>
	);
}
